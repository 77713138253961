import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ro from './ro.json';
import hu from './hu.json';
import en from './en.json';

export default i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    ro: {
      translation: ro,
    },
    en: {
      translation: en,
    },
    hu: {
      translation: hu,
    },
  },
  fallbackLng: 'en',
  react: {
    useSuspense: false,
  },
  detection: {
    order: ['localStorage', 'lang'],
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'lang',
    caches: ['localStorage'],
  },
});

import { WorkTypeUiModel } from './worktype/WorkTypeUiModel';
import { Extra } from '../../domain/Extra';
import { Template } from '../../domain/worktype/templates/Template';
import { Gender } from '../../domain/Gender';
import { WorkPhaseGroup } from '../../domain/WorkPhaseGroup';
import { PhaseUiModel } from './PhaseUiModel';

export class CaseDetail {
  id: number;
  clientName: string;
  clientId?: number;
  clientCompanyId?: number;
  patientName: string;
  description: string;
  patientAge?: number;
  patientGender: Gender['id'];
  selectedLabWork: number;
  selectedTeeth: { teeth: number; quarter: number };
  status: number;
  workSheetEditable: boolean;
  tooths: Tooths;
  labworks: DetailsLabWork[];

  static newInstanceFrom(cd: CaseDetail) {
    const _cd = new CaseDetail();

    _cd.id = cd.id;
    _cd.clientName = cd.clientName;
    _cd.clientId = cd.clientId;
    _cd.clientCompanyId = cd.clientCompanyId;
    _cd.description = cd.description;
    _cd.patientName = cd.patientName;
    _cd.patientAge = cd.patientAge;
    _cd.patientGender = cd.patientGender;
    _cd.selectedLabWork = cd.selectedLabWork;
    _cd.selectedTeeth = cd.selectedTeeth;
    _cd.status = cd.status;
    _cd.workSheetEditable = cd.workSheetEditable;
    _cd.tooths = cd.tooths;
    _cd.labworks = cd.labworks;
    return _cd;
  }
}

export class Tooths {
  upper: {
    id?: number;
    width: number;
    teethp: number;
    type: number;
    labwork: number;
    quarter: number;
    leftConnectionType: number;
    cwidth: string;
    height: string;
    left: string;
    top: string;
    ext: Array<Extra>;
    selectionVerticalOffset: number;
  }[];
  lower: {
    id?: number;
    width: number;
    teethp: number;
    type: number;
    labwork: number;
    quarter: number;
    leftConnectionType: number;
    cwidth: string;
    height: string;
    left: string;
    top: string;
    ext: Array<Extra>;
    selectionVerticalOffset: number;
  }[];
}

export class DetailsLabWork {
  labWorkId: number;
  workType: WorkTypeUiModel;
  phases?: WorkPhaseGroup[] | null;
  editedPhases?: Array<PhaseUiModel> | null;
  labworkName?: string;
  extensions: number[];
  extras: Array<Extra>;

  constructor(
    labWorkId: number,
    workType: WorkTypeUiModel,
    extensions: number[],
    extras: Array<Extra>,
  ) {
    this.labWorkId = labWorkId;
    this.workType = workType;
    this.extensions = extensions;
    this.extras = extras;
  }
}

export type SelectedTemplate = ExistingTemplate | NewTemplate;

type ExistingTemplate = { kind: 'Existing'; id: Template['id'] };
type NewTemplate = { kind: 'New'; name: Template['name'] };

import { CompaniesRepository } from '../../domain/user-management/UsersRepository';
import { NewCompany } from '../../domain/user-management/NewCompany';

type NewCompanyUseCase = Pick<CompaniesRepository, 'updateCompany' | 'updateMyCompanyData'>;

const updateCompanyUseCase = (repo: NewCompanyUseCase, updateCompany: NewCompany) => {
  repo.updateCompany(updateCompany);
};

const updateMyCompanyUseCase = (repo: NewCompanyUseCase, updateCompany: NewCompany) => {
  return repo.updateMyCompanyData(updateCompany);
};

export { updateCompanyUseCase, updateMyCompanyUseCase };

// TODO: remove

export type ExtensionData = {
  code: number;
  message: string;
  data: Array<Extension>;
};

export class Extension {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export type ExtensionList = Array<
  SimpleExtensionHolder | SelectableExtensionHolder | SelectableCategoryHolder
>;

export const SELECTABLE_CATEGORY = 1;
export const SELECTABLE_EXTENSION = 2;
export const SIMPLE_EXTENSION = 3;

type SimpleExtensionHolder = {
  type: typeof SIMPLE_EXTENSION;
  id: number;
  name: string;
  extensions: Array<SimpleExtension>;
};

type SelectableCategoryHolder = {
  type: typeof SELECTABLE_CATEGORY;
  id: number;
  name: string;
  selected: SelectableExtension['id'];
  extensions: Array<SelectableExtension>;
  disabled?: boolean;
};

type SelectableExtensionHolder = {
  type: typeof SELECTABLE_EXTENSION;
  id: number;
  name: string;
  selected: SelectableExtension['id'];
  extensions: Array<SelectableExtension>;
  disabled?: boolean;
};

type SimpleExtension = { id: number; name: string; active: boolean; disabled?: boolean };
type SelectableExtension = { id: number; name: string; active: boolean; selected?: boolean };

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../dsm/Text';
import { Button } from '../dsm/buttons/Button';
import { BaseDialog } from './BaseDialog';

export type ConfirmationDialogProps = {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
  negativeButton: DialogButton;
  positiveButton: DialogButton;
  icon?: JSX.Element;
};

type DialogButton = {
  label: string;
  onClick: () => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { isOpen, title, description, onClose, negativeButton, positiveButton, icon } = props;

  return (
    <BaseDialog
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      containerStyle={{ width: '400px' }}
      header={true}
      isScroll={false}
    >
      <Row style={{ gap: '16px' }}>
        {icon}
        <Text type={TextType.BODY_2}>{description}</Text>
      </Row>
      <ButtonRow>
        <Button onClick={positiveButton.onClick} secondary='secondary'>
          {positiveButton.label}
        </Button>
        <Button onClick={negativeButton.onClick}>{negativeButton.label}</Button>
      </ButtonRow>
    </BaseDialog>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 16px;
`;

export default ConfirmationDialog;

import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';

type GetInvoiceUseCase = Pick<InvoiceRepository, 'invoices' | 'getInvoices' | 'getInvoiceById'>;

const getInvoiceUseCase = (repo: GetInvoiceUseCase) => {
  return repo.getInvoices();
};

const getInvoiceByIdUseCase = (repo: GetInvoiceUseCase, id: number) => {
  return repo.getInvoiceById(id);
};

const getInvoiceByCaseIdUseCase = (repo: GetInvoiceUseCase, id: number) => {
  let invoiceId = -1;
  repo.invoices?.forEach((invoice) => {
    if (invoice.cases.some((inv) => inv.caseId === id)) {
      invoiceId = invoice.id;
      return;
    }
  });
  return invoiceId;
};

export { getInvoiceUseCase, getInvoiceByIdUseCase, getInvoiceByCaseIdUseCase };

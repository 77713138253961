import styled from 'styled-components';

export type OvalButtonProps = {
  selected?: boolean;
};

export const OvalButtonCss = styled.div<OvalButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: ${(props) => (props.selected ? 'var(--dts_black)' : 'var(--dts_light_grey)')};
  cursor: pointer;
`;

import React from 'react';
import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateEfacturaByAccauntatAction,
  activateEfacturaByUserAction,
  checkActivationTokenAction,
  generateEActivationTokenAction,
  getStatusAnafFacturaAction,
  refreshETokenAction,
  resetEfacturaAuthValuesAction,
  saveEfacturaAuthCodeAction,
  saveEfacturaAuthErrorAction,
  uploadEFacturaAction,
} from './E_facturaActions';
import { AppRootStore } from '../appRepositoryImplementation';
import { E_facturaRepositoryState } from './E-facturaReducer';

const eFacturaSelector = (state: AppRootStore) => state.eFactura;

const useE_facturaRepositoryImplementation = (): E_facturaRepository => {
  const { eFacturaStatuses, error, code } = useSelector<AppRootStore, E_facturaRepositoryState>(
    eFacturaSelector,
  );

  const dispatch = useDispatch();

  const saveEfacturaAuthCode = React.useCallback(
    (code: string) => saveEfacturaAuthCodeAction(code)(dispatch),
    [dispatch],
  );
  const saveEfacturaAuthError = React.useCallback(
    (error: string) => saveEfacturaAuthErrorAction(error)(dispatch),
    [dispatch],
  );
  const resetEfacturaAuthValues = React.useCallback(
    () => resetEfacturaAuthValuesAction()(dispatch),
    [dispatch],
  );
  const generateEActivationToken = React.useCallback(
    () => generateEActivationTokenAction()(dispatch),
    [dispatch],
  );

  const activateEfacturaByAccauntat = React.useCallback(
    (activationToken: string, eCode: string) =>
      activateEfacturaByAccauntatAction(activationToken, eCode)(dispatch),
    [dispatch],
  );

  const checkActivationToken = React.useCallback(
    (param: { companyId: number; token: string }) => checkActivationTokenAction(param)(dispatch),
    [dispatch],
  );
  const activateEfacturaByUser = React.useCallback(
    (code: string) => activateEfacturaByUserAction(code)(dispatch),
    [dispatch],
  );

  const refreshEToken = React.useCallback(() => refreshETokenAction()(dispatch), [dispatch]);

  const uploadEFactura = React.useCallback(
    (invoiceId: number) => uploadEFacturaAction(invoiceId)(dispatch),
    [dispatch],
  );

  const getStatusAnafFactura = React.useCallback(
    (invoiceId: number) => getStatusAnafFacturaAction(invoiceId)(dispatch),
    [dispatch],
  );

  return {
    eFacturaStatuses,
    error: error,
    code: code,
    generateEActivationToken,
    activateEfacturaByAccauntat,
    checkActivationToken,
    activateEfacturaByUser,
    refreshEToken,
    uploadEFactura,
    getStatusAnafFactura,
    saveEfacturaAuthCode,
    saveEfacturaAuthError,
    resetEfacturaAuthValues,
  };
};

export { useE_facturaRepositoryImplementation };

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Text, TextType } from '../../components/dsm/Text';
import { Button } from '../../components/dsm/buttons/Button';
import Avatar from '../../components/generic/Avatar';
import { InputText } from '../../components/dsm/InputText';
import { ColorType, getUserColorById, getUserColors } from '../../components/dsm/UserColors';
import {
  LanguageSelector,
  LanguageSelectorProps,
  languageList,
} from '../../components/generic/LanguageSelector';

import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

import chooseLanguage from '../../../images/ChooseLanguage.png';
import chooseName from '../../../images/ChooseName.png';

import { ReactComponent as BackIcon } from '../../../icons/BackIcon.svg';
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg';

import { useSignUpFlowRepositoryImplementation } from '../../../data/signUpFlow/signUpFlowRepositoryImplementation';
import { useSignUpFlowViewModel } from './SignUpFlowViewModel';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { AlertType } from '../../../domain/alert/AlertManager';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

const MAX_STEPS_COUNT = 3;
const PROGRESS_WIDTH = 80;

function FTISteps() {
  const navigate = useNavigate();
  const translate = useTranslate();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const repo = useSignUpFlowRepositoryImplementation();
  const { updateUser } = useSignUpFlowViewModel(repo);
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const [stepCounter, setStepCounter] = useState<number>(1);
  const [progressWidth, setProgressWidth] = useState<number>(PROGRESS_WIDTH / MAX_STEPS_COUNT);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);
  const [selectedColor, setSelectedColor] = useState<{ id: ColorType; value: string }>(
    getUserColorById(0),
  );

  const changeStepCounter = (num: number) => {
    if (num > 0 && MAX_STEPS_COUNT > stepCounter) {
      setStepCounter(stepCounter + num);
      updateProgressWidth(stepCounter + num);
      return;
    }
    if (num < 0 && stepCounter > 1) {
      setStepCounter(stepCounter + num);
      updateProgressWidth(stepCounter + num);
      return;
    }
    if (num < 0 && stepCounter == 1) {
      navigate('/');
      return;
    }
  };

  const updateProgressWidth = (num: number) => {
    setProgressWidth((PROGRESS_WIDTH / MAX_STEPS_COUNT) * num);
  };

  const finishFTI = () => {
    updateUser({
      language: languageList.find((language) => language.id === selectedLanguage)!.languageCode,
      firstName: firstName,
      lastName: lastName,
      profileColor: selectedColor.value,
      isFtiFinished: true,
    }).then((resp) => {
      if (resp.hasError) {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        return;
      } else {
        navigate('/');
      }
    });
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const stepOne = () => {
    return (
      <Section>
        <TitleText type={TextType.TITLE_BOLD_2}>
          {translate(LANGUAGE_KEYS.CHOOSE_LANGUAGE)}
        </TitleText>
        <InformationText type={TextType.BODY_2}>
          {translate(LANGUAGE_KEYS.CHOOSE_LANGUAGE_DESCRIPTION)}
        </InformationText>
        <div style={{ width: '280px' }}>
          <LanguageSelector
            values={languageList.reduce((result: LanguageSelectorProps['values'], language) => {
              result.push({
                key: language.id,
                label: language.language,
                selected: language.id === selectedLanguage,
              });
              return result;
            }, [])}
            onSelect={(changedValueKey) => setSelectedLanguage(changedValueKey)}
            hasSuccessIcon={true}
          />
        </div>
        <img src={chooseLanguage} alt='Choose language' />
      </Section>
    );
  };

  const inputStyle = {
    width: '250px',
    padding: '7px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#151515',
    boxShadow: 'none',
    outline: 'none',
    border: '1px solid var(--dts_light_grey)',
    borderRadius: '5px',
    marginBottom: '8px',
  };

  const stepTwo = () => {
    return (
      <Section>
        <TitleText type={TextType.TITLE_BOLD_2}>
          {translate(LANGUAGE_KEYS.COULD_U_TELL_US_UR_NAME)}
        </TitleText>
        <InformationText type={TextType.BODY_2}>
          {translate(LANGUAGE_KEYS.COULD_U_TELL_US_UR_NAME_DESCRIPTION)}
        </InformationText>
        <InputText
          placeHolder={translate(LANGUAGE_KEYS.FIRST_NAME)}
          width='100%'
          initValue={firstName}
          onChange={handleFirstNameChange}
          onKeyDown={() => {}}
          clickClose={() => {}}
          customStyle={inputStyle}
        />
        <InputText
          placeHolder={translate(LANGUAGE_KEYS.LAST_NAME)}
          width='100%'
          initValue={lastName}
          onChange={handleLastNameChange}
          onKeyDown={() => {}}
          clickClose={() => {}}
          customStyle={inputStyle}
        />
        <img src={chooseName} alt='Choose name' />
      </Section>
    );
  };

  const stepThree = () => {
    return (
      <Section>
        <TitleText type={TextType.TITLE_BOLD_2}>
          {translate(LANGUAGE_KEYS.SELECT_COLOR_FOR_PROFILE)}
        </TitleText>
        <InformationText type={TextType.BODY_2}>
          {translate(LANGUAGE_KEYS.SELECT_COLOR_FOR_PROFILE_DESCRIPTION)}
        </InformationText>
        <ColorContainer>
          {getUserColors().map((color) => {
            return (
              <ColorSpan
                className={`${color.id === selectedColor?.id && 'active'}`}
                onClick={() => {
                  if (color.id !== selectedColor?.id) setSelectedColor(color);
                }}
                key={color.id}
                color={color.value}
              />
            );
          })}
        </ColorContainer>
        <Avatar
          textType={TextType.ROBOTO_400_80_94}
          fullName={firstName + ' ' + lastName}
          size={{ outer: 220, inner: 218 }}
          avatarStyle={{ background: selectedColor?.value }}
        />
      </Section>
    );
  };

  return (
    <PlansContainer>
      <CustomBackIcon
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        width={24}
        height={24}
        onClick={() => {
          changeStepCounter(-1);
        }}
      />
      <PlansContentContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <Content>
          {stepCounter === 1 && stepOne()}
          {stepCounter === 2 && stepTwo()}
          {stepCounter === 3 && stepThree()}
        </Content>
        <Footer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <ProgressIndicator>
            <ProgressIndicator className='progress' style={{ width: `${progressWidth}px` }} />
          </ProgressIndicator>
          {stepCounter < MAX_STEPS_COUNT && (
            <span
              className={`${
                stepCounter === 2 && (firstName === '' || lastName === '') && 'disable-button'
              }`}
            >
              <Button
                textType={TextType.CAPTION_BOLD}
                onClick={() => {
                  changeStepCounter(1);
                }}
              >
                {translate(LANGUAGE_KEYS.CONTINUE)}
              </Button>
            </span>
          )}
          {stepCounter == MAX_STEPS_COUNT && (
            <Button textType={TextType.CAPTION_BOLD} onClick={() => finishFTI()}>
              {translate(LANGUAGE_KEYS.FINISH)}
            </Button>
          )}
        </Footer>
      </PlansContentContainer>
    </PlansContainer>
  );
}

type ColorProps = {
  color: string;
};

const PlansContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--dts_withe_background);
  position: relative;
`;
const PlansContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_white);
    max-width: 528px;
    min-width: 568px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${desktop}px) {
      padding: 56px 36px 56px 36px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 56px 36px 56px 36px;
      overflow: auto;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 10px;
      max-width: calc(100% - 20px);
      min-width: calc(100% - 20px);
    }
    // Mobile
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 100%;
    margin-top: 56px;
  }
`;

const LogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 56px;
`;

const TitleText = styled(Text)`
  margin-bottom: 8px;
  text-align: center;
`;

const InformationText = styled(Text)`
  margin-bottom: 8px;
  max-width: 472px;
  text-align: center;
  opacity: 0.7;
`;

const Footer = styled.div<DeviceSizeProps>(
  ({ desktop, tablet_large, mobile }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
  .disable-button {
    opacity: 50%;
    button {
      cursor: not-allowed;
      :hover {
        background: var(--dts_primary);
      }
    }
  }
  @media (min-width: ${desktop}px) {
    width: 100%;
  }
  @media (max-width: ${tablet_large}px) {
    width: 100%;
  }
  // Mobile
  @media (max-width: ${mobile}px) {
    padding: 10px;
    width: calc(100% - 20px);
  }
`,
);
const ProgressIndicator = styled.span`
  height: 4px;
  width: 80px;
  background: #d9d9d9;
  border-radius: 8px;
  position: relative;
  flex-shrink: 0;
  &.progress {
    background: var(--dts_default_blue);
    position: absolute;
  }
`;

const CustomBackIcon = styled(BackIcon)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: absolute;
    fill: var(--dts_black);
    cursor: pointer;
    :hover {
      fill: var(--dts_hover_blue);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      left: 36px;
      top: 36px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      left: 36px;
      top: 36px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      left: 10px;
      top: 15px;
    }
    // Mobile
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ColorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 436px;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 27px;
  justify-content: center;
`;

const ColorSpan = styled.span<ColorProps>(
  ({ color }) => `
    width: 58px;
    height: 58px;
		background: ${color};
		border-radius: 50%;
    border: 4px solid ${color};
    &.active {
      border-color: var(--dts_default_blue)
    }
`,
);

export default FTISteps;

function captchaValidation(captchaToken: string, secretKey: string, hostName: string) {
  return fetch(
    `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${captchaToken}&remoteip=${hostName}`,
    {
      headers: {
        'Content-Type': 'application / json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
      },
      method: 'POST',
    },
  )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((error) => {
      console.log('catchy captchaValidation: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

export { captchaValidation };

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAILD = 'GET_CONTACT_FAILD';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILD = 'UPDATE_CLIENT_FAILD';
export const UPDATE_WORKER = 'UPDATE_WORKER';
export const UPDATE_WORKER_SUCCESS = 'UPDATE_WORKER_SUCCESS';
export const UPDATE_WORKER_FAILD = 'UPDATE_WORKER_FAILD';
export const SEND_WORKER_INVITATION = 'SEND_WORKER_INVITATION';
export const SEND_WORKER_INVITATION_SUCCESS = 'SEND_WORKER_INVITATION_SUCCESS';
export const SEND_WORKER_INVITATION_FAILD = 'SEND_WORKER_INVITATION_FAILD';
export const ANSWER_WORKER_INVITATION = 'ANSWER_WORKER_INVITATION';
export const ANSWER_WORKER_INVITATION_SUCCESS = 'ANSWER_WORKER_INVITATION_SUCCESS';
export const ANSWER_WORKER_INVITATION_FAILD = 'ANSWER_WORKER_INVITATION_FAILD';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILD = 'DELETE_CONTACT_FAILD';
export const GET_MY_COMPANY_DATA = 'GET_MY_COMPANY_DATA';
export const GET_MY_COMPANY_DATA_SUCCESS = 'GET_MY_COMPANY_DATA_SUCCESS';
export const GET_MY_COMPANY_DATA_FAILD = 'GET_MY_COMPANY_DATA_FAILD';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILD = 'GET_COMPANIES_FAILD';
export const NEW_COMPANIES = 'NEW_COMPANIES';
export const NEW_COMPANIES_SUCCESS = 'NEW_COMPANIES_SUCCESS';
export const NEW_COMPANIES_FAILD = 'NEW_COMPANIES_FAILD';
export const UPDATE_MY_COMPANY_DATA = 'UPDATE_MY_COMPANY_DATA';
export const UPDATE_MY_COMPANY_DATA_SUCCESS = 'UPDATE_MY_COMPANY_DATA_SUCCESS';
export const UPDATE_MY_COMPANY_DATA_FAILD = 'UPDATE_MY_COMPANY_DATA_FAILD';
export const UPDATE_COMPANIES = 'UPDATE_COMPANIES';
export const UPDATE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const UPDATE_COMPANIES_FAILD = 'UPDATE_COMPANIES_FAILD';
export const DELETE_COMPANIES = 'DELETE_COMPANIES';
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS';
export const DELETE_COMPANIES_FAILD = 'DELETE_COMPANIES_FAILD';
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILD = 'CREATE_COMPANY_FAILD';

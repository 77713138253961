import React from 'react';
import { captchaValidationAction } from './googleAction';
import { GoogleRepository } from '../../domain/google/GoogleRepository';

const useGoogleRepositoryImplementation = (): GoogleRepository => {
  const SITE_KEY = '6LeyoHIpAAAAAHJ-6c4a9q6r7fkNMybjYrooBPCT';
  const SECRET_KEY = '6LeyoHIpAAAAAL4OGR9s7Z5a8uxNebMYHR8FV_oq';

  const captchaValidation = React.useCallback(
    (captchaToken: string, hostName: string) =>
      captchaValidationAction(captchaToken, SECRET_KEY, hostName)(),
    [],
  );

  const getCaptchaSiteKey = () => {
    return SITE_KEY;
  };

  return {
    captchaValidation,
    getCaptchaSiteKey,
  };
};

export { useGoogleRepositoryImplementation };

import Column from '../../components/layout/Column';
import { Text, TextType } from '../../components/dsm/Text';
import React from 'react';
import styled from 'styled-components';
import Container from '../../components/layout/Container';
import { ReactComponent as LoadingIcon } from '../../../icons/notFound.svg';

const LoadingContainer = () => {
  return (
    <ContainerStyled orientation='Vertical'>
      <LoadingIcon />
      <Column style={{ alignItems: 'center' }}>
        <Text type={TextType.BODY_BOLD}>Loading...</Text>
        <Text type={TextType.BODY_REGULAR}>Your reports are loading, hold on for a second.</Text>
      </Column>
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  height: 100%;
  background-color: var(--dts_white);
  gap: 36px;
  padding: 36px;
  align-items: center;
  justify-content: center;
`;

export default LoadingContainer;

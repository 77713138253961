import React from 'react';
import { ExtraTagCss } from './ExtraTagCss';
import { Text, TextColor, TextType } from './Text';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import styled from 'styled-components';

export type ExtraTagProps = {
  onCloseClick: () => void;
  value: { name: string; count: number };
  removable: boolean;
};

export const ExtraTag = (props: ExtraTagProps) => {
  const { onCloseClick, value, removable } = props;

  return (
    <ExtraTagCss className={'extension'}>
      <Text type={TextType.CAPTION_2_BOLD_12} clr={TextColor.BLACK}>
        {value.count > 1 && (
          <span style={{ opacity: 0.7, marginRight: '4px' }}>{value.count}x</span>
        )}
        {value.name}
      </Text>
      {removable ? (
        <CloseIconStyled
          onClick={(e) => {
            e.stopPropagation();
            onCloseClick();
          }}
        />
      ) : null}
    </ExtraTagCss>
  );
};

const CloseIconStyled = styled(CloseIcon)`
  width: 7px;
  height: 7px;
  opacity: 0.7;
  path {
    fill: var(--dts_black);
  }
`;

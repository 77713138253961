import React, { useLayoutEffect, FC, useState, useRef } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../../components/dsm/Text';
import { ExpenseLabwork } from '../../../ui-model/reports/ExpenseUiModel';
import Tooltip from '../../../components/dsm/Tooltip';

export type ExpenseLabworkCardProps = {
  expenseLabwork: ExpenseLabwork;
  labworksSize: number;
  index: number;
  refParent: any;
};

export const ExpenseLabworkCard: FC<ExpenseLabworkCardProps> = (expenseLabworkProps) => {
  const { expenseLabwork, labworksSize, index, refParent } = expenseLabworkProps;
  const ref = useRef<HTMLDivElement>(null);
  const refTeethContainer = useRef<HTMLDivElement>(null);
  const refLabworkCard = useRef<HTMLDivElement>(null);

  const [needMoreInfo, setNeedMoreInfo] = useState(false);

  useLayoutEffect(() => {
    if (
      refTeethContainer.current?.offsetWidth !== undefined &&
      ref.current?.offsetWidth !== undefined &&
      refTeethContainer.current?.offsetWidth > ref.current?.offsetWidth
    ) {
      setNeedMoreInfo(true);
    }
  }, []);

  const [showDialog, setShowDialog] = useState(false);

  const Dialog = () =>
    showDialog ? (
      <DialogConatiner
        className={`${
          refLabworkCard.current?.offsetLeft !== undefined &&
          refLabworkCard.current?.offsetLeft + 520 > refParent.current?.offsetWidth
            ? 'left-right'
            : 'left-side'
        }`}
      >
        <TitleText type={TextType.BODY_2_BOLD} ellipsis={true}>
          {expenseLabwork.title}
        </TitleText>
        <SubTitleText type={TextType.CAPTION_REGULAR} ellipsis={true}>
          {expenseLabwork.subTitle}
        </SubTitleText>
        <TeethSectionsConatiner>
          {/* Up section */}
          <TeethsContainer>
            {expenseLabwork.teeths
              .filter((t) => t.id >= 11 && t.id <= 28)
              .map((teeth, index) => {
                return (
                  <TeethBox key={index}>
                    <Text type={TextType.CAPTION_2_BOLD_12}>{teeth.id}</Text>
                    {teeth.connection && <Connection />}
                  </TeethBox>
                );
              })}
          </TeethsContainer>
          {/* Down section */}
          <TeethsContainer>
            {expenseLabwork.teeths
              .filter((t) => t.id >= 31 && t.id <= 48)
              .map((teeth, index) => {
                return (
                  <TeethBox key={index}>
                    <Text type={TextType.CAPTION_2_BOLD_12}>{teeth.id}</Text>
                    {teeth.connection && <Connection />}
                  </TeethBox>
                );
              })}
          </TeethsContainer>
        </TeethSectionsConatiner>
      </DialogConatiner>
    ) : null;

  const toggleDialog = () => setShowDialog((s) => !s);

  const getLabworkCardStyleName = () => {
    let styleType = 3;
    if (labworksSize % 4 === 1 && labworksSize - 1 === index) {
      styleType = 1;
    } else if (
      labworksSize % 4 === 2 &&
      (labworksSize - 1 === index || labworksSize - 2 === index)
    ) {
      styleType = 2;
    }
    switch (styleType) {
      case 1:
        return 'one-item';
      case 2:
        return 'two-item';
      default:
        return 'more-item';
    }
  };

  return (
    <Tooltip
      className='tooltip'
      key={expenseLabwork.labworkId}
      content={'Click more info'}
      placement='bottom'
      disabled={!needMoreInfo}
    >
      <LabWorkCard
        ref={refLabworkCard}
        className={getLabworkCardStyleName()}
        tabIndex={-1}
        key={expenseLabwork.labworkId}
        onClick={needMoreInfo ? toggleDialog : () => {}}
        onBlur={needMoreInfo ? toggleDialog : () => {}}
      >
        <TitleText type={TextType.BODY_2_BOLD} ellipsis={true}>
          {expenseLabwork.title}
        </TitleText>
        <SubTitleText type={TextType.CAPTION_REGULAR} ellipsis={true}>
          {expenseLabwork.subTitle}
        </SubTitleText>
        <div ref={ref} style={{ overflow: 'hidden' }}>
          <TeethsContainer ref={refTeethContainer}>
            {expenseLabwork.teeths.map((teeth, index) => {
              return (
                <TeethBox key={index}>
                  <Text type={TextType.CAPTION_2_BOLD_12}>{teeth.id}</Text>
                  {teeth.connection && <Connection />}
                </TeethBox>
              );
            })}
          </TeethsContainer>
        </div>

        <Dialog />
      </LabWorkCard>
    </Tooltip>
  );
};

const LabWorkCard = styled.div`
  &.one-item {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  &.two-item {
    flex: 0 0 calc(50% - ((24 / 2) * 1px));
    width: calc(50% - ((24 / 2) * 1px));
    max-width: calc(50% - ((24 / 2) * 1px));
  }
  &.more-item {
    flex: 0 0 calc(25% - ((24 / 4) * 3px));
    width: calc(25% - ((24 / 4) * 3px));
    max-width: calc(25% - ((24 / 4) * 3px));
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
`;

const TeethsContainer = styled.div`
  display: inline-flex;
  gap: 4px;
  flex-grow: 1;
  min-heigth: 24px;
`;

const TitleText = styled(Text)`
  min-height: 20px;
`;

const SubTitleText = styled(Text)`
  min-height: 16px;
`;

const TeethBox = styled.div`
  padding: 6px 8px;
  border-radius: 5px;
  background: var(--dts_light_grey);
  position: relative;
`;

const Connection = styled.span`
  position: absolute;
  width: 4px;
  height: 4px;
  background: var(--dts_light_grey);
  right: -4px;
  top: 50%;
`;

const DialogConatiner = styled.div`
  position: absolute;
  top: 0;
  background-color: var(--dts_white);
  padding: 24px;
  z-index: 1000;
  box-shadow: -1px 2px 11px 0px #00000024;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 520px;
  min-heigth: 148px;
  &.left-side {
    left: 0;
  }
  &.left-right {
    right: 0;
  }
`;

const TeethSectionsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const UPDATE_CASES_PHASE_ID_SUCCESS = 'UPDATE_CASES_PHASE_ID_SUCCESS';
export const UPDATE_CASES_PHASE_ID = 'UPDATE_CASES_PHASE_ID';
export const GET_CASES = 'GET_CASES';
export const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS';
export const GET_CASES_FAILD = 'GET_CASES_FAILD';
export const GET_CASE_BY_ID = 'GET_CASE_BY_ID';
export const GET_CASE_BY_ID_SUCCESS = 'GET_CASE_BY_ID_SUCCESS';
export const GET_CASE_BY_ID_FAILD = 'GET_CASE_BY_ID_FAILD';
export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAILD = 'UPDATE_CASE_FAILD';
export const NEW_CASE = 'NEW_CASE';
export const NEW_CASE_SUCCESS = 'NEW_CASE_SUCCESS';
export const NEW_CASE_FAILED = 'NEW_CASE_FAILED';
export const DELETE_CASE = 'DELETE_CASE';
export const DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS';
export const DELETE_CASE_FAILED = 'DELETE_CASE_FAILED';

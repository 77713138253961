import { reset, removeSession, setSubscription } from './loggedUser/loggedUserReducer';

export const checkErrorStatus = (status, dispatch: any): boolean => {
  switch (status) {
    case 200:
      return true;
    case 300:
      return false;
    case 400:
      return false;
    case 401:
      dispatch(reset());
      dispatch(removeSession());
      return false;
    case 500:
      return false;
    case 4001:
      dispatch(
        setSubscription({
          validSubscription: false,
          packageType: -1,
          billingType: -1,
          subscriptionEndTime: -1,
        }),
      );
      return false;
    case undefined:
      return false;
    default:
      return false;
  }
};

export const checkResponseStatus = (status): boolean => {
  switch (status) {
    case 200:
      return true;
    case 300:
      return false;
    case 400:
      return false;
    case 401:
      return false;
    case 500:
      return false;
    case undefined:
      return false;
    default:
      return false;
  }
};

import React, { FC, ReactElement, useState } from 'react';
import { MenuElementCss, MenuSubElementCss } from './MenuElementCss';
import { Text, TextType } from '../dsm/Text';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { To } from 'react-router';
import './MenuElement.css';

import { ReactComponent as ArrowDownIcon } from '../../../icons/arrowDown.svg';
import { SubMenuUiItem } from '../../ui-model/menu/MenuUImodel';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import useTranslate from '../../translations/useTranslate';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { useCompany } from '../../hooks/useCompany';

export type MenuElementProps = {
  name: string;
  isActive?: boolean;
  to: To;
  icon: ReactElement<any, any> | null;
  subMenus?: Array<SubMenuUiItem>;
  menuIsOpen: boolean;
  validSubscription: boolean;
  menuVisibilityExpireSubscription: boolean;
};

export const MenuElement: FC<MenuElementProps> = ({
  to,
  isActive,
  name,
  icon,
  subMenus,
  menuIsOpen,
  validSubscription,
  menuVisibilityExpireSubscription,
}) => {
  const translate = useTranslate();
  const currentCompany = useCompany();

  const [expanded, setExpanded] = useState(true);
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  return subMenus?.length === 0 && to !== undefined ? (
    <NavLinkExtended
      onClick={() => {
        if (!validSubscription && !menuVisibilityExpireSubscription) {
          showPaymentExpireModal(currentCompany.userRole);
        }
      }}
      end
      to={to}
      className={'navigation' + (isActive ? ' active' : '')}
    >
      <MenuElementCss
        className={'menu-item'}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <SelectedIndicator className={'indicator'} />
        {icon}
        <TextExt type={TextType.BODY_2} ellipsis={true}>
          {translate(name)}
        </TextExt>
      </MenuElementCss>
    </NavLinkExtended>
  ) : subMenus !== undefined && subMenus?.length > 0 ? (
    <>
      <SubmenuContainer
        end
        onClick={(e) => {
          if (!validSubscription && !menuVisibilityExpireSubscription) {
            e.preventDefault();
            showPaymentExpireModal(currentCompany.userRole);
          }
        }}
        to={to}
        className={'navigation' + (isActive ? ' active' : '')}
      >
        <MenuElementCss
          className={'menu-item'}
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <SelectedIndicator className={'indicator'} />
          {icon}
          <TextExt type={TextType.BODY_2} ellipsis={true}>
            {translate(name)}
          </TextExt>
          <ArrowContainer>
            <CustomArrowIcon
              className={`${expanded && 'open'}`}
              onClick={() => setExpanded(!expanded)}
            />
          </ArrowContainer>
        </MenuElementCss>
      </SubmenuContainer>
      <SubmenuItemsConatiner className={`${expanded && 'open'}`}>
        {subMenus.map((subMenu, index) => {
          return (
            <NavLinkExtended
              end
              onClick={(e) => {
                if (!validSubscription && !subMenu.visibilityExpireSubscription) {
                  e.preventDefault();
                  showPaymentExpireModal(currentCompany.userRole);
                }
              }}
              key={index}
              to={subMenu.to !== undefined ? subMenu.to : ''}
              className={`${menuIsOpen && 'navigation'} ${!menuIsOpen && 'block-pointer'}`}
            >
              <MenuSubElementCss className={'menu-item'}>
                <TextSub
                  className={`${!menuIsOpen && 'menu-closed'}`}
                  type={TextType.BODY_2}
                  ellipsis={true}
                >
                  {translate(subMenu.name)}
                </TextSub>
              </MenuSubElementCss>
            </NavLinkExtended>
          );
        })}
      </SubmenuItemsConatiner>
    </>
  ) : null;
};

export const NavLinkExtended = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  background-color: var(--dts_white);
  text-decoration: none;
`;

const TextExt = styled(Text)`
  text-align: left;
  vertical-align: top;
  margin-left: 4px;
  flex-grow: 1;
`;

const SelectedIndicator = styled.div`
  width: 4px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &:active {
    background: var(--dts_default_blue);
  }
`;

const SubmenuContainer = styled(NavLinkExtended)`
  display: flex;
  flex-direction: row;
  .menu-item {
    padding: 0px;
  }
`;

const ArrowContainer = styled.span`
  flex-grow: 0;
  padding: 12px;
  line-height: 0;
`;

const CustomArrowIcon = styled(ArrowDownIcon)`
  padding: 5px;
  cursor: pointer;
  &.open {
    transform: rotate(180deg);
  }
`;

const SubmenuItemsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  &.open {
    max-height: 240px;
    transition: max-height 0.3s ease-in;
  }
  .menu-item {
    display: flex;
    width: 175px;
    padding-right: 16px;
  }
`;

const TextSub = styled(Text)`
  margin-left: 52px;
  &.menu-closed {
    padding-left: 4px;
    line-height: 40px;
  }
`;

import { CompaniesRepository } from '../../domain/user-management/UsersRepository';
import { NewCompany } from '../../domain/user-management/NewCompany';

type NewCompanyUseCase = Pick<CompaniesRepository, 'newCompany'>;

const newCompanyUseCase = (repo: NewCompanyUseCase, newContact: NewCompany) => {
  repo.newCompany(newContact);
};

export { newCompanyUseCase };

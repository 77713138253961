import { getHallCompanies } from './lobbyService';
import * as actionTypes from './lobbyActionTypes';
import { CompanyData } from '../../domain/lobby/Lobby';
import { checkErrorStatus } from '../ErrorHandling';

const getLobbyCompaniesAction = (id: number | null) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_LOBBY_COMPANIES });
  if (id !== null) {
    return getHallCompanies(id).then((resp) => {
      if (checkErrorStatus(resp.code, dispatch)) {
        dispatch({ type: actionTypes.GET_LOBBY_COMPANIES_SUCCESS, lobbyCompanies: resp.data });
        return resp.data;
      } else {
        dispatch({ type: actionTypes.GET_LOBBY_COMPANIES_FAILD, lobbyCompanies: [] });
        return [];
      }
    });
  } else {
    dispatch({ type: actionTypes.GET_LOBBY_COMPANIES_FAILD, lobbyCompanies: [] });
    const list: Array<CompanyData> = [];
    return list;
  }
};

export { getLobbyCompaniesAction };

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as TravelExploreIcon } from '../../icons/TravelExplore.svg';

type E_facturaHelpProps = {
  onClose: () => void;
};

const E_facturaHelpModal = (props: E_facturaHelpProps) => {
  const { onClose } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={'Ghid inregistrare Spatiul virtual'}
      onClose={onClose}
      containerStyle={{ width: '882px' }}
      header={true}
      isScroll={false}
    >
      <Information>
        <Description>
          <Label>
            <Text type={TextType.ROBOTO_400_10_14}>Pasul 1</Text>
          </Label>
          <Text type={TextType.BODY_REGULAR}>Inregistreaza-te in Spatiu Privat Virtual (SPV)</Text>
          <Text type={TextType.BODY_2} className='end-paragraph'>
            Primul pas este sa cumperi un certificat digital calificat. De la ei vei primi si un
            token, care poate arata precum cel prezentat in video-ul alaturat.
          </Text>
        </Description>
        <Description>
          <Label>
            <Text type={TextType.ROBOTO_400_10_14}>Pasul 2</Text>
          </Label>
          <Text type={TextType.BODY_REGULAR}>Obtine un certificat digital calificat</Text>
          <Text type={TextType.BODY_2} className='end-paragraph'>
            Dupa achizitionarea certificatului digital, firma ta trebuie sa fie inregistrata in
            Spatiu Privat Virtual (SPV) in nume propriu sau prin reprezentant legal. Poti face acest
            lucru, direct de aici, de pe site-ul anaf.ro
          </Text>
        </Description>
        <Description>
          <Label>
            <Text type={TextType.ROBOTO_400_10_14}>Pasul 3</Text>
          </Label>
          <Text type={TextType.BODY_REGULAR}>Introdu token-ul tau sau al contabilei tale</Text>
          <Text type={TextType.BODY_2} className='end-paragraph'>
            Introdu token-ul in calculator si apasa pe butonul Configurare din pagina de integrare
            cu e-Factura
          </Text>
        </Description>
        <Description>
          <Label>
            <Text type={TextType.ROBOTO_400_10_14}>Pasul 4</Text>
          </Label>
          <Text type={TextType.BODY_REGULAR}>Introdu PIN-ul</Text>
          <Text type={TextType.BODY_2} className='end-paragraph'>
            Introdu PIN-ul de pe token atunci cand este cerut si apasa butonul OK. Asteapta
            confirmarea de la SPV ca integrarea a fost realizata cu succes. Integrarea cu e-Factura
            este PERMANENTA, nu trebuie sa o repeti la fiecare Factura emisa.
          </Text>
        </Description>
      </Information>
      <ButtonsConatiner>
        <Hint>
          <TravelExploreIcon width={20} height={20} />
          <Text type={TextType.CAPTION_BOLD} clr={TextColor.PRIMARY}>
            Instructiuni pentru inregistrarea contribuabilului in SPV, cu certificat digital
          </Text>
        </Hint>
        <Button textType={TextType.CAPTION_BOLD} onClick={onClose}>
          Close
        </Button>
      </ButtonsConatiner>
    </BaseDialog>
  );
};

const Information = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  gap: 24px;
  width: calc(100% - 32px);
  flex-direction: row;
`;

const Description = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  flex: 1 1 0px;
  .end-paragraph {
    flex-grow: 1;
  }
`;

const Label = styled.span`
  padding: 4px 8px 4px 8px;
  border-radius: 3px;
  background: var(--dts_light_grey);
  align-self: flex-start;
  p {
    color: var(--dts_dark_grey);
  }
`;

const ButtonsConatiner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
`;

const Hint = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--dts_default_blue);
    path {
      fill: var(--dts_default_blue);
    }
  }
  &:hover {
    svg {
      fill: var(--dts_hover_blue);
      path {
        fill: var(--dts_hover_blue);
      }
    }
    p {
      color: var(--dts_hover_blue);
    }
  }
`;

export default E_facturaHelpModal;

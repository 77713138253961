import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './buttons/Button';
import { BaseDialog } from '../generic/BaseDialog';
import { TextType } from './Text';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';
import { getUserColors } from './UserColors';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';

type ColorPickerProps = {
  selectColor: string;
  onCancel: () => void;
  onSelectColor: (selectColor: string) => void;
};

const ColorPicker = (props: ColorPickerProps) => {
  const translate = useTranslate();
  const { selectColor, onCancel, onSelectColor } = props;

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [selectedColor, setSelectedColor] = useState<string>(selectColor);

  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.SELECT_COLOR_MODAL_TITLE)}
      titleTextType={TextType.BODY_BOLD}
      onClose={onCancel}
      containerStyle={{ width: window.innerWidth <= mobileSize ? '256px' : '377px' }}
      header={true}
      isScroll={false}
    >
      <ColorContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {getUserColors().map((color) => {
          return (
            <ColorSpan
              className={`color-span ${color.value === selectedColor && 'active'}`}
              onClick={() => {
                setSelectedColor(color.value);
              }}
              key={color.id}
              color={color.value}
            />
          );
        })}
      </ColorContainer>
      <ButtonsContainer>
        <Button textType={TextType.CAPTION_BOLD} onClick={onCancel} secondary='secondary'>
          {translate(LANGUAGE_KEYS.CANCEL)}
        </Button>
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onCancel();
            onSelectColor(selectedColor);
          }}
        >
          {translate(LANGUAGE_KEYS.ADD_COLOR)}
        </Button>
      </ButtonsContainer>
    </BaseDialog>
  );
};

type ColorProps = {
  color: string;
};

const ColorContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 24px;
    margin-bottom: 27px;
    .color-span {
      width: 48px;
      height: 48px;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      .color-span {
        width: 28px;
        height: 28px;
      }
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ColorSpan = styled.span<ColorProps>(
  ({ color }) => `
    cursor:pointer;
    background: ${color};
    border-radius: 50%;
    border: 4px solid ${color};
    &.active {
      border-color: var(--dts_default_blue)
    }
  `,
);

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export default ColorPicker;

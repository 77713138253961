import { BillingRepository } from '../../domain/billing/BillingRepository';

type GetCompanySubscriptionRepository = Pick<BillingRepository, 'getCompanySubscription'>;

const getCompanySubscriptionUseCase = (repo: GetCompanySubscriptionRepository) => {
  return repo.getCompanySubscription();
};

export { getCompanySubscriptionUseCase };
export type { GetCompanySubscriptionRepository };

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import Avatar from '../components/generic/Avatar';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as Close } from '../../icons//close.svg';
import { ReactComponent as CheckmarkIcon } from '../../icons/checkmarkIcon.svg';

type BillingUpdateSubscriptionModalProps = {
  downgrade: boolean;
  title: string;
  packageName: string | undefined;
  description: string;
  endDate: string | undefined;
  userName: string | undefined;
  actionDescription: string;
  buttonText: string;
  packageType: number;
  billingType: number;
  onClose: () => void;
  onClick: (packageType: number, billingType: number) => void;
};

const PLUS_FEATURES = [LANGUAGE_KEYS.FEATURE_UNLIMITED_EMPLOYE, LANGUAGE_KEYS.FEATURE_E_FACTURA];

const BillingUpdateSubscriptionModal = (props: BillingUpdateSubscriptionModalProps) => {
  const translate = useTranslate();
  const {
    downgrade,
    title,
    packageName,
    description,
    endDate,
    userName,
    actionDescription,
    buttonText,
    packageType,
    billingType,
    onClose,
    onClick,
  } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={title}
      onClose={onClose}
      containerStyle={{ width: '408px' }}
      header={true}
      isScroll={false}
    >
      <SubscriptionInformation>
        <Avatar
          fullName={userName}
          size={{ outer: 64, inner: 62 }}
          textType={TextType.TITLE_BOLD_4}
        />
        <Details>
          <Text type={TextType.CAPTION_BOLD}>
            {translate(LANGUAGE_KEYS.SUBSCRIPTION_PLAN, {
              packigename: translate(packageName),
            })}
          </Text>
          <Text type={TextType.CAPTION_REGULAR}>
            {'"' +
              translate(LANGUAGE_KEYS.ACCSES_UNTIL, {
                packigeexpiredate: endDate,
              }) +
              '"'}
          </Text>
        </Details>
      </SubscriptionInformation>
      <Text type={TextType.CAPTION_REGULAR}>{description}</Text>
      <FeaturesList>
        {PLUS_FEATURES.map((feature, index) => {
          return (
            <Feature key={index}>
              {downgrade && <Close className='red' width={12} height={12} />}
              {!downgrade && <CheckmarkIcon className='green' width={18} height={18} />}
              <Text type={TextType.CAPTION_REGULAR}>{translate(feature)}</Text>
            </Feature>
          );
        })}
      </FeaturesList>
      <Text type={TextType.CAPTION_BOLD}>{actionDescription}</Text>
      <Actions>
        <Button
          secondary={'secondary'}
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onClose();
          }}
        >
          {translate(LANGUAGE_KEYS.BACK)}
        </Button>
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onClick(packageType, billingType);
          }}
        >
          {buttonText}
        </Button>
      </Actions>
    </BaseDialog>
  );
};

const SubscriptionInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--border-grey);
  border-radius: 4px;
  gap: 16px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100% - 80px);
  width: calc(100% - 80px);
  word-wrap: break-word;
  white-space: normal;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const FeaturesList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Feature = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  .red {
    path {
      fill: var(--dts_red);
    }
  }
  .green {
    path {
      fill: var(--dts_success);
    }
  }
`;

export default BillingUpdateSubscriptionModal;

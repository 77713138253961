import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';

type resetPasswordUseCasesRepository = Pick<SignUpFlowRepository, 'resetPassword'>;

const resetPasswordUseCases = (repo: resetPasswordUseCasesRepository, username: string) => {
  return repo.resetPassword(username);
};

export { resetPasswordUseCases };
export type { resetPasswordUseCasesRepository };

import {
  FINISHED_W_ERROR_CLUMSY,
  FINISHED_W_ERROR_INATTENTIVE,
  FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE,
} from '../data/cases/detail/PhaseStatus';
import useTranslate from '../presentation/translations/useTranslate';
import { LANGUAGE_KEYS } from '../presentation/translations/languageKeys';

const errorStatus = [
  { value: FINISHED_W_ERROR_CLUMSY, label: LANGUAGE_KEYS.CLUMSY },
  { value: FINISHED_W_ERROR_INATTENTIVE, label: LANGUAGE_KEYS.INATTENTIVE },
  { value: FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE, label: LANGUAGE_KEYS.INSUFFICIENT_KNOWLEDGE },
];

const getErrorStatusByStatusUseCase = (statusId: number) => {
  // const translate = useTranslate();
  switch (statusId) {
    case FINISHED_W_ERROR_CLUMSY:
      return errorStatus[0].label;
    case FINISHED_W_ERROR_INATTENTIVE:
      return errorStatus[1].label;
    case FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE:
      return errorStatus[2].label;
    default:
      return null;
  }
};

const getErrorStatusUseCase = () => {
  const translate = useTranslate();
  errorStatus.forEach((es) => {
    es.label = translate(es.label);
  });
  return errorStatus;
};

export { getErrorStatusUseCase, getErrorStatusByStatusUseCase };

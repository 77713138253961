import React, { useContext, useEffect, useState } from 'react';
import { useCaseRepositoryImplementation } from '../../data/cases/CaseRepositoryImplementation';
import styled from 'styled-components';

import { DashboardCardComponent } from './DashboardCardComponent';
import { useDashboardViewModel } from './DashboardViewModel';
import { DashboardUiModel } from '../ui-model/DashboardUiModel';
import DashboardFilters from './DashboardFilters';
import { useNavigate } from 'react-router-dom';
import { DashboardContext } from './DashboardProvider';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../../data/worktype/WorkTypeRepositoryImplementation';
import { ACTIVE, ALL, DRAFT } from './DashboardFilter';
import Searchbar from '../components/generic/Searchbar';
import EmptyView from '../components/generic/EmptyView';
import { useCompany } from '../hooks/useCompany';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import ToggleHeader from '../components/generic/ToggleHeader';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import DesktopHeader from '../components/generic/DesktopHeader';
import { DeviceSizeProps } from '../DeviceInformations';
import ContentLayout from '../components/layout/ContentLayout';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';

export type DashboardContentBodyHeaderProps = {
  filters: any[];
  onHandleChangeSearch: (e) => void;
  onHandleSearchClose: () => void;
};

function DashboardContentBodyHeader(props: DashboardContentBodyHeaderProps) {
  const { filters, onHandleChangeSearch, onHandleSearchClose } = props;
  const translate = useTranslate();
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const inputSearchStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--dts_black)',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    padding: '9px 35px 9px 8px',
    borderRadius: '3px',
  };

  const onHandleChangeSearchBar = (e) => {
    onHandleChangeSearch(e);
  };

  const onClickSearchClose = () => {
    onHandleSearchClose();
  };

  return (
    <>
      <ContentBodyHeader
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <FilterContent
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <DashboardFilters filters={filters} />
        </FilterContent>
        <SearchContent
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <Searchbar
            placeHolder={translate(LANGUAGE_KEYS.DASHBOARD_SEARCH_BOARD_PLACEHOLDER)}
            onHandleChangeSearchBar={onHandleChangeSearchBar}
            searchInputStyle={inputSearchStyle}
            onHandleCloseSearch={onClickSearchClose}
          />
        </SearchContent>
      </ContentBodyHeader>
    </>
  );
}

function Dashboard() {
  const navigate = useNavigate();
  const translate = useTranslate();
  const repo = useCaseRepositoryImplementation();
  const cRepo = useContactsRepositoryImplementation();
  const wRepo = useWorkTypeRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const {
    dashboardCases,
    getCases,
    getWorkers,
    createCase,
    isEnabledNewCaseFeatureAction,
    validSubscription,
  } = useDashboardViewModel(repo, cRepo, wRepo, featureRepo, loggedUserRepository);

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  const currentCompany = useCompany();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const [dashboardCasesCopy, setDashboardCasesCopy] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [enabledCreateCase, setEnabledCreateCase] = useState(true);

  const setFiltersValue = (cases) => {
    const listActive = cases.filter((item) => {
      if (item.status === ACTIVE) {
        return item;
      }
    });
    const listDraft = cases.filter((item) => {
      if (item.status === DRAFT) {
        return item;
      }
    });
    const filters = [
      {
        label: LANGUAGE_KEYS.DASHBOARD_FILTER_ALL,
        filterType: ALL,
        indicator: false,
        elementNr: cases.length,
      },
      {
        label: LANGUAGE_KEYS.DASHBOARD_FILTER_ACTIVE,
        filterType: ACTIVE,
        indicator: false,
        elementNr: listActive.length,
      },
      // { label: 'Pendings', filterType: PENDING, indicator: true, elementNr: listPending.length },
      {
        label: LANGUAGE_KEYS.DASHBOARD_FILTER_DRAFTS,
        filterType: DRAFT,
        indicator: false,
        elementNr: listDraft.length,
      },
    ];
    return filters;
  };

  useEffect(() => {
    getCases();
  }, []);

  useEffect(() => {
    getWorkers();
  }, []);

  const onHandleChangeSearch = (e) => {
    if (e.target.value.length <= 0) {
      setDashboardCasesCopy([]);
      setSearchActive(false);
    } else {
      const searchString = e.target.value.toLowerCase();
      const list: any = dashboardCases.filter((item) => {
        if (
          item.clientName?.toLowerCase().includes(searchString) ||
          item.patientName?.toLocaleLowerCase().includes(searchString) ||
          item.id?.toString().includes(searchString)
        ) {
          return item;
        }
      });
      setDashboardCasesCopy(list);
      setSearchActive(true);
    }
  };

  const onHandleSearchClose = () => {
    setDashboardCasesCopy([]);
    setSearchActive(false);
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={'Dashboard'}
          enabledClick={
            currentCompany.userRole !== null && !isMobile
              ? isEnabledNewCaseFeatureAction(currentCompany.userRole)
              : false
          }
          buttonText={translate(LANGUAGE_KEYS.NEW_CASE)}
          onClick={() => {
            if (validSubscription) {
              if (enabledCreateCase) {
                setEnabledCreateCase(false);
                createCase((id) => {
                  navigate(`/${currentCompany.companyId}/case/` + id);
                  setEnabledCreateCase(true);
                });
              }
            } else {
              showPaymentExpireModal(currentCompany.userRole);
            }
          }}
          buttonIcon={<PlusIcon />}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={'Dashboard'}
          enabledClick={
            currentCompany.userRole !== null
              ? isEnabledNewCaseFeatureAction(currentCompany.userRole)
              : false
          }
          buttonText={translate(LANGUAGE_KEYS.NEW_CASE)}
          onClick={() => {
            if (validSubscription) {
              if (enabledCreateCase) {
                setEnabledCreateCase(false);
                createCase((id) => {
                  navigate(`/${currentCompany.companyId}/case/` + id);
                  setEnabledCreateCase(true);
                });
              }
            } else {
              showPaymentExpireModal(currentCompany.userRole);
            }
          }}
          buttonIcon={<PlusIcon />}
        />
      )}
      <ContentLayout>
        <DashboardContentBodyHeader
          filters={setFiltersValue(searchActive ? dashboardCasesCopy : dashboardCases)}
          onHandleChangeSearch={onHandleChangeSearch}
          onHandleSearchClose={onHandleSearchClose}
        />
        {searchActive && dashboardCasesCopy.length <= 0 ? (
          <EmptyView allHeightOtherItems={170} />
        ) : (
          <ContentBodyList
            caseList={searchActive ? dashboardCasesCopy : dashboardCases}
            onClick={(id) => navigate(`/${currentCompany.companyId}/case/` + id)}
          />
        )}
      </ContentLayout>
    </>
  );
}

function ContentBodyList(props: {
  caseList: DashboardUiModel[] | undefined;
  onClick: (id: number | undefined) => void;
}) {
  const [menuSelection] = useContext(DashboardContext);

  return (
    <ContentBodyListStyled>
      {buildCaseGrid(
        props.caseList?.filter((value) => {
          return menuSelection & value.status;
        }),
        props.onClick,
      )}
    </ContentBodyListStyled>
  );
}

function buildCaseGrid(
  caseList: DashboardUiModel[] | undefined,
  onClick: (id: number | undefined) => void,
) {
  const caseViw: any[] = [];
  caseList?.map(function (c, i) {
    caseViw.push(
      <DashboardCardComponent
        caseModel={c}
        onClick={() => onClick(c.id)}
        key={i}
      ></DashboardCardComponent>,
    );
  });
  return caseViw;
}

const ContentBodyHeader = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    //Desktop
    @media (min-width: ${desktop}px) {
      flex-wrap: nowrap;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-direction: row;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const FilterContent = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    gap: 8px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      width: 100%;
      justify-content: start;
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: auto;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const SearchContent = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    border-radius: 3px;
    border: 1px solid var(--dts_light_grey);
    min-width: 276px;
    
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      min-width: unset;
      flex-grow: 1;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentBodyListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export default Dashboard;

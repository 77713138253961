import React from 'react';

import { LobbyRepository } from '../../domain/lobby/LobbyRepository';
import { CompanyData } from '../../domain/lobby/Lobby';
import { getLobbyCompaniesUseCase } from '../../useCases/lobby/getLobbyCompaniesUseCase';
import { answerWorkerInvitationUseCase } from '../../useCases/user-management/updateContactUseCase';
import { LobbyCompanyUiModel, LobbyCardsType } from '../ui-model/lobby/LobbyUiModel';
import Avatar from '../components/generic/Avatar';
import { TextType } from '../components/dsm/Text';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';
import { ContactsRepository } from '../../domain/user-management/UsersRepository';
import { AnswerWorkerInvitation } from '../../domain/user-management/Contact';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';

const useLobbyViewModel = (
  repo: LobbyRepository,
  loggedUserRepo: LoggedUserRepository,
  contactRepo: ContactsRepository,
) => {
  const getLobbyCompanies = React.useCallback(() => {
    return getLobbyCompaniesUseCase(repo);
  }, [loggedUserRepo.session, loggedUserRepo.userId]);

  const answerWorkerInvitation = React.useCallback(
    (answerInv: AnswerWorkerInvitation) => {
      return answerWorkerInvitationUseCase(contactRepo, answerInv).then((resp) => {
        if (resp) {
          getLobbyCompanies();
        }
      });
    },
    [loggedUserRepo.session, loggedUserRepo.userId],
  );

  return {
    userName: loggedUserRepo.firstName + ' ' + loggedUserRepo.lastName,
    lobbyCompaniesIsLoading: repo.isLoading,
    lobbyCompanies: changeLobbyCompaniesToUiModel(repo.lobbyCompanies),
    getLobbyCompanies,
    answerWorkerInvitation,
  };

  function changeLobbyCompaniesToUiModel(list: Array<CompanyData>): Array<LobbyCompanyUiModel> {
    const translate = useTranslate();
    const newList: Array<LobbyCompanyUiModel> = [];
    list.forEach((company) => {
      newList.push({
        type:
          company.userRole !== null && company.userRole < 0
            ? LobbyCardsType.INVITATION
            : LobbyCardsType.SIMPLE,
        icon: (
          <Avatar
            fullName={company.companyName === null ? '' : company.companyName}
            size={{ outer: 72, inner: 70 }}
            textType={TextType.TITLE_BOLD_1}
            avatarStyle={{ background: company.color }}
          />
        ),
        companyId: company.companyId,
        companyName: company.companyName,
        address: '',
        userRole: company.userRole,
        companyColor: company.color,
        collaborators: [],
        inviteText:
          company.userRole !== null && company.userRole < 0
            ? translate(LANGUAGE_KEYS.INVITATION_CARD_BODY, {
                date: '12 June 2023',
                name: 'Javier Fernandes',
              })
            : '',
      });
    });
    newList.push({
      type: LobbyCardsType.CREATE,
      icon: null,
      companyId: null,
      companyName: null,
      companyColor: '',
      address: null,
      userRole: null,
      collaborators: null,
      inviteText: null,
    });
    return newList;
  }
};

export { useLobbyViewModel };

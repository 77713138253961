import { Text, TextType } from '../../components/dsm/Text';
import React from 'react';
import styled from 'styled-components';
import { Extra } from '../../../domain/Extra';
import { SelectableList, SelectableListValues } from '../../components/generic/SelectableList';

type WorkSheetToothExtrasProps = {
  title: string;
  extras: Array<Extra>;
  onChange: (extras: Array<Extra>) => void;
  disabled?: boolean;
};

export const Extras = (props: WorkSheetToothExtrasProps) => {
  const { title, extras, onChange, disabled } = props;

  const handleOnExtraChange = (values: SelectableListValues) => {
    onChange(
      values.map((value) => {
        if (value.kind === 'Checkbox') {
          return {
            kind: 'Simple',
            id: value.id,
            groupId: value.groupId,
            name: value.value,
            active: value.checked,
            disabled: value.disabled,
          };
        } else {
          return {
            kind: 'Selectable',
            id: value.id,
            values: value.values.map((value) => ({
              id: value.id,
              name: value.value,
              selected: value.selected,
            })),
          };
        }
      }),
    );
  };

  return (
    <ExtrasContainer>
      <TitleContainer>
        <Text style={{ padding: '0 8px', opacity: '0.7' }} type={TextType.BODY_BOLD}>
          {title}
        </Text>
      </TitleContainer>
      {extras.length > 0 && (
        <SelectableList
          values={extras.map((extra) => {
            switch (extra.kind) {
              case 'Simple':
                return {
                  kind: 'Checkbox',
                  id: extra.id,
                  groupId: extra.groupId,
                  value: extra.name,
                  checked: extra.active || false,
                  disabled: disabled || extra.disabled || false,
                };
              case 'Selectable':
                return {
                  kind: 'Dropdown',
                  id: extra.id,
                  values: extra.values.map((extraValue) => ({
                    id: extraValue.id,
                    value: extraValue.name,
                    selected: extraValue.selected || false,
                  })),
                  disabled: disabled || extra.disabled || false,
                };
            }
          })}
          onChange={(values) => handleOnExtraChange(values)}
        />
      )}
    </ExtrasContainer>
  );
};

const ExtrasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

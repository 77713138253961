import styled from 'styled-components';
import { DeviceSizeProps } from '../../DeviceInformations';

export const MenuElementCss = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 175px;
    gap: 12px;
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
    svg {
      fill: var(--dts_black);
      flex-shrink: 0;
    }
    cursor: pointer;
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 0px 16px 0px 0px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 0;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export const MenuSubElementCss = styled.div`
  min-height: 40px;
`;

import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type NewAuthRepository = Pick<
  E_facturaRepository,
  | 'activateEfacturaByAccauntat'
  | 'activateEfacturaByUser'
  | 'refreshEToken'
  | 'saveEfacturaAuthCode'
  | 'saveEfacturaAuthError'
  | 'resetEfacturaAuthValues'
>;

const activateEfacturaByAccauntatUseCase = (
  repo: NewAuthRepository,
  activationToken: string,
  eCode: string,
) => {
  return repo.activateEfacturaByAccauntat(activationToken, eCode);
};

const activateEfacturaByUserUseCase = (repo: NewAuthRepository, code: string) => {
  return repo.activateEfacturaByUser(code);
};

const refreshETokenUseCase = (repo: NewAuthRepository) => {
  return repo.refreshEToken();
};

const saveEfacturaAuthCodeUseCase = (repo: NewAuthRepository, code: string) => {
  repo.saveEfacturaAuthCode(code);
};
const saveEfacturaAuthErrorUseCase = (repo: NewAuthRepository, error: string) => {
  repo.saveEfacturaAuthError(error);
};

const resetEfacturaAuthValuesUseCase = (repo: NewAuthRepository) => {
  repo.resetEfacturaAuthValues();
};

export {
  activateEfacturaByAccauntatUseCase,
  activateEfacturaByUserUseCase,
  refreshETokenUseCase,
  saveEfacturaAuthCodeUseCase,
  saveEfacturaAuthErrorUseCase,
  resetEfacturaAuthValuesUseCase,
};
export type { NewAuthRepository };

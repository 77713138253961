import React, { useState, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { useTemplatesViewModel } from '../../../worktypeitem/TemplatesViewModel';
import { useTemplateRepositoryImplementation } from '../../../../../../data/worktype/templates/TemplateRepositoryImplementation';
import { WorkPhaseGroup } from '../../../../../../domain/WorkPhaseGroup';
import { TemplatePhaseUiModel } from '../../../../../ui-model/worktype/TemplatePhaseUiModel';

import EditDialog from './EditDialog';
import { LANGUAGE_KEYS } from '../../../../../translations/languageKeys';
import useTranslate from '../../../../../translations/useTranslate';

export type TemplateEditPhaseDialogProps = {
  templateId: number;
  templateName: string;
  all: TemplatePhaseUiModel[];
  added: TemplatePhaseUiModel[];
  modalName: string;
  onClose: () => void;
};

const TemplateEditPhaseDialog = (props: TemplateEditPhaseDialogProps) => {
  const translate = useTranslate();
  const { all, added, modalName, onClose } = props;
  const [addedList, setAddedList] = useState(added);
  const [addedListSize, setAddedListSize] = useState(added.length);
  const [allList, setAllList] = useState(all);
  const [emptyAddedList, setEmptyAddedList] = useState(added.length <= 0);
  const [isScrollToBottom, setIsScrollToBottom] = useState(false);

  const templatesRepo = useTemplateRepositoryImplementation();
  const { addPhasesToTemplate, removePhasesFromTemplate } = useTemplatesViewModel(templatesRepo);

  useEffect(() => {
    setAddedListValue();
  }, []);

  const setAddedListValue = () => {
    addedList.forEach((item) => {
      item.id = uuid();
    });
  };

  const reorder = (list: TemplatePhaseUiModel[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const phaseIds: number[] = [];
    result.map((item, index) => {
      item.order = index;
      phaseIds.push(item.copyId!);
    });
    addPhasesToTemplate({
      templateId: props.templateId,
      templateName: props.templateName,
      phases: phaseIds,
    });

    return result;
  };

  const copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = JSON.parse(JSON.stringify(source));
    const destClone = destination;
    const item: TemplatePhaseUiModel = sourceClone[droppableSource.index];
    item.copyId = item.id;
    destClone.splice(droppableDestination.index, 0, item);
    if (destClone.length > 0) {
      setEmptyAddedList(false);
    } else {
      setEmptyAddedList(true);
    }
    const phaseIds: number[] = [];
    destClone.map((item) => {
      phaseIds.push(item.copyId);
      item.id = uuid();
    });
    setAddedListSize(destClone.length);
    addPhasesToTemplate({
      templateId: props.templateId,
      templateName: props.templateName,
      phases: phaseIds,
    });
    return destClone;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const onDragEndPhase = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    switch (source.droppableId) {
      case destination.droppableId:
        if (destination.droppableId !== 'All') {
          setAddedList(reorder(addedList, source.index, destination.index));
        }
        break;
      case 'All': {
        setAddedList(copy(allList, addedList, source, destination));
        break;
      }
      default:
        move(allList, allList, source, destination);
        break;
    }
    setIsScrollToBottom(false);
  };

  const onHandleClearAll = () => {
    setAllList(all);
    setAddedList([]);
    addedList.length = 0;
    setAddedListSize(0);
    setEmptyAddedList(true);
    setIsScrollToBottom(false);
    removePhasesFromTemplate({
      templateId: props.templateId,
      templateName: props.templateName,
      phases: [],
    });
  };

  const onHandleChangeSearchBar = (e) => {
    if (e.target.value.length <= 0) {
      setAllList(all);
    } else {
      const list: WorkPhaseGroup[] = all.filter((item) => {
        if (item.name?.toUpperCase().includes(e.target.value.toUpperCase())) {
          return item;
        }
      });
      setAllList(list);
    }
    setIsScrollToBottom(false);
  };

  const onHandleCloseSearch = () => {
    setAllList(all);
    setIsScrollToBottom(false);
  };

  const onDeleteItem = (item: TemplatePhaseUiModel, index: number) => {
    const newAddedList = addedList;
    newAddedList.splice(index, 1);
    setAddedList(newAddedList);
    setAddedListSize(newAddedList.length);
    if (newAddedList.length == 0) {
      setEmptyAddedList(true);
    }
    setIsScrollToBottom(false);
    const phaseIds: number[] = [];
    newAddedList.map((item) => {
      phaseIds.push(item.copyId!);
    });

    removePhasesFromTemplate({
      templateId: props.templateId,
      templateName: props.templateName,
      phases: phaseIds,
    });
  };

  const handleClick = (item: TemplatePhaseUiModel) => {
    const copyItem = JSON.parse(JSON.stringify(item));
    copyItem.id = uuid();
    addedList.push(copyItem);
    setAddedListSize(addedList.length);
    if (addedList.length != 0) {
      setEmptyAddedList(false);
    }
    setIsScrollToBottom(true);
    const phaseIds: number[] = [];
    addedList.map((item) => {
      phaseIds.push(item.copyId!);
    });
    addPhasesToTemplate({
      templateId: props.templateId,
      templateName: props.templateName,
      phases: phaseIds,
    });
  };

  return (
    <EditDialog
      allList={allList}
      addedList={addedList}
      emptyAddedList={emptyAddedList}
      addedListSize={addedListSize}
      modalName={modalName}
      emptyAddedListText={translate(LANGUAGE_KEYS.DRAG_ELEMENT_TO_CONNECT_PHASES)}
      addedListTitle={translate(LANGUAGE_KEYS.ADDED_PHASES)}
      searchPlaceHolder={translate(LANGUAGE_KEYS.SEARCH_PHASE)}
      draggableType={'phase'}
      isScrollToBottom={isScrollToBottom}
      dragEndDrop={onDragEndPhase}
      onHandleClearAll={onHandleClearAll}
      onDeleteItem={onDeleteItem}
      onHandleChangeSearchBar={onHandleChangeSearchBar}
      onHandleCloseSearch={onHandleCloseSearch}
      onClose={onClose}
      onClick={handleClick}
    />
  );
};

export default TemplateEditPhaseDialog;

import React from 'react';
import styled from 'styled-components';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { WorktypeItemTab } from './WorktypeItemTab';
import Breadcrumb, { BreadcrumbItem } from '../../../components/generic/Breadcrumb';
import Extensions from './Extensions';
import Teeth from './Teeth';
import Templates from './Templates';
import { useWorktypesViewModel } from '../WorktypesViewModel';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { useCompany } from '../../../hooks/useCompany';
import ToggleHeader from '../../../components/generic/ToggleHeader';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import ContentLayout from '../../../components/layout/ContentLayout';
import DesktopHeader from '../../../components/generic/DesktopHeader';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import { TextType } from '../../../components/dsm/Text';

function WorktypeItemContentBodyHeader(props: {
  worktypeItemId: string | undefined;
  worktypeName: string | undefined;
}) {
  const location = useLocation();
  const translate = useTranslate();
  const currentCompany = useCompany();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const breadcrumbList: BreadcrumbItem[] = [
    {
      name: translate(LANGUAGE_KEYS.WORKTYPES),
      link: `/${currentCompany.companyId}/settings/worktype`,
    },
    { name: props.worktypeName === undefined ? '' : props.worktypeName, link: '' },
  ];

  return (
    <>
      <ContentBodyHeader>
        <HeaderTopConatiner>
          <Breadcrumb
            textType={
              !isTabletSmall && !isTabletLarge && !isMobile
                ? TextType.TITLE_BOLD_4
                : TextType.BODY_BOLD
            }
            breadcrumbList={breadcrumbList}
          />
        </HeaderTopConatiner>

        <WorktypeItemTabs>
          <WorktypeItemTab
            to={
              `/${currentCompany.companyId}/settings/worktype/` +
              props.worktypeItemId +
              '/extensions'
            }
            isActive={
              `/${currentCompany.companyId}/settings/worktype/` +
                props.worktypeItemId +
                '/extensions' ===
              location.pathname
            }
            name={translate(LANGUAGE_KEYS.EXTENSIONS)}
          />
          <WorktypeItemTab
            to={`/${currentCompany.companyId}/settings/worktype/` + props.worktypeItemId + '/teeth'}
            isActive={
              `/${currentCompany.companyId}/settings/worktype/` +
                props.worktypeItemId +
                '/teeth' ===
              location.pathname
            }
            name={translate(LANGUAGE_KEYS.TEETH)}
          />
          <WorktypeItemTab
            to={
              `/${currentCompany.companyId}/settings/worktype/` +
              props.worktypeItemId +
              '/templates'
            }
            isActive={
              `/${currentCompany.companyId}/settings/worktype/` +
                props.worktypeItemId +
                '/templates' ===
              location.pathname
            }
            name={translate(LANGUAGE_KEYS.TEMPLATES)}
          />
          <WorktypeItemTabsBottomBorder />
        </WorktypeItemTabs>
      </ContentBodyHeader>
    </>
  );
}

function WorktypeItem() {
  const { id } = useParams();
  const repo = useWorkTypeRepositoryImplementation();
  const { getWorkTypeNameById } = useWorktypesViewModel(repo);
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  return (
    <>
      {(isTabletSmall || isTabletLarge || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={''}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={undefined}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <WorktypeItemContentBodyHeader
          worktypeItemId={id}
          worktypeName={getWorkTypeNameById(Number(id))}
        />
        <Routes>
          <Route path='/extensions' element={<Extensions />} />
          <Route path='/teeth' element={<Teeth />} />
          <Route path='/templates' element={<Templates />} />
        </Routes>
      </ContentLayout>
    </>
  );
}

const ContentBodyHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderTopConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WorktypeItemTabs = styled.div`
  display: flex;
  height: 28px;
  align-items: flex-end;
`;

const WorktypeItemTabsBottomBorder = styled.div`
  flex: 1 1 auto;
  height: 2px;
  background: #e4e4e4;
  flex: none;
  flex-grow: 1;
  align-self: flex-end;
`;

export default WorktypeItem;

import { ContactsRepository } from '../../domain/user-management/UsersRepository';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
  AnswerWorkerInvitation,
} from '../../domain/user-management/Contact';

type contactUseCase = Pick<
  ContactsRepository,
  'updateClient' | 'updateWorker' | 'sendWorkerInvitation' | 'answerWorkerInvitation'
>;

const updateClientUseCase = (repo: contactUseCase, client: UpdateClient) => {
  repo.updateClient(client);
};

const updateWorkerUseCase = (repo: contactUseCase, worker: UpdateWorker) => {
  repo.updateWorker(worker);
};

const sendWorkerInvitationUseCase = (
  repo: contactUseCase,
  invitationData: SendWorkerInvitation,
) => {
  repo.sendWorkerInvitation(invitationData);
};

const answerWorkerInvitationUseCase = (repo: contactUseCase, answerInv: AnswerWorkerInvitation) => {
  return repo.answerWorkerInvitation(answerInv);
};

export {
  updateClientUseCase,
  updateWorkerUseCase,
  sendWorkerInvitationUseCase,
  answerWorkerInvitationUseCase,
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../components/dsm/buttons/Button';
import { TextType } from '../components/dsm/Text';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { ReactComponent as EfacturaIcon } from '../../icons/e-factura/Efactura.svg';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useEfacturaViewModel } from './E-facturaViewModel';
import { BillingType, PackageType } from '../ui-model/billing/PackageUiModel';
import { useCompany } from '../hooks/useCompany';
import BillingUpdateSubscriptionModal from '../billing/BillingUpdateSubscriptionModal';
import { useBillingRepositoryImplementation } from '../../data/billing/billingRepositoryImplementation';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import E_facturaAuthorizationStartModal from './E-facturaAuthorizationStart';
import E_facturaAccountantModal from './E-facturaAccountantModal';
import E_facturaFailedModal from './E_facturaFailedModal';
import E_facturaSuccessModal from './E_facturaSuccessModal';
import E_facturaHelpModal from './E_facturaHelpModal';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { AUTHORIZATION_LINK } from '../ui-model/e-factura/E-factura';
import E_facturaLoadingModal from './E_facturaLoadingModal';
import { useCompaniesRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import E_facturaUploadConfirmationModal from './E_facturaUploadConfirmationModal';
import Spinner from '../components/dsm/Spinner';

// ------------------------------------------------------

type BillingNotificationDialog = OpenBillingNotificationDialog | ClosedBillingNotificationDialog;

type OpenBillingNotificationDialog = {
  kind: 'Open';
  packageName: string | undefined;
  title: string;
  description: string;
  endDate: string | undefined;
  actionDescription: string;
  buttonText: string;
  onClose: () => void;
  onClick: (packageType: number, billingType: number) => void;
};

type ClosedBillingNotificationDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type AuthorizationStartDialog = OpenAuthorizationStartDialog | ClosedAuthorizationStartDialog;

type OpenAuthorizationStartDialog = {
  kind: 'Open';
  onClose: () => void;
  openAccountantDialog: () => void;
  selfAuthorization: () => void;
  openHelpModalDialog: () => void;
};

type ClosedAuthorizationStartDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type AccountantDialog = OpenAccountantDialog | ClosedAccountantDialog;

type OpenAccountantDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedAccountantDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type FaildModalDialog = OpenFaildModalDialog | ClosedFaildModalDialog;

type OpenFaildModalDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedFaildModalDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type SuccessModalDialog = OpenSuccessModalDialog | ClosedSuccessModalDialog;

type OpenSuccessModalDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedSuccessModalDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type HelpModalDialog = OpenHelpModalDialog | ClosedHelpModalDialog;

type OpenHelpModalDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedHelpModalDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type LoadingModalDialog = OpenLoadingModalDialog | ClosedLoadingModalDialog;

type OpenLoadingModalDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedLoadingModalDialog = { kind: 'Closed' };

// ------------------------------------------------------

// ------------------------------------------------------

type UploadConfirmationDialog = OpenUploadConfirmationDialog | ClosedUploadConfirmationDialog;

type OpenUploadConfirmationDialog = {
  kind: 'Open';
  onUpload: () => void;
  onClose: () => void;
};

type ClosedUploadConfirmationDialog = { kind: 'Closed' };

function E_factura(props: {
  isInvoice: boolean;
  invociceName: string | null;
  invoiceId: number | null;
  isLoadedFactura: boolean | null;
  disableButtonAction: boolean;
  updateStatus: () => void;
}) {
  const translate = useTranslate();

  const currentCompany = useCompany();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const billingRepository = useBillingRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const {
    eCode,
    eError,
    myCompanyEtoken,
    packageType,
    username,
    billingType,
    subscriptionEndTime,
    updateCompanyPackage,
    getMaxCollaborators,
    generateEActivationToken,
    activateEfacturaByUser,
    uploadEFactura,
    resetEfacturaAuthValues,
  } = useEfacturaViewModel(
    billingRepository,
    loggedUserRepository,
    e_factuarRepository,
    companiesRepo,
  );

  const [maxEmployeeNumber, setMaxEmployeeNumber] = useState<number>();

  const [showSpinnerButton, setShowSpinnerButton] = useState<boolean>(false);

  const [locationKey, setLocationKey] = useState(0);

  useEffect(() => {
    getMaxCollaborators().then((resp) => {
      if (resp) {
        resp !== true && setMaxEmployeeNumber(resp);
      }
    });
  }, []);

  const handlePageShow = (event) => {
    if (event.persisted) {
      console.log('handlePageShow good: ' + eError);
      // window.location.reload();
      setLocationKey((prevKey) => prevKey + 1);
    }

    console.log('handlePageShow general not persisted: ' + eError);
  };

  useEffect(() => {
    window.addEventListener('pageshow', handlePageShow);
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  useEffect(() => {
    // console.log('eCode: ' + eCode);
    // console.log('eError: ' + eError);
    // console.log('currentCompany.companyId: ' + currentCompany.companyId);
    const eCode2 = localStorage.getItem('eCode');
    const eError2 = localStorage.getItem('eError');
    console.log('LocalStorage updated:', eError);
    if (eCode2 !== null && currentCompany.companyId !== null) {
      openLoadingModalDialog();
      activateEfacturaByUser(eCode2).then((resp) => {
        closeLoadingModalDialog();
        if (resp) {
          openSuccessModalDialog();
          resetEfacturaAuthValues();
        } else {
          openFailedModalDialog();
          resetEfacturaAuthValues();
        }
      });
    }
    if (eError2 !== null) {
      openFailedModalDialog();
      resetEfacturaAuthValues();
    }
  }, [locationKey, currentCompany.companyId]);

  // ------------------------------------------------------

  const [billingUpgradeDialog, setBillingUpgradeDialog] = React.useState<BillingNotificationDialog>(
    {
      kind: 'Closed',
    },
  );

  const closeBillingUpgradeDialog = () => {
    setBillingUpgradeDialog({ kind: 'Closed' });
  };

  const openBillingUpgradeDialog = () => {
    let packageName = '';
    if (packageType === PackageType.BASIC) packageName = LANGUAGE_KEYS.PACKAGE_TILE_BASIC;
    if (packageType === PackageType.STANDARD) packageName = LANGUAGE_KEYS.PACKAGE_TILE_PRO;
    if (packageType === PackageType.PRO) packageName = LANGUAGE_KEYS.PACKAGE_TILE_PLUS;
    setBillingUpgradeDialog({
      kind: 'Open',
      title: translate(LANGUAGE_KEYS.UPGRADE_SUBSCRIPTION),
      packageName: translate(packageName),
      description: translate(LANGUAGE_KEYS.DOWNGRADE_SUBSCRIPTION_DESCRIPTION, {
        user: username,
        employee_number: maxEmployeeNumber,
      }),
      endDate:
        subscriptionEndTime !== null
          ? new Date(subscriptionEndTime).toLocaleString('en-us', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            })
          : '',
      actionDescription: '',
      buttonText: translate(LANGUAGE_KEYS.UPGRADE),
      onClose: closeBillingUpgradeDialog,
      onClick: (packageType: number, billingType: number) => {
        handleUpdateCompanyPackage(packageType, billingType);
      },
    });
  };

  // ------------------------------------------------------

  const [authorizationStartDialog, setAuthorizationStartDialog] =
    React.useState<AuthorizationStartDialog>({
      kind: 'Closed',
    });

  const closeAuthorizationStartDialog = () => {
    setAuthorizationStartDialog({ kind: 'Closed' });
  };

  const openAuthorizationStartDialog = () => {
    setAuthorizationStartDialog({
      kind: 'Open',
      onClose: closeAuthorizationStartDialog,
      openAccountantDialog: openAccountantDialog,
      selfAuthorization: () => {
        window.location.href = AUTHORIZATION_LINK;
      },
      // selfAuthorization: openSuccessModalDialog,
      openHelpModalDialog: openHelpModalDialog,
    });
  };

  // ------------------------------------------------------

  const [accountantDialog, setAccountantDialog] = React.useState<AccountantDialog>({
    kind: 'Closed',
  });

  const closeAccountantDialog = () => {
    setAccountantDialog({ kind: 'Closed' });
  };

  const openAccountantDialog = () => {
    closeAuthorizationStartDialog();
    setAccountantDialog({
      kind: 'Open',
      onClose: closeAccountantDialog,
    });
  };

  // ------------------------------------------------------

  const [failedModalDialog, setFailedModalDialog] = React.useState<FaildModalDialog>({
    kind: 'Closed',
  });

  const closeFailedModalDialog = () => {
    setFailedModalDialog({ kind: 'Closed' });
  };

  const openFailedModalDialog = () => {
    closeAuthorizationStartDialog();
    setFailedModalDialog({
      kind: 'Open',
      onClose: closeFailedModalDialog,
    });
  };

  // ------------------------------------------------------

  const [successModalDialog, setSuccessModalDialog] = React.useState<SuccessModalDialog>({
    kind: 'Closed',
  });

  const closeSuccessModalDialog = () => {
    setSuccessModalDialog({ kind: 'Closed' });
  };

  const openSuccessModalDialog = () => {
    closeAuthorizationStartDialog();
    setSuccessModalDialog({
      kind: 'Open',
      onClose: closeSuccessModalDialog,
    });
  };

  // ------------------------------------------------------

  const [helpModalDialog, setHelpModalDialog] = React.useState<HelpModalDialog>({
    kind: 'Closed',
  });

  const closeHelpModalDialog = () => {
    setHelpModalDialog({ kind: 'Closed' });
  };

  const openHelpModalDialog = () => {
    closeAuthorizationStartDialog();
    setHelpModalDialog({
      kind: 'Open',
      onClose: closeHelpModalDialog,
    });
  };

  // ------------------------------------------------------

  const [loadingModalDialog, setLoadingModalDialog] = React.useState<LoadingModalDialog>({
    kind: 'Closed',
  });

  const closeLoadingModalDialog = () => {
    setLoadingModalDialog({ kind: 'Closed' });
  };

  const openLoadingModalDialog = () => {
    setLoadingModalDialog({
      kind: 'Open',
      onClose: () => {
        closeLoadingModalDialog();
      },
    });
  };

  // ------------------------------------------------------

  const [uploadFacturaDialog, setUploadFacturaDialog] = React.useState<UploadConfirmationDialog>({
    kind: 'Closed',
  });

  const closeUploadFacturaDialog = () => {
    setUploadFacturaDialog({ kind: 'Closed' });
  };

  const openUploadFacturaDialog = () => {
    setUploadFacturaDialog({
      kind: 'Open',
      onUpload: () => {
        uploadFactura();
      },
      onClose: () => {
        closeUploadFacturaDialog();
      },
    });
  };

  const handleUpdateCompanyPackage = (packageType: PackageType, billingType: BillingType) => {
    if (currentCompany.companyId) {
      updateCompanyPackage({
        id: currentCompany.companyId,
        packageType: PackageType.STANDARD,
        billingType: billingType,
        billingPeriod: 0,
      }).then((resp) => {
        if (resp) {
          closeBillingUpgradeDialog();
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        }
      });
    }
  };

  const handleActivateButton = () => {
    if (packageType === PackageType.BASIC) {
      openBillingUpgradeDialog();
    }
  };

  const uploadFactura = () => {
    closeUploadFacturaDialog();
    setShowSpinnerButton(true);
    if (props.invoiceId !== null && props.invoiceId !== undefined) {
      uploadEFactura(props.invoiceId).then((resp) => {
        if (resp) {
          props.updateStatus();
          showAlert(
            translate(LANGUAGE_KEYS.E_FACTURA_SEND_GOOD_RESPONSE, {
              invoiceName: props.invociceName,
            }),
            AlertType.SUCCESS,
          );
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        }
        setShowSpinnerButton(false);
      });
    }
  };

  return (
    <>
      {packageType === PackageType.BASIC && !showSpinnerButton && (
        <ButtonConatiner className='efactura-button'>
          <Button
            textType={TextType.CAPTION_BOLD}
            onClick={() => {
              !props.disableButtonAction && handleActivateButton();
            }}
          >
            <CustomEfacturaIcon width={20} height={20} />
            {translate(LANGUAGE_KEYS.ACTIVATE_E_FACTURA)}
          </Button>
          {billingUpgradeDialog.kind === 'Open' && (
            <BillingUpdateSubscriptionModal
              downgrade={false}
              title={billingUpgradeDialog.title}
              packageName=''
              description={billingUpgradeDialog.description}
              endDate={billingUpgradeDialog.endDate}
              actionDescription={billingUpgradeDialog.actionDescription}
              userName={username}
              packageType={packageType !== null ? packageType : -1}
              billingType={billingType !== null ? billingType : -1}
              onClose={billingUpgradeDialog.onClose}
              onClick={billingUpgradeDialog.onClick}
              buttonText={billingUpgradeDialog.buttonText}
            />
          )}
        </ButtonConatiner>
      )}
      {packageType !== PackageType.BASIC && !showSpinnerButton && (
        <ButtonConatiner
          className={`efactura-button ${
            myCompanyEtoken === null
              ? 'no-authorization'
              : props.isInvoice
              ? 'authorization upload'
              : 'authorization'
          } `}
        >
          {myCompanyEtoken === null && (
            <Button
              textType={TextType.CAPTION_BOLD}
              onClick={() => {
                !props.disableButtonAction && openAuthorizationStartDialog();
              }}
            >
              <CustomEfacturaIcon width={20} height={20} />
              {translate(LANGUAGE_KEYS.GET_AUTHORIZATION)}
            </Button>
          )}
          {myCompanyEtoken !== null && !props.isInvoice && (
            <Button textType={TextType.CAPTION_BOLD}>
              <CustomEfacturaIcon width={20} height={20} />
              {translate(LANGUAGE_KEYS.AUTHORIZATION_SPV)}
            </Button>
          )}
          {myCompanyEtoken !== null && props.isInvoice && (
            <>
              <Button
                style={
                  props.isLoadedFactura === null
                    ? { background: 'var(--dts_default_blue)' }
                    : props.isLoadedFactura
                    ? { background: '#46464633', pointerEvents: 'none' }
                    : { background: 'var(--dts_default_blue)' }
                }
                textType={TextType.CAPTION_BOLD}
                onClick={() => {
                  !props.disableButtonAction && !props.isLoadedFactura && openUploadFacturaDialog();
                }}
              >
                <CustomEfacturaIcon width={20} height={20} />
                {translate(LANGUAGE_KEYS.SEND_E_FACTURA)}
              </Button>
              {uploadFacturaDialog.kind === 'Open' && (
                <E_facturaUploadConfirmationModal
                  onUpload={uploadFacturaDialog.onUpload}
                  onClose={uploadFacturaDialog.onClose}
                />
              )}
            </>
          )}
          {authorizationStartDialog.kind === 'Open' && (
            <E_facturaAuthorizationStartModal
              onClose={authorizationStartDialog.onClose}
              openAccountantDialog={authorizationStartDialog.openAccountantDialog}
              selfAuthorization={authorizationStartDialog.selfAuthorization}
              openHelpModal={authorizationStartDialog.openHelpModalDialog}
            />
          )}
          {accountantDialog.kind === 'Open' && currentCompany.companyId !== null && (
            <E_facturaAccountantModal
              onClose={accountantDialog.onClose}
              companyId={currentCompany.companyId}
              generateActivtionToken={generateEActivationToken}
            />
          )}
          {failedModalDialog.kind === 'Open' && (
            <E_facturaFailedModal onClose={failedModalDialog.onClose} />
          )}
          {successModalDialog.kind === 'Open' && (
            <E_facturaSuccessModal onClose={successModalDialog.onClose} />
          )}
          {helpModalDialog.kind === 'Open' && (
            <E_facturaHelpModal onClose={helpModalDialog.onClose} />
          )}
          {loadingModalDialog.kind === 'Open' && (
            <E_facturaLoadingModal onClose={loadingModalDialog.onClose} />
          )}
        </ButtonConatiner>
      )}
      {showSpinnerButton && (
        <SpinnerButton className='spinner'>
          <Spinner
            width={30}
            height={30}
            beforeHeight={24}
            beforeWidth={24}
            background={'var(--dts_white)'}
          />
        </SpinnerButton>
      )}
    </>
  );
}

const ButtonConatiner = styled.div`
  &.no-authorization {
    .button {
      background: var(--dts_panding);
    }
    .button:hover {
      opacity: 0.9;
    }
  }
  &.authorization {
    .button {
      background: var(--dts_success);
      pointer-events: none;
    }
    &.upload {
      .button {
        background: var(--dts_default_blue);
        pointer-events: unset;
      }
    }
  }
`;

const CustomEfacturaIcon = styled(EfacturaIcon)`
  fill: var(--dts_white);
`;

const SpinnerButton = styled.div`
  display: flex;
  background: var(--dts_white);
  border-radius: 3px;
  border: 1px solid var(--dts_default_blue);
  min-width: 162px;
  .spinner-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export default E_factura;

export const GET_PHASES = 'GET_PHASES';
export const GET_PHASES_SUCCESS = 'GET_PHASES_SUCCESS';
export const GET_PHASES_FAILD = 'GET_PHASES_FAILD';
export const NEW_PHASES = 'NEW_PHASES';
export const NEW_PHASES_SUCCESS = 'NEW_PHASES_SUCCESS';
export const NEW_PHASES_FAILD = 'NEW_PHASES_FAILD';

export const DELETE_PHASE = 'DELETE_PHASE';
export const DELETE_PHASE_SUCCESS = 'DELETE_PHASE_SUCCESS';
export const DELETE_PHASE_FAILD = 'DELETE_PHASE_FAILD';
export const UPDATE_PHASE = 'UPDATE_PHASE';
export const UPDATE_PHASE_SUCCESS = 'UPDATE_PHASE_SUCCESS';
export const UPDATE_PHASE_FAILD = 'UPDATE_PHASE_FAILD';

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Page404 from '../alert/Page404';
import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import useTranslate from '../translations/useTranslate';
import E_facturaLoadingScreen from './E_facturaLoadingScreen';
import E_facturaSuccesScreen from './E_facturaSuccesScreen';
import E_facturaFailedScreen from './E_facturaFailedScreen';
import { useBillingRepositoryImplementation } from '../../data/billing/billingRepositoryImplementation';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { useEfacturaViewModel } from './E-facturaViewModel';
import { AUTHORIZATION_LINK } from '../ui-model/e-factura/E-factura';
import { useCompaniesRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';

const E_facturaAccountant = () => {
  const { hash } = useParams();
  const location = useLocation();
  const translate = useTranslate();

  const queryParams = new URLSearchParams(location.search);

  const [isValidHash, setIsValidHash] = useState<boolean>(false);
  const [activationToken, setActivationToken] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);
  const [success, setSucces] = useState<boolean | undefined>(undefined);

  const billingRepository = useBillingRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const { activateEfacturaByAccauntat, checkActivationToken } = useEfacturaViewModel(
    billingRepository,
    loggedUserRepository,
    e_factuarRepository,
    companiesRepo,
  );

  useEffect(() => {
    if (hash !== undefined) {
      try {
        setIsValidHash(true);
        const decoded = atob(hash);
        const params = decoded.substring(1).split('&');
        const compId = Number(params[0].substring(3));
        const token = params[1].substring(16);
        checkActivationToken({
          companyId: compId,
          token: token,
        }).then((resp) => {
          if (resp) {
            setActivationToken(token);
          } else {
            setIsValidHash(false);
          }
        });
      } catch {
        setIsValidHash(false);
        setActivationToken(null);
      }
    } else if (!checkURLParameters()) {
      setIsValidHash(false);
      setActivationToken(null);
    }
  }, []);

  const checkURLParameters = (): boolean => {
    if (queryParams.get('error') !== null) {
      setIsValidHash(true);
      setSucces(false);
      setLoading(false);
      return true;
    }
    const eCode = queryParams.get('code');
    const activateToken = window.localStorage.getItem('activationToken');
    if (eCode !== null && activateToken !== null) {
      setIsValidHash(true);
      setLoading(false);
      if (eCode !== null && activateToken !== null) {
        activateEfacturaByAccauntat(activateToken, eCode).then((res) => {
          window.localStorage.removeItem('activationToken');
          if (res) {
            setSucces(true);
          } else {
            setSucces(false);
          }
          setLoading(false);
        });
      }
      return true;
    }
    return false;
  };

  const onHandelAuthentication = () => {
    if (activationToken !== null) {
      window.location.href = AUTHORIZATION_LINK;
      setLoading(true);
      window.localStorage.setItem('activationToken', activationToken);
    }
  };

  return isValidHash ? (
    <>
      {!loading && success === undefined && (
        <Container>
          <LogoIcon width={242} height={48} />
          <Text type={TextType.BODY_REGULAR}>
            {translate(LANGUAGE_KEYS.AUTHORIZATION_ACCOUNTABLE_DESCRIPTION_1)}
          </Text>
          <Button
            textType={TextType.CAPTION_BOLD}
            secondary={'secondary'}
            onClick={() => {
              onHandelAuthentication();
            }}
          >
            {translate(LANGUAGE_KEYS.CONTINUE)}
          </Button>
        </Container>
      )}
      {loading && <E_facturaLoadingScreen />}
      {success !== undefined && success && <E_facturaSuccesScreen />}
      {success !== undefined && !success && (
        <E_facturaFailedScreen
          tryAgain={() => {
            setLoading(true);
            setSucces(undefined);
            checkURLParameters();
          }}
        />
      )}
    </>
  ) : (
    <>
      <Page404 isAuthenticated={false} />
    </>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 560px;
  margin: auto;
  gap: 48px;
  p {
    margin: 0;
  }
`;

export default E_facturaAccountant;

import { PhaseRepository } from '../../../domain/PhaseRepository';
import { UpdatePhase } from '../../../domain/worktype/UpdatePhase';

type GetPhaseRepository = Pick<PhaseRepository, 'updatePhase'>;

const updatePhaseUseCase = (repo: GetPhaseRepository, phase: UpdatePhase) => {
  repo.updatePhase(phase);
};

export { updatePhaseUseCase };

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextType } from '../components/dsm/Text';
import useTranslate from '../translations/useTranslate';
import Spinner from '../components/dsm/Spinner';

const E_facturaLoadingScreen = () => {
  const translate = useTranslate();

  return (
    <Container>
      <LogoIcon width={242} height={48} />
      <Text type={TextType.TITLE_BOLD_4}>{translate(LANGUAGE_KEYS.WAIT_2)}</Text>
      <Spinner
        width={64}
        height={64}
        beforeHeight={54}
        beforeWidth={54}
        background={'var(--dts_white)'}
      />
    </Container>
  );
};

const Container = styled.div`
  height: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 518px;
  margin: 230px auto auto auto;
  p {
    margin: 48px 0px 24px 0px;
  }
`;

export default E_facturaLoadingScreen;

import React, { FC } from 'react';
import styled from 'styled-components';

import { Text, TextColor, TextType } from '../../../components/dsm/Text';
import Avatar from '../../../components/generic/Avatar';
import { PayOffPhaseUi } from '../../../ui-model/reports/PayOffUIModel';
import { ReactComponent as DislikeIcon } from '../../../../icons/dislike.svg';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

export type PayOffPhaseCardProps = {
  payOffPhasekModel: PayOffPhaseUi;
  solvedPhase: (phaseId: number, order: number) => void;
};

export const PayOffPhaseCard: FC<PayOffPhaseCardProps> = (payOffPhaseCardProps) => {
  const translate = useTranslate();
  const { payOffPhasekModel, solvedPhase } = payOffPhaseCardProps;

  const handleSolvedPhase = () => {
    if (payOffPhasekModel.phaseId !== undefined) {
      solvedPhase(payOffPhasekModel.phaseId, payOffPhasekModel.order);
    }
  };

  return (
    <RowBody>
      <Container>
        <Avatar
          fullName={payOffPhasekModel.collaboratorAvatar}
          size={{ outer: 24, inner: 22 }}
          avatarStyle={{ background: payOffPhasekModel.collaboratorProfileColor }}
        />
        <Text type={TextType.CAPTION_BOLD}>{payOffPhasekModel.phaseName}</Text>
        {payOffPhasekModel.wrongPhase !== null && (
          <WrongPhaseContainer>
            <CustomDislikeIcon width={12} height={12} />
            <CustomWrongPhaseText type={TextType.CAPTION_REGULAR}>
              {payOffPhasekModel.wrongPhase}
            </CustomWrongPhaseText>
          </WrongPhaseContainer>
        )}
      </Container>
      <Container>
        <Text type={TextType.CAPTION_REGULAR_3}>{payOffPhasekModel.phaseEndDate}</Text>
        <CustomButton
          onClick={handleSolvedPhase}
          className={`${payOffPhasekModel.accounted ? 'solved' : 'unsolved'}`}
        >
          <Text type={TextType.CAPTION_3_BOLD} clr={TextColor.WHITE}>
            {payOffPhasekModel.accounted
              ? translate(LANGUAGE_KEYS.SOLVED)
              : translate(LANGUAGE_KEYS.UNSOLVED)}
          </Text>
        </CustomButton>
      </Container>
    </RowBody>
  );
};

const RowBody = styled.div`
  padding: 8px 16px;
  background: var(--dts_withe_background);
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 2px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: 8px;
  align-items: center;
`;

const CustomDislikeIcon = styled(DislikeIcon)`
  path {
    fill: var(--dts_red);
  }
`;

const CustomWrongPhaseText = styled(Text)`
  color: var(--dts_red);
`;

const WrongPhaseContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  gap: 4px;
`;
const CustomButton = styled.button`
  padding: 4px 5px 4px 4px;
  max-width: 82px;
  min-width: 82px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  &.solved {
    background: var(--border-grey);
    &:hover {
      background: var(--dts_dark_grey);
    }
  }
  &.unsolved {
    background: var(--dts_default_blue);
    &:hover {
      background: var(--dts_hover_blue);
    }
  }
`;

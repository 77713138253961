import { getCountiesService } from './CountyService';
import * as actionTypes from './countyActionTypes';

const getCountiesAction = () => (dispatch: any) => {
  return getCountiesService().then((resp) => {
    dispatch({ type: actionTypes.SAVE_COUNTY, counties: resp });
    return resp;
  });
};

export { getCountiesAction };

import React from 'react';
import { Text, TextType } from '../../../components/dsm/Text';

import styled from 'styled-components';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

export const WorktypeRowHeader = () => {
  const translate = useTranslate();
  return (
    <>
      <WorkTypeRowContainer>
        <FirstPartContainer>
          <IconComponent></IconComponent>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.WORKTYPES_NAME)}</Text>
        </FirstPartContainer>
        <SecondPartContainer>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.EXTENSIONS)}</Text>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.TOOTH_TEETH)}</Text>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.TEMPLATES)}</Text>
        </SecondPartContainer>
        <ThirdPartContainer>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.STATE)}</Text>
        </ThirdPartContainer>
      </WorkTypeRowContainer>
    </>
  );
};

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const WorkTypeRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  margin: 8px 4px;
  background: var(--dts_withe_background);
  border-radius: 3px;
  gap: 5px;
`;

const FirstPartContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 0 0 45%;
  width: 45%;
  max-width: 45%;
`;

const SecondPartContainer = styled.div`
  display: flex;

  @media (min-width: 697px) {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    gap: 5px;
    p {
      width: 120px;
      text-align: center;
    }
  }

  @media (max-width: 696px) {
    flex: 0 0 45%;
    width: 45%;
    max-width: 45%;
    gap: 3px;
    p {
      width: 70px;
      text-align: center;
    }
  }
`;

const ThirdPartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const IconComponent = styled.div`
  width: 16px;
  height: 16px;
`;

import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { RoundButtonCss } from './RoundButtonCss';

import { Text, TextType } from '../Text';

export type RoundButtonProps = {
  onClick: () => void;
  icon?: ReactElement<any, any>;
  icontoLeft?: boolean;
  buttonState: RoundButtonState;
  indicator?: boolean;
  color?: string;
  textType: TextType;
};

export enum RoundButtonState {
  ACTIVE = 0,
  DEFAULT = 1,
}

export const RoundButton: FC<RoundButtonProps> = ({
  onClick,
  icon,
  icontoLeft,
  indicator,
  buttonState,
  color,
  textType,
  children,
}) => {
  return (
    <RoundButtonCss
      onClick={onClick}
      buttonState={buttonState}
      color={color ? color : 'var(--dts_light_grey)'}
    >
      <RowFlex buttonState={buttonState}>
        {icontoLeft ? icon : ''}
        <CustomText type={textType}>{children}</CustomText>
        {icontoLeft ? '' : icon}
      </RowFlex>
      {indicator === true ? <Indicator /> : ''}
    </RoundButtonCss>
  );
};

const RowFlex = styled.div<Pick<RoundButtonProps, 'buttonState'>>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Indicator = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  right: 4px;
  top: -4px;
  background: var(--dts_red);
  order: 2;
  flex-grow: 0;
  z-index: 2;
  border-radius: 10px;
`;

const CustomText = styled(Text)`
  font-style: normal;
  margin: 0px;
  text-transform: capitalize;
  color: inherit;
`;

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import E_facturaLoadingScreen from './E_facturaLoadingScreen';
import { useBillingRepositoryImplementation } from '../../data/billing/billingRepositoryImplementation';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { useCompaniesRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { useEfacturaViewModel } from './E-facturaViewModel';

const E_facturaRedirectFromAnaf = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const billingRepository = useBillingRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const { saveEfacturaAuthCode, saveEfacturaAuthError } = useEfacturaViewModel(
    billingRepository,
    loggedUserRepository,
    e_factuarRepository,
    companiesRepo,
  );

  useEffect(() => {
    const eError = queryParams.get('error');
    console.log('E_facturaRedirectFromAnaf eError:' + eError);
    if (eError !== null) {
      saveEfacturaAuthError(eError);
      navigate(-1);
    }
    const eCode = queryParams.get('code');
    console.log('E_facturaRedirectFromAnaf eCode:' + eCode);
    if (eCode !== null) {
      saveEfacturaAuthCode(eCode);
      navigate(-1);
    }
  }, []);

  return <E_facturaLoadingScreen />;
};

export default E_facturaRedirectFromAnaf;

import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as PencilIcon } from '../../../../icons/pencil.svg';
import { ReactComponent as StartIcon } from '../../../../icons/start.svg';
import { ReactComponent as ClockIcon } from '../../../../icons/clock.svg';
import { ReactComponent as LikeIcon } from '../../../../icons/like.svg';
import { ReactComponent as DislikeIcon } from '../../../../icons/dislike.svg';
import Tippy from '@tippyjs/react';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

type PhaseButtonProps = {
  variant: PhaseButtonVariant;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  secondary?: boolean;
  disabled?: boolean;
};

type PhaseButtonVariant = 'Assign' | 'Start' | 'Pending' | 'Done' | 'Dislike';

export const PhaseButton = (props: PhaseButtonProps) => {
  const translate = useTranslate();
  const { variant, onClick, secondary, disabled } = props;

  return (
    <Button
      className={`${secondary ? 'gray' : ''}`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    >
      {variant === 'Assign' && (
        <React.Fragment>
          <PencilIcon />
          <label>{translate(LANGUAGE_KEYS.ASSIGN)}</label>
        </React.Fragment>
      )}
      {variant === 'Start' && (
        <React.Fragment>
          <StartIcon />
          <label>{translate(LANGUAGE_KEYS.START)}</label>
        </React.Fragment>
      )}
      {variant === 'Pending' && (
        <React.Fragment>
          <ClockIcon />
          <label>{translate(LANGUAGE_KEYS.START_PENDING)}</label>
        </React.Fragment>
      )}
      {variant === 'Done' && (
        <React.Fragment>
          <LikeIcon />
          <label>{translate(LANGUAGE_KEYS.DONE)}</label>
        </React.Fragment>
      )}
      {variant === 'Dislike' && (
        <React.Fragment>
          <DislikeIcon />
          <Tippy placement={'auto'} animation='shift-away' {...props}></Tippy>
        </React.Fragment>
      )}
    </Button>
  );
};

const Button = styled.button<{ variant: PhaseButtonVariant }>`
  cursor: pointer;
  border-radius: 100px;
  border: none;
  outline: none;
  background: none;
  font-weight: 700;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  label {
    cursor: inherit;
    font-size: 10px;
    line-height: 12px;
  }

  svg {
    width: 8px;
    height: 8px;
    display: block;
    * {
      fill: var(--dts_white);
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  ${({ variant }) =>
    (variant === 'Assign' || variant === 'Start') &&
    css`
      background-color: var(--dts_default_blue);
      color: var(--dts_white);
      :disabled,
      &.gray {
        background-color: var(--dts_dark_grey);
      }
    `};

  ${({ variant }) =>
    variant === 'Pending' &&
    css`
      padding-left: 0;
      padding-right: 0;
      color: #484848;
      svg * {
        fill: #484848;
      }
    `};

  ${({ variant }) =>
    variant === 'Done' &&
    css`
      background-color: #69a868;
      color: var(--dts_white);
      :disabled,
      &.gray {
        background-color: var(--dts_dark_grey);
      }
    `};

  ${({ variant }) =>
    variant === 'Dislike' &&
    css`
      background: #ff7474;
      padding: 8px;
      border-radius: 50%;
      position: relative;
      &:after {
        position: absolute;
        top: -1px;
        right: -1px;
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        // TODO: match background
        border: 1px solid var(--dts_light_grey);
        border-radius: 50%;
        background-color: var(--dts_red);
      }
      // :disabled,
      &.gray {
        background: var(--dts_dark_grey);
        &:after {
          display: none;
        }
      }
    `};
`;

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextType } from '../components/dsm/Text';
import useTranslate from '../translations/useTranslate';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';

const E_facturaSuccesScreen = () => {
  const translate = useTranslate();

  return (
    <Container>
      <LogoIcon className='logo' width={242} height={48} />
      <Text type={TextType.TITLE_BOLD_4}>{translate(LANGUAGE_KEYS.AUTHORIZATION_SUCCESSFULL)}</Text>
      <SuccessIcon width={60} height={60} />
      <Text type={TextType.BODY_REGULAR}>{translate(LANGUAGE_KEYS.NOTHING_TO_DO)}</Text>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 518px;
  margin: 230px auto auto auto;
  .logo {
    margin-bottom: 48px;
  }
  p {
    margin: 0px;
  }
  svg {
    fill: var(--dts_success);
    margin: 14px 0px 14px;
  }
`;

export default E_facturaSuccesScreen;

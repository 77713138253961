export const useApiHeaderWithCompanyId = () => {
  return {
    Authorization:
      window.localStorage.getItem('token') !== null
        ? String(window.localStorage.getItem('token'))
        : '',
    'Access-Control-Request-Headers': 'X-Custom-Header',
    'Content-Type': 'application/json',
    CompanyId: `${(window as any).currentCompany}`,
  };
};

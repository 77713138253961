import React from 'react';
import { useLoggedUserRepositoryImplementation } from '../data/loggedUser/loggedUserRepositoryImplementation';
import { useAppViewModel } from './AppViewModel';
import { MainMenu } from './components/menu/MainMenu';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function PrivateContent(props: { children }) {
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const { session } = useAppViewModel(loggedUserRepo);
  const navigate = useNavigate();

  if (session === null || session === undefined || session.trim() === '') {
    navigate('/');
    return <></>;
  }

  return (
    <Wrapper>
      <MainMenu />
      <ContentWrapper>{props.children}</ContentWrapper>
    </Wrapper>
  );
}

export default PrivateContent;

const Wrapper = styled.div`
  grid-row-start: content;
  grid-column-start: content;
  grid-row-end: content;
  grid-column-end: content;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  background-color: var(--dts_withe_background);
`;

const ContentWrapper = styled.div`
  width: 100%;

  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background-color: var(--dts_withe_background);
`;

import { BASE_URL } from '../Server';
import {
  ReportCasesData,
  ReportCasesRequest,
  ReportCasesByIdsData,
} from '../../domain/reports/Report';
import { SolvedPayOff, SolvedPayOffResponse } from '../../domain/reports/PayOff';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';

function getReportCases(params: ReportCasesRequest): Promise<ReportCasesData> {
  return fetch(BASE_URL + '/getFilteredList', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('getFilteredList error: ' + error);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function getReportCasesByIds(param: Array<number>): Promise<ReportCasesByIdsData> {
  return fetch(BASE_URL + '/getCasesById', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('getCasesById error:' + error);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function accountPhases(param: SolvedPayOff): Promise<SolvedPayOffResponse> {
  return fetch(BASE_URL + '/accountPhases', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('accountPhases error: ' + error);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

export { getReportCases, getReportCasesByIds, accountPhases };

import React from 'react';
import styled from 'styled-components';

import Header from './Header';
import { DeviceSizeProps } from '../DeviceInformations';
import { useDeviceParameters } from '../hooks/useDeviceParameters';

export default function StartPrivateContent(props: { children }) {
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  return (
    <>
      <Header />
      <Wrapper
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {props.children}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background-color: var(--dts_white);
    //Desktop
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      height: calc(100% - 68px);
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      height: calc(100% - 98px);
    }
    // Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

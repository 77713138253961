import React, { FC } from 'react';
import styled from 'styled-components';
import { Text, TextColor, TextType } from '../Text';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { OvalButtonCss } from './OvalButtonCss';

export type OvalButtonRemovableProps = {
  onClick?: () => void;
  removeClick: () => void;
  text?: string;
  secondText?: string;
  selected?: boolean;
  removable: boolean;
};

export const OvalButtonDualLableRemovable: FC<OvalButtonRemovableProps> = ({
  onClick,
  removeClick,
  text,
  secondText,
  selected,
  removable,
}) => {
  //console.log("OvalButtonRemovable: " + text)

  function getIcon() {
    if (removable) {
      return (
        <CloseIconStyled
          selected={selected}
          onClick={(e) => {
            e.stopPropagation();
            removeClick();
          }}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <OvalButtonCss onClick={onClick} selected={selected}>
      <Text type={TextType.BODY_2_BOLD} clr={selected ? TextColor.WHITE : TextColor.BLACK}>
        {text + (secondText ? ',' : '')}
      </Text>
      {secondText && (
        <Text type={TextType.BODY_2_BOLD} clr={selected ? TextColor.WHITE : TextColor.BLACK}>
          {secondText}
        </Text>
      )}
      {getIcon()}
    </OvalButtonCss>
  );
};

const CloseIconStyled = styled(CloseIcon)<Pick<OvalButtonRemovableProps, 'selected'>>`
  width: 7px;
  height: 7px;
  &:hover {
    opacity: 1;
  }

  path {
    fill: ${(props) => (props.selected ? 'var(--dts_white)' : 'var(--dts_black)')};
  }
`;

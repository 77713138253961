import React, { FC, useState } from 'react';

export type InputProps = {
  onChange: (value: string) => void;
  placeHolder?: string;
  width: string;
  initValue?: string;
  pattern: string;
  customStyle?: any;
};

export const InputTextNumber: FC<InputProps> = ({
  onChange,
  placeHolder,
  width,
  initValue,
  customStyle,
  pattern,
}) => {
  const [value, setValue] = useState(initValue ? initValue : '');

  function handleChange(event) {
    if (event.target.validity.valid) {
      setValue(event.target.value);
      onChange(event.target.value);
    }
  }

  const onBlur = () => {
    if (value === '' && initValue != undefined) {
      setValue(initValue);
      onChange(initValue);
    }
  };

  const inputStyles = {
    width: width,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#151515',
    border: ' none',
    boxShadow: 'none', // no box-shadow
    outline: 'none',
    padding: '0',
  };

  return (
    <input
      type='text'
      placeholder={placeHolder ? placeHolder : 'enter text here'}
      value={value}
      style={customStyle ? customStyle : inputStyles}
      onChange={handleChange}
      pattern={pattern}
      onBlur={onBlur}
    />
  );
};

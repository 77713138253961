import React from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../components/dsm/Text';
import img403 from '../../images/403.png';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ToggleHeader from '../components/generic/ToggleHeader';

function Page403() {
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();
  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={undefined}
          buttonText={undefined}
          enabledClick={true}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      <ContentBody>
        <IconConatiner>
          <img src={img403} alt='403 image' />
        </IconConatiner>
        <TextConatiner>
          <Text type={TextType.TITLE_BOLD_2}>Access Denied - Error 403</Text>
          <Text type={TextType.BODY_2}>
            Oops! You can&apos;t access this page due to permission restrictions. Please ensure
            you&apos;re logged in with the right account. If this doesn&apos;t help, contact us or
            head back to the homepage
          </Text>
        </TextConatiner>
      </ContentBody>
    </>
  );
}

const ContentBody = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const IconConatiner = styled.div`
  margin-bottom: 48px;
`;

const TextConatiner = styled.div`
  max-width: 480px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    align-self: center;
    text-align: center;
    &.link {
      cursor: pointer;
      &:hover {
        color: var(--dts_hover_blue);
      }
    }
  }
`;

export default Page403;

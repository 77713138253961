import styled from 'styled-components';
import { RoundButtonProps, RoundButtonState } from './RoundButton';

type Props = Pick<RoundButtonProps, 'buttonState' | 'color'>;

export const RoundButtonCss = styled.button<Props>`
  outline: 0;
  position: relative;
  padding: 8px 16px;
  gap: 10px;
  border: none;
  border-radius: 45px;
  margin: 0;
  flex: none;
  flex-grow: 0;
  order: 4;

  background: ${(props) =>
    props.buttonState === RoundButtonState.DEFAULT ? props.color : 'var(--dts_black)'};
  color: ${(props) =>
    props.buttonState === RoundButtonState.DEFAULT ? 'var(--dts_black)' : 'var(--dts_white)'};

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      props.buttonState === RoundButtonState.DEFAULT ? 'var(--dts_dark_grey)' : 'var(--dts_black)'};
  }
`;

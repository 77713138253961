import type { AnyAction } from 'redux';
import * as actionTypes from './worktypeActionTypes';
import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';

type WorkTypeRepositoryState = Omit<WorkTypeRepository, 'getWorkTypes' | 'updateWorkTypes'>;

const INITIAL_STATE: WorkTypeRepositoryState = {
  workTypes: undefined,
  isLoading: false,
  isUpdating: false,
};

const workTypeReducer = (state: WorkTypeRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_WORK_TYPES:
      return { ...state, isLoading: true };
    case actionTypes.GET_WORK_TYPES_SUCCESS:
      return { ...state, isLoading: false, workTypes: action.workTypes };
    case actionTypes.GET_WORK_TYPES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_WORK_TYPES:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_WORK_TYPES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_WORK_TYPES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
export { workTypeReducer };
export type { WorkTypeRepositoryState };

import { BASE_URL } from '../Server';
import { WorkPhaseGroupData } from '../../domain/WorkPhaseGroupData';
import { UpdatePhase } from '../../domain/worktype/UpdatePhase';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';

function getPhases(): Promise<WorkPhaseGroupData> {
  return fetch(BASE_URL + '/phases', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getPhases: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function newPhase(name: string) {
  return fetch(BASE_URL + '/addPhase', {
    method: 'POST',
    body: JSON.stringify({ name: name }),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy newPhase: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function deletePhase(id: number) {
  return fetch(BASE_URL + `/phase/delete/${id}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy deletePhase: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function updatePhase(phase: UpdatePhase) {
  return fetch(BASE_URL + '/phase/update', {
    method: 'POST',
    body: JSON.stringify(phase),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updatePhase: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

export { getPhases, newPhase, updatePhase, deletePhase };

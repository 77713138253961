import React, { useState } from 'react';
import styled from 'styled-components';

import {
  ContextMenu,
  ContextMenuOffset,
  ContextMenuOption,
} from '../../../components/dsm/ContextMenu';
import { ReactComponent as DotsIcon } from '../../../../icons/dots.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/trash.svg';
import { PhaseLibraryModel } from '../../../ui-model/PhaseLibraryModel';
import { InputText } from '../../../components/dsm/InputText';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import useTranslate from '../../../translations/useTranslate';
import { KeyboardsKeys } from '../../../components/dsm/KeyboardsKeys';

export type PhaseRowProps = {
  PhaseModel: PhaseLibraryModel;
  index: number;
  onDeletePhase: (Phase: PhaseLibraryModel) => void;
  onHandleText: (Phase: PhaseLibraryModel, name: string) => void;
};

export function PhaseRow(PhaseRowProps: PhaseRowProps) {
  const translate = useTranslate();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });
  const [phaseName, setPhaseName] = useState<string>(PhaseRowProps.PhaseModel.name);

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <DeleteIcon />,
      label: translate(LANGUAGE_KEYS.DELETE),
      onClick: () => {
        PhaseRowProps.onDeletePhase(PhaseRowProps.PhaseModel);
      },
    },
  ];

  const handleOnBlur = (value) => {
    if (value !== PhaseRowProps.PhaseModel.name) {
      PhaseRowProps.onHandleText(PhaseRowProps.PhaseModel, value);
    }
  };

  const handleKeyEvent = (event) => {
    switch (event.keyCode) {
      case KeyboardsKeys.ENTER:
        event.preventDefault();
        PhaseRowProps.onHandleText(PhaseRowProps.PhaseModel, event.target.value);
        event.target.blur();
        break;
      case KeyboardsKeys.ESCAPE:
        setPhaseName(PhaseRowProps.PhaseModel.name);
        break;
    }
  };

  const inputStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#151515',
    boxShadow: 'none', // no box-shadow
    outline: 'none',
  };

  return (
    <>
      <PhaseRowContainer>
        <FirstContainer>
          <IconComponent></IconComponent>
          <NameComponent>
            <InputText
              placeHolder={translate(LANGUAGE_KEYS.PHASE)}
              width='100%'
              initValue={phaseName}
              onChange={() => {}}
              onKeyDown={handleKeyEvent}
              onBlur={handleOnBlur}
              clickClose={() => {}}
              customStyle={inputStyle}
            />
          </NameComponent>
        </FirstContainer>

        <ThirdContainer>
          <IconComponent
            onClick={(e) => {
              e.preventDefault();
              handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
            }}
          >
            <DotsIcon />
          </IconComponent>
          <ContextMenuStyle
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            options={ContextMenuOptions}
            offset={contextMenuOffset}
            className='Phase-context'
          />
        </ThirdContainer>
      </PhaseRowContainer>
    </>
  );
}

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const PhaseRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  padding: 10px 24px 10px 4px;
  background: var(--dts_white);
  border-radius: 3px;
  gap: 4px;
  margin-bottom: 8px;
  justify-content: space-between;
  min-height: 36px;
`;

const FirstContainer = styled.div`
  flex-basis: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ThirdContainer = styled.div`
  flex-basis: 16%;
  position: relative;
`;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  padding: 10px 0px;
  svg {
    flex-shrink: 0;
  }
  :hover {
    svg {
      path {
        fill: var(--dts_hover_blue);
      }
    }
  }
`;

const NameComponent = styled.div`
  flex-basis: 95%;
  display: flex;
  min-width: 100px;
  margin-left: 10px;
  input {
    border: none;
    border: 1px solid transparent;
    :focus,
    :active {
      border: 1px solid var(--dts_default_blue);
    }
  }
`;

const ContextMenuStyle = styled(ContextMenu)`
  &.Phase-context {
    position: absolute;
    right: 0;
    top: 15px !important;
    left: unset !important;
  }
`;

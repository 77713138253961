import { AppRootStore } from '../../data/appRepositoryImplementation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { LobbyRepositoryState } from '../../data/lobby/lobbyReducer';
import { CompanyData } from '../../domain/lobby/Lobby';
import { setCompanyId } from '../../data/loggedUser/loggedUserReducer';

const lobbyCompaniesSelector = (state: AppRootStore) => state.lobbyComapnies;

export const useCompany = () => {
  const { companyId } = useParams();

  const dispatch = useDispatch();

  const { lobbyCompanies } = useSelector<AppRootStore, LobbyRepositoryState>(
    lobbyCompaniesSelector,
  );

  const initCompany = {
    companyId: null,
    companyName: null,
    color: '',
    address: null,
    userRole: null,
    validSubscription: false,
  };

  const [company, setCompany] = useState<CompanyData>(initCompany);

  useEffect(() => {
    const currentCompany = lobbyCompanies
      .filter((comp) => comp.companyId === Number(companyId))
      .pop();
    if (currentCompany !== undefined && currentCompany !== null) {
      dispatch(setCompanyId(currentCompany.companyId));
      (window as any).currentCompany = currentCompany.companyId;
      setCompany(currentCompany);
    } else {
      dispatch(setCompanyId(null));
      (window as any).currentCompany = null;
      setCompany(initCompany);
    }
  }, [companyId]);

  return company;
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useTranslate = () => {
  const { t } = useTranslation();

  return useCallback(
    (textKey, interpolationValues = {}) => {
      if (!textKey) return '';

      // Interpolate variables into the translation string
      return t(textKey, { defaultValue: textKey, ...interpolationValues });
    },
    [t],
  );
};

export default useTranslate;

import { PhaseRepository } from '../../domain/PhaseRepository';
import React from 'react';
import { getPhasesUseCase } from '../../useCases/worktype/phases/getPhasesUseCase';

function usePhaseViewModel(repo: PhaseRepository) {
  const getPhases = React.useCallback(
    function () {
      getPhasesUseCase({
        getPhases: repo.getPhases,
        phases: repo.phases,
      });
    },
    [repo.phases],
  );

  return {
    phases: repo.phases,
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    getPhases,
  };
}

export { usePhaseViewModel };

import Extension from './Extension';

export default class ExtensionInfo {
  groupId: number;
  extensions: Extension[];

  constructor(groupId: number, extensions: Extension[]) {
    this.groupId = groupId;
    this.extensions = extensions;
  }
}

import { GoogleRepository } from '../../../domain/google/GoogleRepository';
import {
  getCaptchaSiteKeyUseCase,
  captchaValidationUseCase,
} from '../../../useCases/google/captchaUseCase';

function useCaptchaViewModel(repo: GoogleRepository) {
  const getCaptchaSiteKey = () => {
    return getCaptchaSiteKeyUseCase(repo);
  };

  const captchaValidation = (captchaToken: string, hostName: string) => {
    return captchaValidationUseCase(repo, captchaToken, hostName);
  };

  return {
    getCaptchaSiteKey,
    captchaValidation,
  };
}

export { useCaptchaViewModel };

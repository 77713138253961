import React from 'react';
import { PhaseRepository } from '../../../domain/PhaseRepository';
import { PhaseLibraryModel } from '../../ui-model/PhaseLibraryModel';
import { deletePhaseUseCase } from '../../../useCases/worktype/phases/deletePhaseUseCase';
import { updatePhaseUseCase } from '../../../useCases/worktype/phases/updatePhaseUseCase';
import { newPhaseUseCase } from '../../../useCases/worktype/phases/newPhaseUseCase';
import { UpdatePhase } from '../../../domain/worktype/UpdatePhase';

function usePhasesViewModel(repo: PhaseRepository) {
  const getPhases = (): PhaseLibraryModel[] | [] => {
    const phaseLibraryModels: PhaseLibraryModel[] = [];
    repo.phases?.forEach((phase) => {
      phaseLibraryModels.push(
        new PhaseLibraryModel(
          phase.id === undefined ? -1 : phase.id,
          phase.name === undefined ? '' : phase.name,
        ),
      );
    });
    return phaseLibraryModels;
  };

  const getSortPhases = (): PhaseLibraryModel[] | [] => {
    const phaseLibraryModels: PhaseLibraryModel[] = [];
    repo.phases?.forEach((phase) => {
      phaseLibraryModels.push(
        new PhaseLibraryModel(
          phase.id === undefined ? -1 : phase.id,
          phase.name === undefined ? '' : phase.name,
        ),
      );
    });
    return phaseLibraryModels.sort((a, b) => (a.id > b.id ? -1 : 1));
  };

  const newPhase = React.useCallback(
    function (name: string) {
      newPhaseUseCase(repo, name);
    },
    [repo.newPhase],
  );

  const deletePhase = React.useCallback(
    function (id: number) {
      deletePhaseUseCase(repo, id);
    },
    [repo.deletePhase],
  );

  const updatePhase = React.useCallback(
    function (phase: PhaseLibraryModel) {
      updatePhaseUseCase(repo, new UpdatePhase(phase.id, phase.name));
    },
    [repo.updatePhase],
  );

  return {
    allPhase: repo.phases,
    getPhases,
    getSortPhases,
    newPhase,
    deletePhase,
    updatePhase,
  };
}

export { usePhasesViewModel };

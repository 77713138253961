import React from 'react';
import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';
import { logoutUserUseCases } from '../../useCases/signUpFlow/logoutUserUseCases';

function useErrorPageViewModel(sessionRepo: SignUpFlowRepository) {
  const logoutUser = React.useCallback(
    function () {
      return logoutUserUseCases({ logoutUser: sessionRepo.logoutUser });
    },
    [sessionRepo.authenticateUser],
  );

  return {
    logoutUser,
  };
}

export { useErrorPageViewModel };

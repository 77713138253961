import React, { useEffect, useRef, useState } from 'react';
import { Text, TextType } from '../dsm/Text';
import { DropdownValue, Option, Options, OptionsContainer } from './Dropdown';
import styled from 'styled-components';
import Tooltip from '../dsm/Tooltip';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';

type FloatingDropdownProps = {
  values: Array<Omit<DropdownValue, 'selected'>>;
  selectedValue?: DropdownValue['key'];
  onChange: (key: DropdownValue['key']) => void;
  onClose: () => void;
  x: number;
  y: number;
};

// TODO: scroll bug fix
const FloatingDropdown = (props: FloatingDropdownProps) => {
  const { values, selectedValue, onChange, onClose, x, y } = props;

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const containerRef = useRef<HTMLDivElement>(null);
  const optionRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  useEffect(() => {
    containerRef?.current?.focus();
    optionRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [optionRefs]);

  const handleOnOptionClick = (key: DropdownValue['key']) => onChange(key);

  return (
    <Container>
      <FloatingOptionsContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        ref={containerRef}
        tabIndex={-1}
        onBlur={onClose}
        style={{ left: x, top: y - 40 }}
      >
        <CustomOptions
          className='custom-scrollbar'
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          {values.map((value, index) => (
            <Tooltip key={index} content={value.label} disabled={!visibleTooltips.includes(index)}>
              <Option
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
                className={`${value.key === selectedValue && 'selected'}`}
                key={value.key}
                onClick={() => handleOnOptionClick(value.key)}
              >
                <Text
                  ref={(el) => {
                    if (el) optionRefs.current[index] = el;
                  }}
                  type={
                    value.key === selectedValue ? TextType.CAPTION_BOLD : TextType.CAPTION_REGULAR
                  }
                  ellipsis={true}
                >
                  {value.label}
                </Text>
              </Option>
            </Tooltip>
          ))}
        </CustomOptions>
      </FloatingOptionsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  pointer-events: none;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 201;
`;

const FloatingOptionsContainer = styled(OptionsContainer)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: absolute;
    display: block;
    margin: 0;
    width: auto;
    pointer-events: initial;

    //Desktop
    @media (min-width: ${desktop}px) {

    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 24px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomOptions = styled(Options)`
  max-height: 176px;
`;

export default FloatingDropdown;

import React from 'react';
import { CompaniesRepository } from '../../../domain/user-management/UsersRepository';
import { createCompanyUseCase } from '../../../useCases/user-management/createCompanyUseCase';
import { PackageInformation } from '../../../domain/user-management/Plans';

const usePlansViewModel = (repo: CompaniesRepository) => {
  const createCompany = React.useCallback(
    (plan: { name: string; color: string; packageInformation: PackageInformation }) => {
      return createCompanyUseCase(repo, plan);
    },
    [],
  );

  return {
    createCompany,
  };
};

export { usePlansViewModel };

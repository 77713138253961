import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import {
  BillingType,
  MyCompanySubscriptionUiModel,
  PackageType,
  PackageUiModel,
} from '../ui-model/billing/PackageUiModel';
import { BillingCard } from './BillingCard';

export type BillingCardsProps = {
  isFti: boolean;
  enableTrial: boolean;
  username: string | undefined;
  recomandation?: number;
  packages: Array<PackageUiModel> | undefined;
  mySubscription: MyCompanySubscriptionUiModel | undefined;
  maxEmployeeNumber: number | undefined;
  laborEmployeeNumber: number | undefined;
  updateCompanyPackage: (resp: {
    packageType: PackageType;
    billingType: BillingType;
    billingPeriod: 0;
  }) => void;
};

export const BillingCards: FC<BillingCardsProps> = (billingCards) => {
  const {
    isFti,
    enableTrial,
    username,
    packages,
    recomandation,
    mySubscription,
    maxEmployeeNumber,
    laborEmployeeNumber,
    updateCompanyPackage,
  } = billingCards;

  const packageRefs = useRef<Array<HTMLElement>>([]);

  const setActiveElement = (index) => {
    packageRefs.current.forEach((itemRef) => {
      itemRef.classList.remove('active');
    });
    packageRefs.current[index].classList.add('active');
  };

  return (
    <BillingCardsContainers>
      <div className='container'>
        {packages?.map((pck, index) => {
          return (
            <BillingCard
              setRef={(ref) => {
                if (ref) packageRefs.current[index] = ref;
              }}
              index={index}
              key={index}
              pck={pck}
              isFti={isFti}
              enableTrial={enableTrial}
              username={username}
              recomandation={recomandation}
              mySubscription={mySubscription}
              maxEmployeeNumber={maxEmployeeNumber}
              laborEmployeeNumber={laborEmployeeNumber}
              updateCompanyPackage={updateCompanyPackage}
              onMouseOver={(index: number) => {
                setActiveElement(index);
              }}
            />
          );
        })}
      </div>
    </BillingCardsContainers>
  );
};

const BillingCardsContainers = styled.div`
  width: 100%;
  background: var(--dts_white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  .container {
    display: flex;
    gap: 24px;
  }
`;

import React, { useEffect, useState } from 'react';
import { PhaseUiModel, PhaseUiModelPhase } from '../../ui-model/PhaseUiModel';
import { useLocation } from 'react-router-dom';
import { MobileAddPhaseOrTemplate } from './MobileAddNewComponent';
import { CaseDetailProvider } from '../CaseDetailProvider';

export enum NewComponentType {
  PHASE,
  TEMPLATE,
  DATE,
  ASSIGN,
}

export type AddNewComponentsProps = {
  caseId: number;
  labWorkId: number;
  phase: PhaseUiModelPhase | PhaseUiModel;
  actualLabwork: any;
};

export const AddNewComponents = () => {
  const [data, setData] = useState<AddNewComponentsProps>();

  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state.data !== null) {
      setData(state.data);
    }
  }, [state]);
  return (
    <CaseDetailProvider>
      <MobileAddPhaseOrTemplate
        caseId={data?.caseId}
        labworkId={data?.labWorkId}
        currentPhase={data?.phase}
        actualLabwork={data?.actualLabwork}
      />
    </CaseDetailProvider>
  );
};

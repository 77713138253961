import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { Text, TextType, TextColor } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as LobbyEmptyIcon } from '../../icons/lobbyEmptyIcon.svg';
import { ReactComponent as OwnerIcon } from '../../icons/OwnerIcon.svg';
import { LobbyCardsType } from '../ui-model/lobby/LobbyUiModel';
import Avatar from '../components/generic/Avatar';
import { UserRoles, getRoleName } from '../../domain/user-management/UserRoles';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

export type LobbyCardProps = {
  type: LobbyCardsType;
  icon: ReactElement<any, any> | null;
  companyId: number | null;
  companyName: string | null;
  companyColor: string;
  address: string | null;
  collaborators: Array<any> | null;
  inviteText: string | null;
  userRole: number | null;
  onclickEnter?: (companyId: number, companyName: string | null, userRole: number) => void;
  onClickDecline?: (companyId: number) => void;
  onClickAccept?: (companyId: number) => void;
  onClickCreateNewLab?: () => void;
};

export const LobbyCard: FC<LobbyCardProps> = (lobbyCardProps) => {
  const translate = useTranslate();
  const {
    type,
    icon,
    companyId,
    companyName,
    address,
    collaborators,
    inviteText,
    userRole,
    onclickEnter,
    onClickDecline,
    onClickAccept,
    onClickCreateNewLab,
  } = lobbyCardProps;

  const {
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
    isMobile,
    isTabletLarge,
    isTabletSmall,
  } = useDeviceParameters();

  const onHandleClickEnter = () => {
    if (onclickEnter !== undefined && companyId !== null && userRole !== null) {
      onclickEnter(companyId, companyName, userRole);
    }
  };

  const onHandleClickDecline = () => {
    if (onClickDecline !== undefined && companyId !== null) {
      onClickDecline(companyId);
    }
  };

  const onHandleClickAccept = () => {
    if (onClickAccept !== undefined && companyId !== null) {
      onClickAccept(companyId);
    }
  };

  const SimpleCard = () => {
    return (
      <Card
        className='simple-card'
        onClick={onHandleClickEnter}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {icon}
        <TextConatiner>
          {companyName !== null && (
            <ComapnyContainer>
              <Text type={TextType.BODY_BOLD} ellipsis={true}>
                {companyName}
              </Text>
              {userRole !== null &&
                (userRole === UserRoles.LABOR_OWNER || userRole === UserRoles.LABOR_ADMIN) && (
                  <OwnerConatiner>
                    <OwnerIcon width={13} height={12} />
                    <Text type={TextType.CAPTION_REGULAR}>{translate(getRoleName(userRole))}</Text>
                  </OwnerConatiner>
                )}
            </ComapnyContainer>
          )}
          {address !== null && (
            <AddressText type={TextType.CAPTION_REGULAR} ellipsis={true}>
              {address}
            </AddressText>
          )}
          {collaborators !== null && companyName !== null && (
            <LaborCollaborators>
              <Avatar
                fullName={companyName}
                size={{ outer: 16, inner: 14 }}
                textType={TextType.ROBOTO_SMALL_8}
              />
            </LaborCollaborators>
          )}
        </TextConatiner>
        <ActionsConatiner>
          <EnterLink>
            <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.ENTER)}</Text>
          </EnterLink>
        </ActionsConatiner>
      </Card>
    );
  };

  const InvitationCard = () => {
    return (
      <Card
        className='invitation-card'
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {icon}
        <TextConatiner>
          {companyName !== null && (
            <Text type={TextType.BODY_BOLD} ellipsis={true}>
              {companyName}
            </Text>
          )}
          {inviteText !== null && (
            <Text className='invite-text' type={TextType.CAPTION_REGULAR}>
              {inviteText}
            </Text>
          )}
        </TextConatiner>
        <ActionsConatiner>
          <InvitesButtonStyle className='decline' onClick={onHandleClickDecline}>
            <Text type={TextType.CAPTION_REGULAR} clr={TextColor.WHITE}>
              {translate(LANGUAGE_KEYS.DECLINE)}
            </Text>
          </InvitesButtonStyle>
          <InvitesButtonStyle className='accept' onClick={onHandleClickAccept}>
            <Text type={TextType.CAPTION_BOLD} clr={TextColor.WHITE}>
              {translate(LANGUAGE_KEYS.ACCEPT)}
            </Text>
          </InvitesButtonStyle>
        </ActionsConatiner>
      </Card>
    );
  };

  const CreateNewLabCard = () => {
    return (
      <Card
        className='create-card'
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <LobbyEmptyIcon width={128} height={128} />
        <Button onClick={onClickCreateNewLab} textType={TextType.CAPTION_BOLD}>
          <PlusIcon />
          {translate(LANGUAGE_KEYS.CREATE_NEW_LAB)}
        </Button>
      </Card>
    );
  };

  return (
    <>
      {type === LobbyCardsType.SIMPLE && <SimpleCard />}
      {type === LobbyCardsType.INVITATION && <InvitationCard />}
      {!isTabletLarge && !isTabletSmall && !isMobile && type === LobbyCardsType.CREATE && (
        <CreateNewLabCard />
      )}
    </>
  );
};

const Card = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 21px 15px 21px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    //Desktop
    @media (min-width: ${desktop}px) {
      width: calc(25% - 46px);
      max-width: calc(25% - 46px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      width: calc(33.33% - 45px);
      max-width: calc(33.33% - 45px);
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      width: calc(50% - 42px);
      max-width: calc(50% - 42px);
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: calc(100% - 26px);
      max-width: calc(100% - 26px);
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }

    &.simple-card {
      cursor: pointer;
    }

    &.simple-card,
    &.create-card {
      background: var(--dts_white);
      border: 2px solid var(--dts_white);
    }
    &.invitation-card {
      background: var(--dts_panding);
      border: 2px solid var(--dts_panding);
    }
    &.create-card {
      padding: 28px 14px 28px 14px;
    }
    &:hover {
      border: 2px solid var(--dts_default_blue);
    }
`,
);

const TextConatiner = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 100%;
  .invite-text {
    max-width: 190px;
  }
`;

const AddressText = styled(Text)`
  min-height: 16px;
`;

const LaborCollaborators = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const EnterLink = styled.span`
  padding: 6px;
  p {
    color: var(--dts_default_blue);
    &:hover {
      color: var(--dts_hover_blue);
    }
  }
`;

const ComapnyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const ActionsConatiner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  justify-content: center;
`;

const InvitesButtonStyle = styled.button`
  padding: 7px 16px 7px 16px;
  border-radius: 3px;
  flex: 1 1 0px;
  cursor: pointer;
  &.decline {
    border: 1px solid var(--dts_white);
    background: var(--dts_panding);
    :hover {
      border: 1px solid var(--dts_hover_blue);
      p {
        color: var(--dts_hover_blue);
      }
    }
  }
  &.accept {
    border: 1px solid var(--dts_default_blue);
    background: var(--dts_default_blue);
    :hover {
      background: var(--dts_hover_blue);
    }
  }
`;

const OwnerConatiner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 2px 6px 2px 6px;
  background: var(--dts_withe_background);
  border-radius: 3px;
  gap: 2px;
`;

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import useTranslate from '../translations/useTranslate';
import { Text, TextType } from '../components/dsm/Text';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as WarningIcon } from '../../icons/delete.svg';

export type E_facturaUploadConfirmationModalProps = {
  onUpload: () => void;
  onClose: () => void;
};

const E_facturaUploadConfirmationModal = (props: E_facturaUploadConfirmationModalProps) => {
  const { onUpload, onClose } = props;

  const translate = useTranslate();

  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.SEND_E_FACTURA)}
      titleTextType={TextType.BODY_BOLD}
      onClose={onClose}
      containerStyle={{ width: '406px' }}
      header={true}
      isScroll={false}
    >
      <Information>
        <WarningIcon width={36} height={36} />
        <Description>
          <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.UPLOAD_SPV)}</Text>
        </Description>
      </Information>
      <ButtonsContainer>
        <Button textType={TextType.CAPTION_BOLD} onClick={onClose} secondary='secondary'>
          {translate(LANGUAGE_KEYS.QUIT)}
        </Button>
        <Button textType={TextType.CAPTION_BOLD} onClick={onUpload}>
          {translate(LANGUAGE_KEYS.SEND)}
        </Button>
      </ButtonsContainer>
    </BaseDialog>
  );
};

const Information = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  width: calc(100% - 32px);
  svg {
    fill: var(--dts_success);
    flex-shrink: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

const Description = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export default E_facturaUploadConfirmationModal;

import React from 'react';
import styled from 'styled-components';

import { Text, TextType } from './Text';

import { InputText } from './InputText';
import { ReactComponent as PlusIcon } from '../../../icons/plus.svg';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';

export type SearchAndAddNewProps = {
  handleChangeSearchBar: (e) => void;
  clickSearchClose: () => void;
  addNewText: string;
  isWorker: boolean;
  addNew: (itemModel: any, isWorker: boolean, editInvite: boolean) => void | null;
};

export const SearchAndAddNew = ({
  handleChangeSearchBar,
  clickSearchClose,
  addNewText,
  addNew,
  isWorker,
}: SearchAndAddNewProps) => {
  const translate = useTranslate();
  const onHandleChangeSearchBar = (e) => {
    handleChangeSearchBar(e);
  };

  const onClickSearchClose = () => {
    clickSearchClose();
  };

  const onAddNew = () => {
    addNew(undefined, isWorker, false);
  };

  const inputStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--dts_black)',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
    padding: '10px 35px 10px 8px',
    borderRadius: '3px',
  };

  return (
    <ActionsConatiner>
      <SearchContainer>
        <InputText
          placeHolder={translate(LANGUAGE_KEYS.SEARCH_BOARD)}
          width='100%'
          initValue={''}
          onChange={onHandleChangeSearchBar}
          customStyle={inputStyle}
          isSearch={true}
          onKeyDown={() => {}}
          clickClose={onClickSearchClose}
        />
      </SearchContainer>
      {addNew !== null && (
        <AddContainer onClick={onAddNew}>
          <PlusIcon />
          <Text type={TextType.CAPTION_REGULAR}>{addNewText}</Text>
        </AddContainer>
      )}
    </ActionsConatiner>
  );
};

const ActionsConatiner = styled.div`
  padding: 8px;
  background: var(--dts_light_grey);
`;

const SearchContainer = styled.div`
  background: var(--dts_white);
  border: 1px solid var(--dts_light_grey);
`;

const AddContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  margin-top: 12px;
  p {
    color: var(--dts_default_blue);
  }
  svg {
    path {
      fill: var(--dts_default_blue);
    }
  }
  :hover {
    svg {
      path {
        fill: var(--dts_hover_blue);
      }
    }
    p {
      color: var(--dts_hover_blue);
    }
  }
`;

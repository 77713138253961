import React from 'react';
import {
  getDesktopMenuUseCase,
  getMobileMenuUseCase,
  getProfileMenuUseCase,
  getTabletMenuUseCase,
} from '../../../useCases/menu/getMenuUseCase';

import { SignUpFlowRepository } from '../../../domain/signUpFlow/SignUpFlowRepository';
import { LoggedUserRepository } from '../../../domain/loggedUser/loggedUserRepository';
import { logoutUserUseCases } from '../../../useCases/signUpFlow/logoutUserUseCases';
import { FeaturesRepository } from '../../../domain/features/FeaturesRepository';

function useMenuViewModel(
  sessionRepo: SignUpFlowRepository,
  loggedUserRepo: LoggedUserRepository,
  featuresRepo: FeaturesRepository,
) {
  const getDesktopMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getDesktopMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getTabletMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getTabletMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getMobileMenu = (
    userRole: number | null,
    companyName: string | null,
    companyId: number | null,
  ) => {
    return getMobileMenuUseCase(featuresRepo, userRole, companyName, companyId);
  };

  const getProfileMenu = (companyId: number | null) => {
    return getProfileMenuUseCase(
      featuresRepo,
      loggedUserRepo.firstName + ' ' + loggedUserRepo.lastName,
      loggedUserRepo.profileColor !== null
        ? loggedUserRepo.profileColor
        : 'var(--dts_default_blue)',
      companyId,
    );
  };

  const logoutUser = React.useCallback(
    function () {
      return logoutUserUseCases({ logoutUser: sessionRepo.logoutUser });
    },
    [sessionRepo.authenticateUser],
  );

  return {
    validSubscription: loggedUserRepo.validSubscription,
    getDesktopMenu,
    getTabletMenu,
    getMobileMenu,
    getProfileMenu,
    logoutUser,
  };
}

export { useMenuViewModel };

import { ContactsRepository } from '../../domain/user-management/UsersRepository';

type GetContactsUseCase = Pick<ContactsRepository, 'contacts' | 'getContacts'>;

const getContactsUseCase = (repo: GetContactsUseCase) => {
  repo.getContacts();
};

const getWorkersUseCase = (repo: GetContactsUseCase) => {
  if (repo.contacts?.workers !== undefined) {
    return repo.contacts?.workers;
  } else {
    return [];
  }
};

const getClientsUseCase = (repo: GetContactsUseCase) => {
  if (repo.contacts?.clients !== undefined) {
    return repo.contacts?.clients;
  } else {
    return [];
  }
};

const getClientNameUseCase = (id: number, repo: GetContactsUseCase): string => {
  const client = repo.contacts?.clients.find((client) => client.id === id);
  return client !== undefined ? client.name : '';
};

const getWorkertNameUseCase = (id: number, repo: GetContactsUseCase): string => {
  const worker = repo.contacts?.workers.find((client) => client.id === id);
  return worker !== undefined ? worker.name : '';
};

const getWorkertProfileColorUseCase = (id: number, repo: GetContactsUseCase): string => {
  const worker = repo.contacts?.workers.find((client) => client.id === id);
  return worker !== undefined && worker.user !== null && worker.user.profileColor !== null
    ? worker.user.profileColor
    : 'var(--dts_default_blue)';
};

export {
  getContactsUseCase,
  getWorkersUseCase,
  getClientsUseCase,
  getClientNameUseCase,
  getWorkertNameUseCase,
  getWorkertProfileColorUseCase,
};

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import Avatar from '../components/generic/Avatar';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';

type BillingNotificationModalProps = {
  title: string;
  packageName: string | undefined;
  description: string;
  endDate: string | undefined;
  startDate: string | undefined;
  userName: string | undefined;
  buttonText: string;
  onClose: () => void;
  onClick: () => void;
};

const BillingNotificationModal = (props: BillingNotificationModalProps) => {
  const translate = useTranslate();
  const {
    title,
    packageName,
    description,
    endDate,
    startDate,
    userName,
    buttonText,
    onClose,
    onClick,
  } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={title}
      onClose={onClose}
      containerStyle={{ width: '408px' }}
      header={true}
      isScroll={false}
    >
      <SubscriptionInformation>
        <Avatar
          fullName={userName}
          size={{ outer: 64, inner: 62 }}
          textType={TextType.TITLE_BOLD_4}
        />
        <Details>
          <Text type={TextType.CAPTION_BOLD}>
            {translate(LANGUAGE_KEYS.SUBSCRIPTION_PLAN, {
              packigename: translate(packageName),
            })}
          </Text>
          <Text type={TextType.CAPTION_REGULAR}>
            {'"' +
              translate(LANGUAGE_KEYS.ACCSES_UNTIL, {
                packigeexpiredate: endDate,
              }) +
              '"'}
          </Text>
        </Details>
      </SubscriptionInformation>
      <Text type={TextType.CAPTION_REGULAR}>{description}</Text>
      <Text type={TextType.CAPTION_REGULAR}>
        {translate(LANGUAGE_KEYS.MEMBER_SINE, {
          registrationdate: startDate,
        })}
      </Text>
      <Actions>
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onClose();
          }}
        >
          {translate(LANGUAGE_KEYS.BACK)}
        </Button>
        <Button
          secondary={'secondary'}
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onClick();
          }}
        >
          {buttonText}
        </Button>
      </Actions>
    </BaseDialog>
  );
};

const SubscriptionInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid var(--border-grey);
  border-radius: 4px;
  gap: 16px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100% - 80px);
  width: calc(100% - 80px);
  word-wrap: break-word;
  white-space: normal;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

export default BillingNotificationModal;

import { useDispatch } from 'react-redux';
import React from 'react';
import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';
import { Credentials } from '../../domain/signUpFlow/LoginUserData';
import {
  addUserAction,
  authenticateAction,
  logoutAction,
  resetPasswordAction,
  setPasswordAction,
  updateUserAction,
} from './signUpFlowActions';
import { PasswordData } from '../../domain/signUpFlow/SetPasswordData';

const useSignUpFlowRepositoryImplementation = (): SignUpFlowRepository => {
  const dispatch = useDispatch();

  const authenticateUser = React.useCallback(
    (c: Credentials) => authenticateAction(c)(dispatch),
    [dispatch],
  );

  const addUser = React.useCallback(
    (username: string, isEnabledMailList: boolean) =>
      addUserAction(username, isEnabledMailList)(dispatch),
    [dispatch],
  );

  const setPassword = React.useCallback(
    (password: PasswordData, isRecover: boolean) =>
      setPasswordAction(password, isRecover)(dispatch),
    [dispatch],
  );

  const resetPassword = React.useCallback(
    (email: string) => resetPasswordAction(email)(dispatch),
    [dispatch],
  );

  const updateUser = React.useCallback((data) => updateUserAction(data)(dispatch), [dispatch]);

  const logoutUser = () => {
    return logoutAction(dispatch);
  };

  return {
    authenticateUser,
    addUser,
    setPassword,
    resetPassword,
    updateUser,
    logoutUser,
  };
};

export { useSignUpFlowRepositoryImplementation };

export enum PackageType {
  UNDEFINED = -1,
  BASIC = 0,
  STANDARD = 1,
  PRO = 2,
}

export enum BillingType {
  TRIAL = 0,
  PAYED = 1,
  NOT_PAYED = 2,
  CANCELD = 3,
}

export type PackageUiModel = {
  id: number;
  talkAction: boolean;
  packageType: PackageType;
  billingType: BillingType;
  title: string;
  subtitle: string;
  price: number;
  numberDays: number;
  afterPrice: number;
  features: Array<string>;
  soonFeatures: Array<string>;
};

export type MyCompanySubscriptionUiModel = {
  title: string;
  packageType: PackageType;
  billingType: BillingType;
  price: number;
  formatStartTime: string;
  formatEndTime: string;
  days: number;
};

export class UpdateTeethTypeInfo {
  id: number;
  active: number;
  order: number;

  constructor(id: number, active: number, order: number) {
    this.id = id;
    this.order = order;
    this.active = active;
  }
}

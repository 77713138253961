import { ReactElement } from 'react';

export enum LobbyCardsType {
  SIMPLE = 1,
  INVITATION = 2,
  CREATE = 3,
}

export type LobbyCompanyUiModel = {
  type: LobbyCardsType;
  icon: ReactElement<any, any> | null;
  companyId: number | null;
  companyName: string | null;
  userRole: number | null;
  address: string | null;
  companyColor: string;
  collaborators: Array<any> | null;
  inviteText: string | null;
};

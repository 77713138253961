import { UserRoles } from '../../domain/user-management/UserRoles';

export enum FeatureId {
  DASHBOARD_VISIBILITY,
  CASE_VISIBILITY,
  CALLENDAR_VISIBILITY,
  REPORTS_VISIBILITY, // Reports-Cases,
  REPORTS_OVERVIEW_VISIBILITY, //Overview expense, pay off
  INVOICE_VISIBILITY, // Invoice, Invoice details ,PDF
  FAQ_VISIBILITY,
  SETTINGS_VISIBILITY_ALL, // Settings, General, MyProfile
  SETTINGS_VISIBILITY_SUPERVISOR, // Settings, Phases
  SETTINGS_VISIBILITY_OWNER, // Worktypes, Collaborators, Company Data
  PROFILE_VISIBILITY, // Notifications, Preferences, Manage accounts, Labs, Logout
  PLANS_VISIBILITY,
  NEW_CASE_ACTION,
  DETAIL_WORK_PLATFORM_MODIFICATION_ACTION,
  ASSIGNE_PHASES_ACTION,
  EXTEND_LABWORK_PHASES_ACTION,
  PHASE_STATUS_CHANGE_ACTION,
  CHANGE_DETAIL_DESCRIPTION_ACTION,
  PUBLISH_CASE_ACTION,
  CASE_STATUS_ACTIONS,
  REPORTS_OVERVIEW_MOVES_ACTION,
  EFACTURA_ACTION,
}

export enum SubscriptionType {
  FREE,
  PRO,
}

export type Feature = {
  featureId: FeatureId;
  roles: Array<UserRoles>;
  subscription: SubscriptionType;
};

export const featuresList: Array<Feature> = [
  {
    featureId: FeatureId.DASHBOARD_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.CASE_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.CALLENDAR_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.REPORTS_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.REPORTS_OVERVIEW_VISIBILITY,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.INVOICE_VISIBILITY,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.FAQ_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.SETTINGS_VISIBILITY_ALL,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.SETTINGS_VISIBILITY_SUPERVISOR,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN, UserRoles.LABOR_SUPERVISOR],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.SETTINGS_VISIBILITY_OWNER,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.PROFILE_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.PLANS_VISIBILITY,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.NEW_CASE_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.DETAIL_WORK_PLATFORM_MODIFICATION_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.ASSIGNE_PHASES_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN, UserRoles.LABOR_SUPERVISOR],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.EXTEND_LABWORK_PHASES_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN, UserRoles.LABOR_SUPERVISOR],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.PHASE_STATUS_CHANGE_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN, UserRoles.LABOR_SUPERVISOR],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.CHANGE_DETAIL_DESCRIPTION_ACTION,
    roles: [
      UserRoles.LABOR_OWNER,
      UserRoles.LABOR_ADMIN,
      UserRoles.LABOR_SUPERVISOR,
      UserRoles.LABOR_EMPLOYEE,
    ],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.PUBLISH_CASE_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.CASE_STATUS_ACTIONS,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN, UserRoles.LABOR_SUPERVISOR],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.REPORTS_OVERVIEW_MOVES_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
  {
    featureId: FeatureId.EFACTURA_ACTION,
    roles: [UserRoles.LABOR_OWNER, UserRoles.LABOR_ADMIN],
    subscription: SubscriptionType.FREE,
  },
];

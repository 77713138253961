export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAILD = 'GET_PACKAGES_FAILD';
export const UPDATE_COMPANY_PACKAGE = 'UPDATE_COMPANY_PACKAGE';
export const UPDATE_COMPANY_PACKAGE_SUCCESS = 'UPDATE_COMPANY_PACKAGE_SUCCESS';
export const UPDATE_COMPANY_PACKAGE_FAILD = 'UPDATE_COMPANY_PACKAGE_FAILD';
export const GET_MAX_PACKAGE_COLLABORATOR = 'GET_MAX_PACKAGE_COLLABORATOR';
export const GET_MAX_PACKAGE_COLLABORATOR_SUCCESS = 'GET_MAX_PACKAGE_COLLABORATOR_SUCCESS';
export const GET_MAX_PACKAGE_COLLABORATOR_FAILD = 'GET_MAX_PACKAGE_COLLABORATOR_FAILD';
export const GET_COMPANY_SUBSCRIPTION = 'GET_COMPANY_SUBSCRIPTION';
export const GET_COMPANY_SUBSCRIPTION_SUCCESS = 'GET_COMPANY_SUBSCRIPTION_SUCCESS';
export const GET_COMPANY_SUBSCRIPTION_FAILD = 'GET_COMPANY_SUBSCRIPTION_FAILD';
export const GET_TRIAL_ENABLE = 'GET_TRIAL_ENABLE';
export const GET_TRIAL_ENABLE_SUCCESS = 'GET_TRIAL_ENABLE_SUCCESS';
export const GET_TRIAL_ENABLE_FAILD = 'GET_TRIAL_ENABLE_FAILD';

import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { AppRootStore } from '../appRepositoryImplementation';
import { BillingRepository } from '../../domain/billing/BillingRepository';
import { Package } from '../../domain/billing/Package';
import {
  getCompanySubscriptionAction,
  getMaxCollaboratorsAction,
  getPackagesAction,
  getTrialEnableAction,
  updateCompanyPackageAction,
} from './billingActions';
import { BillingRepositoryState } from './billingReducer';

const billingSelector = (state: AppRootStore) => state.billing;

const useBillingRepositoryImplementation = (): BillingRepository => {
  const { isLoading, isUpdating } = useSelector<AppRootStore, BillingRepositoryState>(
    billingSelector,
  );

  const dispatch = useDispatch();

  const getPackages = React.useCallback(() => getPackagesAction()(dispatch), [dispatch]);

  const updateCompanyPackage = React.useCallback(
    (params: Package) => updateCompanyPackageAction(params)(dispatch),
    [dispatch],
  );

  const getMaxCollaborators = React.useCallback(
    () => getMaxCollaboratorsAction()(dispatch),
    [dispatch],
  );

  const getCompanySubscription = React.useCallback(
    () => getCompanySubscriptionAction()(dispatch),
    [dispatch],
  );

  const getTrialEnable = React.useCallback(() => getTrialEnableAction()(dispatch), [dispatch]);

  return {
    isLoading,
    isUpdating,
    getPackages,
    updateCompanyPackage,
    getMaxCollaborators,
    getCompanySubscription,
    getTrialEnable,
  };
};

export { useBillingRepositoryImplementation };

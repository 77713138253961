import React, { FC, useState } from 'react';
import { Text, TextType } from '../../../../../components/dsm/Text';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { TeethImage } from '../TeethImage';
import { Checkbox } from '../../../../../components/dsm/Checkbox';
import { ReactComponent as Drag } from '../../../../../../icons/drag.svg';
import { TeethUiModel } from '../../../../../ui-model/worktype/TeethUiModel';

export type TeethRowProps = {
  teethModel: TeethUiModel;
  index: number;
  handleCheckbox: (teeth: TeethUiModel) => void;
};

export const TeethRow: FC<TeethRowProps> = (teethRowProps) => {
  const [checked, setchecked] = useState(teethRowProps.teethModel.active!);

  const handleOnCheckboxClick = (index: number, active: boolean) => {
    setchecked(active);
    teethRowProps.teethModel.active = active;
    teethRowProps.handleCheckbox(teethRowProps.teethModel);
  };

  return (
    <Draggable
      draggableId={teethRowProps.index + ''}
      index={teethRowProps.index}
      key={teethRowProps.index}
    >
      {(provided) => (
        <TeethRowContainer
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={`${!checked && 'inactive'}`}
        >
          <FirstContainer>
            <IconComponent {...provided.dragHandleProps}>
              <Drag />
            </IconComponent>
            <TeethImageContainer>
              <TeethImage
                typeTeeth={teethRowProps.teethModel.id}
                width={22}
                height={36}
                isCustomSize={true}
              />
            </TeethImageContainer>
            <TeethNameContainer className='name'>
              <Text type={TextType.BODY_2_BOLD}>{teethRowProps.teethModel.name}</Text>
            </TeethNameContainer>
          </FirstContainer>
          <SecondContainer>
            <Checkbox
              checkboxId={teethRowProps.index}
              onClick={handleOnCheckboxClick}
              active={checked}
              disabled={true}
            />
          </SecondContainer>
        </TeethRowContainer>
      )}
    </Draggable>
  );
};

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const TeethRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  padding: 10px 24px 10px 4px;
  background: var(--dts_white);
  border-radius: 3px;
  gap: 5px;
  margin-bottom: 8px;
  justify-content: space-between;

  opacity: 1;
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-in-out;

  .name,
  span {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-in-out;
  }

  &.inactive {
    opacity: 0.8;
    .name,
    span {
      opacity: 0.5;
    }
    input {
      pointer-events: none;
    }
  }
`;

const FirstContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
const SecondContainer = styled.div``;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }
`;

const TeethImageContainer = styled.span`
  display: flex;
`;

const TeethNameContainer = styled.span``;

export default class UpdateTeethTypeExtensionInfo {
  workTypeId: number;
  extensionId: number;
  active: number;
  price: number;

  constructor(workTypeId: number, extensionId: number, active: number, price: number) {
    this.workTypeId = workTypeId;
    this.extensionId = extensionId;
    this.active = active;
    this.price = price;
  }
}

import {
  getTemplates,
  newTemplate,
  duplicateTemplate,
  deleteTemplate,
  updateTemplate,
  updateTemplateOrderOrActive,
  addTemplateBySubWorkType,
  removeTemplateFromSubWorkType,
  addPhasesToTemplate,
} from './templateService';
import { getWorkTypes } from '../workTypeService';
import * as actionTypes from './templateActionTypes';
import * as worktypeActionTypes from '../worktypeActionTypes';
import { TemplatePhases } from '../../../domain/worktype/templates/TemplatePhases';
import { NewTemplate } from '../../../domain/worktype/templates/NewTemplate';
import { UpdateTemplate } from '../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../domain/worktype/templates/UpdateTemplateOrderOrActive';
import { TemplateBySubWorkType } from '../../../domain/worktype/templates/TemplateBySubWorkType';
import { checkErrorStatus } from '../../ErrorHandling';

const getTemplatesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_TEMPLATE });
  return getTemplates().then((templates) => {
    if (checkErrorStatus(templates.code, dispatch)) {
      dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
      return templates.data;
    } else {
      dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
      return templates.data;
    }
  });
};
const newTemplatesAction = (newTemplateItem: NewTemplate) => (dispatch: any) => {
  dispatch({ type: actionTypes.NEW_TEMPLATE });
  return newTemplate(newTemplateItem).then((temp) => {
    getTemplates()
      .then((templates) => {
        if (checkErrorStatus(templates.code, dispatch)) {
          dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
          return templates.data;
        } else {
          dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
          return templates.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(temp.code, dispatch)) {
          dispatch({ type: actionTypes.NEW_TEMPLATE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.NEW_TEMPLATE_FAILD, error: temp.code });
        }
      });
  });
};

const duplicateTemplateAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DUPLICATE_TEMPLATE });
  return duplicateTemplate(id).then((temp) => {
    getTemplates()
      .then((templates) => {
        if (checkErrorStatus(templates.code, dispatch)) {
          dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
          return templates.data;
        } else {
          dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
          return templates.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(temp.code, dispatch)) {
          dispatch({ type: actionTypes.DUPLICATE_TEMPLATE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DUPLICATE_TEMPLATE_FAILD, error: temp.code });
        }
      });
  });
};

const deleteTemplateAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_TEMPLATE });
  return deleteTemplate(id).then((temp) => {
    getTemplates()
      .then((templates) => {
        if (checkErrorStatus(templates.code, dispatch)) {
          dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
          return templates.data;
        } else {
          dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
          return templates.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(temp.code, dispatch)) {
          dispatch({ type: actionTypes.DELETE_TEMPLATE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DELETE_TEMPLATE_FAILD, error: 11 });
        }
      });
  });
};

const updateTemplateAction = (template: UpdateTemplate) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_TEMPLATE });
  return updateTemplate(template).then((temp) => {
    getTemplates()
      .then((templates) => {
        if (checkErrorStatus(templates.code, dispatch)) {
          dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
          return templates.data;
        } else {
          dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
          return templates.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(temp.code, dispatch)) {
          dispatch({ type: actionTypes.UPDATE_TEMPLATE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.UPDATE_TEMPLATE_FAILD, error: temp.code });
        }
      });
  });
};

const updateTemplateOrderOrActiveAction =
  (templates: UpdateTemplateOrderOrActive[]) => (dispatch: any) => {
    dispatch({ type: actionTypes.UPDATE_TEMPLATE });
    return updateTemplateOrderOrActive(templates).then((temp) => {
      getTemplates()
        .then((templates) => {
          if (checkErrorStatus(templates.code, dispatch)) {
            dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
            return templates.data;
          } else {
            dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
            return templates.data;
          }
        })
        .then(() => {
          if (checkErrorStatus(temp, dispatch)) {
            dispatch({ type: actionTypes.UPDATE_TEMPLATE_SUCCESS });
          } else {
            dispatch({ type: actionTypes.UPDATE_TEMPLATE_FAILD, error: temp.code });
          }
        });
    });
  };

const addTemplateBySubWorkTypeAction =
  (templateBySubWorkType: TemplateBySubWorkType) => (dispatch: any) => {
    dispatch({ type: actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE });
    return addTemplateBySubWorkType(templateBySubWorkType).then((temp) => {
      getTemplates()
        .then((templates) => {
          if (checkErrorStatus(templates.code, dispatch)) {
            dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
            return templates.data;
          } else {
            dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
            return templates.data;
          }
        })
        .then(() => {
          getWorkTypes()
            .then((wt) => {
              if (checkErrorStatus(wt.code, dispatch)) {
                dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_SUCCESS, workTypes: wt.data });
                return wt.data;
              } else {
                dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_FAILD, error: wt.code });
                return wt.data;
              }
            })
            .then(() => {
              if (checkErrorStatus(temp, dispatch)) {
                dispatch({ type: actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE_SUCCESS });
              } else {
                dispatch({
                  type: actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE_FAILD,
                  error: temp.code,
                });
              }
            });
        });
    });
  };

const removeTemplateFromSubWorkTypeAction =
  (templateID: number, extensionID?: number) => (dispatch: any) => {
    dispatch({ type: actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE });
    return removeTemplateFromSubWorkType(templateID, extensionID).then((temp) => {
      getTemplates()
        .then((templates) => {
          if (checkErrorStatus(templates.code, dispatch)) {
            dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
            return templates.data;
          } else {
            dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
            return templates.data;
          }
        })
        .then(() => {
          getWorkTypes()
            .then((wt) => {
              if (checkErrorStatus(wt.code, dispatch)) {
                dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_SUCCESS, workTypes: wt.data });
                return wt.data;
              } else {
                dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_FAILD, error: wt.code });
                return wt.data;
              }
            })
            .then(() => {
              if (checkErrorStatus(temp, dispatch)) {
                dispatch({ type: actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_SUCCESS });
              } else {
                dispatch({
                  type: actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_FAILD,
                  error: temp.code,
                });
              }
            });
        });
    });
  };

const addPhasesToTemplateAction = (templatePhases: TemplatePhases) => (dispatch: any) => {
  dispatch({ type: actionTypes.ADD_NEW_PHASES_TO_TEMPLATE });
  return addPhasesToTemplate(templatePhases).then((temp) => {
    getTemplates()
      .then((templates) => {
        if (checkErrorStatus(templates.code, dispatch)) {
          dispatch({ type: actionTypes.GET_TEMPLATE_SUCCESS, templates: templates.data });
          return templates.data;
        } else {
          dispatch({ type: actionTypes.GET_TEMPLATE_FAILD, error: templates.code });
          return templates.data;
        }
      })
      .then(() => {
        getWorkTypes()
          .then((wt) => {
            if (checkErrorStatus(wt.code, dispatch)) {
              dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_SUCCESS, workTypes: wt.data });
              return wt.data;
            } else {
              dispatch({ type: worktypeActionTypes.GET_WORK_TYPES_FAILD, error: wt.code });
              return wt.data;
            }
          })
          .then(() => {
            if (checkErrorStatus(temp, dispatch)) {
              dispatch({ type: actionTypes.ADD_NEW_PHASES_TO_TEMPLATE_SUCCESS });
            } else {
              dispatch({ type: actionTypes.ADD_NEW_PHASES_TO_TEMPLATE_FAILD, error: temp.code });
            }
          });
      });
  });
};
export {
  getTemplatesAction,
  newTemplatesAction,
  duplicateTemplateAction,
  deleteTemplateAction,
  updateTemplateAction,
  updateTemplateOrderOrActiveAction,
  addTemplateBySubWorkTypeAction,
  removeTemplateFromSubWorkTypeAction,
  addPhasesToTemplateAction,
};

import { TeethRepository } from '../../../domain/worktype/teeth/TeethRepository';
import { TeethType } from '../../../domain/worktype/teeth/TeethType';

type GetTeethRepository = Pick<TeethRepository, 'getTeeth'>;

const getTeethUseCase = (repo: GetTeethRepository) => {
  repo.getTeeth();
};

const getSortTeethUseCase = (teethList: TeethType[] | undefined) => {
  if (teethList === undefined) return [];
  return teethList?.sort((a, b) => (a.order > b.order ? 1 : -1));
};

export { getSortTeethUseCase, getTeethUseCase };

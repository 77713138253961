import { FeaturesRepository } from '../domain/features/FeaturesRepository';
import { LoggedUserRepository } from '../domain/loggedUser/loggedUserRepository';
import { isEnabledRouteByPathName } from '../useCases/menu/getMenuUseCase';
import { MenuType } from './ui-model/menu/MenuUImodel';

function useEnabledRouteViewModel(
  featuresRepo: FeaturesRepository,
  loggedUserRepo: LoggedUserRepository,
) {
  const isEnabledRoute = (
    userRole: number,
    pathname: string,
    deviceType: MenuType,
    validSubscription: boolean,
  ): boolean => {
    return isEnabledRouteByPathName(
      featuresRepo,
      userRole,
      pathname,
      deviceType,
      validSubscription,
    );
  };

  return {
    validSubscription: loggedUserRepo.validSubscription,
    isEnabledRoute,
  };
}

export { useEnabledRouteViewModel };

import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';
import { NewTemplate } from '../../../domain/worktype/templates/NewTemplate';

type GetTemplateRepository = Pick<TemplateRepository, 'newTemplate' | 'getTemplates'>;

const newTemplateUseCase = (repo: GetTemplateRepository, newTemplateItem: NewTemplate) => {
  repo.newTemplate(newTemplateItem);
};

export { newTemplateUseCase };

import {
  ExtensionList,
  SELECTABLE_CATEGORY,
  SELECTABLE_EXTENSION,
  SIMPLE_EXTENSION,
} from './worktype/extension/Extension';

export type Extra = SimpleExtra | SelectableExtra;

type SimpleExtra = {
  kind: 'Simple';
  id: number;
  groupId: number;
  name: string;
  active: boolean;
  disabled?: boolean;
};

type SelectableExtra = {
  kind: 'Selectable';
  id: number;
  values: Array<{
    id: number;
    name: string;
    selected?: boolean;
  }>;
  disabled?: boolean;
};

export function mapExtrasFromExtensionList(
  extensions: ExtensionList,
  extensionList: { id: number; name: string; groupId: number; groupType: number }[],
  includeTypes: number[],
): Array<Extra> {
  const extras: Array<Extra> = [];

  extensions.forEach((extensionGroup) => {
    if (includeTypes.indexOf(extensionGroup.type) < 0) {
      return;
    }

    if (extensionGroup.type === SELECTABLE_CATEGORY) {
      // This type is mandatory so we need to flag one element as selected
      const activeExtensionList = extensionGroup.extensions.filter((ext) => {
        if (ext.active) {
          return ext;
        }
      });
      let selectedValue = activeExtensionList.findIndex((ext) => {
        return (
          extensionList.findIndex((v) => {
            return v.id === ext.id;
          }) > -1
        );
      });
      if (selectedValue === -1) {
        selectedValue = 0;
      }

      extras.push({
        kind: 'Selectable',
        id: extensionGroup.id,
        values: activeExtensionList.map((ext, index) => ({
          ...ext,
          selected: index === selectedValue,
        })),
        disabled: extensionGroup.disabled || false,
      });
    } else if (extensionGroup.type === SELECTABLE_EXTENSION) {
      let activeExtensionList = extensionGroup.extensions.filter((ext) => {
        if (ext.active) {
          return ext;
        }
      });
      if (activeExtensionList.length > 0) {
        activeExtensionList = [
          {
            id: -100,
            name: '- Select ' + extensionGroup.name + ' -',
            active: false,
            selected: false,
          },
          ...activeExtensionList,
        ];
      }
      extras.push({
        kind: 'Selectable',
        id: extensionGroup.id,
        values: activeExtensionList.map((ext) => ({
          ...ext,
          selected:
            extensionList.findIndex((v) => {
              return v.id === ext.id;
            }) > -1,
        })),
        disabled: extensionGroup.disabled || false,
      });
    } else if (extensionGroup.type === SIMPLE_EXTENSION) {
      const activeExtensionList = extensionGroup.extensions.filter((ext) => {
        if (ext.active) {
          return ext;
        }
      });
      activeExtensionList.forEach((extra) => {
        extras.push({
          kind: 'Simple',
          id: extra.id,
          groupId: extensionGroup.id,
          name: extra.name,
          active:
            extensionList.findIndex((v) => {
              return v.id === extra.id;
            }) > -1,
          disabled: extra.disabled || false,
        });
      });
    }
  });
  return extras;
}

import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';
import { InvoiceUpdate } from '../../domain/invoice/Invoice';

type UpdateInvoiceUseCase = Pick<InvoiceRepository, 'updateInvoice'>;

const updateInvoiceUseCase = (repo: UpdateInvoiceUseCase, param: InvoiceUpdate) => {
  return repo.updateInvoice(param);
};

export { updateInvoiceUseCase };

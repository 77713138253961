import React from 'react';
import { Package, PackageModel } from '../../domain/billing/Package';
import { BillingRepository } from '../../domain/billing/BillingRepository';
import { updateCompanyPackageUseCase } from '../../useCases/billing/updateCompanyPackageUseCase';
import { getPackagesUseCase } from '../../useCases/billing/getPackagesUseCase';
import { BillingType, PackageType, PackageUiModel } from '../ui-model/billing/PackageUiModel';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { getMaxCollaboratorsUseCase } from '../../useCases/billing/getMaxCollaboratorsUseCase';
import { getCompanySubscriptionUseCase } from '../../useCases/billing/getCompanySubscriptionUseCase';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';
import { getWorkersUseCase } from '../../useCases/user-management/getContactsUseCase';
import { ContactsRepository } from '../../domain/user-management/UsersRepository';
import { getMEnableTrialUseCase } from '../../useCases/billing/getEnableTrialUseCase';

const BASIC_FEATURES = [
  LANGUAGE_KEYS.FEATURE_CASES,
  LANGUAGE_KEYS.FEATURE_MOBILE,
  LANGUAGE_KEYS.FEATURE_SUPORT,
  LANGUAGE_KEYS.FEATURE_EMPLOYEE_MANAGEMENT,
  LANGUAGE_KEYS.FEATURE_PERSONALIZED_WORKFLOW,
  LANGUAGE_KEYS.FEATURE_CLIENT_MANAGEMENT,
  LANGUAGE_KEYS.FEATURE_INVOICE,
  LANGUAGE_KEYS.FEATURE_EMPLOYE_NUMBER,
];
const STANDARD_FEATURES = [
  LANGUAGE_KEYS.FEATURE_CASES,
  LANGUAGE_KEYS.FEATURE_MOBILE,
  LANGUAGE_KEYS.FEATURE_SUPORT,
  LANGUAGE_KEYS.FEATURE_EMPLOYEE_MANAGEMENT,
  LANGUAGE_KEYS.FEATURE_PERSONALIZED_WORKFLOW,
  LANGUAGE_KEYS.FEATURE_CLIENT_MANAGEMENT,
  LANGUAGE_KEYS.FEATURE_INVOICE,
  LANGUAGE_KEYS.FEATURE_UNLIMITED_EMPLOYE,
  LANGUAGE_KEYS.FEATURE_E_FACTURA,
];
const PRO_FEATURES = [LANGUAGE_KEYS.PACKAGE_DESCRIPTION_PRO];
const PRO_SOON_FEATURES = [
  LANGUAGE_KEYS.FEATURE_SOON_1,
  LANGUAGE_KEYS.FEATURE_SOON_2,
  LANGUAGE_KEYS.FEATURE_SOON_3,
];

const useBillingViewModel = (
  repo: BillingRepository,
  loggedUserRepo: LoggedUserRepository,
  contactRepo: ContactsRepository,
) => {
  const getPackages = React.useCallback(() => {
    return getPackagesUseCase(repo).then((resp) => {
      return packageModelToUiModel(resp);
    });
  }, []);

  const updateCompanyPackage = React.useCallback((params: Package) => {
    return updateCompanyPackageUseCase(repo, params);
  }, []);

  const getMaxCollaborators = React.useCallback(() => {
    return getMaxCollaboratorsUseCase(repo);
  }, []);

  const getCompanySubscription = React.useCallback(() => {
    return getCompanySubscriptionUseCase(repo).then((resp) => {
      let title = '';
      if (resp.packageType === PackageType.BASIC) title = LANGUAGE_KEYS.PACKAGE_TILE_BASIC;
      if (resp.packageType === PackageType.STANDARD) title = LANGUAGE_KEYS.PACKAGE_TILE_PRO;
      if (resp.packageType === PackageType.PRO) title = LANGUAGE_KEYS.PACKAGE_TILE_PLUS;
      return {
        title: title,
        packageType: resp.packageType,
        billingType: resp.status,
        price: resp.price,
        formatStartTime: new Date(resp.startTime).toLocaleString('en-us', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }),
        formatEndTime: new Date(resp.endTime).toLocaleString('en-us', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }),
        days: Math.round((resp.endTime - new Date().getTime()) / (1000 * 60 * 60 * 24)),
      };
    });
  }, []);

  const getWorkersNumber = function () {
    return getWorkersUseCase(contactRepo).length;
  };

  const getEnableTrial = React.useCallback(() => {
    return getMEnableTrialUseCase(repo);
  }, []);

  return {
    username: loggedUserRepo.firstName + ' ' + loggedUserRepo.lastName,
    getPackages,
    updateCompanyPackage,
    getMaxCollaborators,
    getCompanySubscription,
    getWorkersNumber,
    getEnableTrial,
  };
};

function packageModelToUiModel(packageList: Array<PackageModel>): Array<PackageUiModel> {
  const uiList: Array<PackageUiModel> = [];
  packageList.forEach((pck) => {
    if (pck.type === PackageType.BASIC) {
      uiList.push({
        id: pck.id,
        talkAction: false,
        title: LANGUAGE_KEYS.PACKAGE_TILE_BASIC,
        subtitle: LANGUAGE_KEYS.PACKAGE_SUBTILE_BASIC,
        price: 0,
        numberDays: 30,
        afterPrice: pck.price,
        features: BASIC_FEATURES,
        packageType: pck.type,
        billingType: BillingType.TRIAL,
        soonFeatures: [],
      });
    }
    if (pck.type === PackageType.STANDARD) {
      uiList.push({
        id: pck.id,
        talkAction: false,
        title: LANGUAGE_KEYS.PACKAGE_TILE_PLUS,
        subtitle: LANGUAGE_KEYS.PACKAGE_SUBTILE_PLUS,
        price: 0,
        numberDays: 30,
        afterPrice: pck.price,
        features: STANDARD_FEATURES,
        packageType: pck.type,
        billingType: BillingType.TRIAL,
        soonFeatures: [],
      });
    }
  });
  uiList.push({
    id: 3,
    talkAction: true,
    title: LANGUAGE_KEYS.PACKAGE_TILE_PRO,
    subtitle: LANGUAGE_KEYS.PACKAGE_SUBTILE_PRO,
    price: 0,
    numberDays: 30,
    afterPrice: 380,
    features: PRO_FEATURES,
    packageType: PackageType.PRO,
    billingType: BillingType.TRIAL,
    soonFeatures: PRO_SOON_FEATURES,
  });
  return uiList;
}

export { useBillingViewModel };

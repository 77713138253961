import React from 'react';
import { CaseState } from '../../../case/CaseState';
import styled from 'styled-components';
import { InvoiceState } from '../../../../domain/InvoiceState';
import { Text, TextType } from '../Text';
import useTranslate from '../../../translations/useTranslate';

type StateButtonProps = {
  state: CaseState | InvoiceState;
  click:
    | {
        clickable: false;
      }
    | {
        clickable: true;
        onClick: () => void;
      };
};

const StateButton = ({ state, click }: StateButtonProps) => {
  const translate = useTranslate();
  return (
    <StateButtonStyled
      className={`state-${state.id} ${click.clickable && 'clickable'}`}
      onClick={click.clickable ? click.onClick : () => {}}
    >
      {state.icon}
      <Text type={TextType.CAPTION_3_BOLD} style={{ color: 'var(--dts_black)' }}>
        {translate(state.name)}
      </Text>
    </StateButtonStyled>
  );
};

const StateButtonStyled = styled('button')`
  max-width: 82px;
  min-width: 82px;
  padding: 4px 8px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  gap: 2px;
  font-size: 8px;
  line-height: 12px;
  font-weight: 700;
  border: 1px solid var(--outlined);
  background-color: initial;

  &.clickable {
    cursor: pointer;
  }

  svg {
    width: 10px;
    height: 10px;
    path {
      fill: var(--dts_black);
    }
  }
`;

export default StateButton;

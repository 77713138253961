import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { Text, TextType } from '../../../../../components/dsm/Text';
import { ReactComponent as Drag } from '../../../../../../icons/drag.svg';
import { ReactComponent as CloseIcon } from '../../../../../../icons/close.svg';
import { TemplatePhaseUiModel } from '../../../../../ui-model/worktype/TemplatePhaseUiModel';
import { WorkTypeTemplateSubCategoryUiModel } from '../../../../../ui-model/worktype/WorkTypeTemplateSubCategoryUiModel';

const DraggableItem: React.FC<{
  index: number;
  item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel;
  type: string;
  draggableType?: string;
  deleteItem: (
    item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel,
    index: number,
  ) => void;
  onClick: (item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel) => void;
}> = ({ index, item, type, draggableType, deleteItem, onClick }) => {
  const onHandeleDeleteItem = () => {
    deleteItem(item, index);
  };

  const handleClick = () => {
    onClick(item);
  };

  const renderDragableItem = () => {
    return type && type === 'all' ? (
      <Draggable key={item.id} draggableId={item.id + ''} index={index}>
        {(provided, snapshot) => (
          <>
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              style={provided.draggableProps.style}
              onClick={handleClick}
              className={`${snapshot.isDragging && 'dragging'}`}
            >
              <TextContainer>
                <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                  {item.name}
                </Text>
              </TextContainer>
            </Container>
            {snapshot.isDragging && draggableType !== 'extention' && (
              <CloneContainer>
                <TextContainer>
                  <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                    {item.name}
                  </Text>
                </TextContainer>
              </CloneContainer>
            )}
          </>
        )}
      </Draggable>
    ) : (
      <Draggable key={item.id} draggableId={item.id + ''} index={index}>
        {(provided, snapshot) => (
          <Container
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`${snapshot.isDragging && 'dragging'}`}
          >
            <IconComponent {...provided.dragHandleProps}>
              <Drag />
            </IconComponent>
            <TextContainer>
              <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                {item.name}
              </Text>
            </TextContainer>
            <CloseIconComponent>
              <CloseIconStyle onClick={onHandeleDeleteItem}>
                <CloseIcon />
              </CloseIconStyle>
            </CloseIconComponent>
          </Container>
        )}
      </Draggable>
    );
  };

  return renderDragableItem();
};

const Container = styled.div`
  padding: 8px;
  background: var(--dts_white);
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 2px;

  &.dragging {
    box-shadow: -1px 2px 11px 0px #00000024;
    p {
      opacity: 0.5;
    }
  }
`;

const CloneContainer = styled(Container)`
  ~ div {
    transform: none !important;
  }
`;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
  svg {
    flex-shrink: 0;
  }
`;

const TextContainer = styled.div`
  max-width: 150px;
`;

const CloseIconComponent = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const CloseIconStyle = styled.span`
  padding: 3px;
  display: flex;
  cursor: pointer;
  svg {
    flex-shrink: 0;
    width: 9.33px;
    height: 9.33px;
    path {
      fill: var(--dts_black);
    }
  }
  :hover {
    svg {
      path {
        fill: var(--dts_hover_blue);
      }
    }
  }
`;

export default DraggableItem;

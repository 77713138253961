import React, { FC, useState } from 'react';
import { Text, TextType } from '../../../components/dsm/Text';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { Checkbox } from '../../../components/dsm/Checkbox';
import Tooltip from '../../../components/dsm/Tooltip';
import { ReactComponent as Drag } from '../../../../icons/drag.svg';
import { WorkType } from '../../../../domain/worktype/WorkType';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

export type WorktypeRowProps = {
  workTypeModel: WorkType;
  index: number;
  onClick?: () => void;
  handleCheckbox: (workType: WorkType) => void;
};

export const WorktypeRow: FC<WorktypeRowProps> = (workTypeRowProps) => {
  const translate = useTranslate();

  const [checked, setchecked] = useState(workTypeRowProps.workTypeModel.active ? true : false);

  const handleOnCheckboxClick = (id: number, active: boolean) => {
    setchecked(active);
    workTypeRowProps.workTypeModel.active = active ? 1 : 0;
    workTypeRowProps.handleCheckbox(workTypeRowProps.workTypeModel);
  };

  return (
    <>
      <Draggable
        draggableId={workTypeRowProps.index + ''}
        index={workTypeRowProps.index}
        key={workTypeRowProps.index}
      >
        {(provided) => (
          <WorkTypeRowContainer
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={`${!checked && 'inactive'}`}
          >
            <FirstPartContainer>
              <IconComponent {...provided.dragHandleProps}>
                <Drag />
              </IconComponent>
              <NameComponent
                type={TextType.BODY_2_BOLD}
                onClick={checked ? workTypeRowProps.onClick : () => {}}
                className={checked ? 'name' : 'name disable'}
              >
                {workTypeRowProps.workTypeModel.name}
              </NameComponent>
            </FirstPartContainer>
            <SecondPartContainer>
              <WorktypeComponentContainer>
                <Tooltip
                  key={workTypeRowProps.index}
                  content={translate(LANGUAGE_KEYS.EXTENSION_LABEL)}
                  placement='bottom'
                >
                  <WorktypeComponent type={TextType.BODY_2} className='worktype-comp'>
                    {workTypeRowProps.workTypeModel.extensionSize}
                  </WorktypeComponent>
                </Tooltip>
              </WorktypeComponentContainer>

              <WorktypeComponentContainer>
                <Tooltip
                  key={workTypeRowProps.index}
                  content={translate(LANGUAGE_KEYS.TOOTH_LABEL)}
                  placement='bottom'
                >
                  <WorktypeComponent type={TextType.BODY_2} className='worktype-comp'>
                    {workTypeRowProps.workTypeModel.teethTypeSize}
                  </WorktypeComponent>
                </Tooltip>
              </WorktypeComponentContainer>

              <WorktypeComponentContainer>
                <Tooltip
                  key={workTypeRowProps.index}
                  content={translate(LANGUAGE_KEYS.TEMPLATES_LABEL)}
                  placement='bottom'
                >
                  <WorktypeComponent type={TextType.BODY_2} className='worktype-comp'>
                    {workTypeRowProps.workTypeModel.templateSize}
                  </WorktypeComponent>
                </Tooltip>
              </WorktypeComponentContainer>
            </SecondPartContainer>
            <ThirdPartContainer>
              <StateComponent>
                <Checkbox
                  checkboxId={workTypeRowProps.index}
                  onClick={handleOnCheckboxClick}
                  active={checked}
                />
              </StateComponent>
            </ThirdPartContainer>
          </WorkTypeRowContainer>
        )}
      </Draggable>
    </>
  );
};

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const WorkTypeRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  padding: 16px 4px;
  background: var(--dts_white);
  border-radius: 3px;
  gap: 5px;
  margin-bottom: 8px;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-in-out;

  .name,
  span,
  .worktype-comp {
    opacity: 1;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-in-out;
  }

  &.inactive {
    opacity: 0.8;
    .name,
    span,
    .worktype-comp {
      opacity: 0.5;
    }
    input {
      pointer-events: none;
    }
  }
`;

const FirstPartContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  flex: 0 0 45%;
  width: 45%;
  max-width: 45%;
`;

const SecondPartContainer = styled.div`
  display: flex;

  @media (min-width: 697px) {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    gap: 5px;
  }

  @media (max-width: 696px) {
    flex: 0 0 45%;
    width: 45%;
    max-width: 45%;
    gap: 3px;
  }
`;

const ThirdPartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
`;

const NameComponent = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  &.disable {
    pointer-events: none;
    text-decoration: none;
  }
`;

const WorktypeComponentContainer = styled.div`
  font-size: 14px;
  text-align: center;

  @media (min-width: 697px) {
    width: 120px;
  }

  @media (max-width: 696px) {
    width: 70px;
  }
`;

const WorktypeComponent = styled(Text)`
  width: fit-content;
  margin: auto;
`;

const StateComponent = styled.div``;

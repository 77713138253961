import { Case, CaseByIdData, CasesData } from '../../domain/Case';
import { BASE_URL } from '../Server';
import { CaseResponse } from '../../domain/CaseResponse';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';
import { checkResponseStatus } from '../ErrorHandling';

let cases: Case[];

function getCases(): Promise<CasesData> {
  return fetch(BASE_URL + '/cases', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .then((res) => {
      res.forEach((c) => {
        c.labWorks.forEach((lw) => {
          if (lw.phases !== null) {
            lw.phases.sort((a, b) => (a.order > b.order ? 1 : -1));
          }
        });
      });
      cases = res;
      return { code: 200, message: 'Success', cases: res };
    })
    .catch((error) => {
      console.log('getCases catch error: ' + error);
      cases = [];
      return { code: error.status, message: 'Faild', cases: [] };
    });
}

function getCaseById(caseId: number): Promise<CaseByIdData> {
  return fetch(BASE_URL + '/cases/' + caseId, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .then((res) => {
      return { code: 200, message: 'Success', case: res };
    })
    .catch((error) => {
      console.log('getCases catch error: ' + error);
      return { code: error.status, message: 'Faild', case: undefined };
    });
}

function newCase(): Promise<{ code: number; message: string; data: number }> {
  return fetch(BASE_URL + '/addCase', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy newCase: ' + error);
      return { code: error.status, message: error, data: -1 };
    });
}

function updateCase(c: Case): Promise<boolean> {
  return new Promise(() => {
    setTimeout(() => {
      cases.map((value) => {
        if (value.id === c.id) {
          value.labWorks?.map((lw) => {
            c.labWorks?.map((clw) => {
              if (clw.labWorkId === lw.labWorkId) {
                lw.currentPhaseOrder = clw.currentPhaseOrder;
              }
            });
          });
          return true;
        }
      });
    }, 1000);
  });
}

function uploadCaseDetail(caseId: number, stackOfChanges: Array<any>[]): Promise<CaseResponse> {
  const body: { caseId: number; actions: Array<any>[] } = {
    caseId: caseId,
    actions: stackOfChanges,
  };

  return fetch(BASE_URL + '/case/update', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      if (!checkResponseStatus(res.status)) {
        return { code: res.status, message: '', data: null };
      }
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy uploadCaseDetail: ' + error);
      return { code: error.status, message: error, data: null };
    });
}

function deleteCase(
  deleteCases: Array<number>,
): Promise<{ code: number; message: string; data: boolean | null }> {
  return fetch(BASE_URL + '/cases/delete', {
    method: 'POST',
    body: JSON.stringify(deleteCases),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return { code: res.code, message: res.message, data: res.data };
    })
    .catch((error) => {
      console.log('catchy deleteCase: ' + error);
      return { code: error.status, message: error, data: null };
    });
}

export { getCases, getCaseById, newCase, updateCase, uploadCaseDetail, deleteCase };

import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { SubscriptionType, FeatureId } from '../../domain/features/Features';

type GetFeaturesRepository = Pick<FeaturesRepository, 'getEnabledFeatures'>;

const getFeatureByIdUseCase = (
  repo: GetFeaturesRepository,
  userRole: number,
  featureId: FeatureId,
) => {
  const feature = repo
    .getEnabledFeatures(userRole, SubscriptionType.FREE)
    .find((feature) => feature.featureId === featureId);
  if (feature !== null && feature !== undefined && feature.enabled) {
    return true;
  } else {
    return false;
  }
};

export { getFeatureByIdUseCase };

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as FailedIcon } from '../../icons/FailedIcon.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as TravelExploreIcon } from '../../icons/TravelExplore.svg';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

type E_facturaFaildMoadlProps = {
  onClose: () => void;
};

const E_facturaFailedModal = (props: E_facturaFaildMoadlProps) => {
  const { onClose } = props;

  const translate = useTranslate();

  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.AUTHORIZATION_HOME)}
      onClose={onClose}
      containerStyle={{ width: '558px' }}
      header={true}
      isScroll={false}
    >
      <Information>
        <FailedIcon width={60} height={60} />
        <Description>
          <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.AUTHORIZATION_HOME_ERROR)}</Text>
          <TextContainer>
            <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.POSSIBLE_CAUSES_TITLE)}</Text>
            <ul>
              <li>
                <Text type={TextType.BODY_REGULAR}>
                  {translate(LANGUAGE_KEYS.POSSIBLE_CAUSES_1)}
                </Text>
              </li>
              <li>
                <Text type={TextType.BODY_REGULAR}>
                  {translate(LANGUAGE_KEYS.POSSIBLE_CAUSES_2)}
                </Text>
              </li>
              <li>
                <Text type={TextType.BODY_REGULAR}>
                  {translate(LANGUAGE_KEYS.POSSIBLE_CAUSES_3)}
                </Text>
              </li>
            </ul>
          </TextContainer>
          <TextContainer>
            <Text type={TextType.BODY_BOLD}>
              {translate(LANGUAGE_KEYS.RETRY_AUTORIZATION_TITLE)}
            </Text>
            <ul className='number'>
              <li>
                <Text type={TextType.BODY_REGULAR}>
                  {translate(LANGUAGE_KEYS.RETRY_AUTORIZATION_1)}
                </Text>
              </li>
              <li>
                <Text type={TextType.BODY_REGULAR}>
                  {translate(LANGUAGE_KEYS.RETRY_AUTORIZATION_2)}
                </Text>
              </li>
            </ul>
          </TextContainer>
        </Description>
      </Information>
      <Hints>
        <Hint>
          <InfoIcon width={20} height={20} />
          <Text type={TextType.CAPTION_BOLD} clr={TextColor.PRIMARY}>
            {translate(LANGUAGE_KEYS.ABOUT_AUTHORIZATION_ERROR)}
          </Text>
        </Hint>
        <Hint>
          <TravelExploreIcon width={20} height={20} />
          <Text type={TextType.CAPTION_BOLD} clr={TextColor.PRIMARY}>
            {translate(LANGUAGE_KEYS.ABOUT_REGISTRATION_SPV)}
          </Text>
        </Hint>
      </Hints>
      <Button textType={TextType.CAPTION_BOLD} onClick={onClose}>
        {translate(LANGUAGE_KEYS.CLOSE)}
      </Button>
    </BaseDialog>
  );
};

const Information = styled.div`
  padding: 16px;
  background: var(--dts_withe_background);
  width: 100%;
  display: flex;
  gap: 24px;
  width: calc(100% - 32px);
  svg {
    fill: var(--dts_red);
    flex-shrink: 0;
  }
`;

const Description = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  ul {
    margin: 0px;
  }
`;

const TextContainer = styled.div`
  .number {
    list-style-type: decimal;
  }
`;

const Hints = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  & + .button {
    align-self: flex-end;
  }
`;

const Hint = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--dts_default_blue);
    path {
      fill: var(--dts_default_blue);
    }
  }
  &:hover {
    svg {
      fill: var(--dts_hover_blue);
      path {
        fill: var(--dts_hover_blue);
      }
    }
    p {
      color: var(--dts_hover_blue);
    }
  }
`;

export default E_facturaFailedModal;

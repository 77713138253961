import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../../../components/dsm/Text';
import { ReactComponent as AddIcon } from '../../../../icons/add.svg';
import { PhaseUiModel } from '../../../ui-model/PhaseUiModel';
import { DeviceSizeProps } from '../../../DeviceInformations';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import useTranslate from '../../../translations/useTranslate';

type CreateNewPhaseCardProps = {
  beforePhase: PhaseUiModel;
  disabled: boolean;
  handelOnClickCreateNew: (phaseBefore: PhaseUiModel) => void;
};

export const CreateNewPhaseCard = (props: CreateNewPhaseCardProps) => {
  const translate = useTranslate();
  const { beforePhase, disabled, handelOnClickCreateNew } = props;

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const onHandleClick = () => {
    disabled && handelOnClickCreateNew(beforePhase);
  };

  return (
    <CreateNewCardContainer
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
      aria-disabled={disabled}
      className={!disabled ? 'disabled' : ''}
      onClick={onHandleClick}
    >
      <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.ADD_NEW_PHASE)}</Text>
      <CardIcon>
        <CardAddIcon />
      </CardIcon>
    </CreateNewCardContainer>
  );
};

const CreateNewCardContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_withe_background);
    padding: 20px 12px 20px 12px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      max-width: 244px;
      min-width: 244px;
      margin-left: 8px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      max-width: 244px;
      min-width: 244px;
      margin-left: 8px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 24px;
      max-width: unset;
      min-width: unset;
      margin-left: unset;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CardIcon = styled.span`
  padding: 4px;
  line-height: 1;
`;

const CardAddIcon = styled(AddIcon)`
  width: 14px;
  height: 14px;
`;

import { BASE_URL } from '../Server';
import {
  InvoiceUpdateResponse,
  InvoiceUpdate,
  InvoiceResponse,
  InvoiceDeleteResponse,
} from '../../domain/invoice/Invoice';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';

function getInvoices(): Promise<InvoiceResponse> {
  return fetch(BASE_URL + '/invoices', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getInvoices: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function getInvoiceById(id: number): Promise<InvoiceUpdateResponse> {
  return fetch(BASE_URL + '/invoices/' + id, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getInvoiceById: ' + error);
      return { code: error.status, message: error, data: null };
    });
}

function updateInvoice(params: InvoiceUpdate): Promise<InvoiceUpdateResponse> {
  return fetch(BASE_URL + '/updateInvoice', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateInvoice: ' + error);
      return { code: error.status, message: error, data: null };
    });
}

function deleteInvoice(id: number): Promise<InvoiceDeleteResponse> {
  return fetch(BASE_URL + `/invoices/delete/${id}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy deleteInvoice: ' + error);
      return { code: error.status, message: error, data: null };
    });
}

function checkInvoiceNumber(invoicePrefix: string, invoiceNumber: number) {
  return fetch(BASE_URL + `/checkInvoiceNumber?prefix=${invoicePrefix}&number=${invoiceNumber}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy checkInvoiceNumber: ' + error);
      return { code: error.status, message: error, data: false };
    });
}

export { getInvoices, getInvoiceById, updateInvoice, checkInvoiceNumber, deleteInvoice };

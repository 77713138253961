import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../dsm/Text';
import { Button } from '../dsm/buttons/Button';

function DesktopHeader(props: {
  hasContent: boolean;
  title: string | undefined;
  buttonText: string | undefined;
  enabledClick: boolean;
  onClick: () => void | undefined;
  buttonIcon: JSX.Element | undefined;
}) {
  return (
    <ContentHeader className={`${!props.hasContent && 'no-content'}`}>
      <ContentHeaderLeft>
        <TitleText type={TextType.TITLE_BOLD_4} ellipsis={true}>
          {props.title}
        </TitleText>
      </ContentHeaderLeft>
      <ContentHeaderRight>
        {props.enabledClick && (
          <Button
            onClick={() => {
              props.onClick();
            }}
          >
            {props.buttonIcon}
            {props.buttonText}
          </Button>
        )}
      </ContentHeaderRight>
    </ContentHeader>
  );
}

const ContentHeader = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 0px 36px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin-bottom: 16px;
  gap: 8px;
  &.no-content {
    margin-bottom: 0px;
  }
`;

const ContentHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0;
  flex-grow: 1;
  gap: 16px;
  width: calc(100% - 146px);
`;

const ContentHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const TitleText = styled(Text)``;

export default DesktopHeader;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Navbar from './components/Navbar';
import { Text, TextType } from '../components/dsm/Text';
import { ReactComponent as LoginIcon } from '../../icons/login.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ReactComponent as MenuIcon } from '../../icons/menu.svg';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';

const Header = () => {
  const navigate = useNavigate();

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {}, [isMobile]);

  const menuItemsData = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Quick Data Entry',
      url: '/quick-data-entry',
    },
    {
      title: 'Workshelf',
      url: '/workshelf',
    },
    {
      title: 'Callendar',
      url: '/callendar',
    },
    {
      title: 'Reports',
      url: '/reports',
    },
    {
      title: 'Accounting',
      url: '/accounting',
    },
    {
      title: 'Invoicing',
      url: '/invoicing',
    },
    {
      title: 'Assigning',
      url: '/assigning',
    },
    {
      title: 'Contact',
      url: '/contact',
    },
  ];

  return (
    <CustomHeader
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
      className={`${navbarOpen && 'open-menu'}`}
    >
      <NavArea
        className={`${navbarOpen && 'open-menu'}`}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <LogoConatiner
          onClick={() => {
            navigate('/');
            setNavbarOpen(false);
          }}
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <IconLogin
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          />
          <div>
            <Text type={TextType.TITLE_BOLD_2} className='logo-name'>
              DENTECH
            </Text>
            <Text type={TextType.ROBOTO_SMALL_7}>DENTAL MANAGEMENT SYSTEM</Text>
          </div>
        </LogoConatiner>
        <Navbar
          menuItems={menuItemsData}
          navbarOpen={navbarOpen}
          closeNavbar={() => {
            setNavbarOpen(false);
          }}
        />
        <ActionsConatiner
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <ActionButton
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
            onClick={() => {
              navigate('/login');
            }}
          >
            <Text type={TextType.BODY_2_BOLD}>Login</Text>
          </ActionButton>
          {!isMobile && (
            <ActionButton
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              className={isMobile ? '' : 'light-blue-backgroud'}
              onClick={() => {
                navigate('/signup');
              }}
            >
              <Text type={TextType.BODY_2_BOLD}>{isMobile ? 'Sign Up' : 'Start for free'}</Text>
            </ActionButton>
          )}
          {isMobile && !navbarOpen && (
            <MenuIconStyled
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              onClick={() => setNavbarOpen((prev) => !prev)}
            />
          )}
          {isMobile && navbarOpen && (
            <CloseIconStyled
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              onClick={() => setNavbarOpen((prev) => !prev)}
            />
          )}
        </ActionsConatiner>
      </NavArea>
    </CustomHeader>
  );
};

const CustomHeader = styled.header<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      &.open-menu+div {
        overflow: hidden;
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const NavArea = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: no-wrap;
    gap: 10px;
    background: #9696961a;
    @media (max-width: 700px) {
      flex-wrap: wrap;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 16px 36px 16px 36px;

    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 16px 36px 16px 36px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 30px 24px 24px 24px;
      justify-content: space-between;
      gap: 19px;
      &.open-menu {
        background: var(--dts_white)
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const LogoConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo-name {
      line-height: 26px;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const IconLogin = styled(LoginIcon)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    width: 36px;
    height: 36px;
    flex-basis: 1;
    path {
      fill: var(--dts_default_blue);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const ActionsConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      gap: 24px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const ActionButton = styled.button<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 7px 15px;
    border: 1px solid var(--dts_black);
    border-radius: 60px;
    background: transparent;
    min-width: 71px;
    cursor: pointer;
    &.light-blue-backgroud {
      background: #18a0fb4d;
    }
    &:hover {
      background: var(--dts_hover_blue);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const MenuIconStyled = styled(MenuIcon)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer;
    width: 25px;
    height: 24px;
    path{
      fill:var(--dts_black);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      display: none;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin: auto 0;
      right: 0px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CloseIconStyled = styled(CloseIcon)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer;
    width: 18px;
    height: 14px;
    path{
      fill:var(--dts_black);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      display: none;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin: auto 0;
      right: 0px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export default Header;

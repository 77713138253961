import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

type E_facturaSuccessProps = {
  onClose: () => void;
};

const E_facturaSuccessModal = (props: E_facturaSuccessProps) => {
  const { onClose } = props;

  const translate = useTranslate();

  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.AUTHORIZATION_HOME)}
      onClose={onClose}
      containerStyle={{ width: '558px' }}
      header={true}
      isScroll={false}
    >
      <Information>
        <SuccessIcon width={60} height={60} />
        <Description>
          <Text type={TextType.BODY_BOLD}>
            {translate(LANGUAGE_KEYS.AUTHORIZATION_HOME_SUCCSESS)}
          </Text>
          <Text type={TextType.BODY_REGULAR}>
            {translate(LANGUAGE_KEYS.AUTHORIZATION_HOME_SUCCSESS_DESCRIPTION)}
          </Text>
        </Description>
      </Information>
      <Button textType={TextType.CAPTION_BOLD} onClick={onClose}>
        {translate(LANGUAGE_KEYS.CONTINUE)}
      </Button>
    </BaseDialog>
  );
};

const Information = styled.div`
  padding: 16px;
  background: var(--dts_withe_background);
  width: 100%;
  display: flex;
  gap: 24px;
  width: calc(100% - 32px);
  svg {
    fill: var(--dts_success);
    flex-shrink: 0;
  }
  & + .button {
    align-self: flex-end;
  }
`;

const Description = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export default E_facturaSuccessModal;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Breadcrumb, { BreadcrumbItem } from '../components/generic/Breadcrumb';
import { TextType } from '../components/dsm/Text';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';
import { useInvoiceViewModel } from './InvoiceViewModel';
import { InvoiceUi } from '../ui-model/invoice/InvoiceUiModel';
import { ReportsOverviewType } from '../ui-model/reports/ReportOverviewUiModel';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import { useCompany } from '../hooks/useCompany';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import ContentLayout from '../components/layout/ContentLayout';
import InvoiceDetailBody from './InvoiceDetailBody';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';

function InvoiceDetail() {
  const { invoiceId } = useParams();
  const { state } = useLocation();
  const translate = useTranslate();
  const currentCompany = useCompany();
  const repo = useInvoiceRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const { getInvoiceById } = useInvoiceViewModel(
    repo,
    loggedUserRepository,
    featureRepo,
    e_factuarRepository,
  );
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const [invoice, setInvoice] = useState<InvoiceUi>();

  useEffect(() => {
    getInvoiceById(Number(invoiceId)).then((resp) => {
      if (resp.statusCode === 200) {
        if (resp.invoiedata !== null) {
          setInvoice(resp.invoiedata);
        }
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
      }
    });
  }, [repo.getInvoiceById, invoiceId]);

  let breadcrumbList: BreadcrumbItem[] = [];

  if (state !== null && state.cases !== null) {
    breadcrumbList = [
      {
        name: translate(LANGUAGE_KEYS.REPORTS),
        link: `/${currentCompany.companyId}/reports/reportsoverview`,
        state: state.cases !== null && { cases: state.cases, type: ReportsOverviewType.EXPENSE },
      },
      {
        name: `${translate(LANGUAGE_KEYS.INVOICE)} ${invoice?.invoicePrefix} ${
          invoice?.invoiceNumber
        }`,
        link: '',
      },
    ];
  }

  return (
    <Content>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={false}
          title={undefined}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={false}
          title={undefined}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <HeaderWithEFactura>
          <ContentHeader>
            <Breadcrumb
              textType={
                !isTabletSmall && !isTabletLarge && !isMobile
                  ? TextType.TITLE_BOLD_4
                  : TextType.BODY_BOLD
              }
              iconWidth={!isTabletSmall && !isTabletLarge && !isMobile ? 12.35 : 9}
              iconHeight={!isTabletSmall && !isTabletLarge && !isMobile ? 20 : 17}
              breadcrumbList={
                state !== null && state.breadCrumb !== null && state.breadCrumb !== undefined
                  ? state.breadCrumb
                  : breadcrumbList
              }
            />
          </ContentHeader>
        </HeaderWithEFactura>
        <InvoiceDetailBody />
      </ContentLayout>
    </Content>
  );
}

const Content = styled.div`
  height: 100%;
  position: relative;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderWithEFactura = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export default InvoiceDetail;

import React from 'react';
import styled from 'styled-components';
import { Text, TextColor, TextType } from '../../components/dsm/Text';

export type InputField = {
  handleSubmit: (e: any) => void;
  type: string;
  action: 'reset' | 'button' | 'submit' | undefined;
  text: string;
  disabled?: boolean;
};

const FormAction = (props: InputField) => {
  const { handleSubmit, type, action, text, disabled } = props;
  const onHandleSubmit = (e: any) => {
    handleSubmit(e);
  };
  return (
    <>
      {type === 'Button' ? (
        <ActionButton
          type={action}
          onClick={onHandleSubmit}
          className={`${disabled !== undefined && !disabled && 'disabled'}`}
        >
          <Text type={TextType.CAPTION_BOLD} clr={TextColor.WHITE}>
            {text}
          </Text>
        </ActionButton>
      ) : (
        <ActionTextContainer>
          <ActionText type={action}>
            <Text
              type={TextType.CAPTION_REGULAR}
              onClick={onHandleSubmit}
              className={`${disabled !== undefined && !disabled && 'disabled'}`}
            >
              {text}
            </Text>
          </ActionText>
        </ActionTextContainer>
      )}
    </>
  );
};

const ActionButton = styled.button`
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  border-radius: 3px;
  background: var(--dts_default_blue);
  border: none;
  margin-bottom: 16px;
  &:hover {
    background: var(--dts_hover_blue);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
`;

const ActionTextContainer = styled.span`
  text-align: center;
  margin-bottom: 16px;
`;

const ActionText = styled.button`
   {
    background: transparent;
    border: none;
    p {
      cursor: pointer;
      color: var(--dts_default_blue);
      &.disabled {
        pointer-events: none;
      }
      &:hover {
        color: var(--dts_hover_blue);
      }
    }
  }
`;

export default FormAction;

import { useSelector } from 'react-redux';
import { AppRootStore } from '../appRepositoryImplementation';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';
import { LoggedUserReducer } from './loggedUserReducer';

const loggedUserSelector = (state: AppRootStore) => state.loggedUser;

const useLoggedUserRepositoryImplementation = (): LoggedUserRepository => {
  const {
    session,
    userId,
    firstName,
    lastName,
    email,
    companyId,
    lang,
    profileColor,
    tellNumber,
    isFtiFinished,
    accessToken,
    authenticated,
    mode,
    validSubscription,
    packageType,
    billingType,
    subscriptionEndTime,
  } = useSelector<AppRootStore, LoggedUserReducer>(loggedUserSelector);

  return {
    session,
    userId,
    firstName,
    lastName,
    email,
    companyId,
    lang,
    profileColor,
    tellNumber,
    isFtiFinished,
    accessToken,
    authenticated,
    mode,
    validSubscription,
    packageType,
    billingType,
    subscriptionEndTime,
  };
};

export { useLoggedUserRepositoryImplementation };

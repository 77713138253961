import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as HeadIcon } from '../../../../icons/headIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../icons/pencil.svg';
import { ReactComponent as DotsIcon } from '../../../../icons/dots.svg';
import { Text, TextType } from '../../../components/dsm/Text';
import { PhaseButton } from './PhaseButton';
import {
  ContextMenu,
  ContextMenuOffset,
  ContextMenuOption,
} from '../../../components/dsm/ContextMenu';
import { ReactComponent as DuplicateIcon } from '../../../../icons/duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/trash.svg';
import {
  PhaseUiModel,
  PhaseUiModelPhase,
  mapStatusToNumeric,
} from '../../../ui-model/PhaseUiModel';
import Tooltip from '../../../components/dsm/Tooltip';
import { getErrorStatusUseCase } from '../../../../useCases/getErrorStatusUseCase';
import { DeviceSizeProps } from '../../../DeviceInformations';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import Avatar from '../../../components/generic/Avatar';
import { DRAFT } from '../../caseStates';

type PhaseCardProps = {
  phase: PhaseUiModelPhase;
  isEnabledAssigne: boolean;
  phaseModificationsCases: {
    currentPhaseModification: boolean;
    previousPhaseModification: boolean;
    allPhaseModification: boolean;
  };
  caseSatus: number;
  onAssign: (x: number, y: number) => void;
  onErrorStatus: (x: number, y: number) => void;
  onStatusChange: (phase: PhaseUiModelPhase, status: PhaseUiModelPhase['status']) => void;
  onDuplicatePhase: (phase: PhaseUiModel) => void;
  onDeletePhase: (phase: PhaseUiModel) => void;
};

export const PhaseCard = (props: PhaseCardProps) => {
  const {
    phase,
    isEnabledAssigne,
    phaseModificationsCases,
    caseSatus,
    onAssign,
    onErrorStatus,
    onStatusChange,
    onDuplicatePhase,
    onDeletePhase,
  } = props;

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });

  const infoRef = useRef<HTMLDivElement>(null);
  const [isInfoTooltip, setIsInfoTooltip] = useState<boolean>(false);

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const errorStatuses = getErrorStatusUseCase();

  useEffect(() => {
    if (infoRef !== null) {
      if (infoRef.current?.offsetWidth !== undefined && infoRef.current?.offsetWidth >= 169) {
        setIsInfoTooltip(true);
      }
    }
  }, []);

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <DuplicateIcon />,
      label: 'Duplicate',
      onClick: () => {
        onDuplicatePhase(phase);
      },
    },
    {
      key: 1,
      icon: <DeleteIcon />,
      label: 'Delete',
      onClick: () => {
        onDeletePhase(phase);
      },
    },
  ];

  const isEnabledStartButton = () => {
    return (
      caseSatus !== DRAFT &&
      (phaseModificationsCases.allPhaseModification ||
        phaseModificationsCases.currentPhaseModification)
    );
  };

  const isEnabledDoneButton = () => {
    return (
      caseSatus !== DRAFT &&
      (phaseModificationsCases.allPhaseModification ||
        phaseModificationsCases.currentPhaseModification)
    );
  };

  const isEnabledErrorButton = () => {
    return (
      caseSatus !== DRAFT &&
      (phaseModificationsCases.allPhaseModification ||
        phaseModificationsCases.previousPhaseModification)
    );
  };

  const renderUnassigned = () => (
    <PhaseButton
      variant='Assign'
      disabled={!isEnabledAssigne}
      onClick={(e) => isEnabledAssigne && onAssign(e.clientX, e.clientY)}
    />
  );
  const renderOpen = () => (
    <PhaseButton
      variant='Start'
      secondary={true}
      disabled={!isEnabledStartButton()}
      onClick={() => {
        isEnabledStartButton() && onStatusChange(phase, 'Started');
      }}
    />
  );
  const renderOpenForFuture = () => <PhaseButton variant='Pending' onClick={() => {}} />;
  const renderStarted = () => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <PhaseButton
        variant='Done'
        secondary={true}
        disabled={!isEnabledStartButton()}
        onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Finished')}
      />
      <PhaseButton
        variant='Dislike'
        secondary={true}
        disabled={!isEnabledErrorButton()}
        onClick={(e) => isEnabledErrorButton() && onErrorStatus(e.clientX, e.clientY)}
      />
    </div>
  );
  const renderFinished = () => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <PhaseButton variant='Done' disabled={!isEnabledStartButton()} onClick={() => {}} />
      <PhaseButton
        variant='Dislike'
        secondary={true}
        disabled={!isEnabledErrorButton()}
        onClick={(e) => isEnabledErrorButton() && onErrorStatus(e.clientX, e.clientY)}
      />
    </div>
  );

  const renderFinishing = () => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <PhaseButton
        variant='Done'
        disabled={!isEnabledStartButton()}
        onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Started')}
      />
      <PhaseButton
        variant='Dislike'
        secondary={true}
        disabled={!isEnabledErrorButton()}
        onClick={(e) => isEnabledErrorButton() && onErrorStatus(e.clientX, e.clientY)}
      />
    </div>
  );
  const renderFinishedWithError = () => {
    const errorName = errorStatuses.find((error) => {
      if (mapStatusToNumeric(phase.status) == error.value) return error;
    });

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <PhaseButton
          variant='Done'
          secondary={true}
          disabled={!isEnabledStartButton()}
          onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Finished')}
        />
        {!isMobile && (
          <Tooltip key={1} content={errorName?.label} placement='bottom'>
            <span>
              <PhaseButton
                variant='Dislike'
                disabled={!isEnabledErrorButton()}
                onClick={(e) => isEnabledErrorButton() && onErrorStatus(e.clientX, e.clientY)}
              />
            </span>
          </Tooltip>
        )}
        {isMobile && (
          <PhaseButton
            variant='Dislike'
            disabled={!isEnabledErrorButton()}
            onClick={(e) => isEnabledErrorButton() && onErrorStatus(e.clientX, e.clientY)}
          />
        )}
      </div>
    );
  };
  const renderFinishingWithError = () => {
    const errorName = errorStatuses.find((error) => {
      if (mapStatusToNumeric(phase.status) == error.value) return error;
    });
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        <PhaseButton
          variant='Done'
          secondary={true}
          disabled={!isEnabledStartButton()}
          onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Finishing')}
        />
        {!isMobile && (
          <Tooltip key={1} content={errorName?.label} placement='bottom'>
            <span>
              <PhaseButton
                variant='Dislike'
                disabled={!isEnabledDoneButton()}
                onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Started')}
              />
            </span>
          </Tooltip>
        )}
        {isMobile && (
          <PhaseButton
            variant='Dislike'
            disabled={!isEnabledDoneButton()}
            onClick={() => isEnabledDoneButton() && onStatusChange(phase, 'Started')}
          />
        )}
      </div>
    );
  };
  const renderAvatarOverlay = () => (
    <AssignOverlay onClick={(e) => onAssign(e.clientX, e.clientY)}>
      <AssignOverlayBackground />
      <PencilIconStyled />
    </AssignOverlay>
  );
  const renderPhaseOptionContext = () => (
    <ContextMenu
      isOpen={isContextMenuOpen}
      setIsOpen={setIsContextMenuOpen}
      options={ContextMenuOptions}
      offset={contextMenuOffset}
    />
  );

  return (
    <Container
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <Body
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        onContextMenu={(e) => {
          e.preventDefault();
          handleContextMenuClick({ x: e.clientX, y: e.clientY });
        }}
        className={`${phase.status}`}
      >
        {(phase.status === 'Unassigned' ||
          phase.status === 'Open' ||
          phase.status === 'OpenForFuture') &&
          !isMobile &&
          renderPhaseOptionContext()}
        <AvatarContainer>
          {phase.kind === 'WithAssignee' ? (
            <Avatar
              fullName={phase?.assignee.name}
              size={{ outer: 40, inner: 38 }}
              textType={TextType.BODY_BOLD}
              avatarStyle={{ background: phase?.assignee.profileColor }}
            />
          ) : (
            <>
              <HeadIconStyled />
            </>
          )}
          {(phase.status === 'Unassigned' ||
            phase.status === 'Open' ||
            phase.status === 'OpenForFuture') &&
            phase.kind !== 'NoAssignee' &&
            isEnabledAssigne &&
            renderAvatarOverlay()}
        </AvatarContainer>
        <InfoContainer
          ref={infoRef}
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          {isInfoTooltip ? (
            <Tooltip key={1} content={phase.name} placement='bottom'>
              <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                {phase.name}
              </Text>
            </Tooltip>
          ) : (
            <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
              {phase.name}
            </Text>
          )}
          {phase.status === 'Unassigned' && renderUnassigned()}
          {phase.status === 'Open' && renderOpen()}
          {phase.status === 'OpenForFuture' && renderOpenForFuture()}
          {phase.status === 'Started' && renderStarted()}
          {phase.status === 'Finished' && renderFinished()}
          {phase.status === 'Finishing' && renderFinishing()}
          {phase.status === 'FinishedWithError_1' && renderFinishedWithError()}
          {phase.status === 'FinishedWithError_2' && renderFinishedWithError()}
          {phase.status === 'FinishedWithError_3' && renderFinishedWithError()}
          {phase.status === 'FinishingWithError_1' && renderFinishingWithError()}
          {phase.status === 'FinishingWithError_2' && renderFinishingWithError()}
          {phase.status === 'FinishingWithError_3' && renderFinishingWithError()}
        </InfoContainer>
        {isMobile && (
          <IconConatiner>
            <CustomDotsIcon
              onClick={(e) => {
                e.preventDefault();
                handleContextMenuClick({ x: e.clientX, y: e.clientY - 40 });
              }}
            />
            <MobileContextMenuStyle
              isOpen={isContextMenuOpen}
              setIsOpen={setIsContextMenuOpen}
              options={ContextMenuOptions}
              offset={contextMenuOffset}
            />
          </IconConatiner>
        )}
      </Body>
    </Container>
  );
};

const Container = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 100%;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const Body = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_withe_background);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 11px;
    width: 244px;
    padding: 10px 12px;
    box-sizing: border-box;

    &.Started {
      background: var(--phase-started-background-color);
    }

    &.Finished,
    &.Finishing {
      background: var(--phase-finished-background-color);
    }

    &[class*='FinishedWithError'],
    &[class*='FinishingWithError'] {
      background: var(--phase-error-background-color);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 100%;
      border-radius: 3px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const AvatarContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  flex: none;
  order: 0;
  flex-grow: 0;

  svg {
    position: absolute;
  }
`;

const InfoContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 4px;
  //Desktop
    @media (min-width: ${desktop}px) {
      max-width: 169px;
      p {
        max-width: 100%;
      }
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      max-width: 169px;
      p {
        max-width: 100%;
      }
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      max-width: calc(100% - 110px);
      flex-grow: 1;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const HeadIconStyled = styled(HeadIcon)`
  height: 40px;
  width: 40px;
  path {
    fill: var(--dts_dark_grey);
  }
`;

const AssignOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  top: 0;

  &:hover {
    opacity: 1;
  }
`;

const AssignOverlayBackground = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #151515;
  opacity: 0.8;
`;

const PencilIconStyled = styled(PencilIcon)`
  position: absolute;
  height: 20px;
  width: 20px;
  left: 25%;
  top: 25%;
  bottom: 25%;
  right: 25%;
`;

const IconConatiner = styled.span`
  line-height: 0;
  text-align: end;
  position: relative;
`;

const CustomDotsIcon = styled(DotsIcon)`
  padding: 10px;
  flex-shrink: 1;
`;

const MobileContextMenuStyle = styled(ContextMenu)`
  position: absolute;
  right: 5px;
  top: 20px !important;
  left: unset !important;
`;

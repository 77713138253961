import type { AnyAction } from 'redux';
import * as actionTypes from './teethActionTypes';
import { TeethRepository } from '../../../domain/worktype/teeth/TeethRepository';

type TeethRepositoryState = Omit<TeethRepository, 'getTeeth' | 'updateTeeth'>;

const INITIAL_STATE: TeethRepositoryState = {
  teeth: undefined,
  isLoading: false,
  isUpdating: false,
};

const teethReducer = (state: TeethRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_TEETH:
      return { ...state, isLoading: true };
    case actionTypes.GET_TEETH_SUCCESS:
      return { ...state, isLoading: false, teeth: action.teeth };
    case actionTypes.GET_TEETH_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_TEETH:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_TEETH_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_TEETH_FAILD:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
export { teethReducer };
export type { TeethRepositoryState };

export class TemplateBySubWorkType {
  workTypeId: number;
  groupId: number;
  extensionId: number;
  templateId: number;

  constructor(workTypeId: number, groupId: number, extensionId: number, templateId: number) {
    this.workTypeId = workTypeId;
    this.groupId = groupId;
    this.extensionId = extensionId;
    this.templateId = templateId;
  }
}

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { Text, TextType } from '../../../../../components/dsm/Text';
import DroppableItem from './DroppableItem';
import CustomScrollbar from '../../../../../components/generic/CustomScrollbar';
import Searchbar from '../../../../../components/generic/Searchbar';
import { BaseDialog } from '../../../../../components/generic/BaseDialog';
import { ReactComponent as DragDrop } from '../../../../../../icons/drag&drop.svg';
import { ReactComponent as CloseIcon } from '../../../../../../icons/close.svg';
import { TemplatePhaseUiModel } from '../../../../../ui-model/worktype/TemplatePhaseUiModel';
import { WorkTypeTemplateSubCategoryUiModel } from '../../../../../ui-model/worktype/WorkTypeTemplateSubCategoryUiModel';
import { LANGUAGE_KEYS } from '../../../../../translations/languageKeys';
import useTranslate from '../../../../../translations/useTranslate';

export type EditDialogProps = {
  allList: TemplatePhaseUiModel[] | WorkTypeTemplateSubCategoryUiModel[];
  addedList: TemplatePhaseUiModel[] | WorkTypeTemplateSubCategoryUiModel[];
  emptyAddedList: boolean;
  addedListSize: number;
  modalName: string;
  emptyAddedListText: string;
  addedListTitle: string;
  searchPlaceHolder: string;
  draggableType: string;
  isScrollToBottom?: boolean;
  dragEndDrop: (result: DropResult) => void;
  onHandleClearAll: () => void;
  onDeleteItem: (
    item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel,
    index: number,
  ) => void;
  onHandleChangeSearchBar: (e: any) => void;
  onHandleCloseSearch: () => void;
  onClose: () => void;
  onClick: (item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel) => void;
};

const EditDialog = (props: EditDialogProps) => {
  const translate = useTranslate();
  const {
    allList,
    addedList,
    emptyAddedList,
    addedListSize,
    modalName,
    emptyAddedListText,
    addedListTitle,
    searchPlaceHolder,
    draggableType,
    isScrollToBottom,
    dragEndDrop,
    onHandleClearAll,
    onDeleteItem,
    onHandleChangeSearchBar,
    onHandleCloseSearch,
    onClose,
    onClick,
  } = props;

  const refAdded = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isScrollToBottom) {
      scrollToBottom();
    }
  });

  const scrollToBottom = () => {
    const lastChildElement = refAdded.current?.children[0].children[0].lastElementChild;
    lastChildElement?.scrollIntoView({ behavior: 'smooth' });
  };

  const searchInputStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: 'var(--dts_black)',
    border: '1px solid transparent',
    boxShadow: 'none',
    outline: 'none',
    padding: '9px 35px 9px 8px',
    borderRadius: '3px',
  };

  const renderDialogContent = () => {
    return (
      <DragDropContext onDragEnd={dragEndDrop}>
        <Container>
          <ActionsDroppableContainer>
            {emptyAddedList && (
              <EmptyColumn>
                <Text type={TextType.CAPTION_REGULAR}>{emptyAddedListText}</Text>
              </EmptyColumn>
            )}
            <ActionsContainer>
              {!emptyAddedList && (
                <>
                  <TextContainer>
                    <Text type={TextType.CAPTION_BOLD}>{addedListSize}</Text>
                    <Text type={TextType.CAPTION_REGULAR}>{addedListTitle}</Text>
                  </TextContainer>
                  <Actions>
                    <ClearButton onClick={onHandleClearAll}>
                      <Text type={TextType.ROBOTO_SMALL_8}>
                        {translate(LANGUAGE_KEYS.CLEAR_ALL)}
                      </Text>
                      <CloseIconStyle />
                    </ClearButton>
                  </Actions>
                </>
              )}
            </ActionsContainer>
            <CustomScrollbar
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              autoHeightMin={window.innerWidth > 700 ? 306 : 204}
              autoHeightMax={window.innerWidth > 700 ? 306 : 204}
              thumbMinSize={40}
            >
              <DroppableContainer ref={refAdded}>
                <DroppableItem
                  list={addedList}
                  droppableId='Added'
                  type={'added'}
                  draggableType={draggableType}
                  deleteItem={onDeleteItem}
                  onClick={onClick}
                />
              </DroppableContainer>
            </CustomScrollbar>
          </ActionsDroppableContainer>
          <CustomDragDrop />
          <ActionsDroppableContainer>
            <SearchbarContainer>
              <Searchbar
                placeHolder={searchPlaceHolder}
                onHandleChangeSearchBar={onHandleChangeSearchBar}
                searchInputStyle={searchInputStyle}
                onHandleCloseSearch={onHandleCloseSearch}
              />
            </SearchbarContainer>
            <CustomScrollbar
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              autoHeight
              autoHeightMin={window.innerWidth > 700 ? 306 : 204}
              autoHeightMax={window.innerWidth > 700 ? 306 : 204}
              thumbMinSize={40}
            >
              <DroppableContainer>
                <DroppableItem
                  list={allList}
                  droppableId='All'
                  type={'all'}
                  draggableType={draggableType}
                  deleteItem={onDeleteItem}
                  onClick={onClick}
                />
              </DroppableContainer>
            </CustomScrollbar>
          </ActionsDroppableContainer>
        </Container>
      </DragDropContext>
    );
  };

  return (
    <BaseDialog
      isOpen={true}
      title={modalName}
      titleTextType={TextType.BODY_BOLD}
      onClose={onClose}
      containerStyle={window.innerWidth > 700 ? { width: '588px' } : { width: '500px' }}
      header={true}
      isScroll={false}
    >
      {renderDialogContent()}
    </BaseDialog>
  );
};

const ActionsDroppableContainer = styled.div`
  width: 248px;
  height: 350px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 5px;
  display: flex;
  background: var(--dts_withe_background);
  flex-direction: column;
  position: relative;
  gap: 4px;
  @media (max-width: 700px) {
    width: 230px;
    height: 251px;
  }
`;
const Container = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
`;

const ActionsContainer = styled(Container)`
  padding: 6px 8px 6px 8px;
  min-height: 24px;
  margin-top: 2px;

  &.input-element {
    padding: 0px;
    margin-right: 4px;
    margin-left: 4px;
    display: inline-flex;
  }
`;
const Actions = styled.div`
  display: flex;
  flexdirection: row;
  align-items: center;
  gap: 5px;
`;

const DroppableContainer = styled.div``;

const EmptyColumn = styled.div`
  position: absolute;
  display: flex;
  text-align: center;
  inset: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

const CustomDragDrop = styled(DragDrop)`
  flex-shrink: 0;
  @media (max-width: 700px) {
    width: 25px;
    height: 25px;
  }
`;

const CloseIconStyle = styled(CloseIcon)`
  width: 7px;
  height: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
  path {
    fill: currentColor;
  }
`;

const ClearButton = styled.button`
  background: transparent;
  border: 1px solid #464646cc;
  padding: 5px 8px;
  border-radius: 3px;
  color: var(--dts_dark);
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  p,
  svg {
    opacity: 0.7;
  }
  &:hover {
    border: 1px solid var(--dts_dark_grey);
    p,
    svg {
      opacity: 0.7;
    }
  }
  &:focus {
    p,
    svg {
      opacity: 1;
    }
  }
  &:active {
    p,
    svg {
      opacity: 1;
    }
  }
`;

const SearchbarContainer = styled.div`
  max-width: 244px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  border-radius: 3px;
  border: 1px solid var(--dts_light_grey);
`;

export default EditDialog;

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Text, TextColor, TextType } from '../components/dsm/Text';
import { RoundButton } from '../components/dsm/buttons/RoundButton';
import { DashboardContext } from './DashboardProvider';
import useTranslate from '../translations/useTranslate';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { ReactComponent as ArrowDownIcon } from '../../icons/arrowDown.svg';

function DashboardFilters(props: any) {
  const [menuSelection, setMenuSelection] = useContext(DashboardContext);
  const translate = useTranslate();

  const { isMobile } = useDeviceParameters();

  const [selectFilter, setSelectFilter] = useState<any>();

  useEffect(() => {
    setSelectFilter(props.filters.length > 0 ? props.filters[0] : null);
    if (props.filters.length > 0) {
      clickFilter(props.filters[0].filterType);
    }
  }, [props.filters]);

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {!isMobile &&
        props.filters?.map((filter, index) => {
          return (
            <FilterContent key={index}>
              <RoundButton
                onClick={() => clickFilter(filter.filterType)}
                indicator={filter.indicator}
                buttonState={checkState(filter.filterType)}
                textType={TextType.BODY_2_BOLD}
              >
                {translate(filter.label)} ({filter.elementNr})
              </RoundButton>
            </FilterContent>
          );
        })}
      {isMobile && (
        <MobileDropDownFilterContainer tabIndex={0} onBlur={() => setExpanded(false)}>
          <MobileDropDownFilter onClick={() => setExpanded(!expanded)}>
            <Text type={TextType.BODY_2_BOLD} clr={TextColor.WHITE} ellipsis={true}>
              {selectFilter !== null ? translate(selectFilter.label) : ''}
            </Text>
            <ArrowContainer>
              <CustomArrowIcon className={`${expanded && 'open'}`} />
            </ArrowContainer>
          </MobileDropDownFilter>
          <MobileDropDownFilterItems className={`${expanded && 'open'}`}>
            {props.filters?.map((filter, index) => {
              return (
                <LabelText
                  key={index}
                  type={TextType.BODY_2}
                  ellipsis={true}
                  onClick={() => {
                    setSelectFilter(filter);
                    setExpanded(false);
                    clickFilter(filter.filterType);
                  }}
                >
                  {translate(filter.label)}
                </LabelText>
              );
            })}
          </MobileDropDownFilterItems>
        </MobileDropDownFilterContainer>
      )}
    </>
  );

  function checkState(state: number) {
    if (menuSelection === state) return 0;
    return 1;
  }

  function clickFilter(state: number) {
    if (menuSelection !== state) {
      setMenuSelection(state);
    }
  }
}

const FilterContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const MobileDropDownFilterContainer = styled.div``;

const MobileDropDownFilter = styled.span`
  background: var(--dts_black);
  border-radius: 45px;
  display: flex;
  padding: 8px 16px;
  max-width: 105px;
  align-items: center;
  gap: 8px;
`;

const ArrowContainer = styled.span`
  flex-grow: 0;
  line-height: 0;
`;

const CustomArrowIcon = styled(ArrowDownIcon)`
  padding: 5px;
  &.open {
    transform: rotate(180deg);
  }

  path {
    fill: var(--dts_white);
  }
`;

const MobileDropDownFilterItems = styled.div`
  display: none;
  flex-direction: column;
  max-height: 0px;
  transition: max-height 0.3s ease-out;
  background-color: var(--dts_white);
  border-radius: 4px;
  &.open {
    display: flex;
    max-height: 200px;
    min-width: 200px;
    transition: max-height 0.3s ease-in;
    box-shadow: -1px 2px 11px 0px #00000024;
    padding: 5px;
  }
  position: absolute;
  z-index: 9999;
`;

const LabelText = styled(Text)`
  padding: 5px;
`;
export default DashboardFilters;

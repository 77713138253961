import * as actionTypes from './reportActionTypes';
import { getReportCases, getReportCasesByIds, accountPhases } from './reportService';
import { ReportCasesRequest } from '../../domain/reports/Report';
import { SolvedPayOff } from '../../domain/reports/PayOff';
import { checkErrorStatus } from '../ErrorHandling';

const getReportCasesAction = (params: ReportCasesRequest) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_REPORT_CASES });
  return getReportCases(params).then((rc) => {
    if (checkErrorStatus(rc.code, dispatch)) {
      dispatch({ type: actionTypes.GET_REPORT_CASES_SUCCESS, reportCases: rc.data });
      return rc.data;
    } else {
      dispatch({ type: actionTypes.GET_REPORT_CASES_FAILD, reportCases: [] });
      return {
        states: [],
        cases: [],
      };
    }
  });
};
const getReportCasesByIdsAction = (param: Array<number>) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_REPORT_CASES_BY_IDS });
  return getReportCasesByIds(param).then((rc) => {
    if (checkErrorStatus(rc.code, dispatch)) {
      dispatch({ type: actionTypes.GET_REPORT_CASES_BY_IDS_SUCCESS });
    } else {
      dispatch({ type: actionTypes.GET_REPORT_CASES_BY_IDS_FAILD });
    }
    return { data: rc.data, statusCode: rc.code };
  });
};

const accountPhasesAction = (param: SolvedPayOff) => (dispatch: any) => {
  dispatch({ type: actionTypes.SAVE_ACCOUNT_PHASES });
  return accountPhases(param).then((rc) => {
    if (checkErrorStatus(rc.code, dispatch)) {
      dispatch({ type: actionTypes.SAVE_ACCOUNT_PHASES_SUCCESS });
    } else {
      dispatch({ type: actionTypes.SAVE_ACCOUNT_PHASES_FAILD });
    }
    return { data: rc.data, statusCode: rc.code };
  });
};

export { getReportCasesAction, getReportCasesByIdsAction, accountPhasesAction };

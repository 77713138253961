import * as React from 'react';
import { useEffect, useState } from 'react';
import { useCaseRepositoryImplementation } from '../../data/cases/CaseRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../../data/worktype/WorkTypeRepositoryImplementation';
import { useCaseDetailViewModel } from './CaseDetailViewModel';
import { useParams } from 'react-router';
import { useExtensionRepositoryImplementation } from '../../data/worktype/extension/ExtensionRepositoryImplementation';
import { useDispatch } from 'react-redux';
import { resetCaseDetail, setCaseDetail } from '../../data/cases/detail/caseDetailReducer';
import { appRepositoryImplementation } from '../../data/appRepositoryImplementation';
import {
  useContactsRepositoryImplementation,
  useCompaniesRepositoryImplementation,
} from '../../data/user-management/UsersRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useCompany } from '../hooks/useCompany';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { AlertType } from '../../domain/alert/AlertManager';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';

export const CaseDetailContext = React.createContext({} as any);

export function CaseDetailProvider(props: { children }) {
  const { id } = useParams();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const repo = useCaseRepositoryImplementation();
  const wtRepo = useWorkTypeRepositoryImplementation();
  const extensionsRepo = useExtensionRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const invoiceRepo = useInvoiceRepositoryImplementation();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const currentCompany = useCompany();

  const {
    cases,
    allCase,
    contacts,
    extensions,
    caseDetail,
    workTypes,
    workTypesUi,
    clientsUi,
    updateCase,
    updateDescription,
    getCaseById,
    getCompaniesName,
    isEnabledReportsOverview,
    isEnabledInvoice,
    isEnabledCaseStatusAction,
    deleteCase,
    closeReopenCase,
    validSubscription,
    getInvoiceByCaseId,
  } = useCaseDetailViewModel(
    id!,
    currentCompany.userRole,
    repo,
    wtRepo,
    extensionsRepo,
    contactRepo,
    companiesRepo,
    featureRepo,
    loggedUserRepository,
    invoiceRepo,
  );

  const [caseDetailObj, setCaseDetailObj] = useState(caseDetail);
  const [invoiceNumber, setInvoiceNumber] = useState<number>(-1);

  let currentValue;
  function handleChange() {
    const previousValue = currentValue;
    currentValue = appRepositoryImplementation.getState().casesDetail.caseD;

    if (previousValue !== currentValue) {
      setCaseDetailObj(currentValue);
    }
  }

  useEffect(() => {
    getCaseById();
    setInvoiceNumber(getInvoiceByCaseId(Number(id)));
  }, []);

  useEffect(() => {
    setCaseDetailObj(caseDetail);
  }, [allCase]);

  useEffect(() => {
    const unsubscribe = appRepositoryImplementation.subscribe(handleChange);
    dispatch(setCaseDetail(caseDetail));
    return () => {
      unsubscribe();
      dispatch(resetCaseDetail());
    };
  }, [cases, allCase, contacts, extensions, workTypes, currentCompany.companyId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentValue = appRepositoryImplementation.getState().casesDetail.stackOfChanges;
      const loading = appRepositoryImplementation.getState().casesDetail.loading;
      if (!loading && currentValue.length > 0) {
        updateCase().then((resp) => {
          if (!resp) {
            showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const wt = workTypesUi;

  return (
    <CaseDetailContext.Provider
      value={{
        caseDetailObj,
        repo,
        wt,
        clientsUi,
        updateDescription,
        getCompaniesName,
        isEnabledReportsOverview,
        isEnabledInvoice,
        isEnabledCaseStatusAction,
        deleteCase,
        closeReopenCase,
        validSubscription,
        invoiceNumber,
      }}
    >
      {props.children}
    </CaseDetailContext.Provider>
  );
}

import type { AnyAction } from 'redux';
import * as actionTypes from './countyActionTypes';
import { CountiesRepository } from '../../domain/county/CountyRepository';

type CountiesRepositoryState = Omit<CountiesRepository, 'getCounties'>;

const INITIAL_STATE: CountiesRepositoryState = {
  counties: [],
};

const countyReducer = (state: CountiesRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SAVE_COUNTY:
      return { ...state, counties: action.counties };
    default:
      return state;
  }
};
export { countyReducer };
export type { CountiesRepositoryState };

import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = (props: { siteKey: string; onChangeCaptcha: (captchaToken: string) => void }) => {
  const onChange = (value) => {
    props.onChangeCaptcha(value);
  };

  return (
    <ReCAPTCHA
      key='normal-recaptcha'
      sitekey={props.siteKey}
      onChange={onChange}
      size='normal'
      theme='light'
    />
  );
};

export default Recaptcha;

import React, { FC } from 'react';
import { ButtonCss } from './ButtonCss';
import { Text, TextType } from '../Text';
import styled from 'styled-components';

export type ButtonProps = {
  onClick?: () => void;
  secondary?: string;
  style?: React.CSSProperties;
  textType?: TextType;
  minWidth?: number;
};

export const Button: FC<ButtonProps> = ({
  onClick,
  secondary,
  style,
  children,
  textType,
  minWidth,
}) => {
  return secondary === 'secondary' ? (
    <ButtonCss className='button secondary' onClick={onClick} secondary={secondary} style={style}>
      <ButtonTextStyled
        type={textType ? textType : TextType.CAPTION_REGULAR_2}
        ellipsis={true}
        disableUserSelect={true}
      >
        {children}
      </ButtonTextStyled>
    </ButtonCss>
  ) : (
    <ButtonCss className='button' onClick={onClick} style={style} minWidth={minWidth}>
      <ButtonTextStyled
        type={textType ? textType : TextType.CAPTION_REGULAR_2}
        ellipsis={true}
        disableUserSelect={true}
      >
        {children}
      </ButtonTextStyled>
    </ButtonCss>
  );
};

const ButtonTextStyled = styled(Text)`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;

import { BillingRepository } from '../../domain/billing/BillingRepository';

type GetPackagesRepository = Pick<BillingRepository, 'getPackages'>;

const getPackagesUseCase = (repo: GetPackagesRepository) => {
  return repo.getPackages();
};

export { getPackagesUseCase };
export type { GetPackagesRepository };

import { CaseRepository } from '../../domain/CaseRepository';

type GetCasesRepository = Pick<CaseRepository, 'getCases' | 'getCaseById'>;

const getCasesUseCase = (repo: GetCasesRepository) => {
  repo.getCases();
};

const getCaseByIdUseCase = (repo: GetCasesRepository, caseId: number) => {
  return repo.getCaseById(caseId);
};

export { getCasesUseCase, getCaseByIdUseCase };

import { combineReducers, createStore } from 'redux';
import { caseReducer } from './cases/caseReducer';
import { teethReducer } from './worktype/teeth/teethReducer';
import { templateReducer } from './worktype/templates/templateReducer';
import { phaseReducer } from './phase/phaseReducer';
import { menuReducer } from '../presentation/components/menu/menuReducer';
import { workTypeReducer } from './worktype/workTypeReducer';
import { reportReducer } from './reports/reportReducer';
import { extensionReducer } from './worktype/extension/extensionReducer';
import caseDetailReducer from './cases/detail/caseDetailReducer';
import { contactsReducer, companiesReducer } from './user-management/usersReducer';
import { invoiceReducer } from './invoice/invoiceReducer';
import { alertReducer } from './alert/alertReducer';
import { lobbyReducer } from './lobby/lobbyReducer';
import loggedUserReducer from './loggedUser/loggedUserReducer';
import { billingReducer } from './billing/billingReducer';
import { countyReducer } from './county/countyReducer';
import { e_facturaReducer } from './e-factura/E-facturaReducer';

const rootReducer = combineReducers({
  cases: caseReducer,
  casesDetail: caseDetailReducer,
  extension: extensionReducer,
  teeth: teethReducer,
  template: templateReducer,
  phase: phaseReducer,
  workType: workTypeReducer,
  loggedUser: loggedUserReducer,
  menu: menuReducer,
  contacts: contactsReducer,
  companies: companiesReducer,
  report: reportReducer,
  invoice: invoiceReducer,
  alert: alertReducer,
  lobbyComapnies: lobbyReducer,
  billing: billingReducer,
  counties: countyReducer,
  eFactura: e_facturaReducer,
});

const appRepositoryImplementation = createStore(rootReducer);

export type AppRootStore = ReturnType<typeof appRepositoryImplementation.getState>;
export type AppDispatch = typeof appRepositoryImplementation.dispatch;
export { appRepositoryImplementation };

import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as htmlToImage from 'html-to-image';
import { Text, TextType } from '../../components/dsm/Text';
import { CaseDetailContext } from '../CaseDetailProvider';
import { ReactComponent as BackIcon } from '../../../icons/BackIcon.svg';
import { ReactComponent as TeethMenuIcon } from '../../../icons/TeethMenuIcon.svg';
import ToggleHeader from '../../components/generic/ToggleHeader';
import { ExtensionBox } from '../worksheet/ExtensionBox';
import { CaseWorkSheetToothPanoramaRow } from '../worksheet/teethpanorama/CaseWorkSheetToothPanoramaComponent';
import { Tooth } from '../worksheet/teethpanorama/Tooth';
import { OvalButtonDualLableRemovable } from '../../components/dsm/buttons/OvalButtonDualLableRemovable';
import {
  setCaseDetailInsertPhaseToPosition,
  setCaseDetailPhaseAssignee,
  setCaseDetailRemovePhaseFromPosition,
  setCaseDetailSelectedLabwork,
} from '../../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import { TemplateUiModel } from '../../ui-model/worktype/TemplateUiModel';
import { useTemplatesViewModel } from '../../settings/worktypes/worktypeitem/TemplatesViewModel';
import { useTemplateRepositoryImplementation } from '../../../data/worktype/templates/TemplateRepositoryImplementation';
import {
  PhaseUiModel,
  PhaseUiModelPhase,
  mapPhaseUiModelsFromWorkPhaseGroups,
  mapStatusToNumeric,
} from '../../ui-model/PhaseUiModel';
import { PhaseStripe } from '../labwork/phase/PhaseStripe';
import { useCaseOverViewViewModel } from '../CaseOverViewViewModel';
import { useContactsRepositoryImplementation } from '../../../data/user-management/UsersRepositoryImplementation';
import { featuresRepositoryImplementation } from '../../../data/features/FeaturesRepositoryImplementation';
import FloatingDropdown from '../../components/generic/FloatingDropdown';
import { AddNewComponentsProps, NewComponentType } from './MobileAddNewComponentProvider';
import { useCompany } from '../../hooks/useCompany';
import { usePhaseRepositoryImplementation } from '../../../data/phase/phasesRepositoryImplementation';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';

type ErrorStatusDropdown = OpenDropdown | ClosedDropdown;

type OpenDropdown = {
  kind: 'Open';
  options: Array<{ value: number; label: string }>;
  x: number;
  y: number;
  selected?: number;
  onItemClick: (key: number) => void;
  onClose: () => void;
};

type ClosedDropdown = {
  kind: 'Closed';
};

function CaseDetailMobileComponent() {
  const { caseDetailObj, getCompaniesName, validSubscription } = useContext(CaseDetailContext);

  const location = useLocation();
  const { state } = location;
  const { isTabletSmall } = useDeviceParameters();

  const [seeDetails, setSeeDetails] = useState<boolean>(false);
  const [hasPanoramaUrl, setHasPanoramaUrl] = useState<boolean>(false);
  const [panoramaUrl, setPanoramaUrl] = useState<string>('');
  const [actualLabwork, setActualLabwork] = useState<any>();
  const [isPhaseAssigneAction, setIsPhaseAssigneAction] = useState<boolean>(false);
  const [scrollPhaseOrder, setScrollPhaseOrder] = useState<number | null>(null);
  const [actualLabworkId, setActualLabworkId] = useState<number | null>(null);

  const dispatch = useDispatch();
  const currentCompany = useCompany();
  const navigate = useNavigate();

  const repoTemplates = useTemplateRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();

  const { getSortTemplatesByWorkTypeId } = useTemplatesViewModel(repoTemplates);
  const { workers, errorStatuses, isEnabledAssignePhaseAction } = useCaseOverViewViewModel(
    contactRepo,
    featureRepo,
    phaseRepo,
  );

  const panoramaRef = useRef<HTMLDivElement>(null);

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  useEffect(() => {
    setIsPhaseAssigneAction(
      currentCompany.userRole !== null && isEnabledAssignePhaseAction(currentCompany.userRole),
    );
    if (state !== null && state !== undefined) {
      if (state.phaseOrder !== null && state.phaseOrder !== undefined) {
        setScrollPhaseOrder(state.phaseOrder);
      }
      if (state.actualLabworkId !== null && state.actualLabworkId !== undefined) {
        setActualLabworkId(state.actualLabworkId);
      }
      navigate(location.pathname, { state: undefined });
    }
  }, [caseDetailObj, currentCompany.userRole]);

  useEffect(() => {
    setHasPanoramaUrl(false);
    generatePanoramaImg();
    setActualLabworkById();
    setTimeout(() => {
      setHasPanoramaUrl(true);
    }, 1000);
  }, [caseDetailObj]);

  const templates: TemplateUiModel[] = getSortTemplatesByWorkTypeId(
    Number(caseDetailObj.selectedLabWork?.workType?.id),
  );

  const generatePanoramaImg = () => {
    if (panoramaRef.current !== null) {
      htmlToImage.toPng(panoramaRef.current).then(function (dataUrl) {
        setPanoramaUrl(dataUrl);
      });
    }
  };

  function setSelectedLabWork(selectedLabwork: number) {
    dispatch(setCaseDetailSelectedLabwork(selectedLabwork));
  }

  function setActualLabworkById() {
    if (actualLabworkId === null) {
      setActualLabwork(
        caseDetailObj.labworks.find((lab) => lab.labWorkId === caseDetailObj.selectedLabWork),
      );
    } else {
      setSelectedLabWork(actualLabworkId);
      setActualLabworkId(null);
    }
  }

  function getReleatedTemplatePhases() {
    const phases = templates[0]?.phases;
    return phases !== undefined && phases.length > 0
      ? mapPhaseUiModelsFromWorkPhaseGroups(phases, true)
      : [];
  }

  const changeStatus = (
    labWorkId: number,
    phase: PhaseUiModelPhase,
    status: PhaseUiModelPhase['status'],
  ) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailPhaseAssignee({
          labworkId: labWorkId,
          phaseId: phase.id,
          phaseOrder: phase.order,
          assigneeId: phase.kind === 'WithAssignee' ? phase.assignee.id : -1,
          phaseStatus: mapStatusToNumeric(status) || 0,
          workers: workers || [],
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const [errorStatusDropdown, setErrorStatusDropdown] = React.useState<ErrorStatusDropdown>({
    kind: 'Closed',
  });
  const closeErrorStatusDialog = () => setErrorStatusDropdown({ kind: 'Closed' });

  const openErrorStatusDialog = (
    labWorkId: number,
    phaseUiModel: PhaseUiModelPhase,
    x: number,
    y: number,
  ) => {
    if (validSubscription) {
      setErrorStatusDropdown({
        kind: 'Open',
        options: errorStatuses,
        selected: mapStatusToNumeric(phaseUiModel.status) || 0,
        onItemClick: (errorStatus) => {
          dispatch(
            setCaseDetailPhaseAssignee({
              labworkId: labWorkId,
              phaseId: phaseUiModel.id,
              phaseOrder: phaseUiModel.order,
              assigneeId: phaseUiModel.kind === 'WithAssignee' ? phaseUiModel.assignee.id : -1,
              phaseStatus: errorStatus,
              workers: workers || [],
            }),
          );
          closeErrorStatusDialog();
        },
        onClose: closeErrorStatusDialog,
        x: x,
        y: y,
      });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const duplicatePhase = (labWorkId: number, phase: PhaseUiModel) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailInsertPhaseToPosition({
          labworkId: labWorkId,
          phase: phase,
          phaseOrder: phase.order,
          workers: workers,
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const deletePhase = (labWorkId: number, phase: PhaseUiModel) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailRemovePhaseFromPosition({
          labworkId: labWorkId,
          phaseOrder: phase.order,
          workers: workers || [],
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const onAssign = (phase) => {
    if (validSubscription) {
      const data: AddNewComponentsProps = {
        caseId: caseDetailObj.id,
        labWorkId: actualLabwork.labWorkId,
        phase: phase,
        actualLabwork: actualLabwork,
      };
      navigate(
        `/${currentCompany.companyId}/case/${caseDetailObj.id}/newComponent/${NewComponentType.ASSIGN}`,
        {
          state: {
            data: data,
          },
        },
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const onAddPhase = (phase: PhaseUiModel) => {
    if (validSubscription) {
      const data: AddNewComponentsProps = {
        caseId: caseDetailObj.id,
        labWorkId: actualLabwork.labWorkId,
        phase: phase,
        actualLabwork: actualLabwork,
      };
      navigate(
        `/${currentCompany.companyId}/case/${caseDetailObj.id}/newComponent/${NewComponentType.PHASE}`,
        {
          state: {
            data: data,
          },
        },
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const onAddDate = (phase) => {
    if (validSubscription) {
      const data: AddNewComponentsProps = {
        caseId: caseDetailObj.id,
        labWorkId: actualLabwork.labWorkId,
        phase: phase,
        actualLabwork: actualLabwork,
      };
      navigate(
        `/${currentCompany.companyId}/case/${caseDetailObj.id}/newComponent/${NewComponentType.DATE}`,
        {
          state: {
            data: data,
          },
        },
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const onAddTemplate = (phase) => {
    if (validSubscription) {
      const data: AddNewComponentsProps = {
        caseId: caseDetailObj.id,
        labWorkId: actualLabwork.labWorkId,
        phase: phase,
        actualLabwork: actualLabwork,
      };
      navigate(
        `/${currentCompany.companyId}/case/${caseDetailObj.id}/newComponent/${NewComponentType.TEMPLATE}`,
        {
          state: {
            data: data,
          },
        },
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  return (
    <>
      {!seeDetails && (
        <ToggleHeader
          hasContent={true}
          title={`${caseDetailObj.id}`}
          enabledClick={true}
          buttonText={undefined}
          onClick={() => {
            setSeeDetails(true);
          }}
          noButtonDesign={true}
          buttonIcon={<TeethMenuIcon width={32} height={32} />}
        />
      )}
      {!seeDetails && (
        <CaseDetailContent className='no-details'>
          {!hasPanoramaUrl && (
            <TeethPanorama ref={panoramaRef}>
              <CaseWorkSheetToothPanoram
                onMouseLeave={() => {}}
                onMouseDown={() => {}}
                onMouseUp={() => {}}
                style={{ zIndex: '' }}
              >
                <CaseWorkSheetToothPanoramaRow>
                  <CaseWorkSheetToothPanoramaTooths>
                    {caseDetailObj.tooths.upper.map((value, i) => {
                      return (
                        <Tooth
                          key={i}
                          teeth={value.teethp}
                          quarter={value.quarter}
                          width={value.width}
                          height={97}
                          type={value.type}
                          upper={true}
                          opacity={'1'}
                          selected={
                            caseDetailObj.selectedTeeth.teeth === value.teethp &&
                            caseDetailObj.selectedTeeth.quarter === value.quarter
                          }
                          verticalOffset={value.selectionVerticalOffset}
                          onClick={() => {}}
                          onMouseDown={() => {}}
                          onMouseUp={() => {}}
                          onMouseEnter={() => {}}
                          editable={
                            !isTabletSmall ? caseDetailObj.workSheetEditable : isTabletSmall
                          }
                        />
                      );
                    })}
                  </CaseWorkSheetToothPanoramaTooths>
                </CaseWorkSheetToothPanoramaRow>
                <CaseWorkSheetToothPanoramaRow>
                  <CaseWorkSheetToothPanoramaTooths>
                    {caseDetailObj.tooths.lower.map((value, i) => {
                      return (
                        <Tooth
                          key={i}
                          teeth={value.teethp}
                          quarter={value.quarter}
                          width={value.width}
                          height={104}
                          type={value.type}
                          upper={false}
                          opacity={'1'}
                          selected={
                            caseDetailObj.selectedTeeth.teeth === value.teethp &&
                            caseDetailObj.selectedTeeth.quarter === value.quarter
                          }
                          verticalOffset={value.selectionVerticalOffset}
                          onClick={() => {}}
                          onMouseDown={() => {}}
                          onMouseUp={() => {}}
                          onMouseEnter={() => {}}
                          editable={false}
                        />
                      );
                    })}
                  </CaseWorkSheetToothPanoramaTooths>
                </CaseWorkSheetToothPanoramaRow>
              </CaseWorkSheetToothPanoram>
            </TeethPanorama>
          )}
          <LabworksContainer>
            <Labworks>
              {caseDetailObj.labworks.map((value) => {
                return (
                  <OvalButtonDualLableRemovable
                    key={value.labWorkId}
                    onClick={() => {
                      setSelectedLabWork(value.labWorkId);
                      setActualLabworkById();
                      setScrollPhaseOrder(null);
                    }}
                    removeClick={() => {}}
                    text={value?.workType?.name}
                    secondText={
                      value?.workType?.category.find((c) => {
                        return c.id === value?.workType?.selectedCategory;
                      })?.name
                    }
                    selected={caseDetailObj.selectedLabWork === value.labWorkId}
                    removable={false}
                  />
                );
              })}
            </Labworks>
          </LabworksContainer>
          <PhaseList>
            {actualLabwork !== null && actualLabwork !== undefined && (
              <PhaseStripe
                setRef={() => {}}
                isEnabledAssigne={isPhaseAssigneAction}
                phases={
                  actualLabwork.editedPhases !== undefined && actualLabwork.editedPhases !== null
                    ? actualLabwork.editedPhases
                    : getReleatedTemplatePhases()
                }
                caseStatus={caseDetailObj.status}
                scrollPhaseOrder={scrollPhaseOrder}
                onAssign={onAssign}
                onErrorStatus={(phase, x, y) =>
                  openErrorStatusDialog(actualLabwork.labWorkId, phase, x, y)
                }
                onStatusChange={(phase, status) => {
                  changeStatus(actualLabwork.labWorkId, phase, status);
                }}
                onDuplicatePhase={(phase) => {
                  duplicatePhase(actualLabwork.labWorkId, phase);
                }}
                onDeletePhase={(phase) => {
                  deletePhase(actualLabwork.labWorkId, phase);
                }}
                onAddPhase={(phaseBefore, phase) => {
                  if (phase !== undefined) {
                    onAddPhase(phaseBefore);
                  }
                }}
                published={true}
                openAddNewPhaseMobile={(phase) => {
                  onAddPhase(phase);
                }}
                openAddNewTemplateMobile={(phase) => {
                  onAddTemplate(phase);
                }}
                openAddNewDateMobile={(phase) => {
                  onAddDate(phase);
                }}
              />
            )}
            {errorStatusDropdown.kind === 'Open' && (
              <FloatingDropdown
                values={errorStatusDropdown.options.map((option) => {
                  return { key: option.value, label: option.label };
                })}
                selectedValue={errorStatusDropdown.selected}
                onChange={errorStatusDropdown.onItemClick}
                onClose={errorStatusDropdown.onClose}
                x={errorStatusDropdown.x - 80}
                y={errorStatusDropdown.y + 20}
              />
            )}
          </PhaseList>
        </CaseDetailContent>
      )}
      {seeDetails && (
        <CaseDetailContent>
          <PersonalInfo>
            <CustomBackIcon
              width={24}
              height={24}
              onClick={() => {
                setSeeDetails(false);
              }}
            />
            <PersonalInfoContent>
              <DoctorPersonalInfo>
                <Text type={TextType.BODY_BOLD} ellipsis={true}>
                  {caseDetailObj.clientName}
                </Text>
                <Text type={TextType.BODY_REGULAR} ellipsis={true}>
                  {getCompaniesName(caseDetailObj.clientCompanyId)}
                </Text>
              </DoctorPersonalInfo>
              <ClientPersonalInfo>
                <Text type={TextType.BODY_REGULAR} ellipsis={true}>
                  {caseDetailObj.patientName}
                </Text>
                <Text type={TextType.BODY_REGULAR} ellipsis={true}>
                  {caseDetailObj.patientAge}
                </Text>
                <Text type={TextType.BODY_REGULAR} ellipsis={true}>
                  {caseDetailObj.patientGender}
                </Text>
              </ClientPersonalInfo>
              <DateInfo></DateInfo>
            </PersonalInfoContent>
          </PersonalInfo>
          <PanoramaImg src={panoramaUrl} />
          <Extentions>
            {caseDetailObj.labworks.length > 0 &&
              caseDetailObj.labworks.map((labWork, i) => {
                if (labWork.labWorkId === caseDetailObj.selectedLabWork) {
                  return <ExtensionBox key={i} />;
                }
              })}
          </Extentions>
          <Description>
            <Text type={TextType.BODY_BOLD} ellipsis={true}>
              Description
            </Text>
            <Text type={TextType.BODY_REGULAR} ellipsis={true}>
              {caseDetailObj.description !== null
                ? caseDetailObj.description
                : 'No added description'}
            </Text>
          </Description>
          <Attachments>
            <Text type={TextType.BODY_BOLD} ellipsis={true}>
              Attachment
            </Text>
            <Text type={TextType.BODY_REGULAR} ellipsis={true}>
              Not added attachments
            </Text>
          </Attachments>
        </CaseDetailContent>
      )}
    </>
  );
}

const CaseDetailContent = styled.div`
  height: 100%;
  position: relative;
  background: var(--dts_white);
  &.no-details {
    height: calc(100% - 89.5px);
  }
`;

const PersonalInfo = styled.div`
  background: var(--dts_white);
  padding: 6px 18px 6px 18px;
  border-bottom: 1px solid var(--dts_withe_background);
  display: flex;
  gap: 16px;
  min-height: 111px;
`;

const PersonalInfoContent = styled.div`
  flex-grow: 1;
`;

const DoctorPersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClientPersonalInfo = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: 16px;
`;

const DateInfo = styled.div``;

const CustomBackIcon = styled(BackIcon)`
  fill: var(--dts_black);
  cursor: pointer;
  flex-basis: 1;
  :hover,
  :active {
    fill: var(--dts_hover_blue);
  }
`;

const PanoramaImg = styled.img`
  max-width: 100%;
  margin-top: 25px;
`;

const TeethPanorama = styled.div`
  margin-top: 0px 25px;
  padding: 16px 0px;
  background: var(--dts_white);
  position: absolute;
  top: 0;
  z-index: -999999999;
`;

const Extentions = styled.div`
  padding: 16px;
  border-bottom: 1px solid var(--dts_withe_background);
  .extras-box {
    justify-content: flex-start;
  }
  background: var(--dts_white);
`;

const Description = styled.div`
  padding: 18px;
  border-bottom: 1px solid var(--dts_withe_background);
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: var(--dts_white);
`;

const Attachments = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: var(--dts_white);
`;

const CaseWorkSheetToothPanoram = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 100%;
  flex: none;
  flex-grow: 0;
`;

const CaseWorkSheetToothPanoramaTooths = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  width: 100%;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
`;

const LabworksContainer = styled.div`
  border-bottom: 1px solid var(--dts_withe_background);
  border-top: 1px solid var(--dts_withe_background);
  display: flex;
  justify-content: center;
`;

const Labworks = styled.div`
  display: inline-flex;
  gap: 8px;
  flex-wrap: no-wrap;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
  padding: 24px 50px;
`;

const PhaseList = styled.div`
  padding: 24px;
  gap: 4px;
  background: var(--dts_white);
`;

export default CaseDetailMobileComponent;

export enum ColorType {
  CRIMSON_RED,
  NAVY_BLUE,
  EMERALD_GREEN,
  SUNFLOWER_YELLOW,
  ROYAL_PURPLE,
  CORAL_PINK,
  TEAL,
  BURNT_ORANGE,
  SLATE_GRAY,
  TURQUOISE,
  MAGNETA,
  LIME_GREEN,
  SKY_BLUE,
  CHOCOLATE_BROWN,
  LAVENDER,
  PEACH,
}

const UserColorList: Array<{ id: ColorType; value: string }> = [
  {
    id: ColorType.CRIMSON_RED,
    value: '#A10D2A',
  },
  {
    id: ColorType.NAVY_BLUE,
    value: '#00004D',
  },
  {
    id: ColorType.EMERALD_GREEN,
    value: '#2F6B4D',
  },
  {
    id: ColorType.SUNFLOWER_YELLOW,
    value: '#B18904',
  },
  {
    id: ColorType.ROYAL_PURPLE,
    value: '#4E3175',
  },
  {
    id: ColorType.TEAL,
    value: '#005B5B',
  },
  {
    id: ColorType.BURNT_ORANGE,
    value: '#863D00',
  },

  {
    id: ColorType.SLATE_GRAY,
    value: '#4B5966',
  },
  {
    id: ColorType.TURQUOISE,
    value: '#307470',
  },
  {
    id: ColorType.SKY_BLUE,
    value: '#4A91D3',
  },
  {
    id: ColorType.LAVENDER,
    value: '#8470FF',
  },
  {
    id: ColorType.PEACH,
    value: '#FFAB6B',
  },
];

export const getUserColors = (): Array<{ id: ColorType; value: string }> => {
  return UserColorList;
};

export const getUserColorById = (id: ColorType): { id: ColorType; value: string } => {
  const color = UserColorList.find((color) => color.id === id);
  return color !== undefined ? color : UserColorList[0];
};

import { CaseRepository } from '../../domain/CaseRepository';

type createCaseUseCaseRepository = Pick<CaseRepository, 'newCase'>;

const createCaseUseCase = (repo: createCaseUseCaseRepository, onSuccess: (id: number) => void) => {
  return repo.newCase(onSuccess);
};

export { createCaseUseCase };
export type { createCaseUseCaseRepository };

import React from 'react';
import { useDispatch } from 'react-redux';
import { CaseRepository } from '../../domain/CaseRepository';
import { CaseDetail, DetailsLabWork } from '../ui-model/CaseDetail';
import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';
import { WorkTypeUiModel } from '../ui-model/worktype/WorkTypeUiModel';
import { WorkType } from '../../domain/worktype/WorkType';
import { uploadCaseUpdateUseCase } from '../../useCases/case/uploadCaseUpdateUseCase';
import { appRepositoryImplementation } from '../../data/appRepositoryImplementation';
import { Case } from '../../domain/Case';
import { DRAFT } from './caseStates';
import { ExtensionRepository } from '../../domain/worktype/extension/ExtensionRepository';
import { Extra, mapExtrasFromExtensionList } from '../../domain/Extra';
import { ClientData } from '../../domain/user-management/ContactData';
import { ClientUiModel } from '../ui-model/user-management/ContactUiModel';
import {
  SELECTABLE_CATEGORY,
  SELECTABLE_EXTENSION,
  SIMPLE_EXTENSION,
} from '../../domain/worktype/extension/Extension';
import { WorkPhaseGroup } from '../../domain/WorkPhaseGroup';
import {
  ContactsRepository,
  CompaniesRepository,
} from '../../domain/user-management/UsersRepository';
import { mapPhaseUiModelsFromWorkPhaseGroups, PhaseUiModel } from '../ui-model/PhaseUiModel';
import { getSortWorkTypesUseCase } from '../../useCases/worktype/getWorkTypesUseCase';
import {
  getClientsUseCase,
  getWorkersUseCase,
} from '../../useCases/user-management/getContactsUseCase';
import { getCompanyNameUseCase } from '../../useCases/user-management/getCompaniesUseCase';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId } from '../../domain/features/Features';
import { getCaseByIdUseCase } from '../../useCases/case/getCasesUseCases';
import { deleteCasesUseCase, closeReopenUseCase } from '../../useCases/case/actionCaseUseCase';
import { setCaseDetailStatus } from '../../data/cases/detail/caseDetailReducer';
import { UPDATE_DESCRIPTION } from '../../data/cases/detail/caseDetailActionTypes';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';
import { getInvoiceByCaseIdUseCase } from '../../useCases/invoice/getInvoiceUseCase';
import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';

function useCaseDetailViewModel(
  caseId: string,
  userRole: number | null,
  repo: CaseRepository,
  workTypeRepo: WorkTypeRepository,
  extensionRepository: ExtensionRepository,
  contactRepository: ContactsRepository,
  companiesRepo: CompaniesRepository,
  featureRepo: FeaturesRepository,
  loggedUserRepo: LoggedUserRepository,
  invoiceRepo: InvoiceRepository,
) {
  const dispatch = useDispatch();

  const updateCase = React.useCallback(
    function () {
      const currentValue = appRepositoryImplementation.getState().casesDetail.caseD;
      const soc = appRepositoryImplementation.getState().casesDetail.stackOfChanges;
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, currentValue.id, soc);
    },
    [repo.updateCase, appRepositoryImplementation.getState().casesDetail.caseD],
  );

  const updateDescription = React.useCallback(
    function (caseId, description) {
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, caseId, [
        [Date.now(), UPDATE_DESCRIPTION, description],
      ]);
    },
    [repo.updateCase],
  );

  const getCaseById = React.useCallback(
    function () {
      if (caseId !== undefined && caseId !== null) {
        getCaseByIdUseCase(
          { getCases: repo.getCases, getCaseById: repo.getCaseById },
          Number(caseId),
        );
      }
    },
    [repo.getCaseById],
  );

  const getWorkers = React.useCallback(
    function () {
      getWorkersUseCase({
        getContacts: contactRepository.getContacts,
        contacts: contactRepository.contacts,
      });
    },
    [contactRepository.getContacts],
  );

  const isEnabledReportsOverview = () => {
    if (userRole !== null)
      return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.REPORTS_OVERVIEW_VISIBILITY);
  };

  const isEnabledInvoice = () => {
    if (userRole !== null)
      return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.INVOICE_VISIBILITY);
  };

  const isEnabledCaseStatusAction = (userRole: number) => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.CASE_STATUS_ACTIONS);
  };

  const getSortWorkTypes = React.useCallback(
    function () {
      return getSortWorkTypesUseCase({
        getWorkTypes: workTypeRepo.getWorkTypes,
        workTypes: workTypeRepo.workTypes,
      });
    },
    [workTypeRepo.workTypes],
  );

  const getCompaniesName = (id: number): string => {
    return getCompanyNameUseCase(id, {
      getCompanies: companiesRepo.getCompanies,
      companies: companiesRepo.companies,
      getMyCompanyData: companiesRepo.getMyCompanyData,
    });
  };

  const deleteCase = React.useCallback(
    function (deletedCases: Array<number>) {
      return deleteCasesUseCase(
        { deleteCases: repo.deleteCases, closeReopenCase: repo.closeReopenCase },
        deletedCases,
      );
    },
    [repo.deleteCases],
  );

  const closeReopenCase = React.useCallback(
    function (action: number) {
      dispatch(setCaseDetailStatus(action));
      const currentValue = appRepositoryImplementation.getState().casesDetail.caseD;
      const soc = appRepositoryImplementation.getState().casesDetail.stackOfChanges;
      return closeReopenUseCase(
        { deleteCases: repo.deleteCases, closeReopenCase: repo.closeReopenCase },
        action,
        currentValue.id,
        soc,
      );
    },
    [repo.closeReopenCase, appRepositoryImplementation.getState().casesDetail.caseD],
  );

  const getInvoiceByCaseId = (id: number) => {
    return getInvoiceByCaseIdUseCase(invoiceRepo, id);
  };

  return {
    cases: repo.cases,
    allCase: repo.allCase,
    contacts: contactRepository.contacts,
    clients: getClientsUseCase(contactRepository),
    extensions: extensionRepository.extensions,
    workTypes: workTypeRepo.workTypes,
    caseDetail: geCaseDetail(caseId),
    workTypesUi: getWorkTypesUi(),
    clientsUi: getClientsUiModel(getClientsUseCase(contactRepository)),
    validSubscription: loggedUserRepo.validSubscription,
    updateCase,
    updateDescription,
    getCaseById,
    getWorkers,
    getCompaniesName,
    isEnabledReportsOverview,
    isEnabledInvoice,
    isEnabledCaseStatusAction,
    deleteCase,
    closeReopenCase,
    getInvoiceByCaseId,
  };

  function getWorkTypesUi(): WorkTypeUiModel[] {
    const workTypeUiList: WorkTypeUiModel[] = [];
    getSortWorkTypes()?.forEach((workType) => {
      if (workType.id !== undefined && workType.name !== undefined) {
        workTypeUiList.push(getWortTypeUiFromWorkType(workType));
      }
    });

    return workTypeUiList;
  }

  function getClientsUiModel(contacts?: ClientData[]): ClientUiModel[] {
    const contactUiModels: ClientUiModel[] = [];
    contacts?.forEach((contact) => {
      if (contact.id !== undefined && contact.name !== undefined) {
        const companiesName: Array<string> = [];
        companiesRepo.companies
          ?.filter((c) => contact.companies.some((item) => c.id === item))
          .map((company) => {
            companiesName.push(company.name);
          });
        contactUiModels.push({
          id: contact.id,
          avatar: '',
          name: contact.name,
          email: contact.email,
          phone: contact.phoneNumber,
          companies: contact.companies,
          isContact: true,
          companiesName: companiesName,
        });
      }
    });

    return contactUiModels;
  }

  function getWortTypeUiFromWorkType(
    workType: WorkType,
    selectedCategoryId?: number,
  ): WorkTypeUiModel {
    const teethList: number[] = [];
    workType.teethTypeList
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .forEach((teeth) => {
        if (teeth.id !== null && teeth.id !== undefined && teeth.active) {
          teethList.push(teeth.id);
        }
      });
    if (workType.id !== undefined && workType.name !== undefined) {
      const category = workType.extensionList?.find((e) => {
        return e.type === SELECTABLE_CATEGORY;
      });

      category?.extensions.forEach((category) => {
        category.active = category.active ? true : false;
      });

      return new WorkTypeUiModel(
        workType.id,
        workType.name + '',
        workType.active,
        category?.extensions ? category.extensions : [],
        category?.id ? category.id : -1,
        selectedCategoryId || -1,
        teethList,
        workType.teethTypeList,
        workType.templates,
        workType.extensionList,
      );
    }
    return new WorkTypeUiModel(-1, '', 0, [], -1, selectedCategoryId || -1, [], [], [], []);
  }

  function preparePhasesForUi(
    phases: WorkPhaseGroup[] | null,
    assignable: boolean,
  ): Array<PhaseUiModel> | null {
    if (phases == null) return null;
    return mapPhaseUiModelsFromWorkPhaseGroups(
      phases,
      assignable,
      contactRepository?.contacts?.workers,
    );
  }

  function getNewCaseDetail() {
    const newCase = new CaseDetail();

    const labworks = [];

    newCase.tooths = {
      upper: [
        {
          teethp: 8,
          quarter: 1,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.96px',
          height: '9.12px',
          left: '2.34px',
          top: '50.46px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 3,
        },
        {
          teethp: 7,
          quarter: 1,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.52px',
          height: '8.83px',
          left: '45.44px',
          top: '54.84px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 2,
        },
        {
          teethp: 6,
          quarter: 1,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.46px',
          height: '8.75px',
          left: '87.52px',
          top: '58.86px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 5,
          quarter: 1,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.76px',
          height: '8.71px',
          left: '119.77px',
          top: '62.27px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 4,
          quarter: 1,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.74px',
          height: '9.01px',
          left: '151.64px',
          top: '65.51px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 3,
          quarter: 1,
          width: 32,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.96px',
          height: '8.64px',
          left: '186.32px',
          top: '68.48px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 2,
          quarter: 1,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.92px',
          height: '8.48px',
          left: '218.62px',
          top: '70.4px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -2,
        },
        {
          teethp: 1,
          quarter: 1,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.74px',
          height: '8.22px',
          left: '260.63px',
          top: '71.41px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 1,
          quarter: 2,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '',
          height: '',
          left: '',
          top: '',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 2,
          quarter: 2,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.89px',
          height: '8.47px',
          left: '302.48px',
          top: '70.47px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -2,
        },
        {
          teethp: 3,
          quarter: 2,
          width: 32,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.91px',
          height: '8.63px',
          left: '334.75px',
          top: '68.6px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 4,
          quarter: 2,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.91px',
          height: '9.01px',
          left: '366.54px',
          top: '65.68px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 5,
          quarter: 2,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.81px',
          height: '8.71px',
          left: '400.51px',
          top: '62.42px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 6,
          quarter: 2,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.49px',
          height: '8.75px',
          left: '434.04px',
          top: '59px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 7,
          quarter: 2,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.63px',
          height: '8.83px',
          left: '474.01px',
          top: '54.96px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 2,
        },
        {
          teethp: 8,
          quarter: 2,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '12.04px',
          height: '9.12px',
          left: '513.72px',
          top: '50.57px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 3,
        },
      ],

      lower: [
        {
          teethp: 8,
          quarter: 4,
          width: 48,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.01px',
          height: '8.79px',
          left: '2.81px',
          top: '0.75px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 7,
          quarter: 4,
          width: 46,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.74px',
          height: '8.57px',
          left: '53.09px',
          top: '6.19px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 13,
        },
        {
          teethp: 6,
          quarter: 4,
          width: 47,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.66px',
          height: '8.56px',
          left: '100.58px',
          top: '11.49px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 5,
        },
        {
          teethp: 5,
          quarter: 4,
          width: 36,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.04px',
          height: '8.18px',
          left: '135.71px',
          top: '14.76px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -3,
        },
        {
          teethp: 4,
          quarter: 4,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.18px',
          height: '8.22px',
          left: '171.94px',
          top: '15.95px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -7,
        },
        {
          teethp: 3,
          quarter: 4,
          width: 29,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '5.75px',
          height: '8.16px',
          left: '200.68px',
          top: '16.97px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -5,
        },
        {
          teethp: 2,
          quarter: 4,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.33px',
          height: '8.21px',
          left: '228.48px',
          top: '17.94px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 1,
        },
        {
          teethp: 1,
          quarter: 4,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.24px',
          height: '8.14px',
          left: '255.38px',
          top: '18.86px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 4,
        },
        {
          teethp: 1,
          quarter: 3,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '',
          height: '',
          left: '',
          top: '',
          ext: Array<Extra>(),
          selectionVerticalOffset: 4,
        },
        {
          teethp: 2,
          quarter: 3,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.34px',
          height: '8.21px',
          left: '284.18px',
          top: '17.97px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 1,
        },
        {
          teethp: 3,
          quarter: 3,
          width: 29,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '5.76px',
          height: '8.16px',
          left: '313.56px',
          top: '17.01px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -5,
        },
        {
          teethp: 4,
          quarter: 3,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.18px',
          height: '8.22px',
          left: '340.89px',
          top: '15.98px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -7,
        },
        {
          teethp: 5,
          quarter: 3,
          width: 36,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.05px',
          height: '8.18px',
          left: '376.26px',
          top: '14.79px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -3,
        },
        {
          teethp: 6,
          quarter: 3,
          width: 47,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.72px',
          height: '8.57px',
          left: '411.73px',
          top: '11.6px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 5,
        },
        {
          teethp: 7,
          quarter: 3,
          width: 46,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.78px',
          height: '8.57px',
          left: '459.18px',
          top: '6.3px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 13,
        },
        {
          teethp: 8,
          quarter: 3,
          width: 48,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.08px',
          height: '8.8px',
          left: '506.17px',
          top: '0.85px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
      ],
    };

    newCase.labworks = labworks;
    newCase.selectedLabWork = -1;
    newCase.selectedTeeth = { teeth: -1, quarter: -1 };
    newCase.workSheetEditable = true;
    return newCase;
  }

  function getExistingCaseDetail(cases: Case) {
    const newCase = new CaseDetail();

    const labworks: DetailsLabWork[] = [];

    const tooths = {
      upper: [
        {
          teethp: 8,
          quarter: 1,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.96px',
          height: '9.12px',
          left: '2.34px',
          top: '50.46px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 3,
        },
        {
          teethp: 7,
          quarter: 1,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.52px',
          height: '8.83px',
          left: '45.44px',
          top: '54.84px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 2,
        },
        {
          teethp: 6,
          quarter: 1,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.46px',
          height: '8.75px',
          left: '87.52px',
          top: '58.86px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 5,
          quarter: 1,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.76px',
          height: '8.71px',
          left: '119.77px',
          top: '62.27px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 4,
          quarter: 1,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.74px',
          height: '9.01px',
          left: '151.64px',
          top: '65.51px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 3,
          quarter: 1,
          width: 32,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.96px',
          height: '8.64px',
          left: '186.32px',
          top: '68.48px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 2,
          quarter: 1,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.92px',
          height: '8.48px',
          left: '218.62px',
          top: '70.4px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -2,
        },
        {
          teethp: 1,
          quarter: 1,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.74px',
          height: '8.22px',
          left: '260.63px',
          top: '71.41px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 1,
          quarter: 2,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '',
          height: '',
          left: '',
          top: '',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 2,
          quarter: 2,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.89px',
          height: '8.47px',
          left: '302.48px',
          top: '70.47px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -2,
        },
        {
          teethp: 3,
          quarter: 2,
          width: 32,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.91px',
          height: '8.63px',
          left: '334.75px',
          top: '68.6px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 4,
          quarter: 2,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.91px',
          height: '9.01px',
          left: '366.54px',
          top: '65.68px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 5,
          quarter: 2,
          width: 33,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.81px',
          height: '8.71px',
          left: '400.51px',
          top: '62.42px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -1,
        },
        {
          teethp: 6,
          quarter: 2,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '6.49px',
          height: '8.75px',
          left: '434.04px',
          top: '59px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 7,
          quarter: 2,
          width: 42,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.63px',
          height: '8.83px',
          left: '474.01px',
          top: '54.96px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 2,
        },
        {
          teethp: 8,
          quarter: 2,
          width: 40,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '12.04px',
          height: '9.12px',
          left: '513.72px',
          top: '50.57px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 3,
        },
      ],

      lower: [
        {
          teethp: 8,
          quarter: 4,
          width: 48,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.01px',
          height: '8.79px',
          left: '2.81px',
          top: '0.75px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
        {
          teethp: 7,
          quarter: 4,
          width: 46,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.74px',
          height: '8.57px',
          left: '53.09px',
          top: '6.19px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 13,
        },
        {
          teethp: 6,
          quarter: 4,
          width: 47,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.66px',
          height: '8.56px',
          left: '100.58px',
          top: '11.49px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 5,
        },
        {
          teethp: 5,
          quarter: 4,
          width: 36,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.04px',
          height: '8.18px',
          left: '135.71px',
          top: '14.76px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -3,
        },
        {
          teethp: 4,
          quarter: 4,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.18px',
          height: '8.22px',
          left: '171.94px',
          top: '15.95px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -7,
        },
        {
          teethp: 3,
          quarter: 4,
          width: 29,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '5.75px',
          height: '8.16px',
          left: '200.68px',
          top: '16.97px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -5,
        },
        {
          teethp: 2,
          quarter: 4,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.33px',
          height: '8.21px',
          left: '228.48px',
          top: '17.94px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 1,
        },
        {
          teethp: 1,
          quarter: 4,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '9.24px',
          height: '8.14px',
          left: '255.38px',
          top: '18.86px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 4,
        },
        {
          teethp: 1,
          quarter: 3,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '',
          height: '',
          left: '',
          top: '',
          ext: Array<Extra>(),
          selectionVerticalOffset: 4,
        },
        {
          teethp: 2,
          quarter: 3,
          width: 28,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.34px',
          height: '8.21px',
          left: '284.18px',
          top: '17.97px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 1,
        },
        {
          teethp: 3,
          quarter: 3,
          width: 29,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '5.76px',
          height: '8.16px',
          left: '313.56px',
          top: '17.01px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -5,
        },
        {
          teethp: 4,
          quarter: 3,
          width: 34,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.18px',
          height: '8.22px',
          left: '340.89px',
          top: '15.98px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -7,
        },
        {
          teethp: 5,
          quarter: 3,
          width: 36,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '8.05px',
          height: '8.18px',
          left: '376.26px',
          top: '14.79px',
          ext: Array<Extra>(),
          selectionVerticalOffset: -3,
        },
        {
          teethp: 6,
          quarter: 3,
          width: 47,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.72px',
          height: '8.57px',
          left: '411.73px',
          top: '11.6px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 5,
        },
        {
          teethp: 7,
          quarter: 3,
          width: 46,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '7.78px',
          height: '8.57px',
          left: '459.18px',
          top: '6.3px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 13,
        },
        {
          teethp: 8,
          quarter: 3,
          width: 48,
          type: 0,
          labwork: -1,
          leftConnectionType: 0,
          cwidth: '11.08px',
          height: '8.8px',
          left: '506.17px',
          top: '0.85px',
          ext: Array<Extra>(),
          selectionVerticalOffset: 0,
        },
      ],
    };

    let firstLabworkId = -1;
    cases.labWorks?.forEach((lw) => {
      if (firstLabworkId < 0) {
        firstLabworkId = lw.labWorkId;
      }

      const vt = workTypeRepo.workTypes?.find((wt) => wt.id === lw.workType);
      if (vt) {
        labworks.push({
          labWorkId: lw.labWorkId,
          workType: getWortTypeUiFromWorkType(vt, lw.extensionList[0]?.id),
          phases: lw.phases === null ? null : lw.phases || [],
          editedPhases: preparePhasesForUi(lw.phases === null ? null : lw.phases || [], true),
          labworkName: lw.name,
          extensions: [],
          extras: mapExtrasFromExtensionList(vt?.extensionList || [], lw.extensionList, [
            SELECTABLE_EXTENSION,
            SIMPLE_EXTENSION,
          ]),
        });
      }

      lw.teethList?.forEach((_teeth) => {
        const quarter = Math.floor(_teeth.id / 10);
        const teeth = _teeth.id % 10;
        switch (quarter) {
          case 1:
          case 2: {
            const caseDetailUpperTeeth = tooths.upper.find((t) => {
              return t.teethp === teeth && t.quarter === quarter;
            });
            if (caseDetailUpperTeeth !== undefined) {
              caseDetailUpperTeeth.labwork = lw.labWorkId;
              caseDetailUpperTeeth.type = _teeth.role;
              caseDetailUpperTeeth.leftConnectionType = _teeth.connection ? 1 : 0;
              const extraList =
                vt?.teethTypeList.find((v) => {
                  return v.id === _teeth.role;
                })?.extensionList || [];
              caseDetailUpperTeeth.ext = mapExtrasFromExtensionList(
                extraList,
                _teeth.extensionList,
                [SELECTABLE_CATEGORY, SELECTABLE_EXTENSION, SIMPLE_EXTENSION],
              );
            }
            break;
          }
          case 3:
          case 4: {
            const caseDetailLowerTeeth = tooths.lower.find((t) => {
              return t.teethp === teeth && t.quarter === quarter;
            });
            if (caseDetailLowerTeeth !== undefined) {
              caseDetailLowerTeeth.labwork = lw.labWorkId;
              caseDetailLowerTeeth.type = _teeth.role;
              caseDetailLowerTeeth.leftConnectionType = _teeth.connection ? 1 : 0;
              const extraList =
                vt?.teethTypeList.find((v) => {
                  return v.id === _teeth.role;
                })?.extensionList || [];
              caseDetailLowerTeeth.ext = mapExtrasFromExtensionList(
                extraList,
                _teeth.extensionList,
                [SELECTABLE_CATEGORY, SELECTABLE_EXTENSION, SIMPLE_EXTENSION],
              );
            }
            break;
          }
        }
      });
    });

    newCase.tooths = tooths;
    newCase.labworks = labworks;
    newCase.status = cases.status;
    newCase.workSheetEditable =
      newCase.status === DRAFT &&
      userRole !== null &&
      getFeatureByIdUseCase(
        featureRepo,
        userRole,
        FeatureId.DETAIL_WORK_PLATFORM_MODIFICATION_ACTION,
      );
    newCase.selectedLabWork = firstLabworkId;
    newCase.selectedTeeth = { teeth: -1, quarter: -1 };

    newCase.id = cases.id!;
    newCase.patientName = cases.patientName!;
    newCase.patientAge = cases.patientAge!;
    newCase.patientGender = cases.patientGender!;
    newCase.clientName = cases.clientName!;
    newCase.description = cases.description!;
    newCase.clientId = cases.clientId!;
    newCase.clientCompanyId = cases.clientCompanyId!;

    return newCase;
  }

  function geCaseDetail(caseId?: string): CaseDetail {
    if (caseId == undefined || caseId === 'new') {
      return getNewCaseDetail();
    } else {
      const cases = repo.allCase?.find((v) => {
        return v.id + '' === caseId;
      });
      if (cases != null) {
        return getExistingCaseDetail(cases);
      }

      return getNewCaseDetail();
    }
  }
}

export { useCaseDetailViewModel };

import { mapStatusToNumeric, PhaseUiModel } from '../presentation/ui-model/PhaseUiModel';

export class WorkPhaseGroup {
  type: 0 | 1;
  id?: number;
  order: number;
  name?: string;
  status?: number;
  assigneeId?: number;
  date?: number;
  accounted: number;
  finishDate: number;

  constructor(
    type: 0 | 1,
    order: number,
    id?: number,
    name?: string,
    status?: number,
    assigneeId?: number,
    date?: number,
  ) {
    this.type = type;
    this.order = order;
    this.id = id;
    this.name = name;
    this.status = status;
    this.assigneeId = assigneeId;
    this.date = date;
  }

  static newInstanceFromPhaseUimodel(phase: PhaseUiModel) {
    if (phase.kind === 'Date') {
      // Date
      return new WorkPhaseGroup(
        1,
        phase.order,
        undefined,
        undefined,
        undefined,
        undefined,
        phase.date,
      );
    } else {
      // Phase
      return new WorkPhaseGroup(
        0,
        phase.order,
        phase.id,
        phase.name,
        mapStatusToNumeric(phase.status),
        phase.kind === 'WithAssignee' ? phase.assignee.id : undefined,
        undefined,
      );
    }
  }
}

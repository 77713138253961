import { Credentials } from '../../domain/signUpFlow/LoginUserData';
import {
  authenticateUser,
  addUser,
  resetPassword,
  setPassword,
  updateUser,
} from './signUpFlowService';
import * as actionTypes from './signUpFlowActionTypes';
import { reset, userLogin, removeSession, setUserMoreData } from '../loggedUser/loggedUserReducer';
import { checkErrorStatus } from '../ErrorHandling';
import { PasswordData } from '../../domain/signUpFlow/SetPasswordData';

const authenticateAction = (c: Credentials) => (dispatch: any) => {
  dispatch({ type: actionTypes.AUTHENTICATE_USER });
  return authenticateUser(c).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      window.localStorage.clear();
      window.localStorage.setItem('token', resp.toke + '');
      window.localStorage.setItem('userId', resp.userId + '');
      window.localStorage.setItem('lastName', resp.lastName + '');
      window.localStorage.setItem('firstName', resp.firstName + '');
      window.localStorage.setItem('email', resp.email + '');
      window.localStorage.setItem('lang', resp.language + '');
      window.localStorage.setItem('profileColor', resp.profileColor + '');
      window.localStorage.setItem('tellNumber', resp.phoneNumber + '');
      window.localStorage.setItem('isFtiFinished', resp.isFtiFinished + '');
      dispatch(
        userLogin({
          session: resp.toke,
          userId: resp.userId,
          lastName: resp.lastName,
          firstName: resp.firstName,
          email: resp.email,
          companyId: null,
          authenticated: true,
          lang: resp.language,
          profileColor: resp.profileColor,
          tellNumber: resp.phoneNumber,
          isFtiFinished: resp.isFtiFinished,
          mode: 0,
          accessToken: resp.toke,
          validSubscription: false,
          packageType: null,
          billingType: null,
          subscriptionEndTime: null,
        }),
      );
      dispatch({ type: actionTypes.AUTHENTICATE_USER_SUCCESS, session: resp.toke });
      return true;
    } else {
      dispatch({ type: actionTypes.AUTHENTICATE_USER_FAILD, error: resp.code });
      dispatch(reset());
      dispatch(removeSession());
      return false;
    }
  });
};

const addUserAction = (username: string, isEnabledMailList: boolean) => (dispatch: any) => {
  dispatch({ type: actionTypes.ADD_USER });
  return addUser({ email: username, isEnabledMailList: isEnabledMailList }).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.ADD_USER_SUCCESS });
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.ADD_USER_FAILD });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

const setPasswordAction = (password: PasswordData, isRecover: boolean) => (dispatch: any) => {
  dispatch({ type: actionTypes.SET_PASSWORD });
  return setPassword(password).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.SET_PASSWORD_SUCCESS });
      if (isRecover) {
        return { hasError: false, errorCode: resp.code, response: true };
      } else {
        window.localStorage.clear();
        window.localStorage.setItem('token', resp.toke + '');
        window.localStorage.setItem('userId', resp.userId + '');
        window.localStorage.setItem('lastName', resp.lastName + '');
        window.localStorage.setItem('firstName', resp.firstName + '');
        window.localStorage.setItem('email', resp.email + '');
        window.localStorage.setItem('lang', resp.language + '');
        window.localStorage.setItem('profileColor', resp.profileColor + '');
        window.localStorage.setItem('tellNumber', null + '');
        window.localStorage.setItem('isFtiFinished', resp.isFtiFinished + '');
        dispatch(
          userLogin({
            session: resp.toke,
            userId: resp.userId,
            lastName: resp.lastName,
            firstName: resp.firstName,
            email: resp.email,
            companyId: null,
            authenticated: true,
            lang: resp.language,
            profileColor: resp.profileColor,
            tellNumber: resp.phoneNumber,
            isFtiFinished: resp.isFtiFinished,
            mode: 0,
            accessToken: resp.toke,
            validSubscription: false,
            packageType: null,
            billingType: null,
            subscriptionEndTime: null,
          }),
        );
        dispatch({ type: actionTypes.AUTHENTICATE_USER_SUCCESS, session: resp.toke });
      }
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.SET_PASSWORD_FAILD, error: 11 });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

const resetPasswordAction = (email: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.RESET_PASSWORD });
  return resetPassword({ email }).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.RESET_PASSWORD_SUCCESS });
      return { hasError: false, errorCode: resp.code, response: true };
    }
    dispatch({ type: actionTypes.RESET_PASSWORD_FAILD, error: 11 });
    return { hasError: true, errorCode: resp.code, response: false };
  });
};

const updateUserAction = (data) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_USER });
  return updateUser(data).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.ADD_USER_SUCCESS });
      window.localStorage.setItem('lastName', resp.data.lastname + '');
      window.localStorage.setItem('firstName', resp.data.firstname + '');
      window.localStorage.setItem('email', resp.data.email + '');
      window.localStorage.setItem('lang', resp.data.language + '');
      window.localStorage.setItem('profileColor', resp.data.profileColor + '');
      window.localStorage.setItem('tellNumber', resp.data.tellNumber + '');
      window.localStorage.setItem('isFtiFinished', true + '');
      dispatch(
        setUserMoreData({
          session: null,
          userId: null,
          lastName: resp.data.lastname,
          firstName: resp.data.firstname,
          email: resp.data.email,
          companyId: null,
          authenticated: false,
          lang: resp.data.language,
          profileColor: resp.data.profileColor,
          tellNumber: resp.data.tellNumber,
          isFtiFinished: true,
          mode: 0,
          accessToken: null,
          validSubscription: false,
          packageType: null,
          billingType: null,
          subscriptionEndTime: null,
        }),
      );
      return { hasError: false, errorCode: resp.code, errorMessage: '', response: true };
    }
    dispatch({ type: actionTypes.ADD_USER_FAILD });
    return { hasError: true, errorCode: resp.code, errorMessage: resp.message, response: false };
  });
};

const logoutAction = (dispatch) => {
  dispatch({ type: actionTypes.LOGOUT_USER });
  dispatch(reset());
  dispatch(removeSession());
  return true;
};

export {
  authenticateAction,
  addUserAction,
  setPasswordAction,
  resetPasswordAction,
  updateUserAction,
  logoutAction,
};

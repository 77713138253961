import React from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoundButton, RoundButtonState } from '../components/dsm/buttons/RoundButton';
import { TextType } from '../components/dsm/Text';

import Expense from './components/expense/Expense';
import PayOff from './components/payOff/PayOff';
import { ReportsOverviewType } from '../ui-model/reports/ReportOverviewUiModel';
import { useCompany } from '../hooks/useCompany';
import DesktopHeader from '../components/generic/DesktopHeader';
import ToggleHeader from '../components/generic/ToggleHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ContentLayout from '../components/layout/ContentLayout';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';

function ReportsOverview() {
  const location = useLocation();
  const translate = useTranslate();
  const currentCompany = useCompany();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();
  const { state } = location;
  let caseIds: number[] = [];
  if (state.cases !== null) {
    caseIds = state.cases;
  }

  const navigate = useNavigate();

  const type =
    state.type === ReportsOverviewType.EXPENSE
      ? ReportsOverviewType.EXPENSE
      : ReportsOverviewType.PAY_OFF;

  function ReportsOverviewContentBodyHeader() {
    return (
      <>
        <ContentBodyHeader>
          <ButtonsContainer>
            <RoundButton
              onClick={() =>
                navigate(`/${currentCompany.companyId}/reports/reportsoverview`, {
                  state: { cases: caseIds, type: ReportsOverviewType.EXPENSE },
                })
              }
              indicator={false}
              buttonState={
                ReportsOverviewType.EXPENSE === type
                  ? RoundButtonState.ACTIVE
                  : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.EXPENSE)}
            </RoundButton>
            <RoundButton
              onClick={() =>
                navigate(`/${currentCompany.companyId}/reports/reportsoverview`, {
                  state: { cases: caseIds, type: ReportsOverviewType.PAY_OFF },
                })
              }
              indicator={false}
              buttonState={
                ReportsOverviewType.PAY_OFF === type
                  ? RoundButtonState.ACTIVE
                  : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.PAY_OFF)}
            </RoundButton>
          </ButtonsContainer>
        </ContentBodyHeader>
      </>
    );
  }

  return (
    <>
      <Content>
        {(isTabletLarge || isTabletSmall || isMobile) && (
          <ToggleHeader
            hasContent={true}
            title={'Reports'}
            enabledClick={undefined}
            buttonText={undefined}
            onClick={() => {}}
            buttonIcon={undefined}
            noButtonDesign={false}
          />
        )}
        {!isTabletLarge && !isTabletSmall && !isMobile && (
          <DesktopHeader
            hasContent={true}
            title={'Reports'}
            enabledClick={false}
            buttonText={undefined}
            onClick={() => {}}
            buttonIcon={undefined}
          />
        )}
        <ContentLayout>
          <ReportsOverviewContentBodyHeader />
          {ReportsOverviewType.EXPENSE === type && (
            <Expense companyId={currentCompany.companyId} caseIds={caseIds} />
          )}
          {ReportsOverviewType.PAY_OFF === type && (
            <PayOff companyId={currentCompany.companyId} caseIds={caseIds} />
          )}
        </ContentLayout>
      </Content>
    </>
  );
}

const Content = styled.div`
  height: 100%;
  position: relative;

  // Mobile
  @media (max-width: 599px) {
    margin-top: 110px;
    height: calc(100% - 110px);
  }
`;

const ContentBodyHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0px 0px 24px 0px;
`;

export default ReportsOverview;

import React from 'react';
import { Text, TextType } from '../dsm/Text';
import styled from 'styled-components';

import { ReactComponent as EmptyViewIcon } from '../../../icons/EmptyView.svg';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

const EmptyView = (props: any) => {
  const translate = useTranslate();
  return (
    <EmptyViewContent style={{ height: `calc(100% - ${props.allHeightOtherItems}px)` }}>
      <EmptyViewIcon />
      <EmptyViewText>
        <Text type={TextType.BODY_BOLD}>
          {translate(LANGUAGE_KEYS.GENERIC_SELECTED_PAGE_EMPTY_TITLE)}
        </Text>
        <Text type={TextType.BODY_REGULAR}>
          {translate(LANGUAGE_KEYS.GENERIC_SELECTED_PAGE_EMPTY_BODY)}
        </Text>
      </EmptyViewText>
    </EmptyViewContent>
  );
};

const EmptyViewContent = styled.div`
  background: var(--dts_white);
  border: 1px solid var(--dts_light_grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
`;

const EmptyViewText = styled.div`
  text-align: center;
`;

export default EmptyView;

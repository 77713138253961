import React, { useState } from 'react';

import Tooltip from '../components/dsm/Tooltip';
import Avatar from '../components/generic/Avatar';
import { ContextMenu, ContextMenuOffset, ContextMenuOption } from '../components/dsm/ContextMenu';
import styled from 'styled-components';

export type CollaboratorProps = {
  collaborator: any;
  index: number;
  maxCollaboratorsSize: number;
  collaboratorsSize: number;
  collaboratorsList: { key: number; icon: any; label: string }[]; //this list contains the collaborators we could not display
};

export function CollaboratorItem(collaboratorProps: CollaboratorProps) {
  const { collaborator, index, maxCollaboratorsSize, collaboratorsSize, collaboratorsList } =
    collaboratorProps;

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });
  const contextMenuOptions: Array<ContextMenuOption> = collaboratorsList;

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const renderItem = () => {
    if (index < maxCollaboratorsSize) {
      return (
        <Tooltip key={index} content={collaborator.name} placement='bottom'>
          <div
            style={{
              zIndex: maxCollaboratorsSize - index,
              marginLeft: '-4px',
            }}
          >
            <Avatar
              fullName={collaborator.name}
              size={{ outer: 24, inner: 22 }}
              avatarStyle={{ background: collaborator.profileColor }}
            />
          </div>
        </Tooltip>
      );
    }
    if (index === maxCollaboratorsSize) {
      return (
        <Collaborators
          key={index}
          className='more'
          onClick={(e) => {
            e.preventDefault();
            handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
          }}
          style={{
            zIndex: isContextMenuOpen ? 1 : maxCollaboratorsSize - index,
            marginLeft: '-4px',
          }}
        >
          <Avatar
            fullName={collaboratorsSize - maxCollaboratorsSize}
            size={{ outer: 24, inner: 22 }}
          />
          <ContextMenu
            className='collaborators-part custom-scrollbar'
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            options={contextMenuOptions}
            offset={contextMenuOffset}
          />
        </Collaborators>
      );
    }
    return <></>;
  };

  return renderItem();
}

const Collaborators = styled.div`
  .collaborators-part {
    min-width: 207px;
    max-width: 207px;
    max-height: 228px;
    overflow-y: scroll;
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
    svg {
      fill: var(--dts_black);
      path {
        opacity: 1;
      }
    }
  }
  &.more {
    cursor: pointer;
  }
`;

import { ExtraTag, ExtraTagProps } from '../../components/dsm/ExtraTag';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { CaseDetailContext } from '../CaseDetailProvider';
import { useExtensionBoxViewModel } from './ExtensionBoxViewModel';
import { useExtensionRepositoryImplementation } from '../../../data/worktype/extension/ExtensionRepositoryImplementation';

export function ExtensionBox() {
  const { caseDetailObj } = useContext(CaseDetailContext);

  const repo = useExtensionRepositoryImplementation();
  const { extensions, mapExtrasFromExtensionList } = useExtensionBoxViewModel(repo);

  const extraKeys = mapExtrasFromExtensionList(caseDetailObj);

  const getName = (id: number, value: number): ExtraTagProps['value'] => {
    const n = extensions?.find((e) => {
      return e.id === id;
    })?.name;
    return { name: n || '', count: value };
  };

  return (
    <CaseWorkSheetExtrasBox className='extras-box'>
      {[...extraKeys.keys()]
        .filter((key) => key !== -100)
        .map((id) => {
          return (
            <ExtraTag
              key={id}
              value={getName(id, extraKeys.get(id) || 1)}
              onCloseClick={() => {}}
              removable={false}
            />
          );
        })}
    </CaseWorkSheetExtrasBox>
  );
}

const CaseWorkSheetExtrasBox = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;

  /* DTS_White */
  background: #ffffff;
  /* Light Gray */
  // border: 1px solid #E4E4E4;
  border-radius: 3px;

  order: 3;
  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

import { CaseRepository } from '../../domain/CaseRepository';
import React from 'react';
import { CaseDetail } from '../ui-model/CaseDetail';
import { useDispatch } from 'react-redux';
import { setCaseDetailStatus } from '../../data/cases/detail/caseDetailReducer';
import { ACTIVE } from './caseStates';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId } from '../../domain/features/Features';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';

function useCaseDetailFooterPopUpViewModel(repo: CaseRepository, featuresRepo: FeaturesRepository) {
  const dispatch = useDispatch();
  const createCase = React.useCallback(
    (c: CaseDetail) => {
      console.log('useCaseDetailFooterPopUpViewModel');
      console.log(c);
      dispatch(setCaseDetailStatus(ACTIVE));
    },
    [repo.newCase],
  );

  const saveCaseAsDraft = React.useCallback(
    (c: CaseDetail) => {
      console.log('useCaseDetailFooterPopUpViewModel');
      console.log(c);
      // let newCase= getCaseCreate(c, DRAFT)
      // createCaseUseCase({newCase: repo.newCase}, newCase);
    },
    [repo.newCase],
  );

  const isEnabledPublichAction = (userRole: number) => {
    return getFeatureByIdUseCase(featuresRepo, userRole, FeatureId.PUBLISH_CASE_ACTION);
  };

  return {
    isLoading: repo.isLoading,
    isUpdating: repo.isUpdating,
    error: repo.error,
    createCase,
    saveCaseAsDraft,
    isEnabledPublichAction,
  };
}

// function getTemplates(lw: DetailsLabWork[], t: Tooths) {
//   const lwList: TemplateCreate[] = [];
//
//   console.log('getTemplates');
//   lw.forEach((value) => {
//     // const tc = new TemplateCreate(value.labWorkId, undefined)
//
//     const _t: { teethId: number; teethRole: number; connection: number }[] = [];
//
//     // value.teethList?.map(it => {return {teethId: it.id, teethRole: it.role }})
//
//     // let tooths = [...t.lower, t.upper]
//     t.upper
//       .filter((_tc) => {
//         return _tc.labwork === value.labWorkId;
//       })
//       .forEach((vs) => {
//         _t?.push({
//           teethId: vs.quarter * 10 + vs.teethp,
//           teethRole: vs.type,
//           connection: vs.leftConnectionType,
//         });
//       });
//
//     t.lower
//       .filter((_tc) => {
//         return _tc.labwork === value.labWorkId;
//       })
//       .forEach((vs) => {
//         _t?.push({
//           teethId: vs.quarter * 10 + vs.teethp,
//           teethRole: vs.type,
//           connection: vs.leftConnectionType,
//         });
//       });
//
//     const tc = new TemplateCreate(value.labWorkId, value.workType.name, _t, value.extensions);
//     tc.phases = value.editedPhases;
//     tc.workType = value.workType.id;
//     if (value.selectedTemplate.kind === 'Existing') {
//       tc.selectedTemplateId = value?.selectedTemplate.id;
//     }
//     lwList.push(tc);
//   });
//
//   return lwList;
// }

export { useCaseDetailFooterPopUpViewModel };

import React, { FC } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';

import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as LobbyEmptyIcon } from '../../icons/lobbyEmptyIcon.svg';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';

export type LobbyEmptyViewProps = {
  onClickViewPlans: () => void;
  onClickCreateNewLab: () => void;
};

export const LobbyEmptyView: FC<LobbyEmptyViewProps> = (lobbyEmptyViewProps) => {
  const translate = useTranslate();
  const { onClickViewPlans, onClickCreateNewLab } = lobbyEmptyViewProps;

  return (
    <ContentBody>
      <LobbyEmptyIcon width={128} height={128} />
      <TextConatiner>
        <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.EMPTY_LOBBY_TITLE)}</Text>
        <Text type={TextType.BODY_REGULAR}>{translate(LANGUAGE_KEYS.EMPTY_LOBBY_BODY)}</Text>
      </TextConatiner>
      <ActionsConatiner>
        <Button onClick={onClickViewPlans} textType={TextType.CAPTION_BOLD}>
          <PlusIcon />
          {translate(LANGUAGE_KEYS.VIEW_PLANS)}
        </Button>
        <Button onClick={onClickCreateNewLab} textType={TextType.CAPTION_BOLD}>
          <PlusIcon />
          {translate(LANGUAGE_KEYS.CREATE_NEW_LAB)}
        </Button>
      </ActionsConatiner>
    </ContentBody>
  );
};

const ContentBody = styled.div`
  background: var(--dts_white);
  height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  height: calc(100% - 225px);
  justify-content: center;
`;

const TextConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: center;
`;

const ActionsConatiner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

import { emailPattern, passwordPattern } from '../../components/dsm/InputPatterns';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';

export enum InputFieldErrorType {
  INVALID_FIELDS_LOGIN = 0,
  INVALID_EMAIL_RESET_PASSWORD = 1,
  ALREADY_USE_EMAIL = 3,
}

export enum InputFieldType {
  EMAIL_ADDRESS = 0,
  PASSWORD = 1,
}

export const inputFieldErrors: Array<{ errorId: InputFieldErrorType; errorMessage: string }> = [
  {
    errorId: InputFieldErrorType.INVALID_FIELDS_LOGIN,
    errorMessage: LANGUAGE_KEYS.ERROR_MESSAGE_EMAIL_PASSWROD_DO_NOT_MATCH,
  },
  {
    errorId: InputFieldErrorType.INVALID_EMAIL_RESET_PASSWORD,
    errorMessage: LANGUAGE_KEYS.ERROR_MESSAGE_ENTER_VALID_MAIL,
  },
  {
    errorId: InputFieldErrorType.ALREADY_USE_EMAIL,
    errorMessage: LANGUAGE_KEYS.ERROR_MESSAGE_THIS_EMAIL_IS_IN_USE,
  },
];

const validationPaths: Array<{
  fieldType: InputFieldType;
  path: RegExp;
}> = [
  {
    fieldType: InputFieldType.EMAIL_ADDRESS,
    path: emailPattern,
  },
  {
    fieldType: InputFieldType.PASSWORD,
    path: passwordPattern,
  },
];

export const isValidInputField = (fieldType: InputFieldType, value: string): boolean => {
  const errorValidation = validationPaths.find((error) => error.fieldType === fieldType);
  if (errorValidation !== undefined) {
    if (!errorValidation.path.test(value)) {
      return false;
    }
  }

  return true;
};

export const getInputErrorText = (errorId: InputFieldErrorType) => {
  const error = inputFieldErrors.find((error) => error.errorId === errorId);
  if (error !== undefined) {
    return error.errorMessage;
  }
  return '';
};

export class NewTemplate {
  name: string;
  workTypeId: number;
  phaseIds: number[];

  constructor(name: string, workTypeId: number, phaseIds: number[]) {
    this.name = name;
    this.workTypeId = workTypeId;
    this.phaseIds = phaseIds;
  }
}

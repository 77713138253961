import React from 'react';

import { ReactComponent as EnglishFlag } from '../../../icons/languageFlags/1.svg';
import { ReactComponent as HungarianFlag } from '../../../icons/languageFlags/2.svg';
import { ReactComponent as RomanianFlag } from '../../../icons/languageFlags/3.svg';

export const getLanguageFlag = (languageId: number | undefined) => {
  switch (languageId) {
    case 1:
      return <EnglishFlag width={20} height={20} />;
    case 2:
      return <HungarianFlag width={20} height={20} />;
    case 3:
      return <RomanianFlag width={20} height={20} />;
    default:
      return <></>;
  }
};

import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { UserManagementRow } from './UserManagementRow';
import { SearchAndAddNew } from '../components/dsm/SearchAndAddNew';
import { ClientUiModel, WorkerUiModel } from '../ui-model/user-management/ContactUiModel';
import { CompanyUiModel } from '../ui-model/user-management/CompanyUiModel';
import EmptyView from '../components/generic/EmptyView';

export type UserManagementContentProps = {
  isWorker: boolean;
  contacts: (ClientUiModel | CompanyUiModel | WorkerUiModel)[];
  addNewText: string;
  currentUserId: number;
  onDelete: (id: number, isContact: boolean) => void;
  openAddNew: (itemModel: any, isWorker: boolean, editInvite: boolean) => void;
};

export const UserManagementContent: FC<UserManagementContentProps> = ({
  isWorker,
  contacts,
  addNewText,
  currentUserId,
  onDelete,
  openAddNew,
}) => {
  const [searchResult, setSearchResult] = useState<
    (ClientUiModel | CompanyUiModel | WorkerUiModel)[] | null
  >(null);

  const onHandleChangeSearchBar = (e) => {
    if (e.target.value.length <= 0) {
      setSearchResult(null);
    } else {
      const list = contacts?.filter((item) => {
        if (item.name.toUpperCase().includes(e.target.value.toUpperCase())) {
          return item;
        }
      });

      setSearchResult(list);
    }
  };
  const onClickSearchClose = () => {
    setSearchResult(null);
  };

  return (
    <>
      <SearchAndAddNew
        isWorker={isWorker}
        handleChangeSearchBar={onHandleChangeSearchBar}
        clickSearchClose={onClickSearchClose}
        addNewText={addNewText}
        addNew={openAddNew}
      />

      {contacts.length > 0 ? (
        <ContentBody>
          <ContentBodyList
            list={searchResult === null ? contacts : searchResult}
            isWorker={isWorker}
            currentUserId={currentUserId}
            onDelete={onDelete}
            onEdit={openAddNew}
          />
        </ContentBody>
      ) : (
        <EmptyView allHeightOtherItems={240} />
      )}
    </>
  );
};

function ContentBodyList(props: {
  list: any[];
  isWorker: boolean;
  currentUserId: number;
  onDelete: (id: number, isContact: boolean) => void;
  onEdit: (itemModel: any, isWorker: boolean, editInvite: boolean) => void;
}) {
  return (
    <ContentBodyListStyled>
      {buildWorkTypeList(
        props.list?.filter((value) => {
          return value;
        }),
        props.isWorker,
        props.currentUserId,
        props.onDelete,
        props.onEdit,
      )}
    </ContentBodyListStyled>
  );
}

function buildWorkTypeList(
  list: any[],
  isWorker: boolean,
  currentUserId: number,
  onDelete: (id: number, isContact: boolean) => void,
  onEdit: (itemModel: any, isWorker: boolean, isEdit: boolean) => void,
) {
  const listView: any[] = [];
  list?.map(function (item, index) {
    listView.push(
      <UserManagementRow
        key={index}
        isWorker={isWorker}
        itemModel={item}
        currentUserId={currentUserId}
        onDelete={onDelete}
        onEdit={onEdit}
      />,
    );
  });
  return listView;
}

const ContentBody = styled.div`
  margin-top: 24px;
`;

const ContentBodyListStyled = styled.div``;

export default UserManagementContent;

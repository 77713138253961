import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';
import { TemplateBySubWorkType } from '../../../domain/worktype/templates/TemplateBySubWorkType';

type GetTemplateRepository = Pick<TemplateRepository, 'addTemplateBySubWorkType' | 'getTemplates'>;

const addTemplateBySubWorkTypeUseCase = (
  repo: GetTemplateRepository,
  templateBySubWorkType: TemplateBySubWorkType,
) => {
  repo.addTemplateBySubWorkType(templateBySubWorkType);
  repo.getTemplates();
};

export { addTemplateBySubWorkTypeUseCase };

import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as SuccessIcon } from '../icons/notification_success.svg';
import { Text, TextType } from './components/dsm/Text';
import { GlobalContext3 } from './GlobalProvider3';

// import the context we want access to in this component

function Notification(props: {
  message: {
    showFlash: boolean;
    title: string;
    message: string;
    status: string;
  };
}) {
  const [, setNewMessage] = useContext(GlobalContext3);

  useEffect(() => {
    const id = setTimeout(() => {
      setNewMessage((prevArray) => {
        return prevArray.filter((v) => {
          return v !== props.message;
        });
      });
    }, 3000);

    return () => clearTimeout(id);
  }, [props.message]);

  function getIcon(status: string) {
    switch (status) {
      case 'success':
        return <SuccessIcon />;
      default:
        return null;
    }
  }

  return (
    <FlashBox>
      <Text type={TextType.BODY_BOLD} style={{ margin: '0px' }}>
        {props.message.title}
      </Text>
      <FlashBoxDescription>
        {getIcon(props.message.status)}
        <Text type={TextType.CAPTION_REGULAR_3}>{props.message.message}</Text>
      </FlashBoxDescription>
    </FlashBox>
  );
}

export function Notifications() {
  // This component is a consumer of the context
  // which is why we call the consumer from the context
  // which gives us access to the value of the context as a prop

  // const [newMessage, setNewMessage] = useContext(GlobalContext2);
  const [newMessage] = useContext(GlobalContext3);

  // console.log(newMessage[4]?.showFlash);

  const message = newMessage.find((v) => {
    return v.showFlash === true;
  });

  // console.log(newMessage[4]?.showFlash)
  // console.log(message)
  if (message !== undefined) {
    return <Notification message={message} />;
  } else {
    return null;
  }
}

const FlashBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;

  position: absolute;
  width: 272px;
  height: 64px;
  right: 36px;
  bottom: 36px;

  /* DTS_White */
  background: #ffffff;
  /* shadow */
  box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  z-index: 300;
`;

const FlashBoxDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 272px;
  height: 32px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

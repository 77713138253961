import { BillingRepository } from '../../domain/billing/BillingRepository';
import { Package } from '../../domain/billing/Package';

type UpdatePackageCompanyRepository = Pick<BillingRepository, 'updateCompanyPackage'>;

const updateCompanyPackageUseCase = (repo: UpdatePackageCompanyRepository, params: Package) => {
  return repo.updateCompanyPackage(params);
};

export { updateCompanyPackageUseCase };
export type { UpdatePackageCompanyRepository };

import { BASE_URL } from '../Server';
import { ContactData } from '../../domain/user-management/ContactData';
import { CompanyData, MyCompanyData } from '../../domain/user-management/CompanyData';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
  AnswerWorkerInvitation,
} from '../../domain/user-management/Contact';
import { NewCompany } from '../../domain/user-management/NewCompany';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';
import { useApiHeaderWithoutCompanyId } from '../../presentation/hooks/useApiHeaderWithoutCompanyId';
import { Plan } from '../../domain/user-management/Plans';

function getContacts(): Promise<ContactData> {
  return fetch(BASE_URL + '/contacts', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getContacts: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function updateClient(client: UpdateClient) {
  return fetch(BASE_URL + '/modifyContact', {
    method: 'POST',
    body: JSON.stringify(client),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy newContact: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function updateWorker(worker: UpdateWorker) {
  return fetch(BASE_URL + '/modifyContact', {
    method: 'POST',
    body: JSON.stringify(worker),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateWorker: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function sendWorkerInvitation(invitationData: SendWorkerInvitation) {
  return fetch(BASE_URL + '/modifyContact', {
    method: 'POST',
    body: JSON.stringify(invitationData),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateWorker: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function answerInvitation(answerInv: AnswerWorkerInvitation) {
  return fetch(BASE_URL + '/answerInvitation?companyId=' + answerInv.companyId, {
    method: 'POST',
    body: JSON.stringify({ apply: answerInv.apply }),
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy answerInvitation: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function deleteContact(id: number) {
  return fetch(BASE_URL + '/deleteContact/' + id, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy deleteContact: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function getMyCompanyData(): Promise<MyCompanyData> {
  return fetch(BASE_URL + '/getMyCompany', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getMyCompanyData: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function getCompanies(): Promise<CompanyData> {
  return fetch(BASE_URL + '/company', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getCompanies: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function newCompany(newContact: NewCompany) {
  return fetch(BASE_URL + '/addCompany', {
    method: 'POST',
    body: JSON.stringify(newContact),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy newCompany: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function updateCompany(newContact: NewCompany) {
  return fetch(BASE_URL + '/updateCompany', {
    method: 'POST',
    body: JSON.stringify(newContact),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateCompany: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function deleteCompany(id: number) {
  return fetch(BASE_URL + '/deleteContract/' + id, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy deleteCompany: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function createCompany(plan: Plan): Promise<MyCompanyData> {
  return fetch(BASE_URL + `/createCompany`, {
    method: 'POST',
    body: JSON.stringify(plan),
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy createCompany: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

export {
  getContacts,
  updateClient,
  updateWorker,
  answerInvitation,
  sendWorkerInvitation,
  deleteContact,
  getCompanies,
  getMyCompanyData,
  newCompany,
  updateCompany,
  deleteCompany,
  createCompany,
};

import { Text, TextType } from '../../../../components/dsm/Text';
import React from 'react';
import styled from 'styled-components';
import { LANGUAGE_KEYS } from '../../../../translations/languageKeys';
import useTranslate from '../../../../translations/useTranslate';

type PhaseTypeSelectorProps = {
  selectedType: Type;
  setSelectedType: (type: Type) => void;
};

export type Type = 'new-phase' | 'date-and-time' | 'choose-from-template';

const PhaseTypeSelector = (props: PhaseTypeSelectorProps) => {
  const translate = useTranslate();
  const { selectedType, setSelectedType } = props;

  return (
    <Container>
      <RadioInput>
        <Input
          id='new-phase'
          type='radio'
          checked={selectedType === 'new-phase'}
          onChange={() => setSelectedType('new-phase')}
        />
        <Label htmlFor='new-phase'>
          <Text type={TextType.CAPTION_2_BOLD} style={{ lineHeight: '12px' }}>
            {translate(LANGUAGE_KEYS.NEW_PHASE)}
          </Text>
        </Label>
      </RadioInput>
      <RadioInput>
        <Input
          id='date-and-time'
          type='radio'
          checked={selectedType === 'date-and-time'}
          onChange={() => setSelectedType('date-and-time')}
        />
        <Label htmlFor='date-and-time'>
          <Text type={TextType.CAPTION_2_BOLD} style={{ lineHeight: '12px' }}>
            {translate(LANGUAGE_KEYS.DATE_AND_TIME)}
          </Text>
        </Label>
      </RadioInput>
      <RadioInput>
        <Input
          id='choose-from-template'
          type='radio'
          checked={selectedType === 'choose-from-template'}
          onChange={() => setSelectedType('choose-from-template')}
        />
        <Label htmlFor='choose-from-template'>
          <Text type={TextType.CAPTION_2_BOLD} style={{ lineHeight: '12px' }}>
            {translate(LANGUAGE_KEYS.CHOOSE_FROM_TEMPLATE)}
          </Text>
        </Label>
      </RadioInput>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const RadioInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

// TODO: design custom input
const Input = styled.input`
  width: 12px;
  height: 12px;
  margin: 0;
  cursor: inherit;
`;

const Label = styled.label`
  user-select: none;
  display: flex;
  height: auto;
  cursor: inherit;
  padding-left: 8px;
`;

export default PhaseTypeSelector;

import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PhaseCard } from './PhaseCard';
import { CreateNewPhaseCard } from './CreateNewPhaseCard';
import { PhaseUiModel, PhaseUiModelPhase } from '../../../ui-model/PhaseUiModel';
import { ReactComponent as PhaseSeparatorIcon } from '../../../../icons/phaseSeparatorIcon.svg';
import PhaseDateCard from './PhaseDateCard';
import { useLoggedUserRepositoryImplementation } from '../../../../data/loggedUser/loggedUserRepositoryImplementation';
import { usePhaseStripeViewModel } from './PhaseStripeViewModel';
import { featuresRepositoryImplementation } from '../../../../data/features/FeaturesRepositoryImplementation';
import { useCompany } from '../../../hooks/useCompany';
import { DeviceSizeProps } from '../../../DeviceInformations';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import { PhaseMobileContextMenu } from '../../mobile/PhaseMobileContextMenu';

type PhaseStripeProps = {
  setRef: (ref: HTMLDivElement | null) => void;
  phases: Array<PhaseUiModel>;
  isEnabledAssigne: boolean;
  onAssign: (phase: PhaseUiModelPhase, x: number, y: number) => void;
  onErrorStatus: (phase: PhaseUiModelPhase, x: number, y: number) => void;
  onStatusChange: (phase: PhaseUiModelPhase, status: PhaseUiModelPhase['status']) => void;
  onDuplicatePhase: (phase: PhaseUiModel) => void;
  onDeletePhase: (phase: PhaseUiModel) => void;
  onAddPhase: (phaseBefore: PhaseUiModel, phase?: PhaseUiModelPhase) => void;
  published: boolean;
  caseStatus: number;

  scrollPhaseOrder?: number | null;
  openAddNewPhaseMobile?: (phase: PhaseUiModel) => void;
  openAddNewTemplateMobile?: (phase: PhaseUiModelPhase) => void;
  openAddNewDateMobile?: (phase: PhaseUiModelPhase) => void;
};

export const PhaseStripe = (props: PhaseStripeProps) => {
  const {
    setRef,
    phases,
    isEnabledAssigne,
    onAssign,
    onErrorStatus,
    onStatusChange,
    onDuplicatePhase,
    onDeletePhase,
    onAddPhase,
    published,
    caseStatus,
    scrollPhaseOrder,
    openAddNewPhaseMobile,
    openAddNewTemplateMobile,
    openAddNewDateMobile,
  } = props;

  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const currentCompany = useCompany();

  const { userId, isEnabledAllPhaseStatusChange, isEnabledExtendLabworkPhase } =
    usePhaseStripeViewModel(loggedUserRepo, featureRepo);

  const orderedPhases = useMemo(() => {
    return phases?.sort((a, b) => a.order - b.order) || [];
  }, [phases]);

  const refs = orderedPhases.reduce((acc, value) => {
    acc[value.order] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (
      scrollPhaseOrder !== undefined &&
      scrollPhaseOrder !== null &&
      scrollPhaseOrder !== undefined
    ) {
      refs[scrollPhaseOrder].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [refs]);

  const canAddPhase = (nextPhase: PhaseUiModel) => {
    if (nextPhase.kind === 'Date') return true;
    else
      return (
        nextPhase.status === 'Unassigned' ||
        nextPhase.status === 'Open' ||
        nextPhase.status === 'OpenForFuture'
      );
  };

  const isEnabledAllStatusChange = (currentPhase, orderedPhases) => {
    let currentPhaseModification = false;
    let previousPhaseModification = false;
    let allPhaseModification = false;
    if (currentCompany.userRole !== null) {
      if (isEnabledAllPhaseStatusChange(currentCompany.userRole)) allPhaseModification = true;
      if (
        (currentPhase.status === 'Open' ||
          currentPhase.status === 'Started' ||
          currentPhase.status === 'Finishing' ||
          currentPhase.status === 'FinishingWithError_1' ||
          currentPhase.status === 'FinishingWithError_2' ||
          currentPhase.status === 'FinishingWithError_3') &&
        currentPhase.assignee.userId === userId
      )
        currentPhaseModification = true;

      let currentPhaseNewIndex = -1;
      const orderedPhasesWithoutDate = orderedPhases.filter((p) => p.kind != 'Date');

      orderedPhasesWithoutDate.forEach((p, index) => {
        if (currentPhase.id === p.id && currentPhase.order === p.order) {
          currentPhaseNewIndex = index;
        }
      });
      const nextPhase = orderedPhasesWithoutDate[currentPhaseNewIndex + 1];
      const previousPhase = orderedPhasesWithoutDate[currentPhaseNewIndex - 1];

      if (
        (nextPhase !== undefined &&
          nextPhase.status === 'Open' &&
          nextPhase.assignee.userId === userId) ||
        (previousPhase !== undefined &&
          (previousPhase.status === 'Finishing' ||
            previousPhase.status === 'FinishingWithError_1' ||
            previousPhase.status === 'FinishingWithError_2' ||
            previousPhase.status === 'FinishingWithError_3'))
      ) {
        previousPhaseModification = true;
      } else {
        if (
          currentPhase.assignee !== undefined &&
          currentPhase.assignee.userId === userId &&
          currentPhase.status !== 'Open' &&
          currentPhase.status !== 'Started'
        ) {
          previousPhaseModification = true;
        } else {
          previousPhaseModification = false;
        }
      }
    }
    return {
      currentPhaseModification: currentPhaseModification,
      previousPhaseModification: previousPhaseModification,
      allPhaseModification: allPhaseModification,
    };
  };

  const isEnabledExtendLabwork = (): boolean => {
    if (currentCompany.userRole !== null) {
      return isEnabledExtendLabworkPhase(currentCompany.userRole);
    }
    return false;
  };

  return (
    <Container
      ref={(p) => setRef(p)}
      className={published ? '' : 'not-published'}
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      {orderedPhases.map((phase, i) => {
        return (
          <PhaseContainer
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
            key={i}
            ref={refs[phase.order]}
          >
            {phase.kind != 'Date' ? (
              <PhaseCard
                phase={phase}
                isEnabledAssigne={isEnabledAssigne}
                phaseModificationsCases={isEnabledAllStatusChange(phase, orderedPhases)}
                caseSatus={caseStatus}
                onAssign={(x, y) => onAssign(phase, x, y)}
                onErrorStatus={(x, y) => onErrorStatus(phase, x, y)}
                onStatusChange={onStatusChange}
                onDuplicatePhase={onDuplicatePhase}
                onDeletePhase={onDeletePhase}
              />
            ) : (
              <PhaseDateCard phase={phase} onDeletePhase={onDeletePhase} />
            )}
            {i < orderedPhases.length - 1 && !isMobile && (
              <Separator
                className={`${
                  (!canAddPhase(orderedPhases[i + 1]) || !isEnabledExtendLabwork()) && 'disabled'
                }`}
                onClick={() => {
                  if (isEnabledExtendLabwork()) {
                    canAddPhase(orderedPhases[i + 1]) && onAddPhase(phase);
                  }
                }}
              />
            )}
            {i < orderedPhases.length - 1 && isMobile && (
              <PhaseMobileContextMenu
                phase={orderedPhases[i]}
                phaseRef={refs[orderedPhases[i].order]}
                isEnabledExtendLabwork={isEnabledExtendLabwork()}
                canAddPhase={canAddPhase(orderedPhases[i + 1])}
                isPhaseCard={false}
                topPosition={orderedPhases.length > 1}
                openAddNewPhase={openAddNewPhaseMobile}
                openAddNewTemplate={openAddNewTemplateMobile}
                openAddNewDate={openAddNewDateMobile}
              />
            )}
          </PhaseContainer>
        );
      })}
      {published && !isMobile && (
        <CreateNewPhaseCard
          disabled={isEnabledExtendLabwork()}
          beforePhase={orderedPhases[orderedPhases.length - 1]}
          handelOnClickCreateNew={
            isMobile && openAddNewPhaseMobile !== undefined ? openAddNewPhaseMobile : onAddPhase
          }
        />
      )}
      {published && isMobile && (
        <PhaseMobileContextMenu
          phase={orderedPhases[orderedPhases.length - 1]}
          phaseRef={
            orderedPhases[orderedPhases.length - 1] !== undefined
              ? refs[orderedPhases[orderedPhases.length - 1].order]
              : null
          }
          isEnabledExtendLabwork={isEnabledExtendLabwork()}
          canAddPhase={true}
          isPhaseCard={true}
          topPosition={orderedPhases.length > 1}
          openAddNewPhase={openAddNewPhaseMobile}
          openAddNewTemplate={openAddNewTemplateMobile}
          openAddNewDate={openAddNewDateMobile}
        />
      )}
    </Container>
  );
};

const Container = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    width: 100%;
    display: flex;
    align-items: stretch;
    overflow-x: scroll;
    overflow-y: hidden;

    &.not-published {
      opacity: 0.5;
      pointer-events: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      flex-direction: row;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-direction: column;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
      flex-direction: column;
    }
`,
);

const PhaseContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center,
    //Desktop
    @media (min-width: ${desktop}px) {
      flex-direction: row;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-direction: column;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
      flex-direction: column;
    }
 `,
);

const Separator = styled(PhaseSeparatorIcon)`
  cursor: pointer;
  align-self: center;
  :hover {
    * {
      fill: var(--dts_default_blue);
    }
  }
  &.disabled {
    cursor: not-allowed;
    * {
      fill: #d9d9d9;
    }
  }
`;

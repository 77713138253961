import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';

type authenticateUserUseCasesRepository = Pick<SignUpFlowRepository, 'authenticateUser'>;

const authenticateUserUseCases = (
  repo: authenticateUserUseCasesRepository,
  username: string,
  password: string,
) => {
  return repo.authenticateUser({ username: username, password: password });
};

export { authenticateUserUseCases };
export type { authenticateUserUseCasesRepository };

import { Text, TextColor, TextType } from '../../components/dsm/Text';
import React from 'react';
import styled from 'styled-components';
import { LabworkStatus } from './CaseWorkSheetComp';

export function WorkSheetStatus(props: { labWorkStatus: LabworkStatus }) {
  return (
    <CaseWorkSheetBodyStatusContainer>
      <CaseWorkSheetBodyStatusText>
        <Text type={TextType.BODY_BOLD} clr={TextColor.BLACK}>
          {props.labWorkStatus.title}
        </Text>
        <Text style={{ marginTop: '16px' }} type={TextType.BODY_2} clr={TextColor.BLACK}>
          {props.labWorkStatus.description}
        </Text>
        {props.labWorkStatus.altDescription && (
          <Text
            style={{ marginTop: '8px' }}
            type={TextType.CAPTION_REGULAR_2}
            clr={TextColor.BLACK}
          >
            {props.labWorkStatus.altDescription}
          </Text>
        )}
      </CaseWorkSheetBodyStatusText>
      <CaseWorkSheetBodyStatusIndicator></CaseWorkSheetBodyStatusIndicator>
    </CaseWorkSheetBodyStatusContainer>
  );
}

const CaseWorkSheetBodyStatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--dts_withe_background);
  box-sizing: border-box;
`;

const CaseWorkSheetBodyStatusText = styled.div`
  display: flex;
  flex-direction: column;
`;

const CaseWorkSheetBodyStatusIndicator = styled.div``;

import React from 'react';
import { WorkTypeRepository } from '../../../../domain/worktype/WorkTypeRepository';
import { ExtensionRepository } from '../../../../domain/worktype/extension/ExtensionRepository';
import {
  updateExtensionUseCase,
  updateTeethExtensionUseCase,
} from '../../../../useCases/worktype/extension/updateExtensionUseCase';
import UpdateWorkTypeExtensionInfo from '../../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';
import UpdateTeethTypeExtensionInfo from '../../../../domain/worktype/extension/UpdateTeethTypeExtensionInfo';
import ExtensionInfo from '../../../ui-model/worktype/extension/ExtensionInfo';
import Extension from '../../../ui-model/worktype/extension/Extension';

function useExtensionViewModel(workTypeRepo: WorkTypeRepository, repo: ExtensionRepository) {
  const getExtensionsByWorkTypeId = (id: number): ExtensionInfo[] | [] => {
    let extensionsList: ExtensionInfo[] = [];
    workTypeRepo.workTypes
      ?.filter((wt) => wt.id === id)
      .forEach((wt) => {
        extensionsList = [
          ...getExtensions(wt.extensionList),
          ...getTeethExtensions(wt.teethTypeList),
        ];
      });
    return extensionsList;
  };

  const getExtensions = (extensionList): ExtensionInfo[] => {
    const extensionsList: ExtensionInfo[] = [];
    extensionList !== null &&
      extensionList.forEach((ext) => {
        ext.extensions.forEach((extension) => {
          extension.isTeeth = false;
          extension.teethIds = [];
          extension.active = extension.active ? true : false;
        });
        extensionsList.push(new ExtensionInfo(ext.id, ext.extensions));
      });
    return extensionsList;
  };

  const getTeethExtensions = (teethTypeList): ExtensionInfo[] => {
    const teethExtensionsList: ExtensionInfo[] = [];
    const teethExtesions: any[] = [];
    let teethGroupId = -1;
    teethTypeList !== null &&
      teethTypeList.forEach((teethExtension) => {
        teethExtension.extensionList?.forEach((extension) => {
          extension.extensions.forEach((ext) => {
            ext.isTeeth = true;
            ext.teethIds = getTeethIds(ext.id, teethTypeList);
            extension.active = extension.active ? true : false;
            if (!teethExtesions.some((item) => item.id === ext.id)) {
              teethExtesions.push(ext);
              teethGroupId = extension.id;
            }
          });
        });
      });
    if (teethGroupId !== -1) {
      teethExtensionsList.push(new ExtensionInfo(teethGroupId, teethExtesions));
    }
    return teethExtensionsList;
  };

  const getTeethIds = (extensionId, teethTypeList): number[] => {
    const teethIdsList: number[] = [];
    teethTypeList.forEach((teethExtension) => {
      teethExtension.extensionList?.forEach((extension) => {
        if (extension.extensions.some((item) => extensionId === item.id)) {
          if (!teethIdsList.includes(teethExtension.id)) teethIdsList.push(teethExtension.id);
        }
      });
    });
    return teethIdsList;
  };

  const updateExtension = React.useCallback(
    function (workTypeId: number, groupId: number, extension: Extension) {
      if (extension.isTeeth) {
        updateTeethExtensionUseCase(
          {
            updateExtension: repo.updateExtension,
            updateTeethExtension: repo.updateTeethExtension,
          },
          new UpdateTeethTypeExtensionInfo(
            workTypeId,
            extension.id,
            extension.active ? 1 : 0,
            extension.price,
          ),
        );
      } else {
        updateExtensionUseCase(
          {
            updateExtension: repo.updateExtension,
            updateTeethExtension: repo.updateTeethExtension,
          },
          new UpdateWorkTypeExtensionInfo(
            workTypeId,
            groupId,
            extension.id,
            extension.active ? 1 : 0,
            extension.price,
          ),
        );
      }
    },
    [repo.updateExtension],
  );

  return {
    getExtensionsByWorkTypeId,
    updateExtension,
  };
}

export { useExtensionViewModel };

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../dsm/Text';

import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

import { ReactComponent as DropdownIcon } from '../../../icons/arrowDown.svg';
import { ReactComponent as SelectedIcon } from '../../../icons/SelectedDropDownItem.svg';
import { getLanguageFlag } from './LanguageFlag';

export type LanguageSelectorProps = {
  values: Array<DropdownValue>;
  maxHeightOptions?: number;
  hasSuccessIcon: boolean;
  onChange?: (changedValues: LanguageSelectorProps['values']) => void;
  onSelect?: (selectedValueKey: DropdownValue['key']) => void;
};

export type DropdownValue = { key: number; label: string; selected: boolean };

export const languageList = [
  {
    id: 1,
    language: 'English',
    languageCode: 'en',
  },
  {
    id: 2,
    language: 'Hungarian',
    languageCode: 'hu',
  },
  {
    id: 3,
    language: 'Romanian',
    languageCode: 'ro',
  },
];

export const LanguageSelector = (props: LanguageSelectorProps) => {
  const { values, maxHeightOptions, hasSuccessIcon, onChange, onSelect } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  useEffect(() => {
    isOpen && containerRef?.current?.focus();
  }, [isOpen]);

  const getSelectedValue = () => {
    if (values.length === 0) return;
    const value = values.find((value) => value.selected);
    if (value !== undefined) return value;
    return values[0];
  };

  const handleOnOptionClick = (key: DropdownValue['key']) => {
    onChange && onChange(values.map((value) => ({ ...value, selected: value.key === key })));
    onSelect && onSelect(key);
  };

  return (
    <>
      <Container
        ref={containerRef}
        tabIndex={-1}
        onBlur={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
        className='language-container'
      >
        <Selected>
          {getLanguageFlag(getSelectedValue()?.key)}
          <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
            {getSelectedValue()?.label}
          </Text>
          <DropdownIconStyled className={`${isOpen && 'open'}`} />
        </Selected>
        {isOpen && (
          <OptionsContainer
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <Options style={{ maxHeight: `${maxHeightOptions}px` }} className='custom-scrollbar'>
              {values.map((value) => (
                <Option
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                  key={value.key}
                  onClick={() => handleOnOptionClick(value.key)}
                  className={`${hasSuccessIcon && value.selected && 'active'}`}
                >
                  <Text type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {value.label}
                  </Text>
                  {hasSuccessIcon && value.selected && <CustomSelectedIcon />}
                </Option>
              ))}
            </Options>
          </OptionsContainer>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  background-color: var(--dts_white);
  padding: 8.5px 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
`;

const Selected = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const OptionsContainer = styled.div<DeviceSizeProps>(
  ({ mobile, tablet_small, tablet_large, desktop }) => `
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    margin-left: -8px;
    margin-top: 15px;
    z-index: 100;
    background-color: var(--dts_white);
    box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    cursor: initial;
    outline: none;
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 24px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 16px;
      max-width:136px;
    }
`,
);

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 4px;
  max-height: 356px;
  flex-shrink: 0;
`;

export const Option = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer !important;
    &.selected,
    &:hover {
      background-color: var(--dts_withe_background);
    }
    &.active {
      display: flex;
      justify-content: space-between;
      align-item: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
        linear-gradient(0deg, #f2f2f2, #f2f2f2);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 8px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 8px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 6px 8px 6px 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  margin-left: auto;
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
`;

const CustomSelectedIcon = styled(SelectedIcon)`
  fill: var(--dts_black);
  width: 16px;
  height: 16px;
`;

import type { AnyAction } from 'redux';
import { CaseRepository } from '../../domain/CaseRepository';
import * as actionTypes from './casesActionTypes';

type CaseRepositoryState = Omit<
  CaseRepository,
  'getCases' | 'getCaseById' | 'newCase' | 'updateCase' | 'deleteCases' | 'closeReopenCase'
>;
const INITIAL_STATE: CaseRepositoryState = {
  cases: [],
  allCase: [],
  isLoading: false,
  isUpdating: false,
  error: 0,
};

const caseReducer = (state: CaseRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_CASES:
      return { ...state, isLoading: true };
    case actionTypes.GET_CASES_SUCCESS:
      return { ...state, isLoading: false, cases: action.case };
    case actionTypes.GET_CASES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_CASE_BY_ID:
      return { ...state, isLoading: true };
    case actionTypes.GET_CASE_BY_ID_SUCCESS:
      if (!state.allCase.find((c) => action.allCase.id === c.id)) {
        return { ...state, isLoading: false, allCase: [...state.allCase, action.allCase] };
      } else {
        return {
          ...state,
          isLoading: false,
          allCase: state.allCase.map((c) => {
            if (c.id === action.allCase.id) {
              return action.allCase;
            }
            return c;
          }),
        };
      }
    case actionTypes.GET_CASE_BY_ID_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_CASE:
      return { ...state, isUpdating: true, error: 0 };
    case actionTypes.UPDATE_CASE_SUCCESS:
      return { ...state, isUpdating: false };
    case actionTypes.UPDATE_CASE_FAILD:
      return { ...state, isUpdating: true, error: action.error };
    case actionTypes.UPDATE_CASES_PHASE_ID_SUCCESS:
      return { ...state };
    case actionTypes.NEW_CASE:
      return { ...state, isLoading: true, error: 0 };
    case actionTypes.NEW_CASE_SUCCESS:
      return { ...state, isLoading: false, error: 1 };
    case actionTypes.NEW_CASE_FAILED:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DELETE_CASE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_CASE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_CASE_FAILED:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
export { caseReducer };
export type { CaseRepositoryState };

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';

type LoggedUserReducer = Omit<LoggedUserRepository, ''>;

const initialState: LoggedUserReducer = {
  session:
    window.localStorage.getItem('token') !== null && window.localStorage.getItem('token') !== 'null'
      ? window.localStorage.getItem('token')
      : null,
  userId:
    window.localStorage.getItem('userId') !== null &&
    window.localStorage.getItem('userId') !== 'null'
      ? Number(window.localStorage.getItem('userId'))
      : null,
  firstName:
    window.localStorage.getItem('firstName') !== null &&
    window.localStorage.getItem('firstName') !== 'null'
      ? window.localStorage.getItem('firstName')
      : null,
  lastName:
    window.localStorage.getItem('lastName') !== null &&
    window.localStorage.getItem('lastName') !== 'null'
      ? window.localStorage.getItem('lastName')
      : null,
  email:
    window.localStorage.getItem('email') !== null && window.localStorage.getItem('email') !== 'null'
      ? window.localStorage.getItem('email')
      : null,
  companyId: null,
  lang:
    window.localStorage.getItem('lang') !== null && window.localStorage.getItem('lang') !== 'null'
      ? window.localStorage.getItem('lang')
      : null,
  profileColor:
    window.localStorage.getItem('profileColor') !== null &&
    window.localStorage.getItem('profileColor') !== 'null'
      ? window.localStorage.getItem('profileColor')
      : null,
  tellNumber:
    window.localStorage.getItem('tellNumber') !== null &&
    window.localStorage.getItem('tellNumber') !== 'null'
      ? window.localStorage.getItem('tellNumber')
      : null,
  isFtiFinished:
    window.localStorage.getItem('isFtiFinished') !== null &&
    window.localStorage.getItem('isFtiFinished') !== 'null'
      ? window.localStorage.getItem('isFtiFinished') === 'true'
      : false,
  accessToken: null,
  authenticated:
    window.localStorage.getItem('token') !== null &&
    window.localStorage.getItem('token') !== 'null' &&
    window.localStorage.getItem('userId') !== null &&
    window.localStorage.getItem('userId') !== 'null',
  mode: 0,
  validSubscription: false,
  packageType: null,
  billingType: null,
  subscriptionEndTime: null,
};

const loggedUserReducer = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    userLogin: (state, action: PayloadAction<LoggedUserReducer>) => {
      state.session = action.payload.session;
      state.userId = action.payload.userId;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.companyId = action.payload.companyId;
      state.lang = action.payload.lang;
      state.profileColor = action.payload.profileColor;
      state.tellNumber = action.payload.tellNumber;
      state.isFtiFinished = action.payload.isFtiFinished === true;
      state.accessToken = action.payload.accessToken;
      state.authenticated = true;
      state.mode = 0;
    },
    reset: (state) => {
      state.session = null;
      state.userId = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.companyId = null;
      state.accessToken = null;
      state.isFtiFinished = false;
      state.authenticated = false;
      state.mode = 0;
      state.validSubscription = false;
      state.packageType = null;
      state.billingType = null;
      state.subscriptionEndTime = null;
    },
    removeSession: () => {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('firstName');
      window.localStorage.removeItem('lastName');
      window.localStorage.removeItem('email');
      window.localStorage.removeItem('lang');
      window.localStorage.removeItem('profileColor');
      window.localStorage.removeItem('tellNumber');
      window.localStorage.removeItem('isFtiFinished');
      window.localStorage.clear();
    },
    setCompanyId: (state, action: PayloadAction<number | null>) => {
      state.companyId = action.payload;
    },
    setUserMoreData: (state, action: PayloadAction<LoggedUserReducer>) => {
      state.lang = action.payload.lang;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.profileColor = action.payload.profileColor;
      state.tellNumber = action.payload.tellNumber;
      state.isFtiFinished = action.payload.isFtiFinished;
    },
    setSubscription: (
      state,
      action: PayloadAction<{
        validSubscription: boolean;
        packageType: number | null;
        billingType: number | null;
        subscriptionEndTime: number | null;
      }>,
    ) => {
      state.validSubscription = action.payload.validSubscription;
      if (action.payload.packageType !== -1) {
        state.packageType = action.payload.packageType;
      }
      if (action.payload.billingType !== -1) {
        state.billingType = action.payload.billingType;
      }
      if (action.payload.subscriptionEndTime !== -1) {
        state.subscriptionEndTime = action.payload.subscriptionEndTime;
      }
    },
  },
});

export default loggedUserReducer.reducer;
export const { userLogin, reset, removeSession, setCompanyId, setUserMoreData, setSubscription } =
  loggedUserReducer.actions;
export type { LoggedUserReducer };

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useWorkTypeRepositoryImplementation } from '../../../data/worktype/WorkTypeRepositoryImplementation';
import { useWorktypesViewModel } from './WorktypesViewModel';
import { WorkType } from '../../../domain/worktype/WorkType';
import { WorktypeRowHeader } from './components/WorktypeRowHeader';
import { WorktypeRow } from './components/WorktypeRow';
import EmptyView from '../../components/generic/EmptyView';
import { useCompany } from '../../hooks/useCompany';
import ToggleHeader from '../../components/generic/ToggleHeader';
import DesktopHeader from '../../components/generic/DesktopHeader';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import ContentLayout from '../../components/layout/ContentLayout';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';

function WorkTypes() {
  const navigate = useNavigate();
  const translate = useTranslate();
  const currentCompany = useCompany();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const repo = useWorkTypeRepositoryImplementation();
  const { getSortWorkTypes, updateWorktypes } = useWorktypesViewModel(repo);

  const workTypes = getSortWorkTypes();

  const handleDragDrop = (results) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;
    if (type === 'group') {
      const reorderedWorkTypes = workTypes;
      const workTypeSourceIndex = source.index;
      const workTypeDestinatonIndex = destination.index;
      const [removedWorkTypes] = reorderedWorkTypes!.splice(workTypeSourceIndex, 1);
      reorderedWorkTypes!.splice(workTypeDestinatonIndex, 0, removedWorkTypes);
      sortWorktypeList(reorderedWorkTypes!);
      updateWorktypes(reorderedWorkTypes!, true);
    }
  };

  function sortWorktypeList(workTypeList: WorkType[]) {
    return workTypeList.map((workType, index) => {
      workType.order = index;
    });
  }

  const handleCheckbox = (workType: WorkType) => {
    updateWorktypes([workType], false);
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.WORKTYPES)}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.WORKTYPES)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <WorktypeRowHeader />
        {workTypes !== undefined && workTypes.length > 0 ? (
          <DragDropContext onDragEnd={handleDragDrop}>
            <Droppable droppableId='ROOT' type='group'>
              {(provided) => (
                <DroppableProvide {...provided.droppableProps} ref={provided.innerRef}>
                  <ContentBodyList
                    workTypeList={workTypes}
                    onClick={(id) =>
                      navigate(
                        `/${currentCompany.companyId}/settings/worktype/` + id + '/extensions',
                      )
                    }
                    handleCheckbox={handleCheckbox}
                  />
                  {provided.placeholder}
                </DroppableProvide>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <EmptyView allHeightOtherItems={140} />
        )}
      </ContentLayout>
    </>
  );
}

function ContentBodyList(props: {
  workTypeList: WorkType[] | undefined;
  onClick: (id: number | undefined) => void;
  handleCheckbox: (workType: WorkType) => void;
}) {
  return (
    <ContentBodyListStyled>
      {buildWorkTypeList(
        props.workTypeList?.filter((value) => {
          return value;
        }),
        props.onClick,
        props.handleCheckbox,
      )}
    </ContentBodyListStyled>
  );
}

function buildWorkTypeList(
  workTypeList: WorkType[] | undefined,
  onClick: (id: number | undefined) => void,
  handleCheckbox: (workType: WorkType) => void,
) {
  const workTypeView: any[] = [];
  workTypeList?.map(function (c, i) {
    workTypeView.push(
      <WorktypeRow
        workTypeModel={c}
        index={i}
        onClick={() => onClick(c.id)}
        handleCheckbox={handleCheckbox}
        key={c.id}
      />,
    );
  });
  return workTypeView;
}

const ContentBodyListStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const DroppableProvide = styled.div``;

export default WorkTypes;

export const DeviceSizes = {
  desktop: 1440,
  tablet_large: 1439,
  tablet_small: 1215,
  mobile: 768,
  mobile_small: 360,
};

export type DeviceSizeProps = {
  desktop: number;
  tablet_large: number;
  tablet_small: number;
  mobile: number;
  mobile_small: number;
};

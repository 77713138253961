import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as LinkIcon } from '../../icons/link.svg';

type E_facturaAccountantProps = {
  companyId: number;
  generateActivtionToken: () => Promise<string | null>;
  onClose: () => void;
};

const E_facturaAccountantModal = (props: E_facturaAccountantProps) => {
  const translate = useTranslate();
  const { companyId, generateActivtionToken, onClose } = props;

  const [link, setLink] = useState<string>(window.location.origin + '/efactura/');

  useEffect(() => {
    generateActivtionToken().then((resp) => {
      if (resp !== null) {
        const encoded = btoa(`?id=${companyId}&activationToken=${resp}`);
        setLink(link + encoded);
      }
    });
  }, []);
  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.AUTHORIZATION_ACCOUNTABLE_TITLE)}
      onClose={onClose}
      containerStyle={{ width: '558px' }}
      header={true}
      isScroll={false}
    >
      <Text type={TextType.BODY_REGULAR}>
        {translate(LANGUAGE_KEYS.AUTHORIZATION_ACCOUNTABLE_DESCRIPTION_1)}
      </Text>

      <CustumInput value={link} type='text' readOnly={true} />

      <ButtonsConatiner>
        <Actions>
          <Action
            onClick={() => {
              navigator.clipboard.writeText(link);
            }}
          >
            <LinkIcon width={24} height={24} />
            <Text type={TextType.BODY_REGULAR} clr={TextColor.PRIMARY}>
              {translate(LANGUAGE_KEYS.COPY_LINK)}
            </Text>
          </Action>
          <ActionLink href={`mailto:`}>
            <EmailIcon width={24} height={24} />
            <Text type={TextType.BODY_REGULAR} clr={TextColor.PRIMARY}>
              {translate(LANGUAGE_KEYS.SEND_EMAIL)}
            </Text>
          </ActionLink>
        </Actions>
        <Button
          textType={TextType.CAPTION_BOLD}
          onClick={() => {
            onClose();
          }}
        >
          {translate(LANGUAGE_KEYS.CLOSE)}
        </Button>
      </ButtonsConatiner>
    </BaseDialog>
  );
};

const ButtonsConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  .button {
    align-self: flex-end;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const Action = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  svg {
    fill: var(--dts_default_blue);
  }
  &:hover {
    opacity: 0.9;
  }
`;

const ActionLink = styled.a`
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
  text-decoration: none;
  svg {
    fill: var(--dts_default_blue);
  }
  &:hover {
    opacity: 0.9;
  }
`;

const CustumInput = styled.input`
  padding: 16px;
  border: 1px solid var(--dts_dark_grey);
  border-radius: 2px;
  background: var(--dts_withe_background);
`;

export default E_facturaAccountantModal;

import { ReportRepository } from '../../../domain/reports/ReportRepository';
import { SolvedPayOff } from '../../../domain/reports/PayOff';

type AccountPhaseUseCase = Pick<ReportRepository, 'accountPhases'>;

const accountPhaseUseCase = (repo: AccountPhaseUseCase, param: SolvedPayOff) => {
  return repo.accountPhases(param);
};

export { accountPhaseUseCase };

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../../../components/dsm/Text';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import useTranslate from '../../../translations/useTranslate';

function PhaseRowHeader() {
  const translate = useTranslate();
  return (
    <PhaseHeaderRowContainer>
      <FirstContainer>
        <IconComponent></IconComponent>
        <NameComponent>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PHASE_NAME)}</Text>
        </NameComponent>
      </FirstContainer>
      <SecondContainer></SecondContainer>
      <ThirdContainer></ThirdContainer>
    </PhaseHeaderRowContainer>
  );
}

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const PhaseHeaderRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  gap: 4px;
  margin: 24px 0px 8px 0px;
  justify-content: space-between;
`;

const FirstContainer = styled.div`
  flex-basis: 63%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SecondContainer = styled.div`
  display: flex;
  flex-basis: 25%;
  gap: 24px;
`;
const ThirdContainer = styled.div`
  flex-basis: 10%;
  width: 16px;
`;

const IconComponent = styled.div`
  width: 16px;
`;

const NameComponent = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-grow: 1;
`;

export default PhaseRowHeader;

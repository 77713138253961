import React, { useState } from 'react';

import { ReportRepository } from '../../../../domain/reports/ReportRepository';
import { InvoiceRepository } from '../../../../domain/invoice/InvoiceRepository';
import { ReportCasesByIds } from '../../../../domain/reports/Report';
import { getReportsCasesByIdsUseCase } from '../../../../useCases/reports/expense/getReportsCasesByIdsUseCase';
import { CompaniesRepository } from '../../../../domain/user-management/UsersRepository';
import { WorkTypeRepository } from '../../../../domain/worktype/WorkTypeRepository';
import { getCompanyNameUseCase } from '../../../../useCases/user-management/getCompaniesUseCase';
import { updateInvoiceUseCase } from '../../../../useCases/invoice/updateInvoiceUseCase';
import {
  ExpenseUiModel,
  ExpenseCaseUi,
  ExpenseLabwork,
} from '../../../ui-model/reports/ExpenseUiModel';

const useExpenseViewModel = (
  repo: ReportRepository,
  companiesRepo: CompaniesRepository,
  workTypeRepo: WorkTypeRepository,
  invoiceRepo: InvoiceRepository,
) => {
  const [expenseList, setExpenseList] = useState<Array<ExpenseUiModel>>([]);

  const getReportCasesByIds = React.useCallback(
    (params) => {
      getReportsCasesByIdsUseCase(repo, params).then((res) => {
        if (res.statusCode === 200) {
          setExpenseList(setExpenseUiModelList(res.data));
        }
      });
    },
    [
      workTypeRepo.getWorkTypes,
      workTypeRepo.workTypes,
      companiesRepo.getCompanies,
      companiesRepo.companies,
    ],
  );

  const generateInvoice = React.useCallback(
    (params: { companyId: number; caseIds: Map<number, number>; status: number }) => {
      return updateInvoiceUseCase(invoiceRepo, {
        companyId: params.companyId,
        caseIds:
          params.caseIds !== undefined ? Object.fromEntries(params.caseIds.entries()) : undefined,
        status: params.status,
        invoiceDate: new Date().getTime(),
      });
    },
    [invoiceRepo.updateInvoice],
  );

  const getCabinetGroups = (list) => {
    return list?.reduce((groupList, item: ReportCasesByIds) => {
      if (!groupList[item.clientCompanyId]) groupList[item.clientCompanyId] = [];
      groupList[item.clientCompanyId].push(item);
      return groupList;
    }, {});
  };

  const getTeethExtentions = (teethList): Map<number, { size: number; name: string }> => {
    const labworkExtras = new Map<number, { size: number; name: string }>();
    teethList.forEach((teeth) => {
      teeth.extensionList.forEach((extension) => {
        if (labworkExtras.has(extension.id)) {
          labworkExtras.set(extension.id, {
            size: labworkExtras.get(extension.id)!.size + 1,
            name: labworkExtras.get(extension.id)!.name,
          });
        } else {
          labworkExtras.set(extension.id, { size: 1, name: extension.name });
        }
      });
    });
    return labworkExtras;
  };

  const getExpenseCasesLabwork = (
    list,
  ): { expenseLabworks: Array<ExpenseLabwork>; labworkPriceSum: number } => {
    let labworkPriceSum = 0.0;
    const expenseLabworks = list.map((item) => {
      labworkPriceSum += item.price;
      const subCat =
        item.extensionList?.find((e) => e.groupType === 1) !== undefined
          ? `, ${item.extensionList?.find((e) => e.groupType === 1).name}`
          : ``;
      const title = workTypeRepo.workTypes?.find((w) => w.id === item.workType)?.name + subCat;
      const labworkExtras = getTeethExtentions(item.teethList);
      item.extensionList
        ?.filter((e) => e.groupType !== 1)
        .forEach((e) => {
          labworkExtras.set(e.id, { size: 1, name: e.name });
        });
      let subTitle = '';
      labworkExtras.forEach((value) => {
        const num = value.size > 1 ? `${value.size}x` : '';
        if (subTitle == '') {
          subTitle = `${num} ${value.name}`;
        } else {
          subTitle = `${subTitle}, ${num} ${value.name}`;
        }
      });
      const teethsFirstPart = item.teethList
        .filter((t) => t.id >= 11 && t.id <= 18)
        .map((teeth) => {
          return { id: teeth.id, connection: teeth.connection };
        })
        .sort((a, b) => (a.id > b.id ? -1 : 1));
      const teethsSecondPart = item.teethList
        .filter((t) => t.id >= 21 && t.id <= 28)
        .map((teeth) => {
          return { id: teeth.id, connection: teeth.connection };
        })
        .sort((a, b) => (a.id < b.id ? -1 : 1));
      const teethsThirdPart = item.teethList
        .filter((t) => t.id >= 41 && t.id <= 48)
        .map((teeth) => {
          return { id: teeth.id, connection: teeth.connection };
        })
        .sort((a, b) => (a.id > b.id ? -1 : 1));
      const teethsFourthPart = item.teethList
        .filter((t) => t.id >= 31 && t.id <= 38)
        .map((teeth) => {
          return { id: teeth.id, connection: teeth.connection };
        })
        .sort((a, b) => (a.id < b.id ? -1 : 1));
      const teeths = [
        ...teethsFirstPart,
        ...teethsSecondPart,
        ...teethsThirdPart,
        ...teethsFourthPart,
      ];
      return {
        labworkId: item.labWorkId,
        price: item.price,
        title: title,
        subTitle: subTitle,
        teeths: teeths,
      };
    });
    return { expenseLabworks: expenseLabworks, labworkPriceSum: labworkPriceSum };
  };

  function setExpenseUiModelList(list) {
    const expenseUiModelList: Array<ExpenseUiModel> = [];

    const groupsList = getCabinetGroups(list);
    if (groupsList != undefined) {
      Object.keys(groupsList).forEach((key) => {
        const expenseCases: Array<ExpenseCaseUi> = [];
        let allCasePrice = 0;
        groupsList[key].forEach((c) => {
          const labworkDetails = getExpenseCasesLabwork(c.labWorks);
          if (c.invoice === null) {
            allCasePrice += labworkDetails.labworkPriceSum;
          }
          expenseCases.push({
            clientName: c.clientName,
            patientName: c.patientName,
            caseId: c.id,
            labworks: labworkDetails.expenseLabworks,
            caseStatus: c.status,
            invoice: c.invoice,
            allLabworkPrice: labworkDetails.labworkPriceSum,
            isSelected: c.invoice !== null,
          });
        });
        expenseUiModelList.push({
          clientCompanyId: Number(key),
          companyName: getCompanyNameUseCase(Number(key), {
            getCompanies: companiesRepo.getCompanies,
            companies: companiesRepo.companies,
            getMyCompanyData: companiesRepo.getMyCompanyData,
          }),
          expenseCases: expenseCases,
          allCasePrice: allCasePrice,
        });
      });
    }
    return expenseUiModelList;
  }

  return {
    expenseList: expenseList,
    getReportCasesByIds,
    generateInvoice,
  };
};

export { useExpenseViewModel };

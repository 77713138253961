export const INSERT_PHASE_TO_POSITION = 1;
export const DELETE_PHASE = 2;
export const UPDATE_ALL_PHASE = 3;
export const ADD_LAB_WORK = 5;
export const PATIENT = 6;
export const CLIENT = 7;
export const UPDATE_TOOTH = 8;
export const ADD_LAB_WORK_EXTENSIONS = 9;
export const ADD_TOOTH_EXTENSIONS = 10;
export const REMOVE_LAB_WORK = 11;
export const UPDATE_STATUS = 12;
export const UPDATE_PHASE_INFO = 13;
export const UPDATE_DESCRIPTION = 14;

import styled, { css } from 'styled-components';

export enum TextType {
  TITLE_BOLD = 'title-bold',
  TITLE_BOLD_4 = 'title-bold-4',
  TITLE_REGULAR_4 = 'title-regular-4',
  TITLE_BOLD_1 = 'title-bold-1',
  TITLE_BOLD_2 = 'title-bold-2',
  TITLE_BOLD_3 = 'title-bold-3',
  TITLE_BOLD_5 = 'title-bold-5',
  BODY_BOLD = 'body-bold',
  BODY_2 = 'body-2',
  BODY_2_BOLD = 'body-2-bold',
  BODY_REGULAR = 'body-regular',
  CAPTION_BOLD = 'caption-bold',
  CAPTION_2_BOLD = 'caption-2-bold',
  CAPTION_2_BOLD_12 = 'caption-2-bold-12',
  CAPTION_3_BOLD = 'caption-3-bold',
  CAPTION_REGULAR = 'caption-regular',
  CAPTION_REGULAR_2 = 'caption-regular_1',
  CAPTION_REGULAR_3 = 'caption-regular_3',
  ROBOTO_SMALL_7 = 'roboto_small_7',
  ROBOTO_SMALL_8 = 'roboto_small_8',
  ROBOTO_500_10_16 = 'roboto_500_10_16',
  ROBOTO_400_10_14 = 'roboto_400_10_14',
  ROBOTO_400_80_94 = 'roboto_400_80_94',
  ROBOTO_400_30_34 = 'roboto_400_30_34',
  ROBOTO_SMALL_8_BOLD = 'roboto_small_8_bold',
  MENU_VERSION = 'menu_version',
  MENU_DEFAULT = 'menu_default',
}

export enum TextColor {
  WHITE = 0,
  PRIMARY = 1,
  BLACK = 2,
}

type Props = {
  type: TextType;
  clr?: TextColor;
  ellipsis?: boolean;
  disableUserSelect?: boolean;
};

export const Text = styled.p<Props>`
  font-style: normal;
  margin: auto 0;
  color: var(--dts_black);

  ${({ type }) =>
    type === TextType.TITLE_BOLD_1 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
    `}

  ${({ type }) =>
    type === TextType.TITLE_BOLD_2 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    `}
          
  ${({ type }) =>
    type === TextType.TITLE_BOLD_3 &&
    css`
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    `}

  ${({ type }) =>
    type === TextType.TITLE_BOLD_4 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    `}

    ${({ type }) =>
    type === TextType.TITLE_REGULAR_4 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    `}

    ${({ type }) =>
    type === TextType.TITLE_BOLD_5 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 36px;
      line-height: 32px;
    `}

  ${({ type }) =>
    type === TextType.BODY_REGULAR &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    `}

  ${({ type }) =>
    type === TextType.BODY_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    `}  
  
  ${({ type }) =>
    type === TextType.BODY_2 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    `}    
  
  ${({ type }) =>
    type === TextType.BODY_2_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    `}  
          
  ${({ type }) =>
    type === TextType.TITLE_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 47px;
    `}

  ${({ type }) =>
    type === TextType.CAPTION_REGULAR &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    `}

  ${({ type }) =>
    type === TextType.CAPTION_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    `}
          
    ${({ type }) =>
    type === TextType.CAPTION_2_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
    `}
  ${({ type }) =>
    type === TextType.CAPTION_3_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 8px;
      line-height: 12px;
    `}
  
    ${({ type }) =>
    type === TextType.CAPTION_2_BOLD_12 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
    `}

  ${({ type }) =>
    type === TextType.CAPTION_REGULAR_2 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    `}  
  ${({ type }) =>
    type === TextType.CAPTION_REGULAR_3 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    `}
          
  ${({ type }) =>
    type === TextType.ROBOTO_500_10_16 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    `}

  ${({ type }) =>
    type === TextType.ROBOTO_400_10_14 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    `}
  ${({ type }) =>
    type === TextType.ROBOTO_400_80_94 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 80px;
      line-height: 94px;
    `}
    ${({ type }) =>
    type === TextType.ROBOTO_400_30_34 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 30px;
      line-height: 34px;
    `}
  ${({ type }) =>
    type === TextType.ROBOTO_SMALL_7 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 7px;
      line-height: 8.2px;
    `}
    ${({ type }) =>
    type === TextType.ROBOTO_SMALL_8 &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 8px;
      line-height: 9px;
    `}

  ${({ type }) =>
    type === TextType.ROBOTO_SMALL_8_BOLD &&
    css`
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 7px;
      line-height: 8.2px;
    `}
       
  ${({ type }) =>
    type === TextType.MENU_DEFAULT &&
    css`
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--dts_black);
    `}
          
  ${({ type }) =>
    type === TextType.MENU_VERSION &&
    css`
      font-weight: 400;
      font-size: 8px;
      line-height: 18px;
      margin: 0;
    `}
                    
  ${({ clr }) =>
    clr === TextColor.WHITE &&
    css`
      color: var(--dts_white);
    `}

  ${({ clr }) =>
    clr === TextColor.PRIMARY &&
    css`
      color: var(--dts_default_blue);
    `}
  ${({ clr }) =>
    clr === TextColor.BLACK &&
    css`
      color: var(--dts_black);
    `}
  
  ${({ ellipsis }) =>
    ellipsis === true &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}  
  
  ${({ disableUserSelect }) =>
    disableUserSelect === true &&
    css`
      user-select: none;
    `}
`;

import { BASE_URL } from '../Server';
import { Lobby } from '../../domain/lobby/Lobby';
import { useApiHeaderWithoutCompanyId } from '../../presentation/hooks/useApiHeaderWithoutCompanyId';

function getHallCompanies(id: number): Promise<Lobby> {
  return fetch(BASE_URL + `/getHallCompanies/${id}`, {
    method: 'GET',
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: [] };
      }
      return res;
    })
    .catch((error) => {
      console.log('catchy getHallCompanies: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

export { getHallCompanies };

import React from 'react';
import { ContactsRepository } from '../../domain/user-management/UsersRepository';
import { getErrorStatusUseCase } from '../../useCases/getErrorStatusUseCase';
import { getWorkersUseCase } from '../../useCases/user-management/getContactsUseCase';
import { getPhasesValueUseCase } from '../../useCases/worktype/phases/getPhasesUseCase';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { FeatureId } from '../../domain/features/Features';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';
import { mapPhaseUiModelsFromWorkPhaseGroups } from '../ui-model/PhaseUiModel';
import { PhaseRepository } from '../../domain/PhaseRepository';

function useCaseOverViewViewModel(
  repo: ContactsRepository,
  featuresRepo: FeaturesRepository,
  phasesRepo: PhaseRepository,
) {
  const assignMemberToPhase = React.useCallback(function () {}, []);

  const updatePhaseStatus = React.useCallback(function () {}, []);

  const isEnabledAssignePhaseAction = (userRole: number) => {
    return getFeatureByIdUseCase(featuresRepo, userRole, FeatureId.ASSIGNE_PHASES_ACTION);
  };

  const isEnabledChangeDetailDescriptionFeatureAction = (userRole: number) => {
    return getFeatureByIdUseCase(
      featuresRepo,
      userRole,
      FeatureId.CHANGE_DETAIL_DESCRIPTION_ACTION,
    );
  };

  return {
    workers: getWorkersUiModel(),
    phases: mapPhaseUiModelsFromWorkPhaseGroups(getPhases(), true),
    dropDownWorkers: getWorkersForSelect(),
    errorStatuses: getErrorStatusUseCase(),
    assignMemberToPhase,
    updatePhaseStatus,
    getPhases,
    isEnabledAssignePhaseAction,
    isEnabledChangeDetailDescriptionFeatureAction,
  };

  function getWorkersForSelect(): { value: number; label: string }[] {
    const workers = getWorkersUseCase(repo);
    if (workers !== undefined) {
      return workers?.map((template) => {
        const id = template.id ? template.id : -1;
        return { value: id, label: template.name };
      });
    }
    return [];
  }

  function getWorkersUiModel() {
    return getWorkersUseCase(repo);
  }

  function getPhases() {
    return getPhasesValueUseCase(phasesRepo);
  }
}

export { useCaseOverViewViewModel };

import { BASE_URL } from '../Server';
import { Credentials, AuthenticateResponse } from '../../domain/signUpFlow/LoginUserData';
import { CreateAccountData } from '../../domain/signUpFlow/CreateAccountData';
import { PasswordData, SetPasswordData } from '../../domain/signUpFlow/SetPasswordData';
import { UpdateUserData, UpdateUserResponse } from '../../domain/signUpFlow/UpdateUserData';
import { useApiHeaderWithoutCompanyId } from '../../presentation/hooks/useApiHeaderWithoutCompanyId';

function authenticateUser(c: Credentials): Promise<AuthenticateResponse> {
  return fetch(BASE_URL + '/login', {
    method: 'POST',
    body: JSON.stringify(c),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        code: res.status !== undefined ? res.status : 200,
        toke: res.toke,
        userId: res.userId,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phoneNumber: res.phoneNumber,
        language: res.language,
        profileColor: res.profileColor,
        isFtiFinished: res.isFtiFinished,
      };
    })
    .catch((error) => {
      console.log('catchy authenticateUser: ' + error);
      return {
        code: error.status,
        toke: '',
        userId: -1,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        language: '',
        profileColor: '',
        isFtiFinished: false,
      };
    });
}

function addUser(createAccount: {
  email: string;
  isEnabledMailList: boolean;
}): Promise<CreateAccountData> {
  return fetch(BASE_URL + '/addUser', {
    method: 'POST',
    body: JSON.stringify(createAccount),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy addUser: ' + error);
      return { code: error.status, message: 'Something went wrong', data: null };
    });
}

function setPassword(password: PasswordData): Promise<AuthenticateResponse> {
  return fetch(BASE_URL + '/updatePassword', {
    method: 'POST',
    body: JSON.stringify(password),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return {
        code: res.status !== undefined ? res.status : 200,
        toke: res.toke,
        userId: res.userId,
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        phoneNumber: res.phoneNumber,
        language: res.language,
        profileColor: res.profileColor,
        isFtiFinished: res.isFtiFinished,
      };
    })
    .catch((error) => {
      console.log('catchy setPassword: ' + error);
      return {
        code: error.status,
        toke: '',
        userId: -1,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        language: '',
        profileColor: '',
        isFtiFinished: false,
      };
    });
}

function resetPassword(requestPassword: { email: string }): Promise<SetPasswordData> {
  return fetch(BASE_URL + '/requestResetPassword', {
    method: 'POST',
    body: JSON.stringify(requestPassword),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy resetPassword: ' + error);
      return { code: error.status, message: 'Something went wrong', data: null };
    });
}

function updateUser(data: UpdateUserData): Promise<UpdateUserResponse> {
  return fetch(BASE_URL + '/updateUser', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => {
      if (!res.ok) throw new Error(res.statusText);
      else return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateUser: ' + error);
      return { code: error.status, message: 'Something went wrong', data: null };
    });
}

export { authenticateUser, addUser, setPassword, resetPassword, updateUser };

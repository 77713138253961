import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import Spinner from '../components/dsm/Spinner';

type E_facturaLoadingProps = {
  onClose: () => void;
};

const E_facturaLoadingModal = (props: E_facturaLoadingProps) => {
  const { onClose } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={''}
      onClose={onClose}
      containerStyle={{ width: '558px' }}
      header={true}
      isScroll={false}
    >
      <Container>
        <Spinner
          width={64}
          height={64}
          beforeHeight={54}
          beforeWidth={54}
          background={'var(--dts_white)'}
        />
      </Container>
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 36px;
`;

export default E_facturaLoadingModal;

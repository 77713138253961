import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';
import {
  getInvoicesAction,
  getInvoiceByIdAction,
  updateInvoiceAction,
  checkInvoiceNumberAction,
  deleteInvoiceAction,
} from './invoiceActions';
import { AppRootStore } from '../appRepositoryImplementation';
import { InvoiceRepositoryState } from './invoiceReducer';
import { InvoiceUpdate } from '../../domain/invoice/Invoice';

const invoiceSelector = (state: AppRootStore) => state.invoice;

const useInvoiceRepositoryImplementation = (): InvoiceRepository => {
  const { invoices, isLoading, isUpdating } = useSelector<AppRootStore, InvoiceRepositoryState>(
    invoiceSelector,
  );

  const dispatch = useDispatch();

  const getInvoices = React.useCallback(() => getInvoicesAction()(dispatch), [dispatch]);

  const getInvoiceById = React.useCallback(
    (id: number) => getInvoiceByIdAction(id)(dispatch),
    [dispatch],
  );

  const updateInvoice = React.useCallback(
    (param: InvoiceUpdate) => updateInvoiceAction(param)(dispatch),
    [dispatch],
  );

  const deleteInvoice = React.useCallback(
    (id: number) => deleteInvoiceAction(id)(dispatch),
    [dispatch],
  );

  const checkInvoiceNumber = React.useCallback(
    (invoicePrefix: string, invoiceNumber: number) =>
      checkInvoiceNumberAction(invoicePrefix, invoiceNumber)(dispatch),
    [dispatch],
  );

  return {
    invoices,
    isLoading,
    isUpdating,
    getInvoices,
    getInvoiceById,
    updateInvoice,
    checkInvoiceNumber,
    deleteInvoice,
  };
};

export { useInvoiceRepositoryImplementation };

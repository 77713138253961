import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';

type logoutUserUseCasesRepository = Pick<SignUpFlowRepository, 'logoutUser'>;

const logoutUserUseCases = (repo: logoutUserUseCasesRepository) => {
  return repo.logoutUser();
};

export { logoutUserUseCases };
export type { logoutUserUseCasesRepository };

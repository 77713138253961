import Column from '../../components/layout/Column';
import { Text, TextType } from '../../components/dsm/Text';
import React from 'react';
import styled from 'styled-components';
import Container from '../../components/layout/Container';
import { ReactComponent as NotFoundIcon } from '../../../icons/notFound.svg';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';

const NotFoundContainer = () => {
  const translate = useTranslate();
  return (
    <ContainerStyled orientation='Vertical'>
      <NotFoundIcon />
      <Column style={{ alignItems: 'center' }}>
        <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.FILTER_NO_RESULT_TITLE)}</Text>
        <Text type={TextType.BODY_REGULAR}>
          {translate(LANGUAGE_KEYS.FILTER_NO_RESULT_DESCRIPTION)}
        </Text>
      </Column>
    </ContainerStyled>
  );
};

const ContainerStyled = styled(Container)`
  height: 100%;
  background-color: var(--dts_white);
  gap: 36px;
  padding: 36px;
  align-items: center;
  justify-content: center;
`;

export default NotFoundContainer;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { RoundButton, RoundButtonState } from '../components/dsm/buttons/RoundButton';
import { TextType } from '../components/dsm/Text';

import AddContactDialog from './AddContactDialog';
import AddCompanyDialog from './AddCompanyDialog';
import { UserManagementContent } from './UserManagementContent';
import {
  useContactsRepositoryImplementation,
  useCompaniesRepositoryImplementation,
} from '../../data/user-management/UsersRepositoryImplementation';
import { userManagementViewModel } from './UserManagementViewModel';
import { useCompany } from '../hooks/useCompany';
import { WORKER } from '../../domain/user-management/UserStatus';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ContentLayout from '../components/layout/ContentLayout';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useCountiesRepositoryImplementation } from '../../data/county/countiesRepositoryImplementation';

const CLIENTS_CONTACTS = 'clients';
const WORKERS_CONTACTS = 'workers';
const COMPANIES_CONTACTS = 'companies';

type AddNewContactDialog = OpenAddNewContactDialog | ClosedAddNewContactDialog;

type AddNewCompanyDialog = OpenAddNewCompanyDialog | ClosedAddNewCompanyDialog;

type OpenAddNewContactDialog = {
  kind: 'Open';
  addContact: (newContact) => void;
  onClose: () => void;
};

type ClosedAddNewContactDialog = { kind: 'Closed' };

type OpenAddNewCompanyDialog = {
  kind: 'Open';
  addCompany: (newContact) => void;
  onClose: () => void;
};

type ClosedAddNewCompanyDialog = { kind: 'Closed' };

function UserManagement() {
  let { id } = useParams();
  id = id === undefined || Number(id) > 0 ? COMPANIES_CONTACTS : id;
  const navigate = useNavigate();
  const translate = useTranslate();
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const [itemModel, setItemModel] = useState<any>(undefined);
  const [isWorker, setIsWorker] = useState<boolean>(false);
  const [editInvite, setEditInvite] = useState<boolean>(false);

  const currentCompany = useCompany();

  const contactRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const countiesRepo = useCountiesRepositoryImplementation();
  const {
    userId,
    getWorkersUiModel,
    getClientsUiModel,
    getCompaniesUiModel,
    getUsersRoles,
    newContact,
    updateWorker,
    sendWorkerInvitation,
    newCompany,
    updateCompany,
    deleteContact,
    deleteCompany,
    getCountiesWithId,
  } = userManagementViewModel(contactRepo, companiesRepo, loggedUserRepo, countiesRepo);
  const clients = getClientsUiModel().sort((a, b) => {
    if (a.name !== null && b.name !== null) {
      return a.name.localeCompare(b.name);
    }
    return -1;
  });
  const workers = getWorkersUiModel().sort((a, b) => {
    if (a.name !== null && b.name !== null) {
      return a.name.localeCompare(b.name);
    }
    return -1;
  });
  const companies = getCompaniesUiModel().sort((a, b) => {
    if (a.name !== null && b.name !== null) {
      return a.name.localeCompare(b.name);
    }
    return -1;
  });

  const userRoles = getUsersRoles().map((ur) => {
    ur.name = translate(ur.name);
    return ur;
  });

  function WorktypesContentBodyHeader() {
    return (
      <>
        <ContentBodyHeader>
          <ButtonsContainer>
            <RoundButton
              onClick={() => {
                navigate(`/${currentCompany.companyId}/settings/users/` + COMPANIES_CONTACTS);
              }}
              indicator={false}
              buttonState={
                COMPANIES_CONTACTS === id ? RoundButtonState.ACTIVE : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.COMPANIES)}
            </RoundButton>
            <RoundButton
              onClick={() => {
                navigate(`/${currentCompany.companyId}/settings/users/` + CLIENTS_CONTACTS);
              }}
              indicator={false}
              buttonState={
                CLIENTS_CONTACTS === id ? RoundButtonState.ACTIVE : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.CLIENTS)}
            </RoundButton>
            <RoundButton
              onClick={() => {
                navigate(`/${currentCompany.companyId}/settings/users/` + WORKERS_CONTACTS);
              }}
              indicator={false}
              buttonState={
                WORKERS_CONTACTS === id ? RoundButtonState.ACTIVE : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.WORKERS)}
            </RoundButton>
          </ButtonsContainer>
        </ContentBodyHeader>
      </>
    );
  }

  const [addNewContactDialog, setAddNewContactDialog] = useState<AddNewContactDialog>({
    kind: 'Closed',
  });

  const closeAddNewContactDialog = () => {
    setItemModel(undefined);
    setAddNewContactDialog({
      kind: 'Closed',
    });
  };

  const openAddNewContactDialog = (itemModel: any, isWorker: boolean, editInvite: boolean) => {
    setItemModel(itemModel);
    setIsWorker(isWorker);
    setEditInvite(editInvite);
    setAddNewContactDialog({
      kind: 'Open',
      addContact: onAddContact,
      onClose: closeAddNewContactDialog,
    });
  };

  const [addNewCompanyDialog, setAddNewCompanyDialog] = useState<AddNewCompanyDialog>({
    kind: 'Closed',
  });

  const closeAddNewCompanyDialog = () => {
    setItemModel(undefined);
    setAddNewCompanyDialog({
      kind: 'Closed',
    });
  };

  const openAddNewCompanyDialog = (itemModel: any) => {
    setItemModel(itemModel);
    setAddNewCompanyDialog({
      kind: 'Open',
      addCompany: itemModel !== undefined ? onUpdateCompany : onAddCompany,
      onClose: closeAddNewCompanyDialog,
    });
  };

  const onAddContact = (data) => {
    if (data.type === WORKER) {
      updateWorker(data);
    } else {
      newContact(data);
    }
  };

  const onSendEmail = (data) => {
    sendWorkerInvitation(data);
  };

  const onAddCompany = (newCompanyItem) => {
    newCompany(newCompanyItem);
  };

  const onUpdateCompany = (updateCompanyItem) => {
    updateCompany(updateCompanyItem);
  };

  const onDelete = (id: number, isContact: boolean) => {
    if (isContact) {
      deleteContact(id);
    } else {
      deleteCompany(id);
    }
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.COLLABORATORS)}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.COLLABORATORS)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <WorktypesContentBodyHeader />
        {COMPANIES_CONTACTS === id && (
          <UserManagementContent
            isWorker={false}
            contacts={companies}
            addNewText={translate(LANGUAGE_KEYS.ADD_NEW_COMPANY)}
            currentUserId={userId !== null ? userId : -1}
            onDelete={onDelete}
            openAddNew={openAddNewCompanyDialog}
          />
        )}
        {CLIENTS_CONTACTS === id && (
          <UserManagementContent
            isWorker={false}
            contacts={clients}
            addNewText={translate(LANGUAGE_KEYS.ADD_NEW_CLIENT)}
            currentUserId={userId !== null ? userId : -1}
            onDelete={onDelete}
            openAddNew={openAddNewContactDialog}
          />
        )}
        {WORKERS_CONTACTS === id && (
          <UserManagementContent
            isWorker={true}
            contacts={workers}
            addNewText={translate(LANGUAGE_KEYS.ADD_NEW_WORKER)}
            currentUserId={userId !== null ? userId : -1}
            onDelete={onDelete}
            openAddNew={openAddNewContactDialog}
          />
        )}
      </ContentLayout>

      {addNewContactDialog.kind === 'Open' && (
        <AddContactDialog
          modalName={
            itemModel === undefined
              ? `${
                  isWorker
                    ? translate(LANGUAGE_KEYS.ADD_NEW_WORKER)
                    : translate(LANGUAGE_KEYS.ADD_NEW_CLIENT)
                }`
              : `${
                  isWorker
                    ? translate(LANGUAGE_KEYS.EDIT_WORKER_DATA)
                    : translate(LANGUAGE_KEYS.EDIT_CLIENT_DATA)
                }`
          }
          itemModel={itemModel}
          isWorker={isWorker}
          editInvite={editInvite}
          roles={userRoles.filter((role) => {
            if (
              itemModel !== undefined &&
              itemModel.roleId !== undefined &&
              itemModel.roleId !== null &&
              role.id === -1
            ) {
              return false;
            }
            return true;
          })}
          companies={companies.map((company) => {
            return { value: company.id, label: company.name };
          })}
          onAddContact={addNewContactDialog.addContact}
          onSendInviteEmail={onSendEmail}
          onClose={addNewContactDialog.onClose}
        />
      )}

      {addNewCompanyDialog.kind === 'Open' && (
        <AddCompanyDialog
          modalName={
            itemModel === undefined
              ? translate(LANGUAGE_KEYS.ADD_NEW_COMPANY)
              : translate(LANGUAGE_KEYS.EDIT_COMPANY_DATA)
          }
          itemModel={itemModel}
          counties={getCountiesWithId()}
          onAddCompany={addNewCompanyDialog.addCompany}
          onClose={addNewCompanyDialog.onClose}
        />
      )}
    </>
  );
}

const ContentBodyHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
`;

export default UserManagement;

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { ReactComponent as RightIcon } from '../../../icons/rightIcon.svg';
import { Button } from './buttons/Button';
import { ModalStyles } from '../generic/BaseDialog';
import Modal from 'react-modal';
import { Text, TextType } from './Text';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';

type DatePickerProps = {
  date?: Date;
  onCancel: () => void;
  onSave: (date: Date) => void;
  onTimeLabelClick?: () => void;
  isTime: boolean;
};

// TODO: Separate selected date and view date
const DatePicker = (props: DatePickerProps) => {
  const translate = useTranslate();
  const { date = new Date(), onCancel, onSave, onTimeLabelClick, isTime } = props;

  const [selectedDate, setSelectedDate] = useState<Date>(date);

  const handleSelect = (day: number) => {
    const mDate = new Date(selectedDate);
    mDate.setDate(day);
    setSelectedDate(mDate);
  };

  const jumpToPreviousMonth = () => {
    const mDate = new Date(selectedDate);
    mDate.setMonth(selectedDate.getMonth() - 1);
    if (selectedDate.getMonth() === mDate.getMonth()) {
      mDate.setMonth(mDate.getMonth());
      mDate.setDate(0);
    }
    setSelectedDate(mDate);
  };

  const jumpToNextMonth = () => {
    const mDate = new Date(selectedDate);
    mDate.setMonth(selectedDate.getMonth() + 1);
    if (selectedDate.getMonth() + 2 === mDate.getMonth()) {
      mDate.setMonth(mDate.getMonth());
      mDate.setDate(0);
    }
    setSelectedDate(mDate);
  };

  const getMondayIndex = (date: Date) => {
    const mDate = new Date(date);
    mDate.setDate(1);
    return mDate.getDay();
  };

  const getPreviousMonthDayCount = (date: Date) => {
    const mDate = new Date(date);
    mDate.setMonth(date.getMonth());
    mDate.setDate(0);
    return mDate.getDate();
  };

  const getCurrentMonthDayCount = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  const getDayList = useMemo(() => {
    const days: Array<{ day: number; selectable: boolean; active?: boolean }> = [];

    const mondayIndex = getMondayIndex(selectedDate);
    const previousMonthDayCount = getPreviousMonthDayCount(selectedDate);
    const currentMonthDayCount = getCurrentMonthDayCount(selectedDate);

    for (let i = 1; i <= mondayIndex; i++)
      days.push({ day: previousMonthDayCount - mondayIndex + i, selectable: false });
    for (let i = 1; i <= currentMonthDayCount; i++)
      days.push({ day: i, selectable: true, active: i === selectedDate.getDate() });
    for (let i = 1; i <= days.length % 7; i++) {
      days.push({ day: i, selectable: false });
    }

    return days;
  }, [selectedDate]);

  const label = useMemo(
    () => `${translate(mapMonth(selectedDate.getMonth()))} ${selectedDate.getFullYear()}`,
    [selectedDate],
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => onCancel()}
      shouldCloseOnEsc={true}
      style={ModalStyles}
    >
      <DateTimePickerContainer>
        <DateTimePickerHeader>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div onClick={jumpToPreviousMonth}>
              <LeftIcon />
            </div>
            <Text type={TextType.BODY_BOLD} style={{ padding: '0 24px' }}>
              {label}
            </Text>
            <div onClick={jumpToNextMonth}>
              <RightIcon />
            </div>
          </div>
          {isTime && (
            <DateTimePickerSecondaryLabel onClick={onTimeLabelClick}>
              {translate(LANGUAGE_KEYS.TIME)}
            </DateTimePickerSecondaryLabel>
          )}
        </DateTimePickerHeader>
        <CalendarHeader>
          <label>{translate(LANGUAGE_KEYS.SUN)}</label>
          <label>{translate(LANGUAGE_KEYS.MON)}</label>
          <label>{translate(LANGUAGE_KEYS.TUE)}</label>
          <label>{translate(LANGUAGE_KEYS.WED)}</label>
          <label>{translate(LANGUAGE_KEYS.THU)}</label>
          <label>{translate(LANGUAGE_KEYS.FRI)}</label>
          <label>{translate(LANGUAGE_KEYS.SAT)}</label>
        </CalendarHeader>
        <Calendar>
          {getDayList.map((day) => {
            return (
              <div
                className={`${day.selectable && 'selectable'} ${day.active && 'active'}`}
                key={day.day + Math.random()}
                onClick={() => handleSelect(day.day)}
              >
                {day.day}
              </div>
            );
          })}
        </Calendar>
        <DateTimePickerFooter>
          <Button onClick={onCancel} secondary={'secondary'}>
            {translate(LANGUAGE_KEYS.CANCEL)}
          </Button>
          <Button onClick={() => onSave(selectedDate)}>{translate(LANGUAGE_KEYS.SAVE)}</Button>
        </DateTimePickerFooter>
      </DateTimePickerContainer>
    </Modal>
  );
};

export const DateTimePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  user-select: none;
  width: calc(504px - 2 * 36px);
  padding: 36px;
`;

export const DateTimePickerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;

export const DateTimePickerSecondaryLabel = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: var(--dts_default_blue);
  cursor: pointer;
`;

const CalendarHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  label {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--dts_dark);
    opacity: 0.3;
  }
`;

const Calendar = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    color: var(--dts_dark);
    width: 60px;
    height: 60px;
    opacity: 0.3;

    &.selectable {
      opacity: 1;
      cursor: pointer;
    }

    &.active {
      background-color: rgba(24, 160, 251, 0.2);
      border-radius: 100%;
      color: var(--dts_default_blue);
      font-weight: 700;
    }
  }
`;

export const DateTimePickerFooter = styled.div`
  display: flex;
  justify-content: right;
  gap: 16px;
  align-items: center;
`;

export const mapMonth = (month) => {
  const monthNames = [
    LANGUAGE_KEYS.JANUARY,
    LANGUAGE_KEYS.FEBRUARY,
    LANGUAGE_KEYS.MARCH,
    LANGUAGE_KEYS.APRIL,
    LANGUAGE_KEYS.MAY,
    LANGUAGE_KEYS.JUNE,
    LANGUAGE_KEYS.JULY,
    LANGUAGE_KEYS.AUGUST,
    LANGUAGE_KEYS.SEPTEMBER,
    LANGUAGE_KEYS.OCTOBER,
    LANGUAGE_KEYS.NOVEMBER,
    LANGUAGE_KEYS.DECEMBER,
  ];
  // return cutAt ? monthNames[month].substring(0, cutAt) : monthNames[month] || '';
  return monthNames[month];
};

export const mapMonthNumber = (month) => `${month + 1 < 10 ? '0' : ''}${month + 1}`;

export const mapDay = (day) => `${day < 10 ? '0' : ''}${day}`;

export default DatePicker;

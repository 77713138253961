import React, { useState } from 'react';
import { useParams } from 'react-router';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { TeethRow } from '../components/worktypeitem/teeth/TeethRow';
import { useTeethViewModel } from './TeethViewModel';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { useTeethRepositoryImplementation } from '../../../../data/worktype/teeth/TeethRepositoryImplementation';
import { TeethUiModel } from '../../../ui-model/worktype/TeethUiModel';

function Teeth() {
  const { id } = useParams();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const extensionRepo = useTeethRepositoryImplementation();
  const { getSortTeethsByWorkTypeId, updateTeeth } = useTeethViewModel(workTypeRepo, extensionRepo);

  const teethsList: TeethUiModel[] = getSortTeethsByWorkTypeId(
    id !== undefined ? parseInt(id) : -1,
  );

  // If we not use a copy list of teethsList then in case of drag and drop, the elements will jump
  const [teethsListCopy, setTeethsListCopy] = useState<TeethUiModel[]>();

  const handleDragDrop = (results) => {
    console.log(teethsList);
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index)
      return;
    if (type === 'group') {
      const reorderedTeethsList = teethsList;
      const teethSourceIndex = source.index;
      const teethDestinatonIndex = destination.index;
      const [removedTeeth] = reorderedTeethsList.splice(teethSourceIndex, 1);
      reorderedTeethsList.splice(teethDestinatonIndex, 0, removedTeeth);
      sortWorktypeList(reorderedTeethsList!);
      setTeethsListCopy(reorderedTeethsList!);
      updateTeeth(id !== undefined ? parseInt(id) : -1, workTypeRepo, reorderedTeethsList!);
    }
  };

  function sortWorktypeList(teethList: TeethUiModel[]) {
    return teethList.map((teeth, index) => {
      teeth.order = index;
    });
  }

  const handleCheckbox = (teeth: TeethUiModel) => {
    updateTeeth(id !== undefined ? parseInt(id) : -1, workTypeRepo, [teeth]);
  };

  return (
    <TeethContainer>
      <DragDropContext onDragEnd={handleDragDrop}>
        <Droppable droppableId='ROOT' type='group'>
          {(provided) => (
            <DroppableProvide {...provided.droppableProps} ref={provided.innerRef}>
              <TeethList
                teethList={
                  teethsListCopy !== undefined && teethsListCopy.length > 0
                    ? teethsListCopy
                    : teethsList
                }
                handleCheckbox={handleCheckbox}
              />
              {provided.placeholder}
            </DroppableProvide>
          )}
        </Droppable>
      </DragDropContext>
    </TeethContainer>
  );
}

function TeethList(props: {
  teethList: any[] | undefined;
  handleCheckbox: (teeth: TeethUiModel) => void;
}) {
  return (
    <>
      {buildTeethList(
        props.teethList?.filter((value) => {
          return value;
        }),
        props.handleCheckbox,
      )}
    </>
  );
}

function buildTeethList(
  teethList: any[] | undefined,
  handleCheckbox: (teeth: TeethUiModel) => void,
) {
  const teethRowView: any[] = [];
  teethList?.map(function (c, i) {
    teethRowView.push(
      <TeethRow teethModel={c} index={i} handleCheckbox={handleCheckbox} key={c.id} />,
    );
  });
  return teethRowView;
}

const DroppableProvide = styled.div``;

const TeethContainer = styled.div`
  margin-top: 16px;
`;

export default Teeth;

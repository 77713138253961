import { PasswordData } from '../../domain/signUpFlow/SetPasswordData';
import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';

type setPasswordUseCasesRepository = Pick<SignUpFlowRepository, 'setPassword'>;

const setPasswordUseCases = (
  repo: setPasswordUseCasesRepository,
  password: PasswordData,
  isRecover: boolean,
) => {
  return repo.setPassword(password, isRecover);
};

export { setPasswordUseCases };
export type { setPasswordUseCasesRepository };

import { ReactComponent as ToothConnectionU1Icon } from '../../../../icons/toothconnections/tooth_connection_u_1.svg';
import { ReactComponent as ToothConnectionU2Icon } from '../../../../icons/toothconnections/tooth_connection_u_2.svg';
import { ReactComponent as ToothConnectionU3Icon } from '../../../../icons/toothconnections/tooth_connection_u_3.svg';
import { ReactComponent as ToothConnectionU4Icon } from '../../../../icons/toothconnections/tooth_connection_u_4.svg';
import { ReactComponent as ToothConnectionU5Icon } from '../../../../icons/toothconnections/tooth_connection_u_5.svg';
import { ReactComponent as ToothConnectionU6Icon } from '../../../../icons/toothconnections/tooth_connection_u_6.svg';
import { ReactComponent as ToothConnectionU7Icon } from '../../../../icons/toothconnections/tooth_connection_u_7.svg';
import { ReactComponent as ToothConnectionU8Icon } from '../../../../icons/toothconnections/tooth_connection_u_8.svg';
import { ReactComponent as ToothConnectionL1Icon } from '../../../../icons/toothconnections/tooth_connection_l_1.svg';
import { ReactComponent as ToothConnectionL2Icon } from '../../../../icons/toothconnections/tooth_connection_l_2.svg';
import { ReactComponent as ToothConnectionL3Icon } from '../../../../icons/toothconnections/tooth_connection_l_3.svg';
import { ReactComponent as ToothConnectionL4Icon } from '../../../../icons/toothconnections/tooth_connection_l_4.svg';
import { ReactComponent as ToothConnectionL5Icon } from '../../../../icons/toothconnections/tooth_connection_l_5.svg';
import { ReactComponent as ToothConnectionL6Icon } from '../../../../icons/toothconnections/tooth_connection_l_6.svg';
import { ReactComponent as ToothConnectionL7Icon } from '../../../../icons/toothconnections/tooth_connection_l_7.svg';
import { ReactComponent as ToothConnectionL8Icon } from '../../../../icons/toothconnections/tooth_connection_l_8.svg';
import styled, { css } from 'styled-components';

type Props = { rotatey: string; color: string; opacity: string; scale: string };

const styles = css<Props>`
  position: absolute;
  transform: ${(props) => props.rotatey};
  z-index: 10;
  opacity: ${(props) => props.opacity};
  pointer-events: auto;
  path {
    fill: ${(props) => props.color};
  }
  &:hover {
    transform: ${(props) => props.rotatey} ${(props) => props.scale};
  }
`;

export const ToothConnectionU1IconStyled = styled(ToothConnectionU1Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU2IconStyled = styled(ToothConnectionU2Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU3IconStyled = styled(ToothConnectionU3Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU4IconStyled = styled(ToothConnectionU4Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU5IconStyled = styled(ToothConnectionU5Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU6IconStyled = styled(ToothConnectionU6Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU7IconStyled = styled(ToothConnectionU7Icon)<Props>`
  ${styles}
`;
export const ToothConnectionU8IconStyled = styled(ToothConnectionU8Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL1IconStyled = styled(ToothConnectionL1Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL2IconStyled = styled(ToothConnectionL2Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL3IconStyled = styled(ToothConnectionL3Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL4IconStyled = styled(ToothConnectionL4Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL5IconStyled = styled(ToothConnectionL5Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL6IconStyled = styled(ToothConnectionL6Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL7IconStyled = styled(ToothConnectionL7Icon)<Props>`
  ${styles}
`;
export const ToothConnectionL8IconStyled = styled(ToothConnectionL8Icon)<Props>`
  ${styles}
`;

import * as React from 'react';
import { useState } from 'react';

// Step 1. create the context

export const GlobalContext3 = React.createContext({} as any);

export function GlobalProvider3(props: { children }) {
  const [newMessage, setNewMessage] = useState([
    {
      showFlash: false, // whether or not to return html or null
      title: 'LOFASZ', // The message within the notification
      message: 'LOFASZ', // The message within the notification
      status: 'success', // Success or error notification type
    },
  ]);

  return (
    <GlobalContext3.Provider value={[newMessage, setNewMessage]}>
      {/*{console.log('newMessage')}*/}
      {/*{console.log(newMessage)}*/}
      {props.children}
    </GlobalContext3.Provider>
  );
}

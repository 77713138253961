import { BASE_URL } from '../../Server';
import { TemplatesData } from '../../../domain/worktype/templates/Template';
import { TemplatePhases } from '../../../domain/worktype/templates/TemplatePhases';
import { NewTemplate } from '../../../domain/worktype/templates/NewTemplate';
import { UpdateTemplate } from '../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../domain/worktype/templates/UpdateTemplateOrderOrActive';
import { TemplateBySubWorkType } from '../../../domain/worktype/templates/TemplateBySubWorkType';
import { useApiHeaderWithCompanyId } from '../../../presentation/hooks/useApiHeaderWithCompanyId';

function getTemplates(): Promise<TemplatesData> {
  return fetch(BASE_URL + '/template', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return {
        code: 200,
        message: 'Success',
        data: res,
      };
    })
    .catch((error) => {
      console.log('catchy getTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function newTemplate(newTemplate: NewTemplate) {
  return fetch(BASE_URL + '/addTemplate', {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(newTemplate),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy newTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function duplicateTemplate(id: number) {
  return fetch(BASE_URL + `/template/duplicate/${id}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy duplicateTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function deleteTemplate(id: number) {
  return fetch(BASE_URL + `/template/delete/${id}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy deleteTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function updateTemplate(template: UpdateTemplate) {
  return fetch(BASE_URL + '/template/update', {
    method: 'POST',
    body: JSON.stringify(template),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function updateTemplateOrderOrActive(templates: UpdateTemplateOrderOrActive[]) {
  return fetch(BASE_URL + '/template/updateOrderOrActive', {
    method: 'POST',
    body: JSON.stringify({ templates: templates }),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateTemplateOrderOrActive: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function addTemplateBySubWorkType(templateBySubWorkType: TemplateBySubWorkType) {
  return fetch(BASE_URL + '/addTemplateBySubWorkType', {
    method: 'POST',
    body: JSON.stringify(templateBySubWorkType),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy addTemplateBySubWorkType: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function removeTemplateFromSubWorkType(templateID: number, extensionID?: number) {
  let requestURL;
  if (extensionID === undefined) {
    requestURL = `/removeTemplateFromSubcategory?templateId=${templateID}`;
  } else {
    requestURL = `/removeTemplateFromSubcategory?templateId=${templateID}&extensionId=${extensionID}`;
  }
  return fetch(BASE_URL + requestURL, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy removeTemplateFromSubWorkType: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

function addPhasesToTemplate(templatePhases: TemplatePhases) {
  return fetch(BASE_URL + '/template/addPhases', {
    method: 'POST',
    body: JSON.stringify(templatePhases),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy addPhasesToTemplate: ' + error.status);
      return {
        code: error.status,
        message: error,
        data: [],
      };
    });
}

export {
  getTemplates,
  newTemplate,
  duplicateTemplate,
  deleteTemplate,
  updateTemplate,
  updateTemplateOrderOrActive,
  addTemplateBySubWorkType,
  removeTemplateFromSubWorkType,
  addPhasesToTemplate,
};

import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { Text, TextType } from './Text';

type ContextMenuProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  options: Array<ContextMenuOption>;
  offset: ContextMenuOffset;
  manageFocus?: boolean;
  className?: string;
  textType?: TextType;
};

export type ContextMenuOption = {
  key: number;
  icon?: JSX.Element;
  label: string;
  onClick?: () => void;
};
export type ContextMenuOffset = { x: number; y: number };

export const ContextMenu = (props: ContextMenuProps) => {
  const { isOpen, setIsOpen, options, offset, manageFocus = true, className, textType } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    manageFocus && isOpen && containerRef?.current?.focus();
  }, [isOpen]);

  const Options = useMemo(() => {
    return options.map((option) => {
      return (
        <Option
          className='option'
          key={option.key}
          onMouseDown={() => {
            setIsOpen(false);
            option.onClick && option.onClick();
          }}
        >
          {option.icon}
          {textType !== undefined ? (
            <Text type={textType} ellipsis={true}>
              {option.label}
            </Text>
          ) : (
            <Text type={TextType.CAPTION_REGULAR} ellipsis={true}>
              {option.label}
            </Text>
          )}
        </Option>
      );
    });
  }, [options]);

  return isOpen && Options.length > 0 ? (
    <Container
      ref={containerRef}
      tabIndex={-1}
      style={{ left: offset.x, top: offset.y }}
      onBlur={() => setIsOpen(false)}
      className={className}
    >
      {Options}
    </Container>
  ) : (
    <React.Fragment />
  );
};

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  padding: 16px;
  background-color: var(--dts_white);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  outline: none;
  box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
`;

const Option = styled.div`
  padding: 6px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    path {
      opacity: 0.7;
    }
  }

  :hover {
    background-color: var(--dts_withe_background);
  }
`;

import React from 'react';

import { ReportRepository } from '../../domain/reports/ReportRepository';
import { getReportCasesUseCase } from '../../useCases/reports/getReportCasesUseCase';
import { ReportCaseUiModel } from '../ui-model/reports/ReportCaseUiModel';
import { ReportCaseResponse } from '../../domain/reports/Report';
import { getCompanyNameUseCase } from '../../useCases/user-management/getCompaniesUseCase';
import { CompaniesRepository } from '../../domain/user-management/UsersRepository';
import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { getFeatureByIdUseCase } from '../../useCases/features/getFeatureUseCase';
import { FeatureId } from '../../domain/features/Features';
import { DRAFT } from '../case/caseStates';
import { createCaseUseCase } from '../../useCases/case/createCaseUseCase';
import { CaseRepository } from '../../domain/CaseRepository';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';

const useReportViewModel = (
  repo: ReportRepository,
  companiesRepo: CompaniesRepository,
  featureRepo: FeaturesRepository,
  caseRepo: CaseRepository,
  loggedUserRepo: LoggedUserRepository,
) => {
  const getReportCases = React.useCallback(
    (params) => {
      return getReportCasesUseCase(repo, params).then((res) => {
        return { states: res.states, cases: changeReportResponseToUiModel(res.cases) };
      });
    },
    [repo.getReportCases, companiesRepo.companies, companiesRepo.getCompanies],
  );

  const changeReportResponseToUiModel = (
    cases: ReportCaseResponse[] | undefined,
  ): ReportCaseUiModel[] => {
    const uiList: ReportCaseUiModel[] = [];
    cases
      ?.filter((c) => c.status !== DRAFT)
      .map((c) => {
        uiList.push({
          id: c.id,
          clientName: c.clientName,
          clientCompanyName: getCompanyNameUseCase(c.clientCompanyId, {
            getCompanies: companiesRepo.getCompanies,
            companies: companiesRepo.companies,
            getMyCompanyData: companiesRepo.getMyCompanyData,
          }),
          clientCompanyId: c.clientCompanyId,
          patient: c.patientName,
          collaborators: c.collaborators,
          creationDate: getFormattedDate(c.creationDate),
          caseStatus: c.status,
          invoicePrefix: c.invoice === null ? null : c.invoice.invoicePrefix,
          invoiceNumber: c.invoice === null ? null : c.invoice.invoiceNumber,
          invoiceId: c.invoice === null ? null : c.invoice.invoiceId,
        });
      });
    return uiList;
  };

  const isEnabledReportsOverviewMovesAction = (userRole: number) => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.REPORTS_OVERVIEW_MOVES_ACTION);
  };

  const isEnabledInvoice = (userRole: number) => {
    if (userRole !== null)
      return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.INVOICE_VISIBILITY);
  };

  const createCase = React.useCallback(
    (onSuccess: (id: number) => void) => {
      createCaseUseCase({ newCase: caseRepo.newCase }, onSuccess);
    },
    [caseRepo.newCase],
  );

  const isEnabledNewCaseFeatureAction = (userRole: number) => {
    return getFeatureByIdUseCase(featureRepo, userRole, FeatureId.NEW_CASE_ACTION);
  };

  return {
    reportCases: repo.reportCases,
    isLoading: repo.isLoading,
    validSubscription: loggedUserRepo.validSubscription,
    getReportCases,
    isEnabledReportsOverviewMovesAction,
    isEnabledNewCaseFeatureAction,
    createCase,
    isEnabledInvoice,
  };
};

export const getFormattedDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(2);

  return `${month}/${day}/${year}`;
};

export { useReportViewModel };

import type { AnyAction } from 'redux';
import * as actionTypes from './templateActionTypes';
import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';

type TemplateRepositoryState = Omit<
  TemplateRepository,
  | 'getTemplates'
  | 'newTemplate'
  | 'duplicateTemplate'
  | 'deleteTemplate'
  | 'updateTemplate'
  | 'updateTemplateOrderOrActive'
  | 'addTemplateBySubWorkType'
  | 'removeTemplateFromSubWorkType'
  | 'addPhasesToTemplate'
>;

const INITIAL_STATE: TemplateRepositoryState = {
  templates: undefined,
  isLoading: false,
  isUpdating: false,
};

const templateReducer = (state: TemplateRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.GET_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.GET_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false, templates: action.templates };
    case actionTypes.GET_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.NEW_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.NEW_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.NEW_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DUPLICATE_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DUPLICATE_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.DELETE_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.UPDATE_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE:
      return { ...state, isLoading: true };
    case actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.ADD_TEMPLATE_BY_SUB_WORKTYPE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE:
      return { ...state, isLoading: true };
    case actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.ADD_NEW_PHASES_TO_TEMPLATE:
      return { ...state, isLoading: true };
    case actionTypes.ADD_NEW_PHASES_TO_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.ADD_NEW_PHASES_TO_TEMPLATE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
export { templateReducer };
export type { TemplateRepositoryState };

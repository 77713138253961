import { Text, TextType } from '../components/dsm/Text';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CaseWorkSheetComp, LabworkStatus } from './worksheet/CaseWorkSheetComp';
import { CaseDetailContext } from './CaseDetailProvider';
import { LabWorkOverView } from './labwork/LabWorkOverview';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { useCaseOverViewViewModel } from './CaseOverViewViewModel';
import {
  setCaseDetailInsertDateToPosition,
  setCaseDetailInsertPhaseToPosition,
  setCaseDetailInsertTemplateToPosition,
  setCaseDetailPhaseAssignee,
  setCaseDetailRemovePhaseFromPosition,
} from '../../data/cases/detail/caseDetailReducer';
import { useDispatch } from 'react-redux';
import { mapStatusToNumeric, PhaseUiModel, PhaseUiModelPhase } from '../ui-model/PhaseUiModel';
import FloatingDropdown from '../components/generic/FloatingDropdown';
import AddPhaseDialog from './labwork/phase/add-phase/AddPhaseDialog';
import { Template } from '../../domain/worktype/templates/Template';
import { useCompany } from '../hooks/useCompany';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { usePhaseRepositoryImplementation } from '../../data/phase/phasesRepositoryImplementation';
import { DRAFT } from './caseStates';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';

type AssignPhaseDropdown = OpenDropdown | ClosedDropdown;

type ErrorStatusDropdown = OpenDropdown | ClosedDropdown;

type OpenDropdown = {
  kind: 'Open';
  options: Array<{ value: number; label: string }>;
  x: number;
  y: number;
  selected?: number;
  onItemClick: (key: number) => void;
  onClose: () => void;
};

type ClosedDropdown = {
  kind: 'Closed';
};

type AddPhaseDialog = OpenAddPhaseDialog | ClosedAddPhaseDialog;

type OpenAddPhaseDialog = {
  kind: 'Open';
  workTypeId: number;
  selectedCategory: number;
  onClose: () => void;
  onAddPhase: (phase: PhaseUiModel) => void;
  onAddDate: (date: Date) => void;
  onAddTemplate: (template: Template) => void;
};

type ClosedAddPhaseDialog = { kind: 'Closed' };

function CaseOverView(props: { caseId: string | undefined }) {
  const contactRepo = useContactsRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();
  const translate = useTranslate();
  const {
    workers,
    dropDownWorkers,
    errorStatuses,
    isEnabledAssignePhaseAction,
    isEnabledChangeDetailDescriptionFeatureAction,
  } = useCaseOverViewViewModel(contactRepo, featureRepo, phaseRepo);

  const alertManager = useAlertManagerImplementation();
  const { showAlert, showPaymentExpireModal } = useAlertViewModel(alertManager);

  const dispatch = useDispatch();

  const currentCompany = useCompany();

  const { caseDetailObj, wt, clientsUi, updateDescription, validSubscription } =
    useContext(CaseDetailContext);

  const [caseDescription, setCaseDescription] = React.useState<string>();
  const [updateDescriptionCustom, setUpdateDescription] = React.useState<boolean>();
  const [isPhaseAssigneAction, setIsPhaseAssigneAction] = React.useState<boolean>(false);
  const [isDescriptionModificationAction, setIsDescriptionModificationAction] =
    React.useState<boolean>(false);

  const [assignPhaseDropdown, setAssignPhaseDropdown] = React.useState<AssignPhaseDropdown>({
    kind: 'Closed',
  });

  const [addPhaseDialog, setAddPhaseDialog] = React.useState<AddPhaseDialog>({
    kind: 'Closed',
  });

  const MINUTE_MS = 15000;

  useEffect(() => {
    setCaseDescription(caseDetailObj.description);
    setIsPhaseAssigneAction(
      currentCompany.userRole !== null && isEnabledAssignePhaseAction(currentCompany.userRole),
    );
    setIsDescriptionModificationAction(
      currentCompany.userRole !== null &&
        isEnabledChangeDetailDescriptionFeatureAction(currentCompany.userRole),
    );
  }, [caseDetailObj, currentCompany.userRole]);

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdateDescription(true);
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (updateDescriptionCustom) {
      onSaveDescription();
    }
    setUpdateDescription(false);
  }, [updateDescriptionCustom]);

  const closeAssignPhaseModal = () => setAssignPhaseDropdown({ kind: 'Closed' });

  const openAssignPhaseDialog = (
    labWorkId: number,
    phaseUiModel: PhaseUiModelPhase,
    x: number,
    y: number,
  ) => {
    if (validSubscription) {
      setAssignPhaseDropdown({
        kind: 'Open',
        options: dropDownWorkers,
        selected: phaseUiModel.kind === 'WithAssignee' ? phaseUiModel.assignee.id : undefined,
        onItemClick: (contactId) => {
          dispatch(
            setCaseDetailPhaseAssignee({
              labworkId: labWorkId,
              phaseId: phaseUiModel.id,
              phaseOrder: phaseUiModel.order,
              assigneeId: contactId,
              phaseStatus: 0,
              workers: workers || [],
            }),
          );
          closeAssignPhaseModal();
        },
        onClose: closeAssignPhaseModal,
        x: x,
        y: y,
      });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const [errorStatusDropdown, setErrorStatusDropdown] = React.useState<ErrorStatusDropdown>({
    kind: 'Closed',
  });
  const closeErrorStatusDialog = () => setErrorStatusDropdown({ kind: 'Closed' });

  const openErrorStatusDialog = (
    labWorkId: number,
    phaseUiModel: PhaseUiModelPhase,
    x: number,
    y: number,
  ) => {
    if (validSubscription) {
      setErrorStatusDropdown({
        kind: 'Open',
        options: errorStatuses,
        selected: mapStatusToNumeric(phaseUiModel.status) || 0,
        onItemClick: (errorStatus) => {
          dispatch(
            setCaseDetailPhaseAssignee({
              labworkId: labWorkId,
              phaseId: phaseUiModel.id,
              phaseOrder: phaseUiModel.order,
              assigneeId: phaseUiModel.kind === 'WithAssignee' ? phaseUiModel.assignee.id : -1,
              phaseStatus: errorStatus,
              workers: workers || [],
            }),
          );
          closeErrorStatusDialog();
        },
        onClose: closeErrorStatusDialog,
        x: x,
        y: y,
      });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const getCaseStatus = (): LabworkStatus => {
    if (caseDetailObj.labworks?.length > 0) {
      return {
        title: translate(LANGUAGE_KEYS.PENDING_LABWORK),
        description: translate(LANGUAGE_KEYS.FIRST_SELECT_A_WORK),
        altDescription: translate(LANGUAGE_KEYS.THIS_WORK_ORDER_IS_ON_DRAFT),
        icon: 0,
      };
    } else {
      return {
        title: translate(LANGUAGE_KEYS.SELECT_YOUR_WORK_TYPE),
        description: translate(LANGUAGE_KEYS.FIRST_SET_YOUR_WORK),
        icon: 0,
      };
    }
  };

  const changeStatus = (
    labWorkId: number,
    phase: PhaseUiModelPhase,
    status: PhaseUiModelPhase['status'],
  ) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailPhaseAssignee({
          labworkId: labWorkId,
          phaseId: phase.id,
          phaseOrder: phase.order,
          assigneeId: phase.kind === 'WithAssignee' ? phase.assignee.id : -1,
          phaseStatus: mapStatusToNumeric(status) || 0,
          workers: workers || [],
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const duplicatePhase = (labWorkId: number, phase: PhaseUiModel) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailInsertPhaseToPosition({
          labworkId: labWorkId,
          phase: phase,
          phaseOrder: phase.order,
          workers: workers,
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const deletePhase = (labWorkId: number, phase: PhaseUiModel) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailRemovePhaseFromPosition({
          labworkId: labWorkId,
          phaseOrder: phase.order,
          workers: workers || [],
        }),
      );
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const closeAddPhaseDialog = () => setAddPhaseDialog({ kind: 'Closed' });

  const addPhaseDialogAddPhase = (
    labWorkId: number,
    phaseBefore: PhaseUiModel,
    phase: PhaseUiModel,
  ) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailInsertPhaseToPosition({
          labworkId: labWorkId,
          phase: phase,
          phaseOrder: phaseBefore === undefined ? 0 : phaseBefore.order + 1,
          workers: workers,
        }),
      );
      setAddPhaseDialog({ kind: 'Closed' });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const addPhaseDialogAddDate = (labWorkId: number, phaseBefore: PhaseUiModel, date: Date) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailInsertDateToPosition({
          labWorkId,
          phaseOrder: phaseBefore.order + 1,
          timestamp: date.valueOf(),
          workers: workers,
        }),
      );
      setAddPhaseDialog({ kind: 'Closed' });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const addPhaseDialogAddTemplate = (
    labWorkId: number,
    phaseBefore: PhaseUiModel,
    template: Template,
  ) => {
    if (validSubscription) {
      dispatch(
        setCaseDetailInsertTemplateToPosition({
          labWorkId,
          phaseOrder: phaseBefore === undefined ? 0 : phaseBefore.order + 1,
          template,
          workers: workers,
        }),
      );
      setAddPhaseDialog({ kind: 'Closed' });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const onSaveDescription = () => {
    if (caseDescription !== caseDetailObj.description && caseDescription !== undefined) {
      updateDescription(caseDetailObj.id, caseDescription).then((resp) => {
        if (!resp) {
          showAlert(translate(LANGUAGE_KEYS.AUTOSAVE_FAILED), AlertType.FAILD);
        }
      });
    }
  };

  const openAddPhaseDialog = (
    labWorkId: number,
    workTypeId: number,
    selectedCategory: number,
    phaseBefore: PhaseUiModel,
  ) => {
    if (validSubscription) {
      setAddPhaseDialog({
        kind: 'Open',
        workTypeId: workTypeId,
        selectedCategory: selectedCategory,
        onClose: closeAddPhaseDialog,
        onAddPhase: (phase) => addPhaseDialogAddPhase(labWorkId, phaseBefore, phase),
        onAddDate: (date) => addPhaseDialogAddDate(labWorkId, phaseBefore, date),
        onAddTemplate: (template) => addPhaseDialogAddTemplate(labWorkId, phaseBefore, template),
      });
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  return (
    <CaseDetailContentBody className={`${caseDetailObj.status === DRAFT && 'no-published'}`}>
      <CaseWorkSheetComp workTypes={wt} clients={clientsUi} labWorkStatus={getCaseStatus()} />
      {!caseDetailObj.labworks ||
        (caseDetailObj.labworks.length == 0 && (
          <EmptyLabwork>
            <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.NO_WORTYPE)}</Text>
            <Text type={TextType.BODY_REGULAR}>{translate(LANGUAGE_KEYS.PLEASE_FILL)}</Text>
          </EmptyLabwork>
        ))}
      {caseDetailObj.labworks.map((labWork, i) => {
        return (
          <LabWorkOverView
            key={i}
            caseId={Number(props.caseId)}
            labWork={labWork}
            isEnabledAssigne={isPhaseAssigneAction}
            assignPhase={(phase, x, y) => {
              openAssignPhaseDialog(labWork.labWorkId, phase, x, y);
            }}
            changeErrorStatus={(phase, x, y) =>
              openErrorStatusDialog(labWork.labWorkId, phase, x, y)
            }
            onStatusChange={(phase, status) => changeStatus(labWork.labWorkId, phase, status)}
            deletePhase={(phase) => deletePhase(labWork.labWorkId, phase)}
            duplicatePhase={(phase) => duplicatePhase(labWork.labWorkId, phase)}
            onAddPhase={(phaseBefore) =>
              openAddPhaseDialog(
                labWork.labWorkId,
                labWork.workType?.id,
                labWork?.workType?.selectedCategory,
                phaseBefore,
              )
            }
          />
        );
      })}
      <CaseDetailFooter>
        <CaseDetailFooterDescription>
          <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.DESCRIPTION)}</Text>
          <CaseDescriptionArea
            rows={caseDescription === null || caseDescription === '' ? 1 : 6}
            name='text_body'
            onBlur={onSaveDescription}
            onChange={(e) => {
              if (validSubscription) {
                setCaseDescription(e.target.value);
              } else {
                showPaymentExpireModal(currentCompany.userRole);
              }
            }}
            value={caseDescription === null ? '' : caseDescription}
            placeholder={translate(LANGUAGE_KEYS.CLICK_TO_ADD_DESCRIPTION)}
            disabled={!isDescriptionModificationAction}
          />
        </CaseDetailFooterDescription>
        {/*<CaseDetailFooterDescription>*/}
        {/*  <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.DROP_YOUR_FILES)}</Text>*/}
        {/*  <Text style={{ marginTop: '4px', opacity: '0.7' }} type={TextType.CAPTION_REGULAR_2}>*/}
        {/*    {translate(LANGUAGE_KEYS.SUPPORT_FILES)}*/}
        {/*  </Text>*/}
        {/*</CaseDetailFooterDescription>*/}
      </CaseDetailFooter>
      {addPhaseDialog.kind === 'Open' && (
        <AddPhaseDialog
          worktypeId={addPhaseDialog.workTypeId}
          onClose={addPhaseDialog.onClose}
          onAddPhase={addPhaseDialog.onAddPhase}
          onAddDate={addPhaseDialog.onAddDate}
          onAddTemplate={addPhaseDialog.onAddTemplate}
        />
      )}
      {assignPhaseDropdown.kind === 'Open' && (
        <FloatingDropdown
          values={assignPhaseDropdown.options.map((option) => {
            return { key: option.value, label: option.label };
          })}
          onChange={assignPhaseDropdown.onItemClick}
          onClose={assignPhaseDropdown.onClose}
          x={assignPhaseDropdown.x}
          y={assignPhaseDropdown.y}
        />
      )}

      {errorStatusDropdown.kind === 'Open' && (
        <FloatingDropdown
          values={errorStatusDropdown.options.map((option) => {
            return { key: option.value, label: option.label };
          })}
          onChange={errorStatusDropdown.onItemClick}
          onClose={errorStatusDropdown.onClose}
          x={errorStatusDropdown.x}
          y={errorStatusDropdown.y}
        />
      )}
    </CaseDetailContentBody>
  );
}

const CaseDetailContentBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 3px;
  &.no-published {
    margin-bottom: 45px;
  }
`;
const CaseDetailFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const CaseDetailFooterDescription = styled.div`
  flex: 1;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  padding: 16px;
  &:hover {
    border: 1px solid var(--dts_default_blue);
  }
`;

const CaseDescriptionArea = styled.textarea`
  margin-top: 4px;
  resize: none;
  width: 100%;
  border: none;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--dts_black);
  padding: 0px;
  opacity: 0.7;
  ::placeholder {
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: var(--dts_black);
  }
  :focus-visible {
    outline: none;
  }
`;

const EmptyLabwork = styled.div`
  width: 100%;
  background: rgba(228, 228, 228, 0.2);
  padding: 44px;
  text-align: center;
  border: 1px solid var(--dts_withe_background);
  border-radius: 3px;
  box-sizing: border-box;
`;

export default CaseOverView;

import React from 'react';
import styled, { css } from 'styled-components';
import { Text, TextType } from '../../components/dsm/Text';

type PropsLeftConnection = {
  type: number;
  order: number;
};

export function TeethComponent(props: {
  name: string;
  leftConnection: number;
  type: number;
  quarter: number;
  teethp: number;
}) {
  function getWidth() {
    if (
      (props.quarter === 3 && props.teethp === 1) ||
      (props.quarter === 2 && props.teethp === 1)
    ) {
      return '30px';
    }
    return '34px';
  }

  const width = getWidth();

  function getRightOrLeft() {
    if (props.quarter === 1 || props.quarter === 4) {
      return 'flex-start';
    } else {
      return 'flex-end';
    }
  }

  function getLeftConnection() {
    if (width === '34px' && props.leftConnection === 1) {
      let order = 1;
      if (props.quarter === 1 || props.quarter === 4) {
        order = 3;
      }
      return <LeftConnectionContainer order={order} type={props.type}></LeftConnectionContainer>;
    }
    return null;
  }

  return (
    <Container justifyContent={getRightOrLeft()} width={width}>
      <TeethContainer>
        <Text type={TextType.CAPTION_REGULAR_2}>{props.name}</Text>
      </TeethContainer>
      {getLeftConnection()}
    </Container>
  );
}

type Props = {
  width: string;
  justifyContent: string;
};

const Container = styled.span<Props>`
  display: flex;
  width: ${(props) => props.width};
  flex-direction: row;
  align-items: center;
  flex: none;
  order: 0;
  justify-content: ${(props) => props.justifyContent};
  flex-grow: 0;
`;

const TeethContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 24px;
  order: 2;
  background: var(--dts_light_grey);
  border-radius: 5px;
  flex: none;
  flex-grow: 0;
`;

const LeftConnectionContainer = styled.div<PropsLeftConnection>`
  width: 4px;
  height: 4px;
  order: ${(props) => props.order};
  background: var(--dts_light_grey);

  ${({ type }) =>
    type === 1 &&
    css`
      background: var(--dts_light_grey);
    `}
`;

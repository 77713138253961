import * as actionTypes from './worktypeActionTypes';
import { getWorkTypes, updateWorkTypes } from './workTypeService';
import UpdateWorTypeInfo from '../../domain/worktype/UpdateWorTypeInfo';
import { checkErrorStatus } from '../ErrorHandling';

const getWorkTypeAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_WORK_TYPES });
  return getWorkTypes().then((wt) => {
    if (checkErrorStatus(wt.code, dispatch)) {
      dispatch({ type: actionTypes.GET_WORK_TYPES_SUCCESS, workTypes: wt.data });
      return wt.data;
    } else {
      dispatch({ type: actionTypes.GET_WORK_TYPES_FAILD, error: wt.code });
      return wt.data;
    }
  });
};

const updateWorkTypeAction = (worktypeList: UpdateWorTypeInfo[]) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_WORK_TYPES });
  return updateWorkTypes(worktypeList).then((wtl) => {
    if (checkErrorStatus(wtl.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_WORK_TYPES_SUCCESS });
      return wtl;
    } else {
      dispatch({ type: actionTypes.UPDATE_WORK_TYPES_FAILD, error: wtl.code });
      return wtl;
    }
  });
};

export { getWorkTypeAction, updateWorkTypeAction };

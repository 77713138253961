import React from 'react';
import { useParams } from 'react-router';

import styled from 'styled-components';

import WorktypeExtensionRowHeader from '../components/worktypeitem/extensions/ExtensionRowHeader';
import { ExtensionRow } from '../components/worktypeitem/extensions/ExtensionRow';
import { useExtensionViewModel } from './ExtensionViewModel';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { useExtensionRepositoryImplementation } from '../../../../data/worktype/extension/ExtensionRepositoryImplementation';
import Extension from '../../../ui-model/worktype/extension/Extension';
import ExtensionInfo from '../../../ui-model/worktype/extension/ExtensionInfo';

function Extensions() {
  const { id } = useParams();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const extensionRepo = useExtensionRepositoryImplementation();
  const { getExtensionsByWorkTypeId, updateExtension } = useExtensionViewModel(
    workTypeRepo,
    extensionRepo,
  );

  const extensionsList: ExtensionInfo[] = getExtensionsByWorkTypeId(
    id !== undefined ? parseInt(id) : -1,
  );

  const handleChangeExtension = (extension: Extension) => {
    let groupId = -1;
    extensionsList.forEach((extItem) => {
      const isFound = extItem.extensions.find((ext) => {
        return ext.id === extension.id;
      });
      if (isFound) groupId = extItem.groupId;
    });
    updateExtension(id !== undefined ? parseInt(id) : -1, groupId, extension);
  };

  return (
    <ExtensionsContainer>
      <WorktypeExtensionRowHeader />
      {extensionsList?.map((category, index) => {
        return (
          <CategoryContainer key={index}>
            <ExtensionList
              extensionList={category.extensions}
              handleChangeExtension={handleChangeExtension}
            />
          </CategoryContainer>
        );
      })}
    </ExtensionsContainer>
  );
}

function ExtensionList(props: {
  extensionList: Extension[] | undefined;
  handleChangeExtension: (extension: Extension) => void;
}) {
  return (
    <>
      {buildExtensionList(
        props.extensionList?.filter((value) => {
          return value;
        }),
        props.handleChangeExtension,
      )}
    </>
  );
}

function buildExtensionList(
  extensionList: Extension[] | undefined,
  handleChangeExtension: (extension: Extension) => void,
) {
  const extensionRowView: any[] = [];
  extensionList?.map(function (e, i) {
    extensionRowView.push(
      <ExtensionRow
        extensionModel={e}
        index={i}
        key={e.id}
        handleChangeExtension={handleChangeExtension}
      />,
    );
  });
  return extensionRowView;
}

const CategoryContainer = styled.div`
  margin-bottom: 8px;
`;

const ExtensionsContainer = styled.div`
  margin-top: 16px;
`;

export default Extensions;

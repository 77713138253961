import React from 'react';
import { Text, TextType } from '../../../../../components/dsm/Text';

import styled from 'styled-components';
import { LANGUAGE_KEYS } from '../../../../../translations/languageKeys';
import useTranslate from '../../../../../translations/useTranslate';

function ExtensionRowHeader() {
  const translate = useTranslate();
  return (
    <ExtensionRowHeaderContainer>
      <ExtensionNameTeethsContainer>
        <NameContainer>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.EXTENSION_NAME)}</Text>
        </NameContainer>
        <TeethContainer></TeethContainer>
      </ExtensionNameTeethsContainer>
      <ExtensionPriceStateContainer>
        <PriceContainer>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.PRICE)}</Text>
        </PriceContainer>
        <StateContainer>
          <Text type={TextType.CAPTION_REGULAR}>{translate(LANGUAGE_KEYS.STATE)}</Text>
        </StateContainer>
      </ExtensionPriceStateContainer>
    </ExtensionRowHeaderContainer>
  );
}

const ExtensionRowHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 24px 0px 24px;
  margin-bottom: 8px;
  gap: 3px;
  @media (max-width: 1300px) {
    padding: 8px 14px 8px 14px;
  }
  @media (max-width: 765px) {
    padding: 8px 10px 8px 10px;
  }
`;
const ExtensionNameTeethsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 89%;
  gap: 3px;
  @media (max-width: 1300px) {
    flex-basis: 80%;
  }
  @media (max-width: 765px) {
    flex-basis: 70%;
  }
`;
const ExtensionPriceStateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 11%;
  gap: 3px;
  @media (max-width: 1300px) {
    flex-basis: 20%;
  }
  @media (max-width: 765px) {
    flex-basis: 30%;
  }
`;
const NameContainer = styled.div`
  flex-basis: 25.3%;
  @media (max-width: 1300px) {
    flex-basis: 40%;
  }
`;
const TeethContainer = styled.div`
  flex-basis: 74.7%;
  @media (max-width: 1300px) {
    flex-basis: 60%;
  }
`;
const PriceContainer = styled.div`
  flex-basis: 60%;
`;
const StateContainer = styled.div`
  flex-basis: 40%;
  text-align: right;
`;

export default ExtensionRowHeader;

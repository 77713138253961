export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILD = 'GET_TEMPLATE_FAILD';
export const NEW_TEMPLATE = 'NEW_TEMPLATE';
export const NEW_TEMPLATE_SUCCESS = 'NEW_TEMPLATE_SUCCESS';
export const NEW_TEMPLATE_FAILD = 'NEW_TEMPLATE_FAILD';
export const DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE';
export const DUPLICATE_TEMPLATE_SUCCESS = 'DUPLICATE_TEMPLATE_SUCCESS';
export const DUPLICATE_TEMPLATE_FAILD = 'DUPLICATE_TEMPLATE_FAILD';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILD = 'DELETE_TEMPLATE_FAILD';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILD = 'UPDATE_TEMPLATE_FAILD';
export const ADD_TEMPLATE_BY_SUB_WORKTYPE = 'ADD_TEMPLATE_BY_SUB_WORKTYPE';
export const ADD_TEMPLATE_BY_SUB_WORKTYPE_SUCCESS = 'ADD_TEMPLATE_BY_SUB_WORKTYPE_SUCCESS';
export const ADD_TEMPLATE_BY_SUB_WORKTYPE_FAILD = 'ADD_TEMPLATE_BY_SUB_WORKTYPE_FAILD';
export const REMOVE_TEMPLATE_FROM_SUB_WORKTYPE = 'REMOVE_TEMPLATE_FROM_SUB_WORKTYPE';
export const REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_SUCCESS =
  'REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_SUCCESS';
export const REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_FAILD = 'REMOVE_TEMPLATE_FROM_SUB_WORKTYPE_FAILD';
export const ADD_NEW_PHASES_TO_TEMPLATE = 'ADD_NEW_PHASES_TO_TEMPLATE';
export const ADD_NEW_PHASES_TO_TEMPLATE_SUCCESS = 'ADD_NEW_PHASES_TO_TEMPLATE_SUCCESS';
export const ADD_NEW_PHASES_TO_TEMPLATE_FAILD = 'ADD_NEW_PHASES_TO_TEMPLATE_FAILD';

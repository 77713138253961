import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { BaseDialog } from '../components/generic/BaseDialog';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { CountySelector, CountySelectorProps } from '../components/generic/CountySelector';
import { ReactComponent as DropdownIcon } from '../../icons/arrowDown.svg';

export type AddCompanyDialogProps = {
  modalName: string;
  itemModel: any;
  counties: Array<{
    id: number;
    auto: string;
    nume: string;
  }>;
  onAddCompany: (newCompany) => void;
  onClose: () => void;
};

const AddCompanyDialog = (props: AddCompanyDialogProps) => {
  const translate = useTranslate();
  const { modalName, itemModel, counties, onAddCompany, onClose } = props;

  const countySelectRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [name, setName] = useState(
    itemModel !== undefined && itemModel.name !== null ? itemModel.name : '',
  );
  const [streetName, setStreetName] = useState<string>(
    itemModel !== undefined && itemModel.streetName !== null ? itemModel.streetName : '',
  );
  const [cityName, setCityName] = useState<string>(
    itemModel !== undefined && itemModel.cityName !== null ? itemModel.cityName : '',
  );
  const countrySubentityTmp = counties.find(
    (item) =>
      itemModel !== undefined &&
      itemModel.streetName !== null &&
      item.auto === itemModel.countrySubentity,
  );
  const [countrySubentity, setCountrySubentity] = useState<{
    id: number;
    auto: string;
    nume: string;
  }>(countrySubentityTmp === undefined ? counties[0] : countrySubentityTmp);
  const [workstation, setWorkstation] = useState(
    itemModel !== undefined && itemModel.workstation !== null ? itemModel.workstation : '',
  );
  const [cui, setCui] = useState(
    itemModel !== undefined && itemModel.cui !== null ? itemModel.cui : '',
  );
  const [number, setNumber] = useState(
    itemModel !== undefined && itemModel.nrRegCom !== null ? itemModel.nrRegCom : '',
  );
  const [bank, setBank] = useState(
    itemModel !== undefined && itemModel.bank !== null ? itemModel.bank : '',
  );
  const [iban, setIban] = useState(
    itemModel !== undefined && itemModel.codIban !== null ? itemModel.codIban : '',
  );

  const handleChangeName = (value) => {
    const nameValue = value.target.value;
    setName(nameValue);
  };

  const handleChangeStreetName = (value) => {
    const streetNameValue = value.target.value;
    setStreetName(streetNameValue);
  };

  const handleChangeCityName = (value) => {
    const cityNameValue = value.target.value;
    setCityName(cityNameValue);
  };

  const handleChangeCountrySubentity = (value) => {
    const countrySubentityValue = counties.find((item) => item.id === value);
    if (countrySubentityValue !== undefined) setCountrySubentity(countrySubentityValue);
  };

  const handleChangeWorkstation = (value) => {
    const workstationValue = value.target.value;
    setWorkstation(workstationValue);
  };

  const handleChangeCUI = (value) => {
    const cuiValue = value.target.value;
    setCui(cuiValue);
  };

  const handleChangeNumber = (value) => {
    const numberValue = value.target.value;
    setNumber(numberValue);
  };

  const handleChangeBank = (value) => {
    const bankValue = value.target.value;
    setBank(bankValue);
  };

  const handleChangeIban = (value) => {
    const ibanValue = value.target.value;
    setIban(ibanValue);
  };

  const addCompany = () => {
    if (itemModel !== undefined && itemModel.id !== null) {
      onAddCompany({
        id: itemModel.id,
        streetName: streetName,
        cityName: cityName,
        countrySubentity: countrySubentity?.auto,
        identificationCode: 'RO',
        name: name,
        workPlaceAddress: workstation,
        cui: cui,
        nrRegCom: number,
        bank: bank,
        codIban: iban,
      });
    } else {
      onAddCompany({
        streetName: streetName,
        cityName: cityName,
        countrySubentity: countrySubentity?.auto,
        identificationCode: 'RO',
        name: name,
        workPlaceAddress: workstation,
        cui: cui,
        nrRegCom: number,
        bank: bank,
        codIban: iban,
      });
    }
    onClose();
  };

  return (
    <BaseDialog
      isOpen={true}
      title={modalName}
      titleTextType={TextType.BODY_BOLD}
      onClose={onClose}
      containerStyle={{ width: '406px' }}
      header={true}
      isScroll={false}
    >
      <Container ref={modalRef}>
        <FirstSubContainer className='custom-scrollbar'>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.COMPANY_NAME)}</Text>
            <InputComponent
              type={'text'}
              value={name}
              onChange={(it) => {
                handleChangeName(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
              {translate(LANGUAGE_KEYS.CITY)}
            </Text>
            <InputComponent
              type={'text'}
              value={cityName}
              onChange={(it) => {
                handleChangeCityName(it);
              }}
              required
            />
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
              {translate(LANGUAGE_KEYS.ADDRESS)}
            </Text>
            <InputComponent
              type={'text'}
              value={streetName}
              onChange={(it) => {
                handleChangeStreetName(it);
              }}
              required
            />
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer className='county'>
            <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
              {translate(LANGUAGE_KEYS.COUNTY)}
            </Text>
            <Selected ref={countySelectRef} onClick={() => setIsOpen(!isOpen)}>
              <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                {countrySubentity.nume}
              </Text>
              <DropdownIconStyled className={`${isOpen && 'open'}`} />
            </Selected>
            <CountySelector
              parentRef={countySelectRef}
              modalRef={modalRef}
              isOpen={isOpen}
              values={counties.reduce((result: CountySelectorProps['values'], county) => {
                result.push({
                  key: county.id,
                  label: county.nume,
                  selected: county.auto === countrySubentity?.auto,
                });
                return result;
              }, [])}
              onSelect={(changedValueKey) => {
                handleChangeCountrySubentity(changedValueKey);
              }}
              hasSuccessIcon={true}
              onChangeOpenState={(state: boolean) => setIsOpen(state)}
              maxHeightOptions={175}
            />
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PUNCT_DE_LUCRU)}</Text>
            <InputComponent
              type={'text'}
              value={workstation}
              onChange={(it) => {
                handleChangeWorkstation(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.CIF)}</Text>
            <InputComponent
              type={'text'}
              value={cui}
              onChange={(it) => {
                handleChangeCUI(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.NR_REG_COM)}</Text>
            <InputComponent
              type={'text'}
              value={number}
              onChange={(it) => {
                handleChangeNumber(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.BANCA)}</Text>
            <InputComponent
              type={'text'}
              value={bank}
              onChange={(it) => {
                handleChangeBank(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.COD_IBAN)}</Text>
            <InputComponent
              type={'text'}
              value={iban}
              onChange={(it) => {
                handleChangeIban(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}></ErrorText>
          </LabelInputContainer>
        </FirstSubContainer>
        <SecondSubContainer>
          <Button textType={TextType.CAPTION_BOLD} onClick={onClose} secondary='secondary'>
            {translate(LANGUAGE_KEYS.CANCEL)}
          </Button>
          <Button textType={TextType.CAPTION_BOLD} onClick={addCompany}>
            {itemModel === undefined
              ? translate(LANGUAGE_KEYS.CREATE)
              : translate(LANGUAGE_KEYS.EDIT)}
          </Button>
        </SecondSubContainer>
      </Container>
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 48px);
  max-height: calc(100% - 48px);
`;

const FirstSubContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  height: calc(100% - 50px);
  max-height: calc(100% - 50px);
`;

const Selected = styled.div`
  position: relative;
  background-color: var(--dts_white);
  padding: 8.5px 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  &.disabled {
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 2px;
  width: 100%;

  &.visible {
    opacity: 1;
    transition: all 0.2s;
    visibility: visible;
  }
  &.hidden {
    display: none;
    opacity: 0;
    transition: all 0.2s;
    visibility: hidden;
  }
`;

const InputComponent = styled.input`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  color: var(--dts_dark);
  box-shadow: none;
  outline: none;
`;

const ErrorText = styled(Text)`
  width: 100%;
  color: red;
  min-height: 16px;
`;

const SecondSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
`;

export default AddCompanyDialog;

import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, TextType, TextColor } from '../components/dsm/Text';
import styled from 'styled-components';
import { ReactComponent as InvoiceIcon } from '../../icons/filters/invoice.svg';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { ReactComponent as PayedIcon } from '../../icons/payed.svg';
import { ReactComponent as UnpainedIcon } from '../../icons/unpained.svg';
import { ReactComponent as DropdownIcon } from '../../icons/arrowDown.svg';
import { ReactComponent as EmailIcon } from '../../icons/email.svg';
import { ReactComponent as PrintIcon } from '../../icons/print.svg';
import { ReactComponent as PreviewIcon } from '../../icons/preview.svg';
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg';
import { InvoiceListItemUi } from '../ui-model/invoice/InvoiceUiModel';
import { ContextMenu, ContextMenuOffset, ContextMenuOption } from '../components/dsm/ContextMenu';
import InvoicePDF from './InvoicePDF';
import { useInvoiceRepositoryImplementation } from '../../data/invoice/InvoiceRepositoryImplementation';
import { useInvoiceViewModel } from './InvoiceViewModel';
import { InvoiceUi } from '../ui-model/invoice/InvoiceUiModel';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useCompany } from '../hooks/useCompany';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useE_facturaRepositoryImplementation } from '../../data/e-factura/E_facturaRepositoryImplementation';
import { getEfacturaStatusIcon } from '../ui-model/e-factura/E-factura';

export type InvoiceListItemProps = {
  index: number;
  model: InvoiceListItemUi;
  handleSort: () => void;
  handleOpenDetails: (invoiceId: number, cases: Array<number>, invoiceNumber: string) => void;
  handelePaid: (invoiceId: number, state: number) => void;
  deleteInvoice: (invoiceId: number, state: number) => void;
  sortASC: boolean;
  refParent: any;
};

type InvoiceDialog = OpenInvoiceDialog | ClosedInvoiceDialog;

type OpenInvoiceDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedInvoiceDialog = { kind: 'Closed' };

export const InvoiceListItem: FC<InvoiceListItemProps> = (invoiceListItemProps) => {
  const {
    index,
    model,
    handleSort,
    sortASC,
    handleOpenDetails,
    handelePaid,
    deleteInvoice,
    refParent,
  } = invoiceListItemProps;

  const repo = useInvoiceRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();
  const featureRepo = featuresRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const {
    getInvoiceById,
    validSubscription,
    getEfacturInvoiceStatusById,
    getEfacturInvoiceStatusByIdFromCache,
  } = useInvoiceViewModel(repo, loggedUserRepository, featureRepo, e_factuarRepository);
  const alertManager = useAlertManagerImplementation();
  const { showAlert, showPaymentExpireModal } = useAlertViewModel(alertManager);

  const navigate = useNavigate();
  const translate = useTranslate();
  const currentCompany = useCompany();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });
  const [contextMenuTop, setContextMenuTop] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<InvoiceUi>();
  const [print, setPrint] = useState<'print' | ''>('');
  const [invoiceStatusIcon, setInvoiceStatusIcon] = useState<JSX.Element | null>(null);

  useEffect(() => {
    getInvoiceById(model.id).then((resp) => {
      if (resp.statusCode === 200) {
        if (resp.invoiedata !== null) {
          setInvoice(resp.invoiedata);
        }
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
      }
    });
  }, [repo.getInvoiceById]);

  useEffect(() => {
    if (model.einvoiceId !== null) {
      const status = getEfacturInvoiceStatusByIdFromCache(model.id);
      if (status === undefined) {
        getEfacturInvoiceStatusById(model.id).then((resp) => {
          setInvoiceStatusIcon(getEfacturaStatusIcon(resp, 18, 20));
        });
      } else {
        setInvoiceStatusIcon(getEfacturaStatusIcon(status.eInvoiceSatus, 18, 20));
      }
    }
  }, []);

  const [invoiceDialog, setInvoiceDialog] = useState<InvoiceDialog>({
    kind: 'Closed',
  });

  const closeInvoiceDialog = () => {
    setInvoiceDialog({
      kind: 'Closed',
    });
  };

  const openInvoiceDialog = () => {
    setInvoiceDialog({
      kind: 'Open',
      onClose: closeInvoiceDialog,
    });
  };

  const openDetailsPage = () => {
    if (validSubscription) {
      handleOpenDetails(model.id, model.caseIds, model.invoiceNumber);
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    if (refParent.current?.offsetHeight < offset.y + 180) {
      setContextMenuTop(true);
    } else {
      setContextMenuTop(false);
    }
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const onHandlePaid = () => {
    if (validSubscription) {
      let isPaid = 0;
      if (!model.invoiceState) {
        isPaid = 1;
      }
      handelePaid(model.id, isPaid);
    } else {
      showPaymentExpireModal(currentCompany.userRole);
    }
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <PreviewIcon width={24} height={24} />,
      label: translate(LANGUAGE_KEYS.PREVIEW),
      onClick: () => {
        openInvoiceDialog();
      },
    },
    {
      key: 1,
      icon: <PrintIcon width={24} height={24} />,
      label: translate(LANGUAGE_KEYS.PRINT),
      onClick: () => {
        if (validSubscription) {
          setPrint('print');
          setTimeout(() => setPrint(''), 2000);
        } else {
          showPaymentExpireModal(currentCompany.userRole);
        }
      },
    },
    {
      key: 2,
      icon: <EmailIcon width={24} height={24} />,
      label: translate(LANGUAGE_KEYS.MAIL),
      onClick: () => {
        if (validSubscription) {
          navigate('mailto:');
        } else {
          showPaymentExpireModal(currentCompany.userRole);
        }
      },
    },
    {
      key: 3,
      icon: <DeleteIcon width={24} height={24} />,
      label: translate(LANGUAGE_KEYS.DELETE),
      onClick: () => {
        if (validSubscription) {
          deleteInvoice(model.id, 2);
        } else {
          showPaymentExpireModal(currentCompany.userRole);
        }
      },
    },
  ];

  const header = () => {
    return (
      <ItemRow className='header'>
        <InvoiceNumberContainer>
          <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
            {translate(LANGUAGE_KEYS.INVOICE_NO)}
          </Text>
        </InvoiceNumberContainer>
        <CompanyContainer>
          <SortConatiner onClick={handleSort}>
            <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
              {translate(LANGUAGE_KEYS.COMPANY)}
            </Text>
            <CustomDropdownIcon className={`${!sortASC && 'desc'}`} />
          </SortConatiner>
        </CompanyContainer>
        <DateContainer>
          <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
            {translate(LANGUAGE_KEYS.CREATION_DATE)}
          </Text>
        </DateContainer>
        <InVoiceStateContainer>
          <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
            {translate(LANGUAGE_KEYS.INVOICE_STATE)}
          </Text>
        </InVoiceStateContainer>
        <InVoiceEFacturaStatus>
          <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
            {translate('e-Factura')}
          </Text>
        </InVoiceEFacturaStatus>
        <ActionContainer></ActionContainer>
      </ItemRow>
    );
  };

  const Row = () => {
    return (
      <ItemRow className={`row  ${isContextMenuOpen && 'hover'}`}>
        <InvoiceNumberContainer>
          <InvoiceNumber onClick={openDetailsPage}>
            <CustomInvoiceIcon width={20} height={20} />
            <Text type={TextType.BODY_2_BOLD} clr={TextColor.PRIMARY} ellipsis={true}>
              {model.invoiceNumber}
            </Text>
          </InvoiceNumber>
        </InvoiceNumberContainer>
        <CompanyContainer>
          <Text type={TextType.BODY_2} ellipsis={true}>
            {model.clientCompany}
          </Text>
        </CompanyContainer>
        <DateContainer>
          <Text type={TextType.BODY_2} ellipsis={true}>
            {model.creationDate}
          </Text>
        </DateContainer>
        <InVoiceStateContainer>
          <Button
            textType={TextType.CAPTION_3_BOLD}
            style={
              model.invoiceState === 0
                ? {
                    padding: '4px 5px 4px 4px',
                    maxWidth: '82px',
                    minWidth: '82px',
                    background: 'var(--dts_primary)',
                  }
                : {
                    padding: '4px 5px 4px 4px',
                    maxWidth: '82px',
                    minWidth: '82px',
                    background: 'var(--border-grey)',
                  }
            }
            onClick={() => {
              onHandlePaid();
            }}
          >
            {model.invoiceState === 0 && (
              <>
                <CustomPayedIcon width={10} height={10} />
                {translate(LANGUAGE_KEYS.UNPAID)}
              </>
            )}
            {model.invoiceState === 1 && (
              <>
                {' '}
                <CustomUnpainedIcon width={10} height={10} /> {translate(LANGUAGE_KEYS.PAID)}
              </>
            )}
          </Button>
        </InVoiceStateContainer>
        <InVoiceEFacturaStatus>
          {invoiceStatusIcon !== null && invoiceStatusIcon}
        </InVoiceEFacturaStatus>
        <ActionContainer>
          <CustomDotsIcon
            onClick={(e) => {
              e.preventDefault();
              handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
            }}
            width={16}
            height={16}
          />
          <ContextMenuStyle
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            options={ContextMenuOptions}
            offset={contextMenuOffset}
            className={`invoice-context ${contextMenuTop && 'top'}`}
          />
        </ActionContainer>
        {invoiceDialog.kind === 'Open' && invoice !== undefined && (
          <InvoicePDF
            invoiceId={model.id}
            invoiceModel={invoice}
            action='modal'
            onClose={invoiceDialog.onClose}
            isOpen={invoiceDialog.kind === 'Open'}
          />
        )}
        {invoice !== undefined && print === 'print' && (
          <InvoicePDF
            invoiceId={model.id}
            invoiceModel={invoice}
            action={print}
            onClose={() => {}}
            isOpen={false}
          />
        )}
      </ItemRow>
    );
  };

  return (
    <>
      {index === 0 && header()}
      <Row />
    </>
  );
};

const ItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;

  &.header {
    padding: 8px 24px 8px 24px;
  }

  &.row {
    border-radius: 3px;
    background: var(--dts_white);
    border: 1px solid var(--dts_withe_background);
    padding: 16px 24px 16px 24px;
    &:hover,
    &.hover {
      background: #1515151a;
      border: 1px solid #14141400;
    }
  }
`;

const InvoiceNumberContainer = styled.div`
  flex: 0 0 calc(32% - 24px);
  max-width: calc(32% - 24px);
  width: calc(32% - 24px);
  line-height: 1;
`;

const InvoiceNumber = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const CustomInvoiceIcon = styled(InvoiceIcon)`
  path {
    fill: var(--dts_primary);
  }
`;

const CompanyContainer = styled.div`
  flex: 0 0 calc(32% - 24px);
  max-width: calc(32% - 24px);
  width: calc(32% - 24px);
  line-height: 1;
`;

const SortConatiner = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  line-height: 1;
`;

const CustomDropdownIcon = styled(DropdownIcon)`
  &.desc {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
`;

const DateContainer = styled.div`
  flex: 0 0 calc(8% - 24px);
  max-width: calc(8% - 24px);
  width: calc(8% - 24px);
  min-width: 80px;
`;

const InVoiceStateContainer = styled.div`
  width: 82px;
`;

const InVoiceEFacturaStatus = styled.div`
  width: 78px;
  text-align: center;
`;

const CustomPayedIcon = styled(PayedIcon)`
  fill: var(--dts_white);
`;

const CustomUnpainedIcon = styled(UnpainedIcon)`
  fill: var(--dts_white);
`;

const ActionContainer = styled.div`
  flex-grow: 1;
  text-align: right;
  position: relative;
`;

const CustomDotsIcon = styled(DotsIcon)`
  cursor: pointer;
  flex-shrink: 0;
  :hover {
    path {
      fill: var(--dts_hover_blue);
    }
  }
`;

const ContextMenuStyle = styled(ContextMenu)`
  &.invoice-context {
    position: absolute;
    right: 0;
    top: 15px !important;
    left: unset !important;
  }
  &.invoice-context.top {
    right: 0;
    top: unset !important;
    left: unset !important;
    bottom: 20px;
  }
  svg {
    fill: var(--dts_black);
  }
`;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { CollaboratorItem } from '../user-management/CollaboratorItem';
import InvoicePDF from '../invoice/InvoicePDF';
import Avatar from '../components/generic/Avatar';
import { Text, TextType, TextColor } from '../components/dsm/Text';
import { Checkbox } from '../components/dsm/Checkbox';
import StateButton from '../components/dsm/buttons/StateButton';
import { ReactComponent as InvoiceIcon } from '../../icons/filters/invoice.svg';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

export type ReportsRowProps = {
  companyId: number | null;
  model: any;
  disabled: boolean;
  CaseStates: any;
  invoiceEnabled: boolean;
  onHandleCheck: (value: number, active: boolean) => void;
};

const MAX_COLLABORATORS = 9;

type InvoiceDialog = OpenInvoiceDialog | ClosedInvoiceDialog;

type OpenInvoiceDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedInvoiceDialog = { kind: 'Closed' };

export const ReportsRow: FC<ReportsRowProps> = (reportsRowProps) => {
  const { companyId, model, disabled, CaseStates, invoiceEnabled, onHandleCheck } = reportsRowProps;

  const translate = useTranslate();

  const {
    isTabletSmall,
    isTabletLarge,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();

  const [invoiceDialog, setInvoiceDialog] = useState<InvoiceDialog>({
    kind: 'Closed',
  });

  const closeInvoiceDialog = () => {
    setInvoiceDialog({
      kind: 'Closed',
    });
  };

  const openInvoiceDialog = () => {
    setInvoiceDialog({
      kind: 'Open',
      onClose: closeInvoiceDialog,
    });
  };

  const handleCheck = (value: number, active: boolean) => {
    onHandleCheck(value, active);
  };

  const renderCollaboratorsSection = (collaborators) => {
    if (collaborators.length > 0) {
      return collaborators?.map((coll, index) => {
        return (
          <CollaboratorItem
            key={index}
            collaborator={coll}
            index={index}
            maxCollaboratorsSize={MAX_COLLABORATORS}
            collaboratorsSize={collaborators.length}
            collaboratorsList={collaborators
              .slice(MAX_COLLABORATORS - 1, collaborators.length - 1)
              .map((c) => {
                return {
                  icon: (
                    <Avatar
                      fullName={c.name}
                      size={{ outer: 24, inner: 22 }}
                      avatarStyle={{ background: c.profileColor }}
                    />
                  ),
                  label: c.name,
                };
              })}
          />
        );
      });
    }
  };

  return (
    <ReportsRowContainer
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <div className='section one'>
        <ReportsColumnItem className={'checkbox'}>
          <Checkbox
            disabled={disabled}
            checkboxId={model.id}
            onClick={handleCheck}
            active={model.isSelected}
          />
        </ReportsColumnItem>
        <div className='information'>
          <ReportsColumnItem className={'id'}>
            <NavLinkStyled to={`/${companyId}/case/${model.id}`}>
              <Text
                clr={TextColor.PRIMARY}
                type={TextType.BODY_2_BOLD}
                ellipsis={true}
                style={{ cursor: 'pointer' }}
              >
                {model.id}
              </Text>
            </NavLinkStyled>
          </ReportsColumnItem>
          <ReportsColumnItem className={'client'}>
            <Text type={TextType.BODY_2_BOLD} ellipsis={true}>
              {model.clientName}
            </Text>
            {(isTabletSmall || isTabletLarge) && (
              <Text type={TextType.BODY_2} ellipsis={true}>
                {model.clientCompanyName}
              </Text>
            )}

            {(isTabletLarge || isTabletSmall) && (
              <div className='list'>{renderCollaboratorsSection(model.collaborators)}</div>
            )}
          </ReportsColumnItem>
          {!isTabletLarge && !isTabletSmall && (
            <ReportsColumnItem className={'cabinet'}>
              <Text type={TextType.BODY_2} ellipsis={true}>
                {model.clientCompanyName}
              </Text>
            </ReportsColumnItem>
          )}
          {!isTabletLarge && !isTabletSmall && (
            <ReportsColumnItem className={'patient'}>
              <Text type={TextType.BODY_2}>{model.patient}</Text>
            </ReportsColumnItem>
          )}
          {!isTabletLarge && !isTabletSmall && (
            <ReportsColumnItem className={'collaborators list'}>
              {renderCollaboratorsSection(model.collaborators)}
            </ReportsColumnItem>
          )}
        </div>
      </div>
      <div className='section two'>
        <div className='patient-information'>
          {(isTabletSmall || isTabletLarge) && (
            <ReportsColumnItem className={'patient'}>
              <Text type={TextType.BODY_2}>{translate(LANGUAGE_KEYS.PATIENT)}: </Text>
              <Text type={TextType.BODY_2_BOLD} ellipsis={true}>
                {model.patient}
              </Text>
            </ReportsColumnItem>
          )}
          <ReportsColumnItem className={'creation'}>
            <Text type={TextType.BODY_2}>{model.creationDate}</Text>
          </ReportsColumnItem>
        </div>
        <div className='states'>
          <ReportsColumnItem className={'caseState'}>
            <StateButton
              state={CaseStates.find((cs) => cs.id === model.caseStatus) || CaseStates[0]}
              click={{ clickable: false }}
            />
          </ReportsColumnItem>
          <ReportsColumnItem className={'invoice'}>
            {model.invoiceNumber !== null && model.invoicePrefix !== null && (
              <>
                <InvoiceNumberDetails
                  className={`${invoiceEnabled && 'enabled'}`}
                  onClick={() => {
                    if (invoiceEnabled) {
                      openInvoiceDialog();
                    }
                  }}
                >
                  <InvoiceIcon />
                  <Text type={TextType.CAPTION_2_BOLD_12} clr={TextColor.PRIMARY} ellipsis={true}>
                    {model.invoicePrefix} {model.invoiceNumber}
                  </Text>
                </InvoiceNumberDetails>
                {invoiceDialog.kind === 'Open' && (
                  <InvoicePDF
                    invoiceId={model.invoiceId}
                    action='modal'
                    onClose={invoiceDialog.onClose}
                    isOpen={invoiceDialog.kind === 'Open'}
                  />
                )}
              </>
            )}
          </ReportsColumnItem>
        </div>
      </div>
    </ReportsRowContainer>
  );
};
const BaseContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-self: stretch;
    flex-grow: 0;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    .section{
      display: flex;
      align-self: stretch;
      flex-grow: 0;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      &.one {
        flex-grow: 1;
        .information {
          display: flex;
          flex-grow: 1;
          align-self: stretch;
          flex-direction: row;
          align-items: center;
          gap: 24px;
        }
      }
      .patient-information {
        display: flex;
        align-self: stretch;
        flex-grow: 0;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
      .states {
        display: flex;
        align-self: stretch;
        flex-grow: 0;
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      justify-content: space-between;
      gap: 12px;
      .section{
        display: flex;
        align-self: center;
        flex-grow: 0;
        flex-direction: column;
        gap: 12px;
        &.one {
          flex-grow: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          .information {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 4px;
            min-width: 439px;
            max-width: 439px;
            .list {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              margin-left: 4px;
              margin-top: 4px;
            }
          }
        }
        &.two {
          max-width: calc(100% - 487px);
        }
        .patient-information {
          display: flex;
          flex-direction: column;
          gap: 12px;
          text-align: end;
          align-self: flex-end;
          align-items: flex-end;
          max-width: 100%;
          .patient {
            justify-content: flex-end;
            max-width: 100%;
          }
        }
        .states {
          display: flex;
          gap: 12px;
          .invoice{
            justify-content: center;
          }
          justify-content: flex-end;
        }
      }
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ReportsColumnItem = styled.div`
  &.checkbox {
    min-width: 24px;
    max-width: 24px;
    text-align: center;
    .checkbox {
      margin: auto;
    }
  }
  &.id {
    min-width: 60px;
    max-width: 60px;
  }
  &.client {
    flex-grow: 1;
    flex-basis: 0;
  }
  &.cabinet,
  &.patient {
    min-width: 160px;
    display: flex;
    gap: 5px;
    flex-grow: 1;
    flex-basis: 0;
  }
  &.collaborators {
    min-width: 204px;
    max-width: 204px;
    &.list {
      display: flex;
      alignitems: center;
      boxsizing: border-box;
      marginleft: 4px;
    }
  }
  &.creation {
    min-width: 72px;
    max-width: 72px;
    p {
      display: inline-flex;
      gap: 4px;
      align-items: center;
      cursor: pointer;
    }
    &.up svg {
      transform: rotate(180deg) !important;
    }
  }
  &.caseState {
    min-width: 82px;
    max-width: 82px;
  }
  &.invoice {
    display: flex;
    gap: 4px;
    align-items: center;
    min-width: 83px;
    max-width: 83px;
    P {
      display: flex;
      gap: 2px;
      flex-wrap: wrap;
      align-items: center;
    }
    svg {
      width: 10px;
      height: 10px;
      fill: var(--dts_primary);
    }
  }
`;

const InvoiceNumberDetails = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  &.enabled {
    cursor: pointer;
  }
`;

const ReportsRowContainer = styled(BaseContainer)`
  width: calc(100% - 48px);
  padding: 16px 24px;
  background: var(--dts_white);
  &:not(last-child) {
    margin-bottom: 1px;
  }
`;

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;

import { LANGUAGE_KEYS } from '../presentation/translations/languageKeys';

export const Genders: Array<Gender> = [
  {
    id: 0,
    name: LANGUAGE_KEYS.MALE,
  },
  {
    id: 1,
    name: LANGUAGE_KEYS.FEMALE,
  },
  {
    id: 2,
    name: LANGUAGE_KEYS.OTHER,
  },
  {
    id: 3,
    name: LANGUAGE_KEYS.NEUTRAL,
  },
];

export type Gender = { id: number; name: string };

// type Male = { id: 0; name: LANGUAGE_KEYS.MALE };
// type Female = { id: 1; name: 'Female' };
// type Other = { id: 2; name: 'Other' };
// type Neutral = { id: 3; name: 'Neutral' };

export enum AlertType {
  NO_ALLERT = -1,
  FAILD = 0,
  SUCCESS = 1,
  EXPIRE_PAYMENT = 2,
}

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SHOW_PAYMENT_EXPIRE_MODAL = 'SHOW_PAYMENT_EXPIRE_MODAL';
export const HIDE_PAYMENT_EXPIRE_MODAL = 'HIDE_PAYMENT_EXPIRE_MODAL';

interface AlertManager {
  // CaseState
  message: string;
  alertType: AlertType;
  isOpenExpireAlert: boolean;
  userRole: number | null;

  //Actions
  showAlert(message: string, type: AlertType): void;
  hideAlert(): void;
  showPaymentExpireModal(type: AlertType, userRole: number | null);
  hidePaymentExpireModal();
}

export type { AlertManager };

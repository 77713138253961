import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';

type templateRepository = Pick<TemplateRepository, 'removeTemplateFromSubWorkType'>;

const removeTemplateFromSubWorkTypeUseCase = (
  repo: templateRepository,
  templateID: number,
  extensionID?: number,
) => {
  repo.removeTemplateFromSubWorkType(templateID, extensionID);
};

export { removeTemplateFromSubWorkTypeUseCase };

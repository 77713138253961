import React from 'react';
import { authenticateUserUseCases } from '../../../useCases/signUpFlow/authenticateUserUseCases';
import { SignUpFlowRepository } from '../../../domain/signUpFlow/SignUpFlowRepository';
import { resetPasswordUseCases } from '../../../useCases/signUpFlow/resetPasswordUseCase';
import { setPasswordUseCases } from '../../../useCases/signUpFlow/setPasswordUseCase';
import { addUserUseCases } from '../../../useCases/signUpFlow/addUserUseCase';
import { updateUserUseCases } from '../../../useCases/signUpFlow/updateUserUseCase';
import { UpdateUserData } from '../../../domain/signUpFlow/UpdateUserData';

function useSignUpFlowViewModel(repo: SignUpFlowRepository) {
  const authenticateUser = React.useCallback(
    function (username: string, password: string) {
      return authenticateUserUseCases(
        { authenticateUser: repo.authenticateUser },
        username,
        password,
      );
    },
    [repo.authenticateUser],
  );

  const addUser = React.useCallback(
    function (username: string, isEnabledMailList: boolean) {
      return addUserUseCases(repo, username, isEnabledMailList);
    },
    [repo.addUser],
  );

  const setPassword = React.useCallback(
    function (emailKey: string, passwordKey: string, isRecover: boolean) {
      return setPasswordUseCases(
        { setPassword: repo.setPassword },
        { emailKey: emailKey, passwordKey: passwordKey },
        isRecover,
      );
    },
    [repo.setPassword],
  );

  const resetPassword = React.useCallback(
    function (username: string) {
      return resetPasswordUseCases({ resetPassword: repo.resetPassword }, username);
    },
    [repo.resetPassword],
  );

  const updateUser = React.useCallback(
    function (data: UpdateUserData) {
      return updateUserUseCases({ updateUser: repo.updateUser }, data);
    },
    [repo.updateUser],
  );
  return {
    authenticateUser,
    addUser,
    setPassword,
    resetPassword,
    updateUser,
  };
}

export { useSignUpFlowViewModel };

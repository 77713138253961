import React from 'react';
import {
  ToothConnectionL1IconStyled,
  ToothConnectionL2IconStyled,
  ToothConnectionL3IconStyled,
  ToothConnectionL4IconStyled,
  ToothConnectionL5IconStyled,
  ToothConnectionL6IconStyled,
  ToothConnectionL7IconStyled,
  ToothConnectionL8IconStyled,
  ToothConnectionU1IconStyled,
  ToothConnectionU2IconStyled,
  ToothConnectionU3IconStyled,
  ToothConnectionU4IconStyled,
  ToothConnectionU5IconStyled,
  ToothConnectionU6IconStyled,
  ToothConnectionU7IconStyled,
  ToothConnectionU8IconStyled,
} from './TeethConnectionIcons';

interface ToothParams {
  leftTooth: number;
  quarter: number;
  width: string;
  height: string;
  left: string;
  top: string;
  isSelected: boolean;
  isActive: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export function ToothConnection(p: ToothParams) {
  const rotateY = p.quarter === 1 || p.quarter === 4 ? '' : 'scaleX(-1)';
  const scale = p.isActive ? 'scale(3.5)' : '';
  const opacity = p.isSelected && p.isActive ? '1' : '0.4';
  const color = p.isSelected ? 'var(--dts_default_blue)' : '#848484';
  const onClick = p.isActive ? p.onClick : () => {};

  return (
    <ToothConnectionIconStyled
      quarter={p.quarter}
      leftTooth={p.leftTooth}
      color={color}
      opacity={opacity}
      rotatey={rotateY}
      scale={scale}
      onClick={onClick}
      onMouseDown={p.isActive ? p.onMouseDown : () => {}}
      onMouseUp={p.isActive ? p.onMouseUp : () => {}}
      onMouseEnter={p.isActive ? p.onMouseEnter : () => {}}
      onMouseLeave={p.isActive ? p.onMouseLeave : () => {}}
      style={{
        width: p.width,
        height: p.height,
        left: p.left,
        top: p.top,
      }}
    />
  );
}

interface ToothConnectionIconStyledParams {
  quarter: number;
  leftTooth: number;
  rotatey: string;
  scale: string;
  color: string;
  opacity: string;
  style: any;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function ToothConnectionIconStyled(props: ToothConnectionIconStyledParams) {
  switch (props.quarter) {
    case 1:
    case 2:
      switch (props.leftTooth) {
        case 1:
          return (
            <ToothConnectionU1IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 2:
          return (
            <ToothConnectionU2IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 3:
          return (
            <ToothConnectionU3IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 4:
          return (
            <ToothConnectionU4IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 5:
          return (
            <ToothConnectionU5IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 6:
          return (
            <ToothConnectionU6IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 7:
          return (
            <ToothConnectionU7IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 8:
          return (
            <ToothConnectionU8IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        default:
          return <></>;
      }
    case 3:
    case 4:
      switch (props.leftTooth) {
        case 1:
          return (
            <ToothConnectionL1IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 2:
          return (
            <ToothConnectionL2IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 3:
          return (
            <ToothConnectionL3IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 4:
          return (
            <ToothConnectionL4IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 5:
          return (
            <ToothConnectionL5IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 6:
          return (
            <ToothConnectionL6IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 7:
          return (
            <ToothConnectionL7IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        case 8:
          return (
            <ToothConnectionL8IconStyled
              rotatey={props.rotatey}
              color={props.color}
              opacity={props.opacity}
              scale={props.scale}
              style={props.style}
              onMouseDown={props.onMouseDown}
              onMouseUp={props.onMouseUp}
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              onClick={props.onClick}
            />
          );
        default:
          return <></>;
      }
    default:
      return <></>;
  }
}

import { AppRootStore } from '../appRepositoryImplementation';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { PhaseRepository } from '../../domain/PhaseRepository';
import {
  deletePhaseAction,
  getPhasesAction,
  newPhaseAction,
  updatePhaseAction,
} from './phaseActions';
import { PhaseRepositoryState } from './phaseReducer';
import { UpdatePhase } from '../../domain/worktype/UpdatePhase';

const phasesSelector = (state: AppRootStore) => state.phase;

const usePhaseRepositoryImplementation = (): PhaseRepository => {
  const { phases, isLoading, isUpdating } = useSelector<AppRootStore, PhaseRepositoryState>(
    phasesSelector,
  );

  const dispatch = useDispatch();

  const getPhases = React.useCallback(() => getPhasesAction()(dispatch), [dispatch]);

  const newPhase = React.useCallback((name: string) => newPhaseAction(name)(dispatch), [dispatch]);

  const deletePhase = React.useCallback(
    (id: number) => deletePhaseAction(id)(dispatch),
    [dispatch],
  );

  const updatePhase = React.useCallback(
    (phase: UpdatePhase) => updatePhaseAction(phase)(dispatch),
    [dispatch],
  );

  return {
    phases,
    isLoading,
    isUpdating,
    getPhases,
    newPhase,
    deletePhase,
    updatePhase,
  };
};

export { usePhaseRepositoryImplementation };

import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import DraggableItem from './DraggableItem';
import { TemplatePhaseUiModel } from '../../../../../ui-model/worktype/TemplatePhaseUiModel';
import { WorkTypeTemplateSubCategoryUiModel } from '../../../../../ui-model/worktype/WorkTypeTemplateSubCategoryUiModel';

const DroppableItem: React.FC<{
  list: TemplatePhaseUiModel[] | WorkTypeTemplateSubCategoryUiModel[];
  droppableId: string;
  type: string;
  draggableType?: string;
  deleteItem: (
    item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel,
    index: number,
  ) => void;
  onClick: (item: TemplatePhaseUiModel | WorkTypeTemplateSubCategoryUiModel) => void;
}> = ({ list, droppableId, type, draggableType, deleteItem, onClick }) => {
  const renderPhaseDroppableItem = () => {
    return (
      <DroppableContainer>
        {type === 'all' ? (
          <Droppable droppableId={droppableId} isDropDisabled={true}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                data-dragging={snapshot.isDragging}
              >
                {list?.map((item, index) => (
                  <DraggableItem
                    index={index}
                    item={item}
                    key={index}
                    type={type}
                    deleteItem={deleteItem}
                    onClick={onClick}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                data-dragging={snapshot.isDragging}
              >
                {list?.map((item, index) => (
                  <DraggableItem
                    index={index}
                    item={item}
                    key={index}
                    type={type}
                    deleteItem={deleteItem}
                    onClick={onClick}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DroppableContainer>
    );
  };

  const renderExtentionDroppableItem = () => {
    return (
      <DroppableContainer>
        {type === 'all' ? (
          <Droppable droppableId={droppableId} isReorderEnabled={false} isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list?.map((item, index) => (
                  <DraggableItem
                    index={index}
                    item={item}
                    key={item.id}
                    type={type}
                    deleteItem={deleteItem}
                    draggableType={draggableType}
                    onClick={onClick}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : (
          <Droppable droppableId={droppableId} isReorderEnabled={false}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {list?.map((item, index) => (
                  <DraggableItem
                    index={index}
                    item={item}
                    key={item.id}
                    type={type}
                    deleteItem={deleteItem}
                    draggableType={draggableType}
                    onClick={onClick}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DroppableContainer>
    );
  };

  return draggableType === 'extention'
    ? renderExtentionDroppableItem()
    : renderPhaseDroppableItem();
};

const DroppableContainer = styled.div`
  > [data-rbd-droppable-context-id] {
    width: 248px;
    height: 304px;
    @media (max-width: 700px) {
      width: 230px;
    }
  }
`;

export default DroppableItem;

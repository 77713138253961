import { BillingRepository } from '../../domain/billing/BillingRepository';

type GetMaxCollaboratorsRepository = Pick<BillingRepository, 'getMaxCollaborators'>;

const getMaxCollaboratorsUseCase = (repo: GetMaxCollaboratorsRepository) => {
  return repo.getMaxCollaborators();
};

export { getMaxCollaboratorsUseCase };
export type { GetMaxCollaboratorsRepository };

import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoIcon } from '../../icons/logo.svg';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextType } from '../components/dsm/Text';
import useTranslate from '../translations/useTranslate';
import { ReactComponent as FailedIcon } from '../../icons/FailedIcon.svg';
import { Button } from '../components/dsm/buttons/Button';

export type FailedProps = {
  tryAgain: () => void;
};

const E_facturaFailedScreen = (props: FailedProps) => {
  const translate = useTranslate();

  const { tryAgain } = props;

  return (
    <Container>
      <LogoIcon className='logo' width={242} height={48} />
      <Text type={TextType.TITLE_BOLD_4}>{translate(LANGUAGE_KEYS.AUTHORIZATION_ERROR)}</Text>
      <FailedIcon width={60} height={60} />
      <Text type={TextType.BODY_REGULAR}>
        {translate(LANGUAGE_KEYS.AUTHORIZATION_ERROR_SUBTITLE)}
      </Text>
      <ErrorDescription>
        {translate(LANGUAGE_KEYS.AUTHORIZATION_ERROR_DESCRIPTION)}
      </ErrorDescription>
      <Button textType={TextType.CAPTION_BOLD} secondary={'secondary'} onClick={tryAgain}>
        {translate(LANGUAGE_KEYS.TRY_AGAIN)}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 518px;
  min-width: 518px;
  margin: 230px auto auto auto;
  .logo {
    margin-bottom: 48px;
  }
  p {
    margin: 0px;
  }
  svg {
    fill: var(--dts_red);
    margin: 14px 0px 14px;
  }
`;

const ErrorDescription = styled.div`
  padding: 8px;
  background: var(--dts_withe_background);
  width: 100%;
  margin-bottom: 48px;
`;

export default E_facturaFailedScreen;

import React, { FC } from 'react';

import { ReactComponent as HealthyTooth } from '../../../../../icons/teeths/0-teeth.svg';
import { ReactComponent as RootWithCrown } from '../../../../../icons/teeths/1-teeth.svg';
import { ReactComponent as Crown } from '../../../../../icons/teeths/2-teeth.svg';
import { ReactComponent as Prosthetics } from '../../../../../icons/teeths/3-teeth.svg';
import { ReactComponent as ImplantWithCrown } from '../../../../../icons/teeths/4-teeth.svg';
import { ReactComponent as Tap } from '../../../../../icons/teeths/5-teeth.svg';
import { ReactComponent as TapWithProsthetics } from '../../../../../icons/teeths/6-teeth.svg';
import { ReactComponent as TapWithCrown } from '../../../../../icons/teeths/7-teeth.svg';
import { ReactComponent as BarWithImplant } from '../../../../../icons/teeths/8-teeth.svg';
import { ReactComponent as Bar } from '../../../../../icons/teeths/9-teeth.svg';
import { ReactComponent as Telescope } from '../../../../../icons/teeths/10-teeth.svg';
import { ReactComponent as ImplantWithProsthetics } from '../../../../../icons/teeths/11-teeth.svg';
import { ReactComponent as Rail } from '../../../../../icons/teeths/12-teeth.svg';
import { ReactComponent as Link } from '../../../../../icons/teeths/13-teeth.svg';

export type TeethImageProps = {
  typeTeeth: number;
  width: number;
  height: number;
  isCustomSize: boolean;
};

function selectTeethType(typeTeeth: number, width: number, height: number, isCustomSize: boolean) {
  switch (typeTeeth) {
    case 0:
      if (isCustomSize) return <HealthyTooth width={width} height={height} />;
      else return <HealthyTooth />;
    case 1:
      if (isCustomSize) return <RootWithCrown width={width} height={height} />;
      else return <RootWithCrown />;
    case 2:
      if (isCustomSize) return <Crown width={width} height={height} />;
      else return <Crown />;
    case 3:
      if (isCustomSize) return <Prosthetics width={width} height={height} />;
      else return <Prosthetics />;
    case 4:
      if (isCustomSize) return <ImplantWithCrown width={width} height={height} />;
      else return <ImplantWithCrown />;
    case 5:
      if (isCustomSize) return <Tap width={width} height={height} />;
      else return <Tap />;
    case 6:
      if (isCustomSize) return <TapWithProsthetics width={width} height={height} />;
      else return <TapWithProsthetics />;
    case 7:
      if (isCustomSize) return <TapWithCrown width={width} height={height} />;
      else return <TapWithCrown />;
    case 8:
      if (isCustomSize) return <BarWithImplant width={width} height={height} />;
      else return <BarWithImplant />;
    case 9:
      if (isCustomSize) return <Bar width={width} height={height} />;
      else return <Bar />;
    case 10:
      if (isCustomSize) return <Telescope width={width} height={height} />;
      else return <Telescope />;
    case 11:
      if (isCustomSize) return <ImplantWithProsthetics width={width} height={height} />;
      else return <ImplantWithProsthetics />;
    case 12:
      if (isCustomSize) return <Rail width={width} height={height} />;
      else return <Rail />;
    case 13:
      if (isCustomSize) return <Link width={width} height={height} />;
      else return <Link />;
  }
}

export const TeethImage: FC<TeethImageProps> = (teethImageProps) => {
  return (
    <>
      {selectTeethType(
        teethImageProps.typeTeeth,
        teethImageProps.width,
        teethImageProps.height,
        teethImageProps.isCustomSize,
      )}
    </>
  );
};

import * as React from 'react';
import { useState } from 'react';
import { ALL } from './DashboardFilter';

// Step 1. create the context

export const DashboardContext = React.createContext({} as any);

export function DashboardProvider(props: { children }) {
  const [menuSelection, setMenuSelection] = useState(ALL);

  return (
    <DashboardContext.Provider value={[menuSelection, setMenuSelection]}>
      {props.children}
    </DashboardContext.Provider>
  );
}

import React, { FC } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Text, TextType, TextColor } from '../../../components/dsm/Text';
import { PayOffLabworkCard } from './PayOffLabworkCard';
import { PayOffUIModel, PayOffCollaborator } from '../../../ui-model/reports/PayOffUIModel';

export type PayOffRowProps = {
  companyId: number | null;
  payOffModel: PayOffUIModel;
  solvedPhase: (
    caseId: number,
    isPaid: boolean,
    labworkId: number,
    solvedPhaseIds: Map<string, number>,
    unSolvedPhaseIds: Map<string, number>,
  ) => void;
  selectedCollaborator: PayOffCollaborator;
};

export const PayOffRow: FC<PayOffRowProps> = (payOffRowProps) => {
  const { companyId, payOffModel, solvedPhase, selectedCollaborator } = payOffRowProps;

  const handleSolvedPhase = (
    labworkId: number,
    isPaid: boolean,
    solvedPhaseIds: Map<string, number>,
    unSolvedPhaseIds: Map<string, number>,
  ) => {
    solvedPhase(payOffModel.caseId, isPaid, labworkId, solvedPhaseIds, unSolvedPhaseIds);
  };

  return (
    <RowBody>
      <RowHeader>
        <NavLinkStyled to={`/${companyId}/case/${payOffModel.caseId}`}>
          <Text clr={TextColor.PRIMARY} type={TextType.BODY_2_BOLD} style={{ cursor: 'pointer' }}>
            {payOffModel.caseId}
          </Text>
        </NavLinkStyled>
        <TitleConatiner>
          <Text type={TextType.BODY_2_BOLD}>{payOffModel.clientName}</Text>
          <Text type={TextType.BODY_2}>{payOffModel.companyName}</Text>
          <Text type={TextType.BODY_2}>{payOffModel.patientName}</Text>
        </TitleConatiner>
      </RowHeader>
      {payOffModel.labworks.map((labwork, index) => {
        if (
          selectedCollaborator.labworkIds?.some((l) => l === labwork.labworkId) ||
          selectedCollaborator.collaboratorId === -1
        ) {
          return (
            <PayOffLabworkCard
              key={index}
              payOffLabworkModel={labwork}
              solvedPhase={handleSolvedPhase}
              selectedCollaborator={selectedCollaborator}
            />
          );
        }
      })}
    </RowBody>
  );
};

const RowBody = styled.div`
  padding: 24px;
  background: var(--dts_white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const RowHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  gap: 24px;
`;

const TitleConatiner = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  gap: 8px;
`;

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as MagnifierIcon } from '../../../icons/magnifier.svg';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { KeyboardsKeys } from './KeyboardsKeys';

export type InputProps = {
  onChange: (event) => void;
  onKeyDown: (event) => void;
  onBlur?: (value: string) => void;
  clickClose: () => void;
  placeHolder?: string;
  width: string;
  initValue?: string;
  customStyle?: any;
  isSearch?: boolean;
  pattern?: string;
};

export const InputText: FC<InputProps> = ({
  onChange,
  onKeyDown,
  onBlur,
  clickClose,
  placeHolder,
  width,
  initValue,
  customStyle,
  isSearch,
  pattern,
}) => {
  const [value, setValue] = useState(initValue ? initValue : '');
  const [searchActive, setSearchActive] = useState(false);

  function handleChange(event) {
    if (event.target.value == '') {
      setSearchActive(false);
    } else {
      setSearchActive(true);
    }
    setValue(event.target.value);
    onChange(event);
  }

  function handleOnKeyDown(event) {
    switch (event.keyCode) {
      case KeyboardsKeys.ENTER:
        break;
      case KeyboardsKeys.ESCAPE:
        if (initValue !== undefined) {
          setValue(initValue);
        }
        break;
    }
    onKeyDown(event);
  }

  function handleOnBlur() {
    if (onBlur !== undefined) {
      onBlur(value);
    }
  }

  function clickSearch() {
    console.log('click search icon');
  }

  function handleOnClose() {
    clickClose();
    setSearchActive(false);
    setValue('');
  }

  const inputStyles = {
    width: width,
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#151515',
    border: ' none',
    boxShadow: 'none', // no box-shadow
    outline: 'none',
  };

  return isSearch ? (
    <SearchComponent>
      <input
        type='text'
        placeholder={placeHolder ? placeHolder : 'enter text here'}
        value={value}
        style={customStyle ? customStyle : inputStyles}
        onChange={handleChange}
        onBlur={handleOnBlur}
      />
      {!searchActive && (
        <span onClick={clickSearch}>
          <MagnifierIcon />
        </span>
      )}
      {searchActive && (
        <span onClick={handleOnClose}>
          <CloseIcon />
        </span>
      )}
    </SearchComponent>
  ) : (
    <input
      type='text'
      placeholder={placeHolder ? placeHolder : 'enter text here'}
      value={value}
      style={customStyle ? customStyle : inputStyles}
      onChange={handleChange}
      onKeyDown={handleOnKeyDown}
      pattern={pattern}
      onBlur={handleOnBlur}
    />
  );
};

const SearchComponent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  span {
    padding: 7px 8px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: var(--dts_black);
      }
    }
    :hover {
      svg {
        path {
          fill: var(--dts_pressed_blue);
        }
      }
    }
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DropdownIcon } from '../../../icons/arrowDown.svg';
import { Text, TextType } from '../dsm/Text';
import Tooltip from '../dsm/Tooltip';
import { ReactComponent as SelectedIcon } from '../../../icons/SelectedDropDownItem.svg';
import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

export type DropdownProps = {
  values: Array<DropdownValue>;
  textType?: TextType;
  maxHeightOptions?: number;
  hasSuccessIcon: boolean;
  disabled?: boolean;
  onChange?: (changedValues: DropdownProps['values']) => void;
  onSelect?: (selectedValueKey: DropdownValue['key']) => void;
};

export type DropdownValue = { key: number; label: string; selected: boolean };

export const Dropdown = (props: DropdownProps) => {
  const { values, textType, maxHeightOptions, hasSuccessIcon, disabled, onChange, onSelect } =
    props;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const optionRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  useEffect(() => {
    isOpen && containerRef?.current?.focus();
    optionRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [isOpen, optionRefs]);

  const getSelectedLabel = () =>
    values.find((value) => value.selected)?.label || (values.length > 0 && values[0].label);

  const handleOnOptionClick = (key: DropdownValue['key']) => {
    onChange && onChange(values.map((value) => ({ ...value, selected: value.key === key })));
    onSelect && onSelect(key);
  };

  return (
    <>
      <Container
        className={`${disabled && 'disabled'}`}
        ref={containerRef}
        tabIndex={-1}
        onBlur={() => setIsOpen(false)}
        onClick={() => {
          !disabled && setIsOpen(!isOpen);
        }}
      >
        <Selected>
          <Text type={textType ? textType : TextType.CAPTION_REGULAR_2} ellipsis={true}>
            {getSelectedLabel()}
          </Text>
          <DropdownIconStyled className={`${isOpen && 'open'}`} />
        </Selected>
        {isOpen && (
          <OptionsContainer
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <Options
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              style={{ maxHeight: `${maxHeightOptions}px` }}
              className='custom-scrollbar'
            >
              {values.map((value, index) => (
                <Tooltip
                  key={index}
                  content={value.label}
                  disabled={!visibleTooltips.includes(index)}
                >
                  <Option
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    key={value.key}
                    onClick={() => handleOnOptionClick(value.key)}
                    className={`${hasSuccessIcon && value.selected && 'active'}`}
                  >
                    <Text
                      ref={(el) => {
                        if (el) optionRefs.current[index] = el;
                      }}
                      type={textType ? textType : TextType.CAPTION_REGULAR}
                      ellipsis={true}
                    >
                      {value.label}
                    </Text>
                    {hasSuccessIcon && value.selected && <CustomSelectedIcon />}
                  </Option>
                </Tooltip>
              ))}
            </Options>
          </OptionsContainer>
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  background-color: var(--dts_white);
  padding: 8.5px 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  &.disabled {
    cursor: not-allowed;
  }
`;

const Selected = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const OptionsContainer = styled.div<DeviceSizeProps>(
  ({ mobile, tablet_small, tablet_large, desktop }) => `
    width: 100%;
    position: absolute;
    box-sizing: border-box;
    margin-left: -8px;
    margin-top: 15px;
    z-index: 100;
    background-color: var(--dts_white);
    box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    cursor: initial;
    outline: none;
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 24px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 16px;
      max-width:136px;
    }
`,
);

export const Options = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 4px;
    flex-shrink: 0;
    //Desktop
    @media (min-width: ${desktop}px) {
      max-height: 350px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      max-height: 355px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      max-height: 255px;
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export const Option = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer !important;
    &.selected,
    &:hover {
      background-color: var(--dts_withe_background);
    }
    &.active {
      display: flex;
      justify-content: space-between;
      align-item: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
        linear-gradient(0deg, #f2f2f2, #f2f2f2);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 8px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 8px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 6px 8px 6px 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
`;

const CustomSelectedIcon = styled(SelectedIcon)`
  fill: var(--dts_black);
  width: 16px;
  height: 16px;
`;

import type { AnyAction } from 'redux';
import * as actionTypes from './billingActionTypes';
import { BillingRepository } from '../../domain/billing/BillingRepository';

type BillingRepositoryState = Omit<
  BillingRepository,
  | 'updateCompanyPackage'
  | 'getPackages'
  | 'getMaxCollaborators'
  | 'getCompanySubscription'
  | 'getTrialEnable'
>;

const INITIAL_STATE: BillingRepositoryState = {
  isLoading: false,
  isUpdating: false,
};

const billingReducer = (state: BillingRepositoryState = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMPANY_PACKAGE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_COMPANY_PACKAGE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.UPDATE_COMPANY_PACKAGE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_PACKAGES:
      return { ...state, isLoading: true };
    case actionTypes.GET_PACKAGES_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_PACKAGES_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_MAX_PACKAGE_COLLABORATOR:
      return { ...state, isLoading: true };
    case actionTypes.GET_MAX_PACKAGE_COLLABORATOR_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_MAX_PACKAGE_COLLABORATOR_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_COMPANY_SUBSCRIPTION:
      return { ...state, isLoading: true };
    case actionTypes.GET_COMPANY_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_COMPANY_SUBSCRIPTION_FAILD:
      return { ...state, isLoading: false, error: action.error };
    case actionTypes.GET_TRIAL_ENABLE:
      return { ...state, isLoading: true };
    case actionTypes.GET_TRIAL_ENABLE_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GET_TRIAL_ENABLE_FAILD:
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};
export { billingReducer };
export type { BillingRepositoryState };

import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import styled from 'styled-components';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

/**
 * Custom Tippy component for tooltips
 */
const Tooltip = (props: TippyProps & { forReference?: React.RefObject<HTMLElement> }) => {
  return (
    <React.Fragment>
      <TippyStyled placement={'auto'} animation='shift-away' {...props}></TippyStyled>
    </React.Fragment>
  );
};

const TippyStyled = styled(Tippy)`
  background-color: var(--dts_black);
  border-radius: 3px;
  padding: 8px 16px;
  color: var(--dts_white);

  div {
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }
`;

export default Tooltip;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ContentLayout from '../components/layout/ContentLayout';
import { BillingCards } from './BillingCards';
import { useCompany } from '../hooks/useCompany';
import styled from 'styled-components';
import { useBillingRepositoryImplementation } from '../../data/billing/billingRepositoryImplementation';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useBillingViewModel } from './BillingViewModel';
import {
  BillingType,
  MyCompanySubscriptionUiModel,
  PackageType,
  PackageUiModel,
} from '../ui-model/billing/PackageUiModel';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { AlertType } from '../../domain/alert/AlertManager';
import useTranslate from '../translations/useTranslate';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import Breadcrumb, { BreadcrumbItem } from '../components/generic/Breadcrumb';
import { TextType } from '../components/dsm/Text';

function ManageSubscription() {
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const translate = useTranslate();
  const navigate = useNavigate();
  const currentCompany = useCompany();

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const repo = useBillingRepositoryImplementation();
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const {
    username,
    getPackages,
    updateCompanyPackage,
    getCompanySubscription,
    getMaxCollaborators,
    getWorkersNumber,
  } = useBillingViewModel(repo, loggedUserRepo, contactRepo);

  const [packages, setPackages] = useState<Array<PackageUiModel>>();
  const [maxEmployeeNumber, setMaxEmployeeNumber] = useState<number>();
  const [subscription, setSubscription] = useState<MyCompanySubscriptionUiModel>();

  const breadcrumbList: BreadcrumbItem[] = [
    {
      name: translate(LANGUAGE_KEYS.MENU_BILLING),
      link: `/${currentCompany.companyId}/settings/billing`,
    },
  ];

  useEffect(() => {
    getPackages().then((resp) => {
      setPackages(resp);
    });
    getCompanySubscription().then((resp) => {
      setSubscription(resp);
    });
    getMaxCollaborators().then((resp) => {
      if (resp) {
        resp !== true && setMaxEmployeeNumber(resp);
      }
    });
  }, []);

  const handleUpdateCompanyPackage = (resp: {
    packageType: PackageType;
    billingType: BillingType;
    billingPeriod: 0;
  }) => {
    if (currentCompany.companyId) {
      updateCompanyPackage({
        id: currentCompany.companyId,
        packageType: resp.packageType,
        billingType: resp.billingType,
        billingPeriod: resp.billingType,
      }).then((resp) => {
        if (resp) {
          navigate(`/${currentCompany.companyId}/settings/billing`);
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        }
      });
    }
  };

  return (
    <>
      <HeaderTopConatiner>
        <Breadcrumb
          textType={
            !isTabletSmall && !isTabletLarge && !isMobile
              ? TextType.TITLE_BOLD_4
              : TextType.BODY_BOLD
          }
          breadcrumbList={breadcrumbList}
          iconWidth={12.35}
          iconHeight={20}
        />
      </HeaderTopConatiner>
      <ContentLayout>
        <ManageSubscriptionContainer>
          <BillingCards
            username={username}
            isFti={false}
            enableTrial={false}
            mySubscription={subscription}
            recomandation={subscription?.packageType}
            packages={packages}
            updateCompanyPackage={handleUpdateCompanyPackage}
            maxEmployeeNumber={maxEmployeeNumber}
            laborEmployeeNumber={getWorkersNumber()}
          />
        </ManageSubscriptionContainer>
      </ContentLayout>
    </>
  );
}

const ManageSubscriptionContainer = styled.div`
  height: 100%;
  background: var(--dts_white);
  display: flex;
`;

const HeaderTopConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 40px;
  padding: 0px 36px;
`;

export default ManageSubscription;

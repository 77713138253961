import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as UploadIcon } from '../../icons/upload.svg';
import React from 'react';
import styled from 'styled-components';

function CaseChat() {
  return (
    <CaseDetailContentBody>
      <CaseDetailFooter>
        <CaseDetailFooterDescription>
          <CaseDetailFooterDescriptionTexts>
            <Text type={TextType.BODY_2_BOLD}>Add Additional Description CHAT</Text>
            <Text type={TextType.MENU_DEFAULT}>Click here to add info</Text>
          </CaseDetailFooterDescriptionTexts>
        </CaseDetailFooterDescription>
        <CaseDetailFooterDescription>
          <CaseDetailFooterDescriptionTexts>
            <Text type={TextType.BODY_2_BOLD}>Attachment</Text>
            <Text type={TextType.MENU_DEFAULT}>Use your camera or choos from your library</Text>
          </CaseDetailFooterDescriptionTexts>
          <Button onClick={click} secondary={'secondary'}>
            <UploadIcon />
            Upload file
          </Button>
        </CaseDetailFooterDescription>
      </CaseDetailFooter>
    </CaseDetailContentBody>
  );
}

function click() {
  console.log('click');
}

const CaseDetailContentBody = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;

  width: 100%;

  /* DTS_White */
  background: #ffffff;
  border-radius: 3px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
`;
const CaseDetailFooter = styled.div`
  /* Frame 146 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 100%;
  height: 124px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;
const CaseDetailFooterDescription = styled.div`
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 556px;
  height: 124px;

  /* DTS_White */
  background: #ffffff;
  /* Light Gray */
  border: 1px solid #e4e4e4;
  border-radius: 3px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
`;
const CaseDetailFooterDescriptionTexts = styled.div`
  /* Header- Default fiew */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 524px;
  height: 40px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

export default CaseChat;

export const GET_INVOICES = 'GET_INVOICE';
export const GET_INVOICES_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICES_FAILD = 'GET_INVOICE_FAILD';
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_FAILD = 'GET_INVOICE_BY_ID_FAILD';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILD = 'UPDATE_INVOICE_FAILD';
export const CHECK_INVOICE_NUMBER = 'CHECK_INVOICE_NUMBER';
export const CHECK_INVOICE_NUMBER_SUCCESS = 'CHECK_INVOICE_NUMBER_SUCCESS';
export const CHECK_INVOICE_NUMBER_FAILD = 'CHECK_INVOICE_NUMBER_FAILD';
export const DELETE_INVOICES = 'DELETE_INVOICE';
export const DELETE_INVOICES_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICES_FAILD = 'DELETE_INVOICE_FAILD';

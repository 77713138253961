import React from 'react';
import styled from 'styled-components';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as SuccessIcon } from '../../icons/success.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { Text, TextType } from '../components/dsm/Text';
import { useSelector } from 'react-redux';
import { AppRootStore } from '../../data/appRepositoryImplementation';
import { AlertState } from '../../data/alert/alertReducer';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from './AlertViewModel';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import { AlertType } from '../../domain/alert/AlertManager';
import useTranslate from '../translations/useTranslate';
import Modal from 'react-modal';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as WarningIcon } from '../../icons/delete.svg';
import { UserRoles } from '../../domain/user-management/UserRoles';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

const errorSelector = (state: AppRootStore) => state.alert;

export const Alert = () => {
  const { message, alertType, isOpenExpireAlert, userRole } = useSelector<AppRootStore, AlertState>(
    errorSelector,
  );

  const translate = useTranslate();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const alertManager = useAlertManagerImplementation();
  const { hideAlert, hidePaymentExpireModal } = useAlertViewModel(alertManager);

  const startTimer = () => {
    setTimeout(() => {
      hideAlert();
    }, 3000);
  };

  return (
    <>
      {(alertType === AlertType.SUCCESS || alertType === AlertType.FAILD) && (
        <Toast
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
          role='alert'
          className={`${alertType === 0 && 'fail'} ${alertType === 1 && 'success'}`}
        >
          {startTimer()}
          <ToastMessage className='toast-message'>
            {alertType !== 0 && <SuccessIcon />}
            {alertType !== 1 && <InfoIcon />}
            <Text type={TextType.CAPTION_BOLD} clr={0}>
              {message}
            </Text>
          </ToastMessage>
          <ToastCloseButton
            className={`${alertType === 0 && 'fail'} ${alertType === 1 && 'success'}`}
            onClick={() => {
              hideAlert();
            }}
          >
            <Close />
          </ToastCloseButton>
        </Toast>
      )}
      {alertType === AlertType.EXPIRE_PAYMENT && (
        <Modal
          isOpen={isOpenExpireAlert}
          onRequestClose={() => {
            hidePaymentExpireModal();
          }}
          shouldCloseOnEsc={true}
          style={ModalStyles}
        >
          <ExpireModalConatiner
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <Text type={TextType.TITLE_BOLD_4} clr={0}>
              {translate('Your Payment Method Expired')}
            </Text>
            <Text type={TextType.BODY_2} clr={0}>
              {userRole === UserRoles.LABOR_OWNER &&
                translate(
                  'Update your payment method to continue enjoying uninterrupted access to our premium features and content.',
                )}
              {userRole !== UserRoles.LABOR_OWNER &&
                translate(
                  'Please contact your admin to update  payment method to continue enjoying uninterrupted access to our premium features and content.',
                )}
            </Text>
            <FooterContainer>
              <WarningIcon />
              <Actions>
                <Button
                  textType={TextType.CAPTION_BOLD}
                  secondary={'secondary'}
                  onClick={() => {
                    hidePaymentExpireModal();
                  }}
                >
                  {userRole === UserRoles.LABOR_OWNER && translate('Not Now')}
                  {userRole !== UserRoles.LABOR_OWNER && translate(LANGUAGE_KEYS.CLOSE)}
                </Button>
                {userRole === UserRoles.LABOR_OWNER && (
                  <Button
                    textType={TextType.CAPTION_BOLD}
                    onClick={() => {
                      // onClick();
                    }}
                  >
                    {translate('Update Payment Method')}
                  </Button>
                )}
              </Actions>
            </FooterContainer>
          </ExpireModalConatiner>
        </Modal>
      )}
    </>
  );
};

const Toast = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    z-index: 9999999;
    position: fixed;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    &.fail {
      background-color: var(--alert-message-fail-background);
    }
    &.success {
      background-color: var(--alert-message-success-background);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      max-width: 403px;
      width: 403px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      max-width: 328px;
      width: 328px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ToastMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px 8px 12px;
  gap: 4px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: var(--dts_white);
    }
  }
`;

const ToastCloseButton = styled.span`
  padding: 14px 16px 14px 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  &.fail {
    background-color: var(--alert-button-fail-background);
  }
  &.success {
    background-color: var(--alert-button-success-background);
  }
`;

const ModalStyles: Modal.Styles = {
  content: {
    position: 'initial',
    background: 'var(--dts_red)',
    boxShadow: '-1px 2px 11px rgba(0, 0, 0, 0.14)',
    padding: '0px',
    boxSizing: 'border-box',
    overflow: 'visible',
    borderRadius: '3px',
  },
  overlay: {
    background: 'var(--react-modal-background)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const ExpireModalConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (min-width: ${desktop}px) {
      width: 488px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      width: 488px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 300px;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const FooterContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: space-between;
  svg {
    path {
      fill: var(--dts_white);
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  .button.secondary:hover {
    border-color: var(--dts_white);
    p {
      color: var(--dts_white);
    }
  }
`;

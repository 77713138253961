import React from 'react';

import { ReactComponent as IDIcon } from '../icons/filters/id.svg';
import { ReactComponent as CompanyIcon } from '../icons/filters/company.svg';
import { ReactComponent as PatientIcon } from '../icons/filters/patient.svg';
import { ReactComponent as DateIcon } from '../icons/filters/date.svg';
import { ReactComponent as StateIcon } from '../icons/filters/state.svg';
import { LANGUAGE_KEYS } from '../presentation/translations/languageKeys';

export const Filters: Array<Filter> = [
  { id: 0, name: LANGUAGE_KEYS.ID, icon: <IDIcon /> },
  { id: 1, name: LANGUAGE_KEYS.COMPANY, icon: <CompanyIcon /> },
  { id: 2, name: LANGUAGE_KEYS.PATIENT, icon: <PatientIcon /> },
  { id: 3, name: LANGUAGE_KEYS.DATE_FROM, icon: <DateIcon /> },
  { id: 4, name: LANGUAGE_KEYS.DATE_TO, icon: <DateIcon /> },
  { id: 5, name: LANGUAGE_KEYS.STATE, icon: <StateIcon /> },
];

export type Filter = Id | Company | Patient | DateFrom | DateTo | State;

type Id = { id: 0; name: string; icon: JSX.Element };
type Company = { id: 1; name: string; icon: JSX.Element };
type Patient = { id: 2; name: string; icon: JSX.Element };
type DateFrom = { id: 3; name: string; icon: JSX.Element };
type DateTo = { id: 4; name: string; icon: JSX.Element };
type State = { id: 5; name: string; icon: JSX.Element };

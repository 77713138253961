import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../icons/close.svg';
import { Text, TextType } from '../dsm/Text';

type BaseDialogProps = {
  isOpen: boolean;
  title?: string;
  titleTextType?: TextType;
  onClose: () => void;
  containerStyle?: React.CSSProperties;
  children?: ReactNode;
  header: boolean;
  isScroll: boolean;
};

export const BaseDialog = (props: BaseDialogProps) => {
  const { isOpen, title, titleTextType, onClose, containerStyle, children, header, isScroll } =
    props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      shouldCloseOnEsc={true}
      style={ModalStyles}
    >
      <Container style={containerStyle} className={`${isScroll && 'scroll-component'}`}>
        {header === true && (
          <Row>
            <Text type={titleTextType ? titleTextType : TextType.BODY_BOLD} ellipsis={true}>
              {title || ''}
            </Text>
            <CloseIconStyled onClick={onClose} />
          </Row>
        )}
        {children}
      </Container>
    </Modal>
  );
};

export const ModalStyles: Modal.Styles = {
  content: {
    position: 'initial',
    background: 'var(--dts_white)',
    boxShadow: '-1px 2px 11px rgba(0, 0, 0, 0.14)',
    padding: '0px',
    boxSizing: 'border-box',
    overflow: 'visible',
    display: 'flex',
    maxHeight: '85%',
  },
  overlay: {
    background: 'var(--react-modal-background)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  padding: 36px;
  &.scroll-component {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  @media (max-width: 700px) {
    gap: 12px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  flex-shrink: 0;
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  path {
    fill: var(--dts_black);
  }
  :hover {
    path {
      fill: var(--dts_pressed_blue);
    }
  }
`;

import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type ContainerProps = {
  orientation?: Orientation;
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

type Orientation = 'Vertical' | 'Horizontal';

const Container = (props: ContainerProps) => {
  const orientation: Orientation = props.orientation || 'Vertical';

  return (
    <ContainerStyled {...props} className={`${orientation} ${props.className}`}>
      {props.children}
    </ContainerStyled>
  );
};

const ContainerStyled = styled.div`
  display: flex;

  &.Vertical {
    flex-direction: column;
  }

  &.Horizontal {
    flex-direction: row;
  }
`;

export default Container;

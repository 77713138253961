import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from './Text';
import { ReactComponent as HidePasswordIcon } from '../../../icons/hidePassword.svg';
import { ReactComponent as ShowPasswordIcon } from '../../../icons/showPassword.svg';

export type InputField = {
  handleChange: (e: any) => void;
  value: any;
  labelText: string;
  labelFor: string;
  id: string;
  name: string;
  type: any;
  isRequired: boolean;
  placeholder: string;
  customClass?: any;
  error: boolean;
  autoComplete: string;
  strengthPassword: boolean;
};

const InputField = (props: InputField) => {
  const {
    handleChange,
    value,
    labelText,
    labelFor,
    id,
    name,
    type,
    isRequired,
    placeholder,
    customClass,
    error,
    autoComplete,
    strengthPassword,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [strengthMessage, setStrengthMessage] = useState('');

  const onHandleChange = (e) => {
    const passwordValue = e.target.value;

    if (strengthPassword && type === 'password' && passwordValue !== '') {
      const strengthChecks = {
        length: 0,
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
      };

      strengthChecks.length = passwordValue.length >= 8 ? 1 : 0;
      strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
      strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
      strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
      strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

      const verifiedList = Object.values(strengthChecks).filter((value) => value);

      const strength =
        verifiedList.length == 5 ? 'Strong' : verifiedList.length >= 2 ? 'Medium' : 'Weak';

      setStrengthMessage(strength);
    } else {
      setStrengthMessage('');
    }
    handleChange(e);
  };

  return (
    <InputFieldConatiner>
      <label htmlFor={labelFor}>
        <Text type={TextType.BODY_2}>{labelText}</Text>
        {strengthMessage !== '' && (
          <StrenghtText className={strengthMessage} type={TextType.ROBOTO_400_10_14}>
            {strengthMessage}
          </StrenghtText>
        )}
      </label>
      <InputContainer>
        <CustomInput
          onChange={onHandleChange}
          value={value}
          id={id}
          name={name}
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          required={isRequired}
          className={`${customClass} ${error && 'error'}  ${type === 'password' && 'password'}`}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
        {type === 'password' && (
          <>
            {showPassword && (
              <CustomShowPasswordIcon
                width={16}
                height={16}
                onClick={() => {
                  setShowPassword(false);
                }}
              />
            )}
            {!showPassword && (
              <CustomHidePasswordIcon
                width={16}
                height={16}
                onClick={() => {
                  setShowPassword(true);
                }}
              />
            )}
          </>
        )}
      </InputContainer>
    </InputFieldConatiner>
  );
};

const InputFieldConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
`;

const CustomInput = styled.input`
  width: 100%;
  padding: 11px 7px 11px 7px;
  border-radius: 3px;
  border: 1px solid var(--dts_light_grey);
  font-weight: 400;
  font-size: 12px;
  &.error {
    border: 1px solid var(--dts_red);
  }
  &.password {
    padding-right: 32px;
  }
`;

const CustomHidePasswordIcon = styled(HidePasswordIcon)`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 8px;
  fill: var(--dts_black);
`;

const CustomShowPasswordIcon = styled(ShowPasswordIcon)`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 8px;
  fill: var(--dts_black);
`;

const StrenghtText = styled(Text)`
  &.Weak {
    color: var(--dts_red);
  }
  &.Medium {
    color: var(--dts_panding);
  }
  &.Strong {
    color: var(--dts_success);
  }
`;

export default InputField;

import { WorkPhaseGroup } from '../../domain/WorkPhaseGroup';
import { WorkerData } from '../../domain/user-management/ContactData';
import {
  FINISHED,
  FINISHED_W_ERROR_CLUMSY,
  FINISHED_W_ERROR_INATTENTIVE,
  FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE,
  OPEN,
  STARTED,
} from '../../data/cases/detail/PhaseStatus';

export type PhaseUiModel = PhaseUiModelPhase | PhaseUiModelDate;

type BasePhaseUiModel = {
  kind: 'Date' | 'WithoutAssignee' | 'WithAssignee' | 'NoAssignee';
  status:
    | 'NotAssignable'
    | 'Unassigned'
    | 'Open'
    | 'OpenForFuture'
    | 'Started'
    | 'Finished'
    | 'FinishedWithError_1'
    | 'FinishedWithError_2'
    | 'FinishedWithError_3'
    | 'Finishing'
    | 'FinishingWithError_1'
    | 'FinishingWithError_2'
    | 'FinishingWithError_3';
  id: number;
  order: number;
  name: string;
};

export type PhaseUiModelPhase =
  | PhaseUiModelWithAssignee
  | PhaseUiModelWithoutAssignee
  | PhaseUiModelNoAssignee;

export type PhaseUiModelWithAssignee = BasePhaseUiModel & {
  kind: 'WithAssignee';
  status:
    | 'Open'
    | 'OpenForFuture'
    | 'Started'
    | 'Finished'
    | 'FinishedWithError_1'
    | 'FinishedWithError_2'
    | 'FinishedWithError_3'
    | 'Finishing'
    | 'FinishingWithError_1'
    | 'FinishingWithError_2'
    | 'FinishingWithError_3';
  assignee: Assignee;
};

export type PhaseUiModelWithoutAssignee = BasePhaseUiModel & {
  kind: 'WithoutAssignee';
  status: 'NotAssignable' | 'Unassigned';
};

export type PhaseUiModelNoAssignee = BasePhaseUiModel & {
  kind: 'NoAssignee';
  status:
    | 'Open'
    | 'OpenForFuture'
    | 'Started'
    | 'Finished'
    | 'FinishedWithError_1'
    | 'FinishedWithError_2'
    | 'FinishedWithError_3'
    | 'Finishing'
    | 'FinishingWithError_1'
    | 'FinishingWithError_2'
    | 'FinishingWithError_3';
};

export type PhaseUiModelDate = Omit<BasePhaseUiModel, 'status' | 'id' | 'name'> & {
  kind: 'Date';
  order: BasePhaseUiModel['order'];
  date: number;
};

type Assignee = {
  id: number;
  name: string;
  profileColor: string;
};

export const mapPhaseUiModelsFromWorkPhaseGroups = (
  phases: Array<WorkPhaseGroup>,
  assignable: boolean,
  workers?: Array<WorkerData>,
): Array<PhaseUiModel> => {
  if (phases !== null) {
    return phases.map((phase, index) => {
      // Check if it's date
      if (phase.type === 1) {
        return {
          kind: 'Date',
          order: phase.order,
          date: phase.date,
        } as PhaseUiModelDate;
      } else {
        let status: PhaseUiModelPhase['status'] = 'NotAssignable';
        const previousPhase = getNextPhase(phase, phases, -1);
        const nextPhase = getNextPhase(phase, phases, 1);
        if (!assignable) status = 'NotAssignable';
        else if ((phase.assigneeId === undefined || phase.assigneeId <= 0) && phase.id !== 1)
          status = 'Unassigned';
        else if (phase.status === FINISHED) {
          if (phases.length !== index + 1 && nextPhase !== undefined && nextPhase.status === OPEN)
            status = 'Finishing';
          else status = 'Finished';
        } else if (phase.status === FINISHED_W_ERROR_CLUMSY) {
          if (phases.length !== index + 1 && nextPhase !== undefined && nextPhase.status === OPEN)
            status = 'FinishingWithError_1';
          else status = 'FinishedWithError_1';
        } else if (phase.status === FINISHED_W_ERROR_INATTENTIVE) {
          if (phases.length !== index + 1 && nextPhase !== undefined && nextPhase.status === OPEN)
            status = 'FinishingWithError_2';
          else status = 'FinishedWithError_2';
        } else if (phase.status === FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE) {
          if (phases.length !== index + 1 && nextPhase !== undefined && nextPhase.status === OPEN)
            status = 'FinishingWithError_3';
          else status = 'FinishedWithError_3';
        } else if (phase.status === STARTED) status = 'Started';
        else if (phase.status === OPEN) {
          if (index === 0) status = 'Open';
          else if (
            previousPhase !== undefined &&
            (previousPhase.status === FINISHED ||
              previousPhase.status === FINISHED_W_ERROR_CLUMSY ||
              previousPhase.status === FINISHED_W_ERROR_INATTENTIVE ||
              previousPhase.status === FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE)
          )
            status = 'Open';
          else status = 'OpenForFuture';
        }
        let kind: PhaseUiModel['kind'];
        if (phase.id === 1) {
          kind = 'NoAssignee';
        } else {
          kind =
            status === 'NotAssignable' || status === 'Unassigned'
              ? 'WithoutAssignee'
              : 'WithAssignee';
        }
        const baseModel: BasePhaseUiModel = {
          kind: kind,
          id: phase.id!,
          order: phase.order,
          name: phase.name!,
          status: status,
        };
        if (baseModel.kind === 'WithAssignee') {
          const assignee = workers?.find((worker) => worker.id === phase.assigneeId);
          return {
            ...baseModel,
            assignee: {
              id: phase.assigneeId,
              userId: assignee?.user !== null ? assignee?.user.userId : phase.assigneeId,
              name: assignee?.name || '',
              profileColor:
                assignee?.user !== null && assignee?.user.profileColor !== null
                  ? assignee?.user.profileColor
                  : 'var(--dts_default_blue)',
            },
          } as PhaseUiModelWithAssignee;
        } else return baseModel as PhaseUiModelWithoutAssignee;
      }
    });
  } else {
    return [{ kind: 'NoAssignee', status: 'Open', id: -1, order: -1, name: '' }];
  }
};

function getNextPhase(
  phase: WorkPhaseGroup,
  phases: Array<WorkPhaseGroup>,
  type: -1 | 1,
): WorkPhaseGroup | undefined {
  const filterPhases = phases.filter((e) => e.type !== 1);
  let nextPhase;
  filterPhases.forEach((phaseItem, index) => {
    if (phaseItem.order === phase.order && filterPhases[index + type] !== undefined) {
      if (type === 1 && filterPhases.length === index + 1) nextPhase = filterPhases[index];
      else if (type === -1 && index - 1 < 0) nextPhase = filterPhases[0];
      else nextPhase = filterPhases[index + type];
      return;
    }
  });
  return nextPhase;
}

export const mapStatusToNumeric = (status: PhaseUiModelPhase['status']) => {
  switch (status) {
    case 'Open' || 'NotAssignable' || 'Unassigned' || 'OpenForFuture':
      return OPEN;
    case 'Started':
      return STARTED;
    case 'Finished':
      return FINISHED;
    case 'Finishing':
      return FINISHED;
    case 'FinishedWithError_1':
    case 'FinishingWithError_1':
      return FINISHED_W_ERROR_CLUMSY;
    case 'FinishingWithError_2':
    case 'FinishedWithError_2':
      return FINISHED_W_ERROR_INATTENTIVE;
    case 'FinishedWithError_3':
    case 'FinishingWithError_3':
      return FINISHED_W_ERROR_INSUFFICIENT_KNOWLEDGE;
    default:
      return OPEN;
  }
};

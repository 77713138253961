import React, { useContext, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCompany } from '../hooks/useCompany';
import styled from 'styled-components';
import { RoundButton, RoundButtonState } from '../components/dsm/buttons/RoundButton';
import { TextType } from '../components/dsm/Text';
import { ReportsOverviewType } from '../ui-model/reports/ReportOverviewUiModel';
// import { ReactComponent as QRCodeIcon } from '../../icons/qrcode.svg';
import { ReactComponent as DotsIcon } from '../../icons/dots.svg';
import { CaseDetailContext } from './CaseDetailProvider';
import CaseOverView from './CaseOverView';
import CaseChat from './CaseChat';
import Expense from '../reports/components/expense/Expense';
import PayOff from '../reports/components/payOff/PayOff';
import { ACTIVE, CLOSED, DRAFT, INVOICED } from './caseStates';
import InvoiceDetailBody from '../invoice/InvoiceDetailBody';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { ContextMenu, ContextMenuOffset, ContextMenuOption } from '../components/dsm/ContextMenu';
import { ReactComponent as DeleteIcon } from '../../icons/trash.svg';
import { ReactComponent as WarningIcon } from '../../icons/delete.svg';
import { ReactComponent as Close } from '../../icons/close.svg';
import { ReactComponent as ReopenIcon } from '../../icons/reopen.svg';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import ConfirmationDialog, {
  ConfirmationDialogProps,
} from '../components/generic/ConfirmationDialog';

export function CaseDetailHeader(props: { caseId: string | undefined }) {
  const location = useLocation();
  const navigate = useNavigate();
  const currentCompany = useCompany();
  const translate = useTranslate();

  const alertManager = useAlertManagerImplementation();
  const { showAlert, showPaymentExpireModal } = useAlertViewModel(alertManager);

  const {
    caseDetailObj,
    isEnabledReportsOverview,
    isEnabledInvoice,
    isEnabledCaseStatusAction,
    deleteCase,
    closeReopenCase,
    validSubscription,
    invoiceNumber,
  } = useContext(CaseDetailContext);

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });

  const InitialDeleteDialog: ConfirmationDialogProps = {
    isOpen: false,
    title: translate(LANGUAGE_KEYS.DELETE_LABWORK_TITLE),
    description: translate(LANGUAGE_KEYS.DELETE_LABWORK_DESCRIPT),
    onClose: () => {},
    negativeButton: { label: translate(LANGUAGE_KEYS.CANCEL), onClick: () => {} },
    positiveButton: { label: translate(LANGUAGE_KEYS.REJECT), onClick: () => {} },
  };

  const [deleteDialog, setDeleteDialog] =
    React.useState<ConfirmationDialogProps>(InitialDeleteDialog);

  const closeDeleteDialog = () => setDeleteDialog({ ...deleteDialog, isOpen: false });

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: DRAFT,
      icon: <DeleteIcon width={16} height={12} />,
      label: translate(LANGUAGE_KEYS.DELETE),
      onClick: () => {
        removeCaseClick();
      },
    },
    {
      key: ACTIVE,
      icon: <CloseIconStyle width={16} height={12} />,
      label: translate(LANGUAGE_KEYS.CLOSE),
      onClick: () => {
        closeReopenCase(CLOSED).then((resp) => {
          if (resp) {
            showAlert(
              translate(LANGUAGE_KEYS.CLOSE_CASAE_NOTIFICATION_SUCCESSFULL),
              AlertType.SUCCESS,
            );
          } else {
            showAlert(translate(LANGUAGE_KEYS.CLOSE_CASAE_NOTIFICATION_FAILED), AlertType.FAILD);
          }
        });
      },
    },
    {
      key: CLOSED,
      icon: <ReopenIconStyle width={16} height={16} />,
      label: translate(LANGUAGE_KEYS.REOPEN),
      onClick: () => {
        closeReopenCase(DRAFT).then((resp) => {
          if (resp) {
            showAlert(
              translate(LANGUAGE_KEYS.REOPEN_CASAE_NOTIFICATION_SUCCESSFULL),
              AlertType.SUCCESS,
            );
          } else {
            showAlert(translate(LANGUAGE_KEYS.REOPEN_CASAE_NOTIFICATION_FAILED), AlertType.FAILD);
          }
        });
      },
    },
  ];

  const checkExpense = () => {
    if (caseDetailObj !== null && caseDetailObj !== undefined && isEnabledReportsOverview()) {
      return (
        caseDetailObj.status !== undefined &&
        caseDetailObj.status !== INVOICED &&
        caseDetailObj.status !== CLOSED
      );
    }
    return false;
  };

  const checkPayOff = () => {
    if (caseDetailObj !== null && caseDetailObj !== undefined && isEnabledReportsOverview()) {
      return caseDetailObj.status !== undefined && caseDetailObj.status !== DRAFT;
    }
    return false;
  };

  const checkInvoice = () => {
    if (caseDetailObj !== null && caseDetailObj !== undefined && isEnabledInvoice()) {
      return caseDetailObj.status !== undefined && caseDetailObj.status === INVOICED;
    }
    return false;
  };

  function showConfirmRemoveCaseDialog() {
    setDeleteDialog({
      ...deleteDialog,
      isOpen: true,
      negativeButton: { label: translate(LANGUAGE_KEYS.CANCEL), onClick: closeDeleteDialog },
      positiveButton: {
        label: translate(LANGUAGE_KEYS.REJECT),
        onClick: () => {
          deleteCase([caseDetailObj.id]).then((resp) => {
            if (resp) {
              navigate(`/${currentCompany.companyId}`);
              showAlert(
                translate(LANGUAGE_KEYS.DELETE_CASAE_NOTIFICATION_SUCCESSFULL),
                AlertType.SUCCESS,
              );
            } else {
              showAlert(translate(LANGUAGE_KEYS.DELETE_CASAE_NOTIFICATION_FAILED), AlertType.FAILD);
            }
          });
        },
      },
      onClose: closeDeleteDialog,
    });
  }

  function removeCaseClick() {
    showConfirmRemoveCaseDialog();
  }

  return (
    <>
      <ContentBodyHeader>
        <ButtonsContainer>
          <RoundButton
            onClick={() => {
              if (validSubscription) {
                navigate(`/${currentCompany.companyId}/case/` + props.caseId);
              } else {
                showPaymentExpireModal(currentCompany.userRole);
              }
            }}
            indicator={false}
            buttonState={
              `/${currentCompany.companyId}/case/` + props.caseId === location.pathname
                ? RoundButtonState.ACTIVE
                : RoundButtonState.DEFAULT
            }
            textType={TextType.BODY_2_BOLD}
          >
            {`${props.caseId} ${translate(LANGUAGE_KEYS.CASE)}`}
          </RoundButton>
          {/*<RoundButton*/}
          {/*  onClick={() => {*/}
          {/*    if (validSubscription) {*/}
          {/*      navigate(`/${currentCompany.companyId}/case/` + props.caseId + '/chat');*/}
          {/*    } else {*/}
          {/*      showPaymentExpireModal(currentCompany.userRole);*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  indicator={false}*/}
          {/*  buttonState={*/}
          {/*    `/${currentCompany.companyId}/case/` + props.caseId + '/chat' === location.pathname*/}
          {/*      ? RoundButtonState.ACTIVE*/}
          {/*      : RoundButtonState.DEFAULT*/}
          {/*  }*/}
          {/*  textType={TextType.BODY_2_BOLD}*/}
          {/*>*/}
          {/*  {translate(LANGUAGE_KEYS.CHAT)}*/}
          {/*</RoundButton>*/}
          {checkExpense() && (
            <RoundButton
              onClick={() => {
                if (validSubscription) {
                  navigate(`/${currentCompany.companyId}/case/` + props.caseId + '/expense', {
                    state: { cases: [props.caseId], type: ReportsOverviewType.EXPENSE },
                  });
                } else {
                  showPaymentExpireModal(currentCompany.userRole);
                }
              }}
              indicator={false}
              buttonState={
                `/${currentCompany.companyId}/case/` + props.caseId + '/expense' ===
                location.pathname
                  ? RoundButtonState.ACTIVE
                  : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.EXPENSE)}
            </RoundButton>
          )}
          {checkPayOff() && (
            <RoundButton
              onClick={() => {
                if (validSubscription) {
                  navigate(`/${currentCompany.companyId}/case/` + props.caseId + '/payoff', {
                    state: { cases: [props.caseId], type: ReportsOverviewType.PAY_OFF },
                  });
                } else {
                  showPaymentExpireModal(currentCompany.userRole);
                }
              }}
              indicator={false}
              buttonState={
                `/${currentCompany.companyId}/case/` + props.caseId + '/payoff' ===
                location.pathname
                  ? RoundButtonState.ACTIVE
                  : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.PAY_OFF)}
            </RoundButton>
          )}
          {checkInvoice() && (
            <RoundButton
              onClick={() => {
                if (validSubscription) {
                  navigate(
                    `/${currentCompany.companyId}/case/` +
                      props.caseId +
                      '/invoice/' +
                      invoiceNumber,
                  );
                } else {
                  showPaymentExpireModal(currentCompany.userRole);
                }
              }}
              indicator={false}
              buttonState={
                `/${currentCompany.companyId}/case/` +
                  props.caseId +
                  '/invoice/' +
                  invoiceNumber ===
                location.pathname
                  ? RoundButtonState.ACTIVE
                  : RoundButtonState.DEFAULT
              }
              textType={TextType.BODY_2_BOLD}
            >
              {translate(LANGUAGE_KEYS.INVOICE)}
            </RoundButton>
          )}
        </ButtonsContainer>
        <ContentBodyHeaderQRCodeAndDots>
          {/*<ContentBodyHeaderQRCodeInfo onClick={() => {}}>*/}
          {/*  <QRCodeIconStyled />*/}
          {/*  <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.PRINT_WITH_QR)}</Text>*/}
          {/*</ContentBodyHeaderQRCodeInfo>*/}
          {isEnabledCaseStatusAction(currentCompany.userRole) && (
            <ContentBodyHeaderDotsInfo>
              <DotsIconStyled
                onClick={(e) => {
                  e.preventDefault();
                  if (validSubscription) {
                    handleContextMenuClick({ x: e.clientX, y: e.clientY });
                  } else {
                    showPaymentExpireModal(currentCompany.userRole);
                  }
                }}
              />
              <ContextMenuStyle
                isOpen={isContextMenuOpen}
                setIsOpen={setIsContextMenuOpen}
                options={ContextMenuOptions.filter((option) => option.key === caseDetailObj.status)}
                offset={contextMenuOffset}
                className='context'
              />
              <ConfirmationDialog
                isOpen={deleteDialog.isOpen}
                title={deleteDialog.title}
                description={deleteDialog.description}
                negativeButton={deleteDialog.negativeButton}
                positiveButton={deleteDialog.positiveButton}
                onClose={deleteDialog.onClose}
                icon={<WarningIcon />}
              />
            </ContentBodyHeaderDotsInfo>
          )}
        </ContentBodyHeaderQRCodeAndDots>
      </ContentBodyHeader>
      <Routes>
        <Route path='/' element={<CaseOverView caseId={props.caseId} />} />
        {validSubscription && <Route path='/chat' element={<CaseChat />} />}
        {checkExpense() && validSubscription && (
          <Route
            path='/expense'
            element={
              <Expense companyId={currentCompany.companyId} caseIds={[Number(props.caseId)]} />
            }
          />
        )}
        {checkPayOff() && validSubscription && (
          <Route
            path='/payoff'
            element={
              <PayOff companyId={currentCompany.companyId} caseIds={[Number(props.caseId)]} />
            }
          />
        )}
        {checkInvoice() && validSubscription && (
          <Route path='/invoice/:invoiceId' element={<InvoiceDetailBody />} />
        )}
      </Routes>
    </>
  );
}

const ContentBodyHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 16px 0;
  width: 100%;
  height: 38px;
  justify-content: space-between;
  align-items: center;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
`;

const ContentBodyHeaderQRCodeAndDots = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 4px 0 4px;
  text-decoration: none;

  /* Inside auto layout */
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
`;
// const ContentBodyHeaderQRCodeInfo = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   padding: 0;
//   gap: 11px;

//   /* Inside auto layout */
//   flex: none;
//   order: 0;
//   flex-grow: 0;
// `;

const ContentBodyHeaderDotsInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 11px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
`;

// const QRCodeIconStyled = styled(QRCodeIcon)`
//   width: 18px;
//   height: 18px;
// `;

const DotsIconStyled = styled(DotsIcon)`
  margin-left: 9px;
  width: 16px;
  height: 4px;
  padding: 10px;
  cursor: pointer;
`;

const ContextMenuStyle = styled(ContextMenu)`
  &.context {
    position: absolute;
    right: 0;
    top: 15px !important;
    left: unset !important;
  }
  .option svg {
    width: inherit;
    height: inherit;
  }
`;

const CloseIconStyle = styled(Close)`
  fill: var(--dts_black);
  path {
    fill: var(--dts_black);
  }
`;

const ReopenIconStyle = styled(ReopenIcon)`
  fill: var(--dts_black);
`;

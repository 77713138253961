import * as React from 'react';

export const ToothPanoramaPContext = React.createContext({} as any);

export function ToothPanoramaProvider(props: { children }) {
  const [dragedConnectionElement, setDragedConnectionElement] = React.useState({
    labWork: -2,
    leftConnectionType: -3,
    teeth: -1,
    quarter: -1,
  });

  return (
    <ToothPanoramaPContext.Provider value={[dragedConnectionElement, setDragedConnectionElement]}>
      {props.children}
    </ToothPanoramaPContext.Provider>
  );
}

import React from 'react';
import styled from 'styled-components';

import { Text, TextType, TextColor } from '../components/dsm/Text';
import homeImg from '../../images/HomeImg.png';
import { useNavigate } from 'react-router';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';

const Home = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();
  return (
    <HomeConatiner
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <InfoConatiner>
        <TitleContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <Text type={TextType.TITLE_BOLD_5}>
            Welcome to <strong>Dentech</strong>:
          </Text>
          <Text type={TextType.TITLE_BOLD_2}>Your Dental Management Solution!</Text>
        </TitleContainer>
        <DescriptionText>
          <Text type={TextType.TITLE_REGULAR_4}>
            Uncover Dentech – the seamless bridge linking Dental Cabinets, and Dental Technicians.
            <br />
            Beyond an app, Dentech is a powerful dental management system, streamlining workflows,
            managing workloads, and boosting communication
          </Text>
        </DescriptionText>
        <ActionButton
          onClick={() => {
            navigate('/signup');
          }}
        >
          <Text type={TextType.TITLE_BOLD_2} clr={TextColor.WHITE}>
            {translate(LANGUAGE_KEYS.START_FOR_FREE)}
          </Text>
        </ActionButton>
      </InfoConatiner>

      <CustomImg src={homeImg} alt='home image' />
    </HomeConatiner>
  );
};

const HomeConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    padding: 16px 36px 16px 36px;
    display: flex;
    max-width: 100%;
    //Desktop
    @media (min-width: ${desktop}px) {
      justify-content: center;
      max-width: 100%;
      flex-wrap: wrap;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      justify-content: center;
      max-width: 100%;
      flex-wrap: wrap;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      flex-direction: column;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
      flex-direction: column;
    }
`,
);

const InfoConatiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const TitleContainer = styled.div<DeviceSizeProps>(
  ({ mobile, tablet_small, tablet_large, desktop }) => `
    max-width: 495px;
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 170px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 10px;
    }
`,
);
const DescriptionText = styled.div`
  max-width: 495px;
`;

const ActionButton = styled.button`
  padding: 7px 23px 7px 23px;
  border: 1px solid var(--dts_black);
  border-radius: 60px;
  background: var(--dts_default_blue);
  align-self: flex-start;
  cursor: pointer;
  &:hover {
    background: var(--dts_hover_blue);
  }
`;

const CustomImg = styled.img`
  height: 100%;
  max-width: 100%;
  display: block;
  margin: auto;
`;

export default Home;

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../../icons/BackIcon.svg';
import styled from 'styled-components';

export const MobileBackButton = () => {
  const navigate = useNavigate();

  return (
    <IconBackground>
      <CustomBackIcon
        width={24}
        height={24}
        onClick={() => {
          navigate(-1);
        }}
      />
    </IconBackground>
  );
};

const CustomBackIcon = styled(BackIcon)`
  left: 36px;
  top: 36px;
  fill: var(--dts_black);
  cursor: pointer;
  :hover {
    fill: var(--dts_hover_blue);
  }
`;

const IconBackground = styled.span`
  background: var(--dts_withe_background);
  border-radius: 50%;
  padding: 8px;
  line-height: 0;
  margin-top: 15px;
  margin-left: 15px;
  display: inline-flex;
`;

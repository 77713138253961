import React, { useState } from 'react';
import styled from 'styled-components';

import Avatar from '../../../components/generic/Avatar';
import { Text, TextType } from '../../../components/dsm/Text';
import {
  ContextMenu,
  ContextMenuOffset,
  ContextMenuOption,
} from '../../../components/dsm/ContextMenu';
import { PayOffCollaborator } from '../../../ui-model/reports/PayOffUIModel';

export type CollaboratorProps = {
  collaborator: PayOffCollaborator;
  index: number;
  collaboratorSettings: {
    style: 'simple' | 'just-avatar' | 'avatar-more';
    displayCollSize: number;
  };
  selectedCollaborator: PayOffCollaborator;
  collaboratorsList: { key: number; icon: any; label: string }[]; //this list contains the collaborators we could not display
  changeCollaborators: (index: number) => void;
};

export function PayOffCollaboratorItem(collaboratorProps: CollaboratorProps) {
  const {
    collaborator,
    index,
    collaboratorSettings,
    selectedCollaborator,
    collaboratorsList,
    changeCollaborators,
  } = collaboratorProps;

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });
  const contextMenuOptions: Array<ContextMenuOption> = collaboratorsList;

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const handleChangeCollaborators = (collaboratorId) => {
    changeCollaborators(collaboratorId);
  };

  const collaboratorFilterItem = () => {
    if (index < collaboratorSettings.displayCollSize) {
      return (
        <Collaborator
          key={index}
          className={`${
            collaborator.collaboratorId === selectedCollaborator.collaboratorId && 'active'
          } ${collaboratorSettings.style}  ${
            collaborator.phaseIds.every((p) => p.accounted === 1) &&
            collaborator.collaboratorId !== -1 &&
            'solved-all'
          }`}
          onClick={() => {
            handleChangeCollaborators(collaborator.collaboratorId);
          }}
        >
          <span className='avatar'>
            <Avatar
              fullName={collaborator.collaboratorName}
              size={{ outer: 20, inner: 18 }}
              avatarStyle={{ background: collaborator.collaboratorColor }}
            />
          </span>
          <Text className='collaborator-name' type={TextType.BODY_2_BOLD} ellipsis={true}>
            {collaborator.collaboratorName}
          </Text>
        </Collaborator>
      );
    }
    if (index === collaboratorSettings.displayCollSize) {
      return (
        <Collaborator
          key={index}
          className={`${
            collaborator.collaboratorId === selectedCollaborator.collaboratorId && 'active'
          } ${collaboratorSettings.style}`}
          onClick={(e) => {
            e.preventDefault();
            handleContextMenuClick({ x: e.clientX - 250, y: e.clientY });
          }}
        >
          <span className='avatar'>
            <Avatar fullName={collaboratorsList.length - index} size={{ outer: 20, inner: 18 }} />
          </span>
          <ContextMenu
            className='collaborators-part custom-scrollbar'
            isOpen={isContextMenuOpen}
            setIsOpen={setIsContextMenuOpen}
            options={contextMenuOptions}
            offset={contextMenuOffset}
          />
        </Collaborator>
      );
    }
    return <></>;
  };

  return collaboratorFilterItem();
}

const Collaborator = styled.div`
  max-width: 110px;
  display: flex;
  gap: 4px;
  flex-wrap: no-wrap;
  align-items: center;
  padding: 0px 8px 6px 8px;
  margin-bottom: -2px;
  border-bottom: 2px solid var(--dts_light_grey);
  cursor: pointer;
  .avatar {
    line-height: 0;
    flex-grow: 1;
  }
  &.active {
    border-bottom: 2px solid
      var(
        --dts_default_blue,
        linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%),
        --dts_default_blue
      );
  }
  &.just-avatar,
  &.avatar-more {
    padding: 0px 0px 6px 0px;
    margin-right: -4px;
    .collaborator-name {
      display: none;
    }
  }

  &.solved-all {
    opacity: 0.5;
  }

  .collaborators-part {
    min-width: 207px;
    max-width: 207px;
    max-height: 228px;
    padding: 24px;
    overflow-y: scroll;
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    svg {
      fill: var(--dts_black);
      path {
        opacity: 1;
      }
    }
  }
`;

export const ACTIVATE_E_FACTURA = 'ACTIVATE_E_FACTURA';
export const ACTIVATE_E_FACTURA_SUCCESS = 'ACTIVATE_E_FACTURA_SUCCESS';
export const ACTIVATE_E_FACTURA_FAILD = 'ACTIVATE_E_FACTURA_FAILD';
export const GET_ACTIVATION_TOKEN = 'GET_ACTIVATION_TOKEN';
export const GET_ACTIVATION_TOKEN_SUCCESS = 'GET_ACTIVATION_TOKEN_SUCCESS';
export const GET_ACTIVATION_TOKEN_FAILD = 'GET_ACTIVATION_TOKEN_FAILD';
export const CHECK_ACTIVATION_TOKEN = 'CHECK_ACTIVATION_TOKEN';
export const CHECK_ACTIVATION_TOKEN_SUCCESS = 'CHECK_ACTIVATION_TOKEN_SUCCESS';
export const CHECK_ACTIVATION_TOKEN_FAILD = 'CHECK_ACTIVATION_TOKEN_FAILD';
export const UPDATE_INVOICE_WITH_ANAF_DATA = 'UPDATE_INVOICE_WITH_ANAF_DATA';
export const UPDATE_INVOICE_WITH_ANAF_DATA_SUCCESS = 'UPDATE_INVOICE_WITH_ANAF_DATA_SUCCESS';
export const UPDATE_INVOICE_WITH_ANAF_DATA_FAILD = 'UPDATE_INVOICE_WITH_ANAF_DATA_FAILD';
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS';
export const REFRESH_ACCESS_TOKEN_FAILD = 'REFRESH_ACCESS_TOKEN_FAILD';
export const UPLOAD_E_FACTURA = 'UPLOAD_E_FACTURA';
export const UPLOAD_E_FACTURA_SUCCESS = 'UPLOAD_E_FACTURA_SUCCESS';
export const UPLOAD_E_FACTURA_FAILD = 'UPLOAD_E_FACTURA_FAILD';
export const GET_E_FACTURA_STATUS = 'GET_E_FACTURA_STATUS';
export const GET_E_FACTURA_STATUS_SUCCESS = 'GET_E_FACTURA_STATUS_SUCCESS';
export const GET_E_FACTURA_STATUS_FAILD = 'GET_E_FACTURA_STATUS_FAILD';
export const SAVE_ANAF_AUTH_RESPONSE_CODE = 'SAVE_ANAF_AUTH_RESPONSE_CODE';
export const SAVE_ANAF_AUTH_RESPONSE_ERROR = 'SAVE_ANAF_AUTH_RESPONSE_CODE';
export const ANAF_AUTH_VALUE_DELETE = 'ANAF_AUTH_VALUE_DELETE';

import React from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../dsm/Text';
import { Button } from '../dsm/buttons/Button';
import { ReactComponent as MenuIcon } from '../../../icons/menu.svg';
import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { useDispatch } from 'react-redux';
import * as actionTypes from '../menu/menuActionTypes';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';

function ToggleHeader(props: {
  hasContent: boolean;
  title: string | undefined;
  buttonText: string | undefined;
  enabledClick: boolean | undefined;
  onClick: () => void;
  buttonIcon: JSX.Element | undefined;
  noButtonDesign: boolean | undefined;
  secondary?: boolean | undefined;
  clickLeftIcon?: () => void | undefined;
}) {
  const dispatch = useDispatch();
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  return (
    <ContentHeader className={`${props.secondary && 'secondary'}`}>
      <MenuIconStyled
        onClick={() => {
          dispatch({ type: actionTypes.MENU_OPEN });
        }}
      />
      {props.hasContent && (
        <Content
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <ContentHeaderLeft>
            {props.secondary && <LeftIconStyled onClick={props.clickLeftIcon} />}

            <TitleText type={TextType.BODY_BOLD} ellipsis={true}>
              {props.title}
            </TitleText>
          </ContentHeaderLeft>
          <ContentHeaderRight>
            {props.enabledClick && !props.noButtonDesign && (
              <Button
                onClick={() => {
                  props.onClick();
                }}
                minWidth={52}
              >
                {props.buttonIcon !== undefined && props.buttonIcon}
                {props.buttonText !== undefined && props.buttonText}
              </Button>
            )}
            {props.enabledClick && props.noButtonDesign && (
              <span
                onClick={() => {
                  props.onClick();
                }}
              >
                {props.buttonIcon}
              </span>
            )}
          </ContentHeaderRight>
        </Content>
      )}
    </ContentHeader>
  );
}

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0px 36px 0px 36px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      margin-bottom: 16px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-bottom: 26px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
      padding: 0px 16px 0px 16px;
    }
`,
);

const ContentHeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto 0;
  flex-grow: 1;
  gap: 16px;
  width: calc(100% - 116px);
`;

const ContentHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const MenuIconStyled = styled(MenuIcon)`
  cursor: pointer;
  fill: var(--dts_black);
  padding: 8px 16px 8px 16px;
  &:hover {
    fill: var(--dts_hover_blue);
  }
`;

const LeftIconStyled = styled(LeftIcon)`
  margin: auto 0;
  cursor: pointer;
`;

const TitleText = styled(Text)`
  line-height: 22px;
`;

export default ToggleHeader;

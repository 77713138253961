import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';
import { UpdateUserData } from '../../domain/signUpFlow/UpdateUserData';

type updateUserUseCasesRepository = Pick<SignUpFlowRepository, 'updateUser'>;

const updateUserUseCases = (repo: updateUserUseCasesRepository, data: UpdateUserData) => {
  return repo.updateUser(data);
};

export { updateUserUseCases };
export type { updateUserUseCasesRepository };

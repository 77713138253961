import { Template } from '../../../domain/worktype/templates/Template';
import { TeethType } from '../../../domain/worktype/teeth/TeethType';
import { ExtensionList } from '../../../domain/worktype/extension/Extension';

export class WorkTypeUiModel {
  id: number;
  name: string;
  active: number;
  category: { id: number; name: string; active: boolean }[];
  categoryId: number;
  selectedCategory: number;
  teethTypes: number[];
  teethTypeList: Array<TeethType>;
  templates: Template[];
  extensionList: ExtensionList;

  constructor(
    id: number,
    name: string,
    active: number,
    category: { id: number; name: string; active: boolean }[],
    categoryId: number,
    selectedCategoryId: number,
    teethTypes: number[],
    teethTypeList: Array<TeethType>,
    templates: Template[],
    extensionList: ExtensionList,
  ) {
    this.id = id;
    this.name = name;
    this.active = active;
    this.category = category;
    this.categoryId = categoryId;
    this.selectedCategory = selectedCategoryId;
    this.teethTypes = teethTypes;
    this.teethTypeList = teethTypeList;
    this.templates = templates;
    this.extensionList = extensionList;
  }
}

import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';

type addUserUseCasesRepository = Pick<SignUpFlowRepository, 'addUser'>;

const addUserUseCases = (
  repo: addUserUseCasesRepository,
  username: string,
  isEnabledMailList: boolean,
) => {
  return repo.addUser(username, isEnabledMailList);
};

export { addUserUseCases };
export type { addUserUseCasesRepository };

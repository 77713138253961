const CLIENT_ID = 'cc687172423449094de5239374337e8a7e3ee71d0beb9f66';

export const AUTHORIZATION_LINK = `https://logincert.anaf.ro/anaf-oauth2/v1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${process.env.REACT_APP_E_FACTURA_REDIRECT_URI}&token_content_type=jwt`;

import React from 'react';
import { ReactComponent as EfacturaAccept } from '../../../icons/e-factura/EfacturaAccept.svg';
import { ReactComponent as EfacturaError } from '../../../icons/e-factura/EfacturaError.svg';
import { ReactComponent as EfacturaInProgress } from '../../../icons/e-factura/EfacturaInProgress.svg';

export enum EFacturaStatus {
  OK = 'ok',
  IN_PRELUCRARE = 'in prelucrare',
  NOK = 'nok',
}

export const getEfacturaStatusByValue = (value: string | null) => {
  switch (value) {
    case EFacturaStatus.OK:
      return 'success';
    case EFacturaStatus.IN_PRELUCRARE:
      return 'progress';
    case EFacturaStatus.NOK:
    case null:
      return 'error';
    default:
      return null;
  }
};

export const getEfacturaStatusIcon = (value: string | null, width: number, height: number) => {
  switch (value) {
    case EFacturaStatus.OK:
      return <EfacturaAccept width={width} height={height} />;
    case EFacturaStatus.IN_PRELUCRARE:
      return <EfacturaInProgress width={width} height={height} />;
    case EFacturaStatus.NOK:
    case null:
      return <EfacturaError width={width} height={height} />;
    default:
      return null;
  }
};

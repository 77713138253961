import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';
import { TemplateUiModel } from '../../../presentation/ui-model/worktype/TemplateUiModel';

type GetTemplateRepository = Pick<TemplateRepository, 'getTemplates'>;

const getTemplatesUseCases = (repo: GetTemplateRepository) => {
  repo.getTemplates();
};

const getSortTemplatesUseCase = (templates: TemplateUiModel[]): TemplateUiModel[] | [] => {
  return templates?.sort((a, b) => (a.order > b.order ? 1 : -1));
};

export { getTemplatesUseCases, getSortTemplatesUseCase };

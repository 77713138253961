import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Text, TextType } from '../../components/dsm/Text';
import { Button } from '../../components/dsm/buttons/Button';
import { InputText } from '../../components/dsm/InputText';

import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { usePlansViewModel } from './PlansViewModel';
import {
  useCompaniesRepositoryImplementation,
  useContactsRepositoryImplementation,
} from '../../../data/user-management/UsersRepositoryImplementation';

import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

// import { ReactComponent as PencilIcon } from '../../../icons/pencil.svg';
import { ReactComponent as BackIcon } from '../../../icons/BackIcon.svg';
import { ReactComponent as LogoIcon } from '../../../icons/logo.svg';
import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import useTranslate from '../../translations/useTranslate';
import Avatar from '../../components/generic/Avatar';
import ColorPicker from '../../components/dsm/ColorPicker';
import { AlertType } from '../../../domain/alert/AlertManager';
import { BillingCards } from '../../billing/BillingCards';
import { useBillingRepositoryImplementation } from '../../../data/billing/billingRepositoryImplementation';
import { useLoggedUserRepositoryImplementation } from '../../../data/loggedUser/loggedUserRepositoryImplementation';
import { useBillingViewModel } from '../../billing/BillingViewModel';
import { BillingType, PackageType, PackageUiModel } from '../../ui-model/billing/PackageUiModel';

const MAX_STEPS_COUNT = 2;

const recomandationTypes = [
  { id: 0, label: '1-5' },
  { id: 1, label: '5-10' },
  { id: 2, label: '10+' },
];

function Plans() {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [enableTrial, setEnableTrial] = useState<boolean>(false);
  const [stepCounter, setStepCounter] = useState<number>(1);
  const [labName, setLabname] = useState<string>('');
  const [maxEmployeeNumber, setMaxEmployeeNumber] = useState<number>();
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);
  const [color, setColor] = useState<string>('var(--dts_default_blue)');
  const [selectedRecomandation, setSelectedRecomandation] = useState<{ id: number; label: string }>(
    recomandationTypes[1],
  );
  const [packages, setPackages] = useState<Array<PackageUiModel>>();
  // const [image, setImage] = useState(null);
  // const [previewUrl, setPreviewUrl] = useState('');
  // const fileInput = useRef<HTMLInputElement>(null);
  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const repo = useCompaniesRepositoryImplementation();
  const { createCompany } = usePlansViewModel(repo);

  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const repoBilling = useBillingRepositoryImplementation();
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const { getPackages, getMaxCollaborators, getEnableTrial } = useBillingViewModel(
    repoBilling,
    loggedUserRepo,
    contactRepo,
  );

  useEffect(() => {
    getPackages().then((resp) => {
      setPackages(resp);
    });
    getMaxCollaborators().then((resp) => {
      if (resp) {
        resp !== true && setMaxEmployeeNumber(resp);
      }
    });
    getEnableTrial().then((resp) => {
      setEnableTrial(resp);
    });
  }, []);

  // const handleFile = (file) => {
  //   setImage(file);
  //   setPreviewUrl(URL.createObjectURL(file));
  // };

  // const handleOndragOver = (event) => {
  //   event.preventDefault();
  // };
  // const handleOndrop = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const imageFile = event.dataTransfer.files[0];
  //   handleFile(imageFile);
  // };

  const changeStepCounter = (num: number) => {
    if (num > 0 && MAX_STEPS_COUNT > stepCounter) {
      setStepCounter(stepCounter + num);
      return;
    }
    if (num < 0 && stepCounter > 1) {
      setStepCounter(stepCounter + num);
      return;
    }
    if (num < 0 && stepCounter == 1) {
      navigate('/');
      return;
    }
  };

  const handleTextChange = (event) => {
    setLabname(event.target.value);
  };

  const finishPlans = (resp: {
    id: number;
    packageType: PackageType;
    billingType: BillingType;
    billingPeriod: 0;
  }) => {
    createCompany({
      name: labName,
      color: color,
      packageInformation: { packageType: resp.packageType, billingType: resp.billingType },
    }).then((resp) => {
      if (resp) {
        if (resp !== true) navigate(`/${resp.id}`);
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
    });
  };

  const inputStyle = {
    width: '250px',
    padding: '7px',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#151515',
    boxShadow: 'none',
    outline: 'none',
    border: '1px solid var(--dts_light_grey)',
    borderRadius: '5px',
  };

  const stepOne = () => {
    return (
      <>
        <CustomBackIcon
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
          width={24}
          height={24}
          onClick={() => {
            changeStepCounter(-1);
          }}
        />
        <PlansContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <Content>
            <LogoContainer>
              <LogoIcon />
            </LogoContainer>
            <Section>
              <TitleText type={TextType.TITLE_BOLD_2}>
                {translate(LANGUAGE_KEYS.CREATE_LABORATORY_TITLE)}
              </TitleText>
              <InformationText type={TextType.BODY_2}>
                {translate(LANGUAGE_KEYS.CREATE_LABORATORY_DESCRIPTION)}
              </InformationText>
              <InputText
                placeHolder={translate(LANGUAGE_KEYS.ADD_LABORATORY_NAME)}
                width='100%'
                initValue={labName}
                onChange={handleTextChange}
                onKeyDown={() => {}}
                clickClose={() => {}}
                customStyle={inputStyle}
              />

              {/* <DragDropBox
          onDragOver={handleOndragOver}
          onDrop={handleOndrop}
          onClick={() => {
            if (fileInput.current !== null) {
              fileInput.current.click();
            }
          }}
        >
          {!previewUrl && (
            <DragText type={TextType.CAPTION_REGULAR}>Drag & Drop your logo here</DragText>
          )}
          <input
            type='file'
            accept='image/*'
            ref={fileInput}
            hidden
            onChange={(e) => {
              if (e.target.files !== null) handleFile(e.target.files[0]);
            }}
          />
          {previewUrl && (
            <ImageContainer className='image'>
              <img src={previewUrl} width={272} height={272} alt='image' />
            </ImageContainer>
          )}
        </DragDropBox> */}
              <Recomandations>
                {recomandationTypes.map((recomandation, index) => {
                  return (
                    <Recomandation
                      key={index}
                      onClick={() => {
                        setSelectedRecomandation(recomandation);
                      }}
                    >
                      <Input
                        id={`${recomandation.id}`}
                        type='radio'
                        checked={recomandation.id === selectedRecomandation.id}
                        readOnly={true}
                      />
                      <Text type={TextType.CAPTION_2_BOLD_12}>{recomandation.label}</Text>
                    </Recomandation>
                  );
                })}
              </Recomandations>
              <AvatarConatiner
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
              >
                <PencilContainer
                  onClick={() => {
                    setIsColorPickerOpen(true);
                  }}
                >
                  <Avatar
                    textType={TextType.ROBOTO_400_80_94}
                    fullName={labName === '' ? ' ' : labName}
                    size={{ outer: 272, inner: 270 }}
                    avatarStyle={{ background: color }}
                  />
                </PencilContainer>
                {isColorPickerOpen && (
                  <ColorPicker
                    selectColor={color}
                    onSelectColor={(color: string) => {
                      setColor(color);
                    }}
                    onCancel={() => setIsColorPickerOpen(false)}
                  />
                )}
              </AvatarConatiner>
            </Section>
          </Content>
          <Footer
            className={`${labName === '' && 'disabled-button'}`}
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <Button
              textType={TextType.CAPTION_BOLD}
              onClick={() => {
                if (labName !== '') {
                  changeStepCounter(1);
                }
              }}
            >
              {translate(LANGUAGE_KEYS.CONTINUE)}
            </Button>
          </Footer>
        </PlansContentContainer>
      </>
    );
  };

  const stepTwo = () => {
    return (
      <PackageContainer>
        <HeaderConatiner>
          <LeftIcon
            width={12.35}
            height={20}
            onClick={() => {
              changeStepCounter(-1);
            }}
          />
          <Text type={TextType.TITLE_BOLD_4} ellipsis={true}>
            {LANGUAGE_KEYS.PACKIGE_SELECTION}
          </Text>
        </HeaderConatiner>
        <BillingCards
          isFti={true}
          enableTrial={enableTrial}
          username={undefined}
          mySubscription={undefined}
          packages={packages}
          updateCompanyPackage={finishPlans}
          recomandation={selectedRecomandation.id}
          maxEmployeeNumber={maxEmployeeNumber}
          laborEmployeeNumber={undefined}
        />
      </PackageContainer>
    );
  };

  return (
    <PlansContainer>
      {stepCounter === 1 && stepOne()}
      {stepCounter === 2 && stepTwo()}
    </PlansContainer>
  );
}

const PlansContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--dts_withe_background);
  position: relative;
`;

const HeaderConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  svg {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const PlansContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_white);
    max-width: 528px;
    min-width: 568px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: ${desktop}px) {
      padding: 56px 36px 56px 36px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 56px 36px 56px 36px;
      overflow: auto;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 10px;
      max-width: calc(100% - 20px);
      min-width: calc(100% - 20px);
    }
    // Mobile
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 56px;
`;

const TitleText = styled(Text)`
  margin-bottom: 8px;
  text-align: center;
`;

const InformationText = styled(Text)`
  margin-bottom: 8px;
  max-width: 472px;
  text-align: center;
  opacity: 0.7;
`;

const Footer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 10px;
    &.disabled-button {
      .button{
        &:hover{
          background: var(--dts_default_blue);
        }
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      width: 100%;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      width: 100%;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 10px;
      width: calc(100% - 20px);
    }
    // Mobile
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--dts_white);
  padding: 36px 36px 0px 36px;
`;

// const DragDropBox = styled.div`
//   margin-top: 56px;
//   background: #d9d9d9;
//   border: 1px solid var(--dts_dark_grey);
//   border-radius: 50%;
//   min-width: 270px;
//   max-width: 270px;
//   min-height: 270px;
//   max-height: 270px;
//   display: flex;
//   justify-content: center;
//   cursor: pointer;
// `;

// const DragText = styled(Text)`
//   max-width: 80px;
//   text-align: center;
//   color: var(--dts_dark_grey);
// `;

// const ImageContainer = styled.div`
//   border-radius: 50%;
//   img {
//     border-radius: 50%;
//   }
// `;

const Recomandations = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const Recomandation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  width: 12px;
  height: 12px;
  margin: 0;
  cursor: inherit;
`;

const AvatarConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    margin-top: 56px;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin: auto;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const PencilContainer = styled.span``;

const CustomBackIcon = styled(BackIcon)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: absolute;
    fill: var(--dts_black);
    cursor: pointer;
    :hover {
      fill: var(--dts_hover_blue);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      left: 36px;
      top: 36px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      left: 36px;
      top: 36px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      left: 10px;
      top: 15px;
    }
    // Mobile
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export default Plans;

import { BASE_URL } from '../Server';
import { useApiHeaderWithoutCompanyId } from '../../presentation/hooks/useApiHeaderWithoutCompanyId';
import {
  CompanySubscriptiondata,
  MaxPackageCollaborators,
  Package,
  PackageModelResponse,
  TrialEnable,
  UpdatepackageResponse,
} from '../../domain/billing/Package';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';

function getPackages(): Promise<PackageModelResponse> {
  return fetch(BASE_URL + '/packages', {
    method: 'GET',
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: [] };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: [] };
    });
}

function updateCompanyPackage(params: Package): Promise<UpdatepackageResponse> {
  return fetch(BASE_URL + `/updateCompanyPackage`, {
    method: 'POST',
    headers: useApiHeaderWithoutCompanyId(),
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function getMaxCollaborators(): Promise<MaxPackageCollaborators> {
  return fetch(BASE_URL + '/getMaxBasicCollaborators', {
    method: 'GET',
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: -1 };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: -1 };
    });
}

function getCompanySubscription(): Promise<CompanySubscriptiondata> {
  return fetch(BASE_URL + '/companySubscription', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: -1 };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: -1 };
    });
}

function getTrialEnable(): Promise<TrialEnable> {
  return fetch(BASE_URL + '/trialEnable', {
    method: 'GET',
    headers: useApiHeaderWithoutCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: false };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: false };
    });
}

export {
  getPackages,
  updateCompanyPackage,
  getMaxCollaborators,
  getCompanySubscription,
  getTrialEnable,
};

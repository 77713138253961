import React, { useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../components/dsm/Text';
import InputField from '../../components/dsm/InputField';
import { Checkbox } from '../../components/dsm/Checkbox';
import FormAction from '../components/FormAction';
import { useNavigate } from 'react-router';
import { InputFieldErrorType, getInputErrorText } from '../components/FieldUtils';
import { useSignUpFlowRepositoryImplementation } from '../../../data/signUpFlow/signUpFlowRepositoryImplementation';
import { useSignUpFlowViewModel } from './SignUpFlowViewModel';
import { useAlertManagerImplementation } from '../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../alert/AlertViewModel';
import { AlertType } from '../../../domain/alert/AlertManager';
import Recaptcha from './Recaptcha';
import { useGoogleRepositoryImplementation } from '../../../data/google/googleRepositoryImplementation';
import { useCaptchaViewModel } from './CaptchaViewModel';
import { MobileBackButton } from '../../components/generic/MobileBackButton';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';
import useTranslate from '../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../translations/languageKeys';
import { emailPattern } from '../../components/dsm/InputPatterns';

const createAccountFields = [
  {
    labelText: LANGUAGE_KEYS.EMAIL,
    labelFor: 'email-address',
    id: 'email-address',
    name: 'email',
    type: 'email',
    autoComplete: 'email',
    isRequired: true,
    placeholder: 'dentech@dentech.ro',
  },
];

const SignUp = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const repo = useSignUpFlowRepositoryImplementation();
  const { addUser } = useSignUpFlowViewModel(repo);
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);
  const captchaRepo = useGoogleRepositoryImplementation();
  const { captchaValidation, getCaptchaSiteKey } = useCaptchaViewModel(captchaRepo);

  const fields = createAccountFields;
  const fieldsState = {};
  fields.forEach((field) => (fieldsState[field.id] = ''));
  const [loginState, setLoginState] = useState(fieldsState);
  const [checked, setchecked] = useState(false);
  const [errorText, setErrorText] = useState<string>('');
  const [captchaIsValid, setCaptchaIsValid] = useState<boolean>(false);

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };

  const handleOnCheckboxClick = (index: number, active: boolean) => {
    setchecked(active);
  };

  const onChangeCaptcha = (captchaToken: string) => {
    // captchaValidation(captchaToken, window.location.hostname);
    setCaptchaIsValid(captchaToken !== null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = loginState['email-address'];
    if (!emailPattern.test(email)) {
      return;
    }
    addUser(email, checked).then((resp) => {
      if (!resp.hasError) {
        navigate('/checkemail', {
          state: {
            email: email,
          },
        });
        setErrorText('');
        return;
      }
      if (resp.hasError && resp.errorCode === 300) {
        setErrorText(translate(getInputErrorText(InputFieldErrorType.ALREADY_USE_EMAIL)));
        return;
      }
      if (resp.hasError) {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
        setErrorText('');
        return;
      }
    });
  };

  return (
    <>
      {isMobile && <MobileBackButton />}
      <SignUpContainer
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        <ContentContainer
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <TitleText type={TextType.TITLE_BOLD_2}>
            {translate(LANGUAGE_KEYS.START_FOR_FREE)}
          </TitleText>
          <CustomForm>
            <ErrorText type={TextType.BODY_2}>{errorText}</ErrorText>
            <InputFields>
              {createAccountFields.map((field) => {
                return (
                  <InputField
                    key={field.id}
                    handleChange={handleChange}
                    value={loginState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                    error={false}
                    autoComplete={field.autoComplete}
                    strengthPassword={false}
                  />
                );
              })}
            </InputFields>

            <Recaptcha siteKey={getCaptchaSiteKey()} onChangeCaptcha={onChangeCaptcha} />

            <MailList>
              <Checkbox
                widthBox={16}
                heightBox={16}
                checkboxId={0}
                onClick={handleOnCheckboxClick}
                active={checked}
                disabled={true}
              />
              <Text type={TextType.CAPTION_REGULAR_2}>
                {translate(LANGUAGE_KEYS.AGREE_TO_JOIN_MAIL_LIST)}
              </Text>
            </MailList>
          </CustomForm>
          <FormAction
            handleSubmit={handleSubmit}
            text={translate(LANGUAGE_KEYS.CREATE_ACCOUNT)}
            action='submit'
            type='Button'
            disabled={checked && loginState['email-address'] !== '' && captchaIsValid}
          />
          <TermsPrivacyText type={TextType.CAPTION_REGULAR}>
            {translate(LANGUAGE_KEYS.BY_CLICK_YOU_AGREE)}{' '}
            <span
              onClick={() => {
                navigate('/terms');
              }}
            >
              {translate(LANGUAGE_KEYS.TERMS_AND_CONDITIONS)}
            </span>{' '}
            &{' '}
            <span
              onClick={() => {
                navigate('/privacy');
              }}
            >
              {translate(LANGUAGE_KEYS.PRIVACY_POLICY)}
            </span>
            .
          </TermsPrivacyText>
          <LoginText>
            <Text type={TextType.CAPTION_REGULAR}>
              {translate(LANGUAGE_KEYS.ALREADY_HAVE_AN_ACCOUNT)}
              <FormAction
                handleSubmit={() => {
                  navigate('/login');
                }}
                text={translate(LANGUAGE_KEYS.LOG_IN)}
                action={undefined}
                type='Text'
              />
            </Text>
          </LoginText>
        </ContentContainer>
      </SignUpContainer>
    </>
  );
};

const SignUpContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    align-items: center;
    flex-direction: column;
    //Desktop
    @media (min-width: ${desktop}px) {
      height: calc(100% - 68px);
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const ContentContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 304px;
    min-width: 304px;
    //Desktop
    @media (min-width: ${desktop}px) {
      margin-top: 68px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin-top: 20px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomForm = styled.form`
  margin-bottom: 16px;
`;

const TitleText = styled(Text)`
  margin-bottom: 48px;
`;

const InputFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  margin-bottom: 16px;
`;

const MailList = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
  padding: 6px 8px 6px 8px;
`;

const TermsPrivacyText = styled(Text)`
  span {
    color: var(--dts_default_blue);
    cursor: pointer;
    &:hover {
      color: var(--dts_hover_blue);
    }
  }
`;

const LoginText = styled.div`
  text-align: center;
  margin-top: 24px;
`;

const ErrorText = styled(Text)`
  color: var(--dts_red);
  margin-bottom: 8px;
  text-align: left;
  line-height: 20px;
`;

export default SignUp;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { LobbyEmptyView } from './LobbyEmptyView';
import { LobbyCard } from './LobbyCard';
import { LobbyCardsType } from '../ui-model/lobby/LobbyUiModel';
import { lobbyRepositoryImplementation } from '../../data/lobby/lobbyRepositoryImplementation';
import { useLobbyViewModel } from './LobbyViewModel';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useContactsRepositoryImplementation } from '../../data/user-management/UsersRepositoryImplementation';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../DeviceInformations';
import ToggleHeader from '../components/generic/ToggleHeader';
import { ReactComponent as ComputerIcon } from '../../icons/ComputerIcon.svg';
import { ReactComponent as CloseButtonIcon } from '../../icons/close.svg';
import ContentLayout from '../components/layout/ContentLayout';
import DesktopHeader from '../components/generic/DesktopHeader';

function Lobby() {
  const repo = lobbyRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const { userName, lobbyCompanies, getLobbyCompanies, answerWorkerInvitation } = useLobbyViewModel(
    repo,
    loggedUserRepo,
    contactRepo,
  );
  const navigate = useNavigate();
  const {
    isTabletSmall,
    isTabletLarge,
    isMobile,
    mobileSmallSize,
    mobileSize,
    tabletLargeSize,
    tabletSmallSize,
    desktopSize,
  } = useDeviceParameters();
  const [mobilePlanCard, setMobilePlanCard] = useState<boolean>(true);

  useEffect(() => {
    getLobbyCompanies();
  }, [loggedUserRepo.session, loggedUserRepo.userId]);

  const onClickViewPlans = () => {};

  const onClickCreateNewLab = () => {
    navigate('/plans');
  };

  const onclickEnter = (companyId: number) => {
    navigate(`/${companyId}`);
  };

  const onClickDecline = (companyId: number) => {
    answerWorkerInvitation({ companyId: companyId, apply: false });
  };

  const onClickAccept = (companyId: number) => {
    answerWorkerInvitation({ companyId: companyId, apply: true });
  };

  const translate = useTranslate();

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={'Welcome ' + userName + ' !'}
          buttonText=''
          enabledClick={false}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={'Welcome ' + userName + ' !'}
          enabledClick={true}
          buttonText={translate(LANGUAGE_KEYS.CREATE_NEW_LAB)}
          onClick={() => {
            onClickCreateNewLab();
          }}
          buttonIcon={<PlusIcon />}
        />
      )}
      <ContentLayout>
        <Text type={TextType.BODY_BOLD} ellipsis={true}>
          {translate(LANGUAGE_KEYS.MY_LABORATORIES)}
        </Text>
        {isMobile && mobilePlanCard && (
          <MobilePlanCard
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <CustomCloseButtonIcon
              width={16}
              height={16}
              onClick={() => {
                setMobilePlanCard(false);
              }}
            />
            <CustomComputerIcon width={40} height={40} />
            <MobilePlanCardContent
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
            >
              <Text type={TextType.CAPTION_REGULAR}>
                {translate(LANGUAGE_KEYS.UPGRADE_FOR_PREMIUM_FEATURES)}
              </Text>
              <Button
                onClick={() => {
                  navigate('/plans');
                }}
                textType={TextType.CAPTION_BOLD}
              >
                <PlusIcon />
                {translate(LANGUAGE_KEYS.VIEW_PLANS)}
              </Button>
            </MobilePlanCardContent>
          </MobilePlanCard>
        )}
        {lobbyCompanies.length <= 0 && (
          <LobbyEmptyView
            onClickViewPlans={onClickViewPlans}
            onClickCreateNewLab={onClickCreateNewLab}
          />
        )}
        {lobbyCompanies.length > 0 && (
          <SecondSection
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            {lobbyCompanies.map((item, index) => {
              if (item.type === LobbyCardsType.SIMPLE) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    collaborators={item.collaborators}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    onclickEnter={onclickEnter}
                  />
                );
              }
              if (item.type === LobbyCardsType.INVITATION) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    collaborators={item.collaborators}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    onClickDecline={onClickDecline}
                    onClickAccept={onClickAccept}
                  />
                );
              }
              if (item.type === LobbyCardsType.CREATE) {
                return (
                  <LobbyCard
                    key={index}
                    type={item.type}
                    icon={item.icon}
                    companyColor={item.companyColor}
                    address={item.address}
                    companyId={item.companyId}
                    companyName={item.companyName}
                    collaborators={item.collaborators}
                    userRole={item.userRole}
                    inviteText={item.inviteText}
                    onClickCreateNewLab={onClickCreateNewLab}
                  />
                );
              }
            })}
          </SecondSection>
        )}
      </ContentLayout>
    </>
  );
}

const SecondSection = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-wrap: wrap;
    //Desktop
    @media (min-width: ${desktop}px) {
      gap: 16px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      gap: 16px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      gap: 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MobilePlanCard = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
     display: none;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 16px;
      display: flex;
      gap: 16px;
      background: var(--dts_light_grey);
      position: relative;
      margin-bottom:8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const MobilePlanCardContent = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
     display: none;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
     display: flex;
     flex-direction: column;
     gap: 8px;
     button{
      align-self: flex-start;
     }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomComputerIcon = styled(ComputerIcon)`
  fill: var(--dts_red);
`;

const CustomCloseButtonIcon = styled(CloseButtonIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export default Lobby;

import { Plan } from '../../domain/user-management/Plans';
import { CompaniesRepository } from '../../domain/user-management/UsersRepository';

type createCompanyUseCase = Pick<CompaniesRepository, 'createCompany'>;

const createCompanyUseCase = (repo: createCompanyUseCase, plan: Plan) => {
  return repo.createCompany(plan);
};

export { createCompanyUseCase };

import React from 'react';
import { CaseRepository } from '../../../domain/CaseRepository';
import { uploadCaseUpdateUseCase } from '../../../useCases/case/uploadCaseUpdateUseCase';
import {
  INSERT_PHASE_TO_POSITION,
  UPDATE_PHASE_INFO,
} from '../../../data/cases/detail/caseDetailActionTypes';
import { PHASE_TYPE_PHASE, PHASE_TYPE_TIME } from '../../../data/cases/detail/caseDetailPhaseTypes';
import { Template } from '../../../domain/worktype/templates/Template';

function useMobileCaseViewModel(repo: CaseRepository) {
  const updateMobilePhase = React.useCallback(
    function (caseId, labWorkId, position, phaseId) {
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, caseId, [
        [Date.now(), INSERT_PHASE_TO_POSITION, labWorkId, position, PHASE_TYPE_PHASE, phaseId],
      ]);
    },
    [repo.updateCase],
  );

  const updateMobileDate = React.useCallback(
    function (caseId, labWorkId, position, date) {
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, caseId, [
        [
          Date.now(),
          INSERT_PHASE_TO_POSITION,
          labWorkId,
          position,
          PHASE_TYPE_TIME,
          date.getTime(),
        ],
      ]);
    },
    [repo.updateCase],
  );

  const updateMobileTemplate = React.useCallback(
    function (caseId, labWorkId, position, template: Template) {
      const newChange: Array<number> = [];
      newChange.push(Date.now());
      newChange.push(INSERT_PHASE_TO_POSITION);
      newChange.push(labWorkId);
      newChange.push(position);
      template?.phases?.forEach((phase) => {
        if (phase.id !== undefined) {
          newChange.push(PHASE_TYPE_PHASE);
          newChange.push(phase.id);
        }
      });
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, caseId, [newChange]);
    },
    [repo.updateCase],
  );

  const updateMobileAssigne = React.useCallback(
    function (caseId, labWorkId, phaseId, phaseOrder, assigneeId, status) {
      const newChange: Array<number | string | any> = [];
      newChange.push(Date.now());
      newChange.push(UPDATE_PHASE_INFO);
      newChange.push(labWorkId);
      newChange.push(phaseId);
      newChange.push(phaseOrder);
      newChange.push(assigneeId);
      newChange.push(status);
      return uploadCaseUpdateUseCase({ updateCase: repo.updateCase }, caseId, [newChange]);
    },
    [repo.updateCase],
  );

  return {
    updateMobilePhase,
    updateMobileDate,
    updateMobileTemplate,
    updateMobileAssigne,
  };
}

export { useMobileCaseViewModel };

import * as actionTypes from './teethActionTypes';
import { getTeeth, updateTeethTypeInfo } from './teethService';
import { UpdateTeethTypeInfo } from '../../../domain/worktype/teeth/UpdateTeethTypeInfo';
import { checkErrorStatus } from '../../ErrorHandling';

const getTeethAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_TEETH });
  return getTeeth().then((t) => {
    if (checkErrorStatus(t.code, dispatch)) {
      dispatch({ type: actionTypes.GET_TEETH_SUCCESS, teeth: t.data });
      return t.data;
    } else {
      dispatch({ type: actionTypes.UPDATE_TEETH_FAILD, error: t.code });
      return t.data;
    }
  });
};

const updateTeethAction =
  (workTypeid: number, teethList: UpdateTeethTypeInfo[]) => (dispatch: any) => {
    dispatch({ type: actionTypes.UPDATE_TEETH });
    return updateTeethTypeInfo(workTypeid, teethList).then((teeth) => {
      if (checkErrorStatus(teeth.code, dispatch)) {
        dispatch({ type: actionTypes.UPDATE_TEETH_SUCCESS });
        return teeth.data;
      } else {
        dispatch({ type: actionTypes.UPDATE_TEETH_FAILD, error: teeth.code });
        return teeth.data;
      }
    });
  };

export { getTeethAction, updateTeethAction };

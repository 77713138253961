import React from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../../../../../components/dsm/Text';
import { LANGUAGE_KEYS } from '../../../../../translations/languageKeys';
import useTranslate from '../../../../../translations/useTranslate';

function TemplateRowHeader() {
  const translate = useTranslate();
  return (
    <TemplateHeaderRowContainer>
      <FirstContainer>
        <IconComponent></IconComponent>
        <NameComponent>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.TEMPLATE_NAME)}</Text>
        </NameComponent>
      </FirstContainer>
      <SecondContainer>
        {/*<ExtensionStyle>*/}
        {/*  <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.EXTENSIONS)}</Text>*/}
        {/*</ExtensionStyle>*/}
        <PhaseStyle>
          <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PHASES)}</Text>
        </PhaseStyle>
      </SecondContainer>
      <ThirdContainer></ThirdContainer>
    </TemplateHeaderRowContainer>
  );
}

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const TemplateHeaderRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  gap: 4px;
  margin: 24px 4px 8px 4px;
  justify-content: space-between;
`;

const FirstContainer = styled.div`
  flex-basis: 63%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SecondContainer = styled.div`
  display: flex;
  flex-basis: 25%;
  gap: 24px;
`;
const ThirdContainer = styled.div`
  flex-basis: 10%;
  width: 16px;
`;

const IconComponent = styled.div`
  width: 16px;
`;

const NameComponent = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-grow: 1;
`;

const PhaseStyle = styled.span`
  min-width: 108px;
`;

export default TemplateRowHeader;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './presentation/App';
import { BrowserRouter } from 'react-router-dom';
import { appRepositoryImplementation } from './data/appRepositoryImplementation';
import { Provider } from 'react-redux';
import { BreakpointProvider } from 'react-socks';

ReactDOM.render(
  <BrowserRouter
    basename={process.env.REACT_APP_BASE_NAME === '/' ? undefined : process.env.REACT_APP_BASE_NAME}
  >
    <BreakpointProvider>
      <Provider store={appRepositoryImplementation}>
        <App />
      </Provider>
    </BreakpointProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

import * as actionTypes from './invoiceActionTypes';
import {
  getInvoices,
  getInvoiceById,
  updateInvoice,
  checkInvoiceNumber,
  deleteInvoice,
} from './invoiceService';
import { InvoiceUpdate } from '../../domain/invoice/Invoice';
import { checkErrorStatus } from '../ErrorHandling';

const getInvoicesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_INVOICES });
  return getInvoices().then((invoice) => {
    if (checkErrorStatus(invoice.code, dispatch)) {
      dispatch({ type: actionTypes.GET_INVOICES_SUCCESS, invoices: invoice.data });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    } else {
      dispatch({ type: actionTypes.GET_INVOICES_FAILD });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    }
  });
};

const getInvoiceByIdAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_INVOICE_BY_ID });
  return getInvoiceById(id).then((invoice) => {
    if (checkErrorStatus(invoice.code, dispatch)) {
      dispatch({ type: actionTypes.GET_INVOICE_BY_ID_SUCCESS });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    } else {
      dispatch({ type: actionTypes.GET_INVOICE_BY_ID_FAILD });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    }
  });
};

const updateInvoiceAction = (param: InvoiceUpdate) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_INVOICE });
  return updateInvoice(param).then((invoice) => {
    if (checkErrorStatus(invoice.code, dispatch)) {
      dispatch({ type: actionTypes.UPDATE_INVOICE_SUCCESS });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    } else {
      dispatch({ type: actionTypes.UPDATE_INVOICE_FAILD });
      return { invoiedata: invoice.data, statusCode: invoice.code };
    }
  });
};

const deleteInvoiceAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_INVOICES });
  return deleteInvoice(id).then((invoice) => {
    if (checkErrorStatus(invoice.code, dispatch)) {
      dispatch({ type: actionTypes.DELETE_INVOICES_SUCCESS });
      return true;
    } else {
      dispatch({ type: actionTypes.DELETE_INVOICES_FAILD });
      return false;
    }
  });
};

const checkInvoiceNumberAction =
  (invoicePrefix: string, invoiceNumber: number) => (dispatch: any) => {
    dispatch({ type: actionTypes.CHECK_INVOICE_NUMBER });
    return checkInvoiceNumber(invoicePrefix, invoiceNumber).then((resp) => {
      if (checkErrorStatus(resp.code, dispatch) && resp.data) {
        dispatch({ type: actionTypes.CHECK_INVOICE_NUMBER_SUCCESS });
        return { data: resp.data, statusCode: resp.code };
      } else {
        dispatch({ type: actionTypes.CHECK_INVOICE_NUMBER_FAILD });
        return { data: resp.data, statusCode: resp.code };
      }
    });
  };

export {
  getInvoicesAction,
  getInvoiceByIdAction,
  updateInvoiceAction,
  checkInvoiceNumberAction,
  deleteInvoiceAction,
};

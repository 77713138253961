import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import Row from '../components/layout/Row';
import { Button } from '../components/dsm/buttons/Button';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';
import { AlertType } from '../../domain/alert/AlertManager';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import ToggleHeader from '../components/generic/ToggleHeader';
import DesktopHeader from '../components/generic/DesktopHeader';
import { useDeviceParameters } from '../hooks/useDeviceParameters';
import ContentLayout from '../components/layout/ContentLayout';
import Avatar from '../components/generic/Avatar';
import {
  languageList,
  LanguageSelector,
  LanguageSelectorProps,
} from '../components/generic/LanguageSelector';
import { useLoggedUserRepositoryImplementation } from '../../data/loggedUser/loggedUserRepositoryImplementation';
import { useMyProfileViewModel } from './MyProfileViewModel';
import { ReactComponent as PencilIcon } from '../../icons/pencil.svg';
import ColorPicker from '../components/dsm/ColorPicker';
import { useSignUpFlowRepositoryImplementation } from '../../data/signUpFlow/signUpFlowRepositoryImplementation';
import { InputFieldType, isValidInputField } from '../welcome/components/FieldUtils';
import { DeviceSizeProps } from '../DeviceInformations';
import { emailPattern } from '../components/dsm/InputPatterns';

function MyProfile() {
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);
  const translate = useTranslate();
  const repo = useLoggedUserRepositoryImplementation();
  const signUpRepo = useSignUpFlowRepositoryImplementation();
  const { lastName, firstName, profileColor, tellNumber, lang, email, updateUser, resetPassword } =
    useMyProfileViewModel(repo, signUpRepo);
  const { isTabletSmall, isTabletLarge, isMobile } = useDeviceParameters();

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [firstNameCopy, setFirstNameCopy] = useState<string>('');
  const [lastNameCopy, setLastNameCopy] = useState<string>('');
  const [profileColorCopy, setProfileColorCopy] = useState<string>('');
  const [tellNumberCopy, setTellNumberCopy] = useState<string>('');
  const [emailCopy, setEmailCopy] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<number>(1);

  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>(false);

  const [changedDataNumber, setChangedDataNumber] = useState<number>(0);

  const [changeValues, setChangeValues] = useState<{ [x: string]: any }>();

  useEffect(() => {
    firstName !== null && setFirstNameCopy(firstName);
    lastName !== null && setLastNameCopy(lastName);
    profileColor !== null && setProfileColorCopy(profileColor);
    tellNumber !== null && setTellNumberCopy(tellNumber);
    email !== null && setEmailCopy(email);
    if (lang !== null) {
      const lan = languageList.find((l) => l.languageCode === lang);
      if (lan !== undefined) setSelectedLanguage(lan?.id);
    }
  }, [repo.firstName, repo.lastName, repo.lang, repo.profileColor, repo.tellNumber]);

  const setDefaultUserData = () => {
    setFirstNameCopy(firstName === null ? '' : firstName);
    setLastNameCopy(lastName === null ? '' : lastName);
    setProfileColorCopy(profileColor === null ? '' : profileColor);
    setTellNumberCopy(tellNumber === null ? '' : tellNumber);
    setEmailCopy(email === null ? '' : email);
    if (lang !== null) {
      const lan = languageList.find((l) => l.languageCode === lang);
      if (lan !== undefined) setSelectedLanguage(lan?.id);
    }
    setChangeValues(undefined);
  };

  const setChangedData = (isDifferentValue: boolean) => {
    if (isDifferentValue) {
      setChangedDataNumber(changedDataNumber + 1);
    } else {
      setChangedDataNumber(changedDataNumber - 1);
    }
  };

  const handleChangeFirstName = (value) => {
    const nameValue = value.target.value;
    setChangedData(nameValue !== firstName);
    setFirstNameCopy(nameValue);
    if (nameValue !== firstName) {
      setChangeValues({ ...changeValues, firstName: nameValue });
    } else {
      changeValues !== undefined && delete changeValues['firstName'];
    }
  };

  const handleChangeLastName = (value) => {
    const nameValue = value.target.value;
    setChangedData(nameValue !== lastName);
    setLastNameCopy(nameValue);
    if (nameValue !== lastName) {
      setChangeValues({ ...changeValues, lastName: nameValue });
    } else {
      changeValues !== undefined && delete changeValues['lastName'];
    }
  };

  const handleChangeTellNumber = (value) => {
    const phonePattern = /^\d+$/;
    const tellNumberValue = value.target.value;
    if (phonePattern.test(tellNumberValue) || tellNumberValue === '') {
      setChangedData(tellNumberValue !== tellNumber);
      setTellNumberCopy(tellNumberValue);
      if (tellNumberValue !== tellNumber) {
        setChangeValues({ ...changeValues, phoneNumber: tellNumberValue });
      } else {
        changeValues !== undefined && delete changeValues['phoneNumber'];
      }
    }
  };

  const handleChangeEmail = (value) => {
    const emailValue = value.target.value;
    setChangedData(emailValue !== email);
    setEmailCopy(emailValue);
    if (email !== emailValue) {
      setChangeValues({ ...changeValues, email: emailValue });
    } else {
      changeValues !== undefined && delete changeValues['email'];
    }
  };

  const handleChangeProfileColor = (color) => {
    setChangedData(color !== profileColor);
    setProfileColorCopy(color);
    if (color !== profileColor) {
      setChangeValues({ ...changeValues, profileColor: color });
    } else {
      changeValues !== undefined && delete changeValues['profileColor'];
    }
  };

  const handleChangeLanguage = (newLangId) => {
    const newLang = languageList.find((l) => l.id === newLangId);
    if (newLang !== undefined) {
      setChangedData(newLang.languageCode !== lang);
      setSelectedLanguage(newLang.id);
      if (newLang.languageCode !== lang) {
        setChangeValues({ ...changeValues, language: newLang.languageCode });
      } else {
        changeValues !== undefined && delete changeValues['language'];
      }
    }
  };

  const handleSaveData = () => {
    if (changeValues !== undefined) {
      if (changeValues['email'] !== undefined) {
        if (!emailPattern.test(changeValues['email'])) {
          showAlert(translate(LANGUAGE_KEYS.INVALID_EMAIL_ADDRESS), AlertType.FAILD);
          return;
        }
      }
      updateUser(changeValues).then((resp) => {
        if (resp.hasError) {
          showAlert(
            resp.errorMessage === ''
              ? translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC)
              : resp.errorMessage,
            AlertType.FAILD,
          );
          return;
        } else {
          setChangedDataNumber(0);
          showAlert(translate(LANGUAGE_KEYS.UPDATE_MY_PROFILE_SUCCESSFULL), AlertType.SUCCESS);
        }
      });
    }
  };

  const handleResetPassword = () => {
    if (
      email !== undefined &&
      email !== null &&
      isValidInputField(InputFieldType.EMAIL_ADDRESS, email)
    ) {
      resetPassword(email).then((resp) => {
        if (resp.hasError) {
          showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
          return;
        } else {
          showAlert(
            translate(LANGUAGE_KEYS.ALERT_RESET_PASSWORD_SEND_EMAIL_SUCCESSFULL),
            AlertType.SUCCESS,
          );
        }
      });
    } else {
      showAlert(translate(LANGUAGE_KEYS.INVALID_EMAIL_ADDRESS), AlertType.FAILD);
    }
  };

  return (
    <>
      {(isTabletLarge || isTabletSmall || isMobile) && (
        <ToggleHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.MY_PROFILE)}
          enabledClick={undefined}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
          noButtonDesign={false}
        />
      )}
      {!isTabletLarge && !isTabletSmall && !isMobile && (
        <DesktopHeader
          hasContent={true}
          title={translate(LANGUAGE_KEYS.MY_PROFILE)}
          enabledClick={false}
          buttonText={undefined}
          onClick={() => {}}
          buttonIcon={undefined}
        />
      )}
      <ContentLayout>
        <ContentBody className={`${changedDataNumber > 0 && 'plus-margin'}`}>
          <Section>
            <SectionTitle type={TextType.BODY_2_BOLD} ellipsis={true}>
              {translate(LANGUAGE_KEYS.PROFILE_INFO)}
            </SectionTitle>
            <SectionBody
              className='profile-data'
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
            >
              <AvatarConatiner
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
              >
                <Avatar
                  textType={TextType.ROBOTO_400_30_34}
                  fullName={firstNameCopy + ' ' + lastNameCopy}
                  size={{ outer: 80, inner: 78 }}
                  avatarStyle={{ background: profileColorCopy }}
                />
                <PencilContainer
                  style={{ background: profileColorCopy }}
                  onClick={() => {
                    setIsColorPickerOpen(true);
                  }}
                >
                  <PencilIcon />
                </PencilContainer>
                {isColorPickerOpen && (
                  <ColorPicker
                    selectColor={profileColorCopy}
                    onSelectColor={(color: string) => {
                      handleChangeProfileColor(color);
                    }}
                    onCancel={() => setIsColorPickerOpen(false)}
                  />
                )}
              </AvatarConatiner>
              <InputsContent>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.FIRST_NAME)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={firstNameCopy}
                    onChange={(it) => {
                      handleChangeFirstName(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.LAST_NAME)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={lastNameCopy}
                    onChange={(it) => {
                      handleChangeLastName(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.PHONE_NUMBER)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={tellNumberCopy}
                    onChange={(it) => {
                      handleChangeTellNumber(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.EMAIL)}
                  </LabelText>
                  <InputComponent
                    mobile_small={mobileSmallSize}
                    mobile={mobileSize}
                    tablet_small={tabletSmallSize}
                    tablet_large={tabletLargeSize}
                    desktop={desktopSize}
                    type={'text'}
                    value={emailCopy}
                    onChange={(it) => {
                      handleChangeEmail(it);
                    }}
                    required
                  />
                </LabelInputContainer>
                <LabelInputContainer>
                  <LabelText type={TextType.CAPTION_REGULAR} ellipsis={true}>
                    {translate(LANGUAGE_KEYS.PASSWORD)}
                  </LabelText>
                  <ResetPasswordText
                    type={TextType.CAPTION_REGULAR}
                    onClick={handleResetPassword}
                    clr={TextColor.PRIMARY}
                  >
                    {translate(LANGUAGE_KEYS.RESET_PASSWORD)}
                  </ResetPasswordText>
                </LabelInputContainer>
              </InputsContent>
            </SectionBody>
          </Section>
          <Section>
            <SectionTitle type={TextType.BODY_2_BOLD}>
              {translate(LANGUAGE_KEYS.LANGUAGE)}
            </SectionTitle>
            <SectionBody
              className='language'
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
            >
              <Text type={TextType.CAPTION_REGULAR}>
                {translate(LANGUAGE_KEYS.LANGUAGE_SELECT_TEXT)}
              </Text>
              <LanguageSelector
                values={languageList.reduce((result: LanguageSelectorProps['values'], language) => {
                  result.push({
                    key: language.id,
                    label: language.language,
                    selected: language.id === selectedLanguage,
                  });
                  return result;
                }, [])}
                onSelect={(changedValueKey) => {
                  handleChangeLanguage(changedValueKey);
                }}
                hasSuccessIcon={true}
              />
            </SectionBody>
          </Section>
        </ContentBody>
        {changedDataNumber > 0 && (
          <BottomContainer>
            <Button
              style={{ width: 'auto' }}
              textType={TextType.CAPTION_BOLD}
              secondary={'secondary'}
              onClick={() => {
                setChangedDataNumber(0);
                setDefaultUserData();
              }}
            >
              {translate(LANGUAGE_KEYS.CANCEL)}
            </Button>
            <Button
              style={{ width: 'auto' }}
              onClick={() => {
                handleSaveData();
              }}
            >
              {translate(LANGUAGE_KEYS.SAVE)}
            </Button>
          </BottomContainer>
        )}
      </ContentLayout>
    </>
  );
}

const ContentBody = styled.div`
  background: var(--dts_white);
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  .&plus-margin {
    margin-bottom: 60px;
  }
`;

const InputsContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div``;

const SectionTitle = styled(Text)`
  border-bottom: 1px solid #d9d9d9;
`;

const AvatarConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    position: relative;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      margin: auto;
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const PencilContainer = styled.span`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  padding: 6px 7px;
  border: 2px solid var(--dts_white);
  line-height: 1;
  cursor: pointer;
`;

const SectionBody = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    margin-top: 16px;
    display: flex;
    gap: 24px;
    align-items: flex-start;
    &.language {
      gap: 8px;
      flex-direction: column;
      .language-container {
        min-width: 215px;
      }
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      &.profile-data {
        flex-direction: column;
      }
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 8px;
  width: 100%;

  &.visible {
    opacity: 1;
    transition: all 0.2s;
    visibility: visible;
  }
  &.hidden {
    display: none;
    opacity: 0;
    transition: all 0.2s;
    visibility: hidden;
  }
`;

const LabelText = styled(Text)`
  max-width: 112px;
  min-width: 112px;
`;

const ResetPasswordText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: var(--dts_hover_blue);
  }
`;

const InputComponent = styled.input<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    padding: 8px;
    border: 1px solid var(--dts_light_grey);
    border-radius: 3px;
    color: var(--dts_dark);
    box-shadow: none;
    outline: none;
    max-width: 344px;
    min-width: 344px;

    ::placeholder {
      color: var(--dts_dark);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      max-width: calc(100% - 168px);
      min-width: calc(100% - 138px);
    }
    //Mobile small 
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const BottomContainer = styled(Row)`
  position: fixed;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  padding: 0 24px;
  background-color: var(--dts_white);
  justify-content: end;
  box-shadow: -1px 2px 11px 0 rgba(0, 0, 0, 0.14);
  gap: 16px;
`;

export default MyProfile;

import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type GenerateEActivationTokenRepository = Pick<
  E_facturaRepository,
  'generateEActivationToken' | 'checkActivationToken'
>;

const generateEActivationTokenUseCase = (repo: GenerateEActivationTokenRepository) => {
  return repo.generateEActivationToken();
};

const checkActivationTokenUseCase = (
  repo: GenerateEActivationTokenRepository,
  param: { companyId: number; token: string },
) => {
  return repo.checkActivationToken(param);
};

export { generateEActivationTokenUseCase, checkActivationTokenUseCase };
export type { GenerateEActivationTokenRepository };

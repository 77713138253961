export default class UpdateWorkTypeExtensionInfo {
  workTypeId: number;
  groupId: number;
  extensionId: number;
  active: number;
  price: number;

  constructor(
    workTypeId: number,
    groupId: number,
    extensionId: number,
    active: number,
    price: number,
  ) {
    this.workTypeId = workTypeId;
    this.groupId = groupId;
    this.extensionId = extensionId;
    this.active = active;
    this.price = price;
  }
}

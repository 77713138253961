import { LoggedUserRepository } from '../domain/loggedUser/loggedUserRepository';

function useAppViewModel(loggedUserRepo: LoggedUserRepository) {
  return {
    session: loggedUserRepo.session,
    userName: loggedUserRepo.firstName + ' ' + loggedUserRepo.lastName,
  };
}

export { useAppViewModel };

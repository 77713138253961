import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LeftIcon } from '../../../icons/leftIcon.svg';
import { Text, TextType } from '../dsm/Text';

export type BreadcrumbProps = {
  textType?: TextType;
  iconWidth?: number;
  iconHeight?: number;
  breadcrumbList: Array<BreadcrumbItem>;
};

export type BreadcrumbItem = {
  name: string;
  link: string;
  state?: any;
};

function Breadcrumb(breadcrumbProps: BreadcrumbProps) {
  const { textType, iconWidth, iconHeight, breadcrumbList } = breadcrumbProps;
  const navigate = useNavigate();
  return (
    <BreadcrumbContent>
      {breadcrumbList?.map((breadcrumb, index) => {
        return breadcrumb.link !== undefined && breadcrumb.link !== '' ? (
          <BreadcrumbContentLink
            key={index}
            onClick={() => {
              console.log(breadcrumb.state);
              navigate(breadcrumb.link, { state: breadcrumb.state });
            }}
          >
            <SVGcontainer>
              <LeftIcon
                width={iconWidth === undefined ? '7.41' : iconWidth}
                height={iconHeight === undefined ? '12' : iconHeight}
              />
            </SVGcontainer>
            <Text type={textType === undefined ? TextType.BODY_BOLD : textType} ellipsis={true}>
              {breadcrumb.name}
            </Text>
          </BreadcrumbContentLink>
        ) : (
          <BreadcrumbContent key={index}>
            <SVGcontainer>
              <LeftIcon
                width={iconWidth === undefined ? '7.41' : iconWidth}
                height={iconHeight === undefined ? '12' : iconHeight}
              />
            </SVGcontainer>
            <Text type={textType === undefined ? TextType.BODY_BOLD : textType} ellipsis={true}>
              {breadcrumb.name}
            </Text>
          </BreadcrumbContent>
        );
      })}
    </BreadcrumbContent>
  );
}

const BreadcrumbContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
`;

const BreadcrumbContentLink = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: none;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const SVGcontainer = styled.p`
  line-height: 0px;
  margin: 0;
`;

export default Breadcrumb;

import React from 'react';
import styled from 'styled-components';
import { BaseDialog } from '../components/generic/BaseDialog';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { ReactComponent as KeyWithCloude } from '../../icons/e-factura/KeyWithCloude.svg';
import { ReactComponent as Certificate } from '../../icons/e-factura/Certificate.svg';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';

type E_facturaAuthorizationStartProps = {
  onClose: () => void;
  openAccountantDialog: () => void;
  selfAuthorization: () => void;
  openHelpModal: () => void;
};

const E_facturaAuthorizationStartModal = (props: E_facturaAuthorizationStartProps) => {
  const translate = useTranslate();
  const { onClose, openAccountantDialog, selfAuthorization, openHelpModal } = props;

  return (
    <BaseDialog
      isOpen={true}
      title={translate(LANGUAGE_KEYS.AUTHORIZATION_TITLE)}
      onClose={onClose}
      containerStyle={{ width: '558px' }}
      header={true}
      isScroll={false}
    >
      <DescriptionContainer>
        <Text type={TextType.BODY_REGULAR}>
          {translate(LANGUAGE_KEYS.AUTHORIZATION_DESCRIPTION_1)}
        </Text>
        <Text type={TextType.BODY_REGULAR}>
          {translate(LANGUAGE_KEYS.AUTHORIZATION_DESCRIPTION_2)}
        </Text>
      </DescriptionContainer>
      <OptionCards>
        <Option onClick={openAccountantDialog}>
          <KeyWithCloude />
          <Text type={TextType.BODY_BOLD}>
            {translate(LANGUAGE_KEYS.AUTHORIZATION_ACCOUNTABLE)}
          </Text>
        </Option>
        <Option onClick={selfAuthorization}>
          <Certificate />
          <Text type={TextType.BODY_BOLD}>{translate(LANGUAGE_KEYS.AUTHORIZATION_SELF)}</Text>
        </Option>
      </OptionCards>
      <div>
        <Hint
          onClick={() => {
            // openHelpModal()
          }}
        >
          <InfoIcon width={20} height={20} />
          <Text type={TextType.CAPTION_BOLD} clr={TextColor.PRIMARY}>
            {translate(LANGUAGE_KEYS.AUTHORIZATION_HELP)}
          </Text>
        </Hint>
      </div>
    </BaseDialog>
  );
};

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OptionCards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const Option = styled.div`
  flex: 1 1 0;
  max-width: calc(50% - 40px);
  border: 1px solid var(--dts_dark_grey);
  padding: 24px 16px 24px 16px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  p {
    text-transform: uppercase;
    overflow: hidden;
  }
  svg {
    margin-bottom: 16px;
    fill: var(--dts_default_blue);
  }
  &:hover {
    background: var(--dts_default_blue);
    p {
      color: var(--dts_white);
    }
    svg {
      fill: var(--dts_white);
    }
  }
`;

const Hint = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  svg {
    fill: var(--dts_default_blue);
    path {
      fill: var(--dts_default_blue);
    }
  }
  &:hover {
    svg {
      fill: var(--dts_hover_blue);
      path {
        fill: var(--dts_hover_blue);
      }
    }
    p {
      color: var(--dts_hover_blue);
    }
  }
`;

export default E_facturaAuthorizationStartModal;

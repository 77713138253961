import type { AnyAction } from 'redux';
import * as actionTypes from './menuActionTypes';

const INITIAL_STATE = {
  open:
    window.localStorage.getItem('menuState') !== null
      ? Number(localStorage.getItem('menuState'))
      : 1,
};

const menuReducer = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      localStorage.setItem('menuState', '1');
      return { ...state, open: 1 };
    case actionTypes.MENU_CLOSE:
      localStorage.setItem('menuState', '0');
      return { ...state, open: 0 };
    default:
      return state;
  }
};
export { menuReducer };

import { deletePhase, getPhases, newPhase, updatePhase } from './phasesService';
import * as actionTypes from './phaseActionTypes';
import { UpdatePhase } from '../../domain/worktype/UpdatePhase';
import { checkErrorStatus } from '../ErrorHandling';

const getPhasesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_PHASES });
  return getPhases().then((phaseGroup) => {
    if (checkErrorStatus(phaseGroup.code, dispatch)) {
      dispatch({ type: actionTypes.GET_PHASES_SUCCESS, phases: phaseGroup.data });
      return phaseGroup.data;
    } else {
      dispatch({ type: actionTypes.GET_PHASES_FAILD, error: phaseGroup.code });
      return phaseGroup.data;
    }
  });
};

const newPhaseAction = (name: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.NEW_PHASES });
  return newPhase(name)
    .then((resp) => {
      if (checkErrorStatus(resp.code, dispatch)) {
        dispatch({ type: actionTypes.NEW_PHASES_SUCCESS });
        return;
      } else {
        dispatch({ type: actionTypes.NEW_PHASES_FAILD, error: resp.code });
        return;
      }
    })
    .then(() => {
      dispatch({ type: actionTypes.GET_PHASES });
      getPhases().then((phaseGroup) => {
        if (checkErrorStatus(phaseGroup.code, dispatch)) {
          dispatch({ type: actionTypes.GET_PHASES_SUCCESS, phases: phaseGroup.data });
          return phaseGroup.data;
        } else {
          dispatch({ type: actionTypes.GET_PHASES_FAILD, error: phaseGroup.code });
          return phaseGroup.data;
        }
      });
    });
};

const deletePhaseAction = (id: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.DELETE_PHASE });
  return deletePhase(id).then((resp) => {
    getPhases()
      .then((phases) => {
        if (checkErrorStatus(phases.code, dispatch)) {
          dispatch({ type: actionTypes.GET_PHASES_SUCCESS, phases: phases.data });
          return phases.data;
        } else {
          dispatch({ type: actionTypes.GET_PHASES_FAILD, error: phases.code });
          return phases.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp, dispatch)) {
          dispatch({ type: actionTypes.DELETE_PHASE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DELETE_PHASE_FAILD, error: resp.code });
        }
      });
  });
};

const updatePhaseAction = (phase: UpdatePhase) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPDATE_PHASE });
  return updatePhase(phase).then((resp) => {
    getPhases()
      .then((phases) => {
        if (checkErrorStatus(phases.code, dispatch)) {
          dispatch({ type: actionTypes.GET_PHASES_SUCCESS, phases: phases.data });
          return phases.data;
        } else {
          dispatch({ type: actionTypes.GET_PHASES_FAILD, error: phases.code });
          return phases.data;
        }
      })
      .then(() => {
        if (checkErrorStatus(resp, dispatch)) {
          dispatch({ type: actionTypes.UPDATE_PHASE_SUCCESS });
        } else {
          dispatch({ type: actionTypes.UPDATE_PHASE_FAILD, error: resp.code });
        }
      });
  });
};

export { getPhasesAction, newPhaseAction, deletePhaseAction, updatePhaseAction };

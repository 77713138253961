import styled from 'styled-components';

type Props = {
  secondary?;
};

export const ExtraTagCss = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px;
  gap: 8px;

  /* Light Gray */
  background: #e4e4e4;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

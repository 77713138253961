import { BillingRepository } from '../../domain/billing/BillingRepository';

type GetEnableTrialRepository = Pick<BillingRepository, 'getTrialEnable'>;

const getMEnableTrialUseCase = (repo: GetEnableTrialRepository) => {
  return repo.getTrialEnable();
};

export { getMEnableTrialUseCase };
export type { GetEnableTrialRepository };

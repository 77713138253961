import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, TextType } from '../../components/dsm/Text';
import { ReactComponent as PhaseSeparatorMobileIcon } from '../../../icons/phaseSeparatorMobileIcon.svg';
import { PhaseUiModelDate, PhaseUiModelPhase } from '../../ui-model/PhaseUiModel';
import { CreateNewPhaseCard } from '../labwork/phase/CreateNewPhaseCard';

type PhaseMobileContextMenuProps = {
  phase: PhaseUiModelPhase | PhaseUiModelDate;
  phaseRef: any;
  isEnabledExtendLabwork: boolean;
  canAddPhase: boolean;
  isPhaseCard: boolean;
  topPosition: boolean;
  openAddNewPhase?: (phase: PhaseUiModelPhase) => void;
  openAddNewTemplate?: (phase: PhaseUiModelPhase) => void;
  openAddNewDate?: (phase: any) => void;
};

export const PhaseMobileContextMenu = (props: PhaseMobileContextMenuProps) => {
  const {
    phase,
    isEnabledExtendLabwork,
    canAddPhase,
    isPhaseCard,
    topPosition,
    openAddNewPhase,
    openAddNewTemplate,
    openAddNewDate,
  } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <MobileSeparatorContainer
      className={`${!(isEnabledExtendLabwork && canAddPhase) && 'disabled'} ${
        !topPosition && 'top'
      }`}
      tabIndex={0}
      onBlur={() => {
        setExpanded(false);
      }}
      onClick={() => {
        if (isEnabledExtendLabwork && canAddPhase) {
          setExpanded(!expanded);
        }
      }}
    >
      {!isPhaseCard && (
        <MobileSeparator
          onClick={() => {
            if (isEnabledExtendLabwork && canAddPhase) {
              setExpanded(!expanded);
            }
          }}
        />
      )}
      {isPhaseCard && (
        <CreateNewPhaseCard
          disabled={isEnabledExtendLabwork}
          beforePhase={phase}
          handelOnClickCreateNew={() => {
            if (isEnabledExtendLabwork && canAddPhase) {
              setExpanded(!expanded);
            }
          }}
        />
      )}
      {isEnabledExtendLabwork && canAddPhase && (
        <ItemsContainer
          className={`${expanded && 'open'} ${isPhaseCard && 'card'} ${!topPosition && 'top'}`}
          style={{ left: `${(window.innerWidth - 180) / 2}px` }}
        >
          <ItemText
            type={TextType.CAPTION_BOLD}
            onClick={() => {
              openAddNewPhase !== undefined && openAddNewPhase(phase as PhaseUiModelPhase);
            }}
          >
            Add New Phase
          </ItemText>
          <ItemText
            type={TextType.CAPTION_BOLD}
            onClick={() => {
              openAddNewDate !== undefined && openAddNewDate(phase as PhaseUiModelDate);
            }}
          >
            Add Date and Time
          </ItemText>
          <ItemText
            type={TextType.CAPTION_BOLD}
            onClick={() => {
              openAddNewTemplate !== undefined && openAddNewTemplate(phase as PhaseUiModelPhase);
            }}
          >
            Choose from Template
          </ItemText>
        </ItemsContainer>
      )}
    </MobileSeparatorContainer>
  );
};

const MobileSeparator = styled(PhaseSeparatorMobileIcon)`
  cursor: pointer;
  width: 100%;
  :hover {
    * {
      fill: var(--dts_default_blue);
    }
  }
  &.disabled {
    cursor: not-allowed;
    * {
      fill: #d9d9d9;
    }
  }
`;

const MobileSeparatorContainer = styled.div`
  position: relative;
  &.top {
    min-height: 160px;
  }
  &.disabled {
    cursor: not-allowed;
    * {
      fill: #d9d9d9;
    }
  }
`;

const ItemsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 0px;
  overflow: hidden;
  background: var(--dts_white);
  z-index: 999999;
  padding: 0px;
  &.card {
    bottom: 20px;
  }
  &.top {
    bottom: unset;
    top: 20px;
  }
  &.open {
    max-height: 200px;
    max-width: 139px;
    padding: 16px;
    box-shadow: -1px 2px 11px 0px #00000024;
  }
`;

const ItemText = styled(Text)`
  padding: 6px 8px 6px 8px;
`;

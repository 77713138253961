import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { To } from 'react-router';

import { Text, TextType } from '../../../components/dsm/Text';

export type WorktypeItemTabProps = {
  name: string;
  isActive?: boolean;
  to: To;
  hasNotification?: boolean;
};

export const WorktypeItemTab: FC<WorktypeItemTabProps> = ({
  to,
  isActive,
  name,
  hasNotification,
}) => {
  return (
    <NavLinkExtended to={to} className={isActive ? ' active' : ''} isactive={isActive + ''} end>
      {hasNotification && <Indicator />}
      <WorktypeItemTabbCss className={'menu-item'}>
        <Text type={TextType.BODY_2_BOLD}>{name}</Text>
      </WorktypeItemTabbCss>
      <SelectedIndicator className={'indicator'} />
    </NavLinkExtended>
  );
};

export const SelectedIndicator = styled.div`
  height: 2px;

  /* DTS_Primary */

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
  background: #e4e4e4;
`;

interface CaseNavLinkProps extends Omit<NavLinkProps, 'caseSensitive' | 'style' | 'children'> {
  isactive?: string;
}

export const NavLinkExtended = styled(NavLink)<CaseNavLinkProps>`
  /* Auto layout */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 6px;
  text-decoration: none;
  height: 28px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  ${SelectedIndicator} {
    ${({ isactive }) =>
      isactive === 'true' &&
      css`
        background: #18a0fb;
      `}
  }
`;

const WorktypeItemTabbCss = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 8px;

  // width: 100%;
  height: 20px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

const Indicator = styled.div`
  position: absolute;
  right: 10px;
  top: -10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--dts_red);
  order: 2;
  z-index: 1;
  flex-grow: 0;
`;

import React, { useState } from 'react';
import styled from 'styled-components';

export type CheckboxputProps = {
  checkboxId: number;
  disabled?: boolean;
  onClick: (value: number, active: boolean) => void;
  active: boolean;
  widthBox?: number;
  heightBox?: number;
};

export const Checkbox = ({
  checkboxId,
  disabled,
  onClick,
  active,
  widthBox,
  heightBox,
}: CheckboxputProps) => {
  const [value, setValue] = useState(active);

  const handleOnCheckboxClick = (index: number) => {
    setValue(!value);
    onClick(index, !value);
  };

  const CheckmarkStyle = {
    width: `${widthBox + 'px'}`,
    height: `${heightBox + 'px'}`,
    minWidth: `${widthBox + 'px'}`,
  };

  return (
    <CheckboxContainer
      aria-disabled={disabled !== undefined && !disabled}
      className={`checkbox ${disabled !== undefined && !disabled && 'disabled'}`}
      onClick={() => handleOnCheckboxClick(checkboxId)}
      style={CheckmarkStyle}
    >
      <Checkmark className={`${active ? 'on' : 'off'}`} style={CheckmarkStyle} />
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  &.disabled {
    cursor: not-allowed;
  }
`;

const Checkmark = styled.div`
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  min-width: 20px;
  border: 1px solid #151515;
  border-radius: 2px;
  box-sizing: border-box;
  &.on {
    &:after {
      content: '';
      position: absolute;
      border-bottom: 2px solid #151515;
      border-right: 2px solid #151515;
      width: 25%;
      height: 50%;
      top: 1px;
      left: 30%;
      transform: rotate(45);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transition: 0.2s;
    }
  }
  &.off {
    &:after {
      content: '';
      position: absolute;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent;
      width: 25%;
      height: 50%;
      top: 1px;
      left: 30%;
      transform: rotate(45);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transition: 0.2s;
    }
  }
`;

export class UpdateTemplate {
  id: number;
  name?: string;
  phaseIds?: number[];

  constructor(id: number, name?: string, phaseIds?: number[]) {
    this.id = id;
    this.name = name;
    this.phaseIds = phaseIds;
  }
}

import { WorkTypeRepository } from '../../domain/worktype/WorkTypeRepository';
import UpdateWorTypeInfo from '../../domain/worktype/UpdateWorTypeInfo';

type UpdateWorktypesRepository = Pick<WorkTypeRepository, 'updateWorkTypes' | 'getWorkTypes'>;

const updateWorktypesUseCase = (
  repo: UpdateWorktypesRepository,
  worktypeList: UpdateWorTypeInfo[],
) => {
  console.log('Here we go again updateWorktypesUseCase');
  repo.updateWorkTypes(worktypeList);
  repo.getWorkTypes();
};

export { updateWorktypesUseCase };
export type { UpdateWorktypesRepository };

import React, { useMemo, useState } from 'react';
import { PhaseUiModel, PhaseUiModelDate } from '../../../ui-model/PhaseUiModel';
import styled from 'styled-components';
import { ReactComponent as FlagIcon } from '../../../../icons/phase-card-date/flag.svg';
import { ReactComponent as DateAndTimeIcon } from '../../../../icons/phase-card-date/dateAndTime.svg';
import { ReactComponent as TimeIcon } from '../../../../icons/phase-card-date/time.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/trash.svg';
import { Text, TextType } from '../../../components/dsm/Text';
import { mapMonth } from '../../../components/dsm/DatePicker';
import {
  ContextMenu,
  ContextMenuOffset,
  ContextMenuOption,
} from '../../../components/dsm/ContextMenu';
import { useDeviceParameters } from '../../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../../DeviceInformations';
import useTranslate from '../../../translations/useTranslate';

type PhaseDateCardProps = {
  phase: PhaseUiModelDate;
  onDeletePhase: (phase: PhaseUiModel) => void;
};

const PhaseDateCard = (props: PhaseDateCardProps) => {
  const translate = useTranslate();
  const { phase, onDeletePhase } = props;

  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(false);
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <DeleteIcon />,
      label: 'Delete',
      onClick: () => {
        onDeletePhase(phase);
      },
    },
  ];

  const formattedDate = useMemo(() => {
    const date = new Date(phase.date);
    return `${date.getDate()} ${translate(mapMonth(date.getMonth())).substring(0, 3)}`;
  }, [phase.date]);

  const formattedTime = useMemo(() => {
    const date = new Date(phase.date);
    return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    }`;
  }, [phase.date]);

  return (
    <Container
      mobile_small={mobileSmallSize}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <Body
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
        onClick={(e) => {
          e.preventDefault();
          isMobile && handleContextMenuClick({ x: (window.innerWidth - 110.69) / 2, y: e.clientY });
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          handleContextMenuClick({ x: e.clientX, y: e.clientY });
        }}
      >
        {!isMobile && (
          <Row
            className='icon'
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <FlagIcon />
          </Row>
        )}
        <Row
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <DateAndTimeIcon />
          <Text type={TextType.CAPTION_REGULAR_2}>{formattedDate}</Text>
        </Row>
        <Row
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <TimeIcon />
          <Text type={TextType.CAPTION_REGULAR_2}>{formattedTime}</Text>
        </Row>
        {isMobile && (
          <Row
            className='icon'
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
          >
            <FlagIcon />
          </Row>
        )}
        <ContextMenu
          isOpen={isContextMenuOpen}
          setIsOpen={setIsContextMenuOpen}
          options={ContextMenuOptions}
          offset={contextMenuOffset}
        />
      </Body>
    </Container>
  );
};

const Container = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 100%;
      
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const Body = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    background: var(--dts_withe_background);
    height: 100%;
    display: flex;
    box-sizing: border-box;
    //Desktop
    @media (min-width: ${desktop}px) {
      flex-direction: column;
      padding: 4px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      flex-direction: column;
      padding: 4px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 100%;
      flex-direction: row;
      gap: 16px;
      border-radius: 3px;
      padding: 10px 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const Row = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    svg {
      width: 8px;
      height: 8px;
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      &.icon {
        margin-bottom: 12px;
        justify-content: center; 
      }
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      &.icon {
        margin-bottom: 12px;
        justify-content: center; 
      }
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      &.icon {
       margin-left: auto; 
      }
      p{
        line-height: 14px;
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export default PhaseDateCard;

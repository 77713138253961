import { TeethRepository } from '../../../domain/worktype/teeth/TeethRepository';
import { WorkTypeRepository } from '../../../domain/worktype/WorkTypeRepository';
import { UpdateTeethTypeInfo } from '../../../domain/worktype/teeth/UpdateTeethTypeInfo';

type UpdateTeethRepository = Pick<TeethRepository, 'updateTeeth'>;

const updateTeethUseCase = (
  repo: UpdateTeethRepository,
  worktypeRepo: WorkTypeRepository,
  workTypeId: number,
  teethList: UpdateTeethTypeInfo[],
) => {
  repo.updateTeeth(workTypeId, teethList);
  worktypeRepo.getWorkTypes();
};

export { updateTeethUseCase };
export type { UpdateTeethRepository };

export class UpdateTemplateOrderOrActive {
  active: number;
  id: number;
  order: number;

  constructor(active: number, id: number, order: number) {
    this.active = active;
    this.id = id;
    this.order = order;
  }
}

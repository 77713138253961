import React from 'react';
import styled from 'styled-components';

export type SpinnerProps = {
  height: number;
  width: number;
  beforeWidth: number;
  beforeHeight: number;
  background: string;
};

const Spinner = (props: SpinnerProps) => {
  const { width, height, beforeWidth, beforeHeight, background } = props;
  return (
    <SpinnerConatiner className='spinner-container'>
      <SpinnerItem
        style={{ width: width, height: height }}
        beforeWidth={beforeWidth}
        beforeHeight={beforeHeight}
        background={background}
      />
    </SpinnerConatiner>
  );
};

const SpinnerConatiner = styled.div`
  position: relative;
  margin-top: 32px;
`;

const SpinnerItem = styled.div<{ beforeWidth: number; beforeHeight: number; background: string }>(
  ({ beforeWidth, beforeHeight, background }) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  background: conic-gradient(
    from 180deg at 50% 50%,
    var(--dts_default_blue) 0deg,
    rgba(25, 161, 251, 0.15) 360deg
  );
  border-radius: 56px;
  animation: 1s rotate infinite linear;

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: ${beforeHeight}px;
    width: ${beforeWidth}px;
    background: ${background};
    border-radius: 48px;
  }
`,
);

export default Spinner;

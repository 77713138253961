import React, { FC } from 'react';
import { Text, TextType } from '../dsm/Text';
import styled from 'styled-components';

type MenuFooterProp = {
  ver: string;
};

export const MenuFooter: FC<MenuFooterProp> = ({ ver }) => {
  return (
    <MenuFooterWrapper>
      <Text type={TextType.MENU_VERSION}>
        f{process.env.REACT_APP_ENVIRONMENT_INITIAL} {ver}
      </Text>
    </MenuFooterWrapper>
  );
};

const MenuFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 5px 5px 10px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

import React, { useMemo } from 'react';
import { Text, TextType } from '../dsm/Text';
import styled from 'styled-components';
import { BaseDialog } from './BaseDialog';

export type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  options: Array<DialogOptionWithTitle>;
  onOptionClick: (key: number | string) => void;
  containerStyle?: React.CSSProperties;
};

type DialogOptionWithTitle = { title?: string; options: Array<DialogOption> };
type DialogOption = { key: number | string; label: string };

const Dialog = (props: DialogProps) => {
  const { isOpen, onClose, title, options, onOptionClick, containerStyle } = props;

  const renderOptions = useMemo(() => {
    return (
      <Options className='custom-scrollbar'>
        {options.map((optionSection, index) => {
          return (
            <div key={index}>
              {optionSection?.title && <OptionsTitle>{optionSection.title}</OptionsTitle>}
              {optionSection.options.map((option) => {
                return (
                  <Option
                    key={option.key}
                    type={TextType.CAPTION_REGULAR_2}
                    onClick={() => onOptionClick(option.key)}
                  >
                    {option.label}
                  </Option>
                );
              })}
            </div>
          );
        })}
      </Options>
    );
  }, [options]);

  return (
    <BaseDialog
      isOpen={isOpen}
      title={title}
      onClose={() => onClose()}
      containerStyle={containerStyle}
      header={true}
      isScroll={false}
    >
      {options && renderOptions}
    </BaseDialog>
  );
};

const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  overflow: auto;
`;

const OptionsTitle = styled.div`
  padding: 2px 10px;
  background-color: var(--dts_withe_background);
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 6px;
`;

const Option = styled(Text)`
  padding: 6px 8px;
  cursor: pointer;
  &:hover {
    background-color: var(--dts_withe_background);
  }
`;

export default Dialog;

import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type UploadEFacturaRepository = Pick<E_facturaRepository, 'uploadEFactura'>;

const uploadEFacturaUseCase = (repo: UploadEFacturaRepository, invoiceId: number) => {
  return repo.uploadEFactura(invoiceId);
};

export { uploadEFacturaUseCase };
export type { UploadEFacturaRepository };

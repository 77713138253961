import styled from 'styled-components';

type Props = {
  secondary?;
  minWidth?;
};

export const ButtonCss = styled.button<Props>`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '120px')};
  outline: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;

  cursor: pointer;

  padding: ${(props) => (!props.secondary ? '10px 16px' : '9px 15px')};
  border: ${(props) => (!props.secondary ? 'none' : '1px solid var(--dts_dark)')};
  background: ${(props) => (!props.secondary ? 'var(--dts_default_blue)' : 'var(--dts_white)')};
  fill: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_default_blue)')};

  color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_dark)')};

  p {
    color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_dark)')};
  }

  &:hover {
    background: ${(props) => (!props.secondary ? 'var(--dts_hover_blue)' : 'var(----dts_white)')};
    border: ${(props) => (!props.secondary ? 'none' : '1px solid var(--dts_hover_blue)')};
    fill: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_hover_blue)')};
    color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_hover_blue)')};
    p {
      color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_hover_blue)')};
    }
  }

  &:active {
    background: ${(props) => (!props.secondary ? 'var(--dts_pressed_blue)' : 'var(----dts_white)')};
    border: ${(props) => (!props.secondary ? 'none' : '1px solid var(--dts_pressed_blue)')};
    fill: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_pressed_blue)')};
    color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_pressed_blue)')};
    p {
      color: ${(props) => (!props.secondary ? 'var(--dts_white)' : 'var(--dts_pressed_blue)')};
    }
  }
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import {
  ContextMenu,
  ContextMenuOffset,
  ContextMenuOption,
} from '../../../../../components/dsm/ContextMenu';
import { Text, TextType } from '../../../../../components/dsm/Text';
import { InputText } from '../../../../../components/dsm/InputText';
import Tooltip from '../../../../../components/dsm/Tooltip';
import TemplateEditPhaseDialog from './TemplateEditPhaseDialog';
import { ReactComponent as Drag } from '../../../../../../icons/drag.svg';
import { ReactComponent as DotsIcon } from '../../../../../../icons/dots.svg';
import { ReactComponent as DuplicateIcon } from '../../../../../../icons/duplicate.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../icons/trash.svg';
import { TemplateUiModel } from '../../../../../ui-model/worktype/TemplateUiModel';
import { usePhaseRepositoryImplementation } from '../../../../../../data/phase/phasesRepositoryImplementation';
import { usePhaseViewModel } from '../../../../../phase/PhaseViewModel';
import { TemplatePhaseUiModel } from '../../../../../ui-model/worktype/TemplatePhaseUiModel';
import { LANGUAGE_KEYS } from '../../../../../translations/languageKeys';
import useTranslate from '../../../../../translations/useTranslate';
import { KeyboardsKeys } from '../../../../../components/dsm/KeyboardsKeys';

export type TemplateRowProps = {
  templateModel: TemplateUiModel;
  index: number;
  onDeleteTemplate: (template: TemplateUiModel) => void;
  onDuplicateTemplate: (template: TemplateUiModel) => void;
  onHandleText: (template: TemplateUiModel, name: string) => void;
};

type TemplateDialog = OpenTemplateDialog | ClosedTemplateDialog;

type OpenTemplateDialog = {
  kind: 'Open';
  onClose: () => void;
};

type ClosedTemplateDialog = { kind: 'Closed' };

export function TemplateRow(templateRowProps: TemplateRowProps) {
  const translate = useTranslate();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [contextMenuOffset, setContextMenuOffset] = useState<ContextMenuOffset>({ x: 0, y: 0 });

  const [templateName, setTemplateName] = useState<string>(templateRowProps.templateModel.name);

  const phaseRepo = usePhaseRepositoryImplementation();
  const { phases } = usePhaseViewModel(phaseRepo);

  const handleContextMenuClick = (offset: ContextMenuOffset) => {
    setIsContextMenuOpen(true);
    setContextMenuOffset(offset);
  };

  const ContextMenuOptions: Array<ContextMenuOption> = [
    {
      key: 0,
      icon: <DuplicateIcon />,
      label: translate(LANGUAGE_KEYS.DUPLICATE),
      onClick: () => {
        templateRowProps.onDuplicateTemplate(templateRowProps.templateModel);
      },
    },
    {
      key: 1,
      icon: <DeleteIcon />,
      label: translate(LANGUAGE_KEYS.DELETE),
      onClick: () => {
        templateRowProps.onDeleteTemplate(templateRowProps.templateModel);
      },
    },
  ];

  const handleOnBlur = (value) => {
    if (value !== templateRowProps.templateModel.name) {
      templateRowProps.onHandleText(templateRowProps.templateModel, value);
    }
  };

  const handleKeyEvent = (event) => {
    switch (event.keyCode) {
      case KeyboardsKeys.ENTER:
        event.preventDefault();
        templateRowProps.onHandleText(templateRowProps.templateModel, event.target.value);
        event.target.blur();
        break;
      case KeyboardsKeys.ESCAPE:
        setTemplateName(templateRowProps.templateModel.name);
        break;
    }
  };

  const [templatePhaseEditDialog, setTemplatePhaseEditDialog] = useState<TemplateDialog>({
    kind: 'Closed',
  });

  const closeTemplatePhaseEditDialog = () => {
    setTemplatePhaseEditDialog({
      kind: 'Closed',
    });
  };

  const openTemplatePhaseEditDialog = () => {
    setTemplatePhaseEditDialog({
      kind: 'Open',
      onClose: closeTemplatePhaseEditDialog,
    });
  };

  const inputStyle = {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#151515',
    boxShadow: 'none', // no box-shadow
    outline: 'none',
  };

  return (
    <>
      <Draggable
        draggableId={templateRowProps.index + ''}
        index={templateRowProps.index}
        key={templateRowProps.index}
      >
        {(provided) => (
          <TemplateRowContainer {...provided.draggableProps} ref={provided.innerRef}>
            <FirstContainer>
              <IconComponent {...provided.dragHandleProps}>
                <Drag />
              </IconComponent>
              <NameComponent>
                <InputText
                  placeHolder={translate(LANGUAGE_KEYS.EMPTY_TEMPLATE_NAME)}
                  width='100%'
                  initValue={templateName}
                  onChange={() => {}}
                  onKeyDown={handleKeyEvent}
                  onBlur={handleOnBlur}
                  clickClose={() => {}}
                  customStyle={inputStyle}
                />
              </NameComponent>
            </FirstContainer>
            <SecondContainer>
              <Phase>
                <Tooltip
                  key={templateRowProps.index + +'-phase'}
                  content={translate(LANGUAGE_KEYS.ADD_NEW_PHASE)}
                  placement='bottom'
                >
                  <ExtentionPhaseContainer onClick={openTemplatePhaseEditDialog}>
                    <NumberBox>
                      <Text type={TextType.CAPTION_2_BOLD_12}>
                        {templateRowProps.templateModel.phasesSize}
                      </Text>
                    </NumberBox>
                    <Text type={TextType.CAPTION_2_BOLD_12}>
                      {translate(LANGUAGE_KEYS.ADDED_PHASES)}
                    </Text>
                  </ExtentionPhaseContainer>
                </Tooltip>
              </Phase>
            </SecondContainer>
            <ThirdContainer>
              <IconComponent
                onClick={(e) => {
                  e.preventDefault();
                  handleContextMenuClick({ x: e.clientX, y: e.clientY + 10 });
                }}
              >
                <DotsIcon />
              </IconComponent>
              <ContextMenuStyle
                isOpen={isContextMenuOpen}
                setIsOpen={setIsContextMenuOpen}
                options={ContextMenuOptions}
                offset={contextMenuOffset}
                className='template-context'
              />
            </ThirdContainer>
          </TemplateRowContainer>
        )}
      </Draggable>
      {templatePhaseEditDialog.kind === 'Open' && (
        <TemplateEditPhaseDialog
          templateId={templateRowProps.templateModel.id}
          templateName={templateRowProps.templateModel.name}
          onClose={templatePhaseEditDialog.onClose}
          all={
            phases !== undefined
              ? phases.map(
                  (phase) =>
                    new TemplatePhaseUiModel(
                      phase.type,
                      phase.order,
                      phase.id,
                      phase.name,
                      phase.status,
                      phase.assigneeId,
                      phase.date,
                      phase.id,
                    ),
                )
              : []
          }
          added={templateRowProps.templateModel.phases}
          modalName={templateRowProps.templateModel.name}
        />
      )}
    </>
  );
}

const BaseContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-grow: 0;
`;

const TemplateRowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  padding: 10px 24px 10px 4px;
  background: var(--dts_white);
  border-radius: 3px;
  gap: 4px;
  margin-bottom: 8px;
  justify-content: space-between;
  min-height: 36px;
`;

const FirstContainer = styled.div`
  flex-basis: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const SecondContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-basis: 19%;
  gap: 24px;
  align-items: center;
`;
const ThirdContainer = styled.div`
  flex-basis: 16%;
  position: relative;
`;

const IconComponent = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  padding: 10px 0px;
  svg {
    flex-shrink: 0;
  }
  :hover {
    svg {
      path {
        fill: var(--dts_hover_blue);
      }
    }
  }
`;

const NameComponent = styled.div`
  flex-basis: 95%;
  display: flex;
  min-width: 100px;
  input {
    border: none;
    border: 1px solid transparent;
    :focus,
    :active {
      border: 1px solid var(--dts_default_blue);
    }
  }
`;

const Phase = styled.div`
  min-width: 108px;
`;

const ExtentionPhaseContainer = styled.span`
  display: inline-flex;
  cursor: pointer;
  > p {
    background: var(--dts_light_grey);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 6px 8px;
    display: flex;
  }
`;

const NumberBox = styled.span`
  padding: 6px 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: var(--border-grey);
  display: flex;
  min-width: 12px;
  justify-content: center;
`;

const ContextMenuStyle = styled(ContextMenu)`
  &.template-context {
    position: absolute;
    right: 0;
    top: 15px !important;
    left: unset !important;
  }
`;

import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text, TextType } from '../dsm/Text';

import { DeviceSizeProps } from '../../DeviceInformations';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';

import { ReactComponent as SelectedIcon } from '../../../icons/SelectedDropDownItem.svg';

export type CountySelectorProps = {
  parentRef: RefObject<HTMLDivElement> | null;
  modalRef: RefObject<HTMLDivElement> | null;
  values: Array<DropdownValue>;
  maxHeightOptions?: number;
  hasSuccessIcon: boolean;
  isOpen: boolean;
  onSelect: (selectedValueKey: DropdownValue['key']) => void;
  onChangeOpenState: (state: boolean) => void;
};

export type DropdownValue = { key: number; label: string; selected: boolean };

export const CountySelector = (props: CountySelectorProps) => {
  const {
    parentRef,
    modalRef,
    values,
    maxHeightOptions,
    hasSuccessIcon,
    isOpen,
    onSelect,
    onChangeOpenState,
  } = props;

  const [dropdownPosition, setDropdownPosition] = useState<{
    top: number;
    left: number;
    width: number;
  }>({
    top: 0,
    left: 0,
    width: 0,
  });

  const optionRefs = useRef<Array<HTMLElement>>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const { mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const positionDropdown = () => {
    if (parentRef !== null && modalRef !== null) {
      const dropdownMenu = dropdownRef.current;
      const parent = parentRef.current;
      const modal = modalRef.current;

      if (dropdownMenu && parent && modal) {
        const parentRect = parent.getBoundingClientRect();
        const modalRect = modal.getBoundingClientRect();
        const dropdownHeight = dropdownMenu.offsetHeight;
        // const dropdownWidth = dropdownMenu.offsetWidth;

        const shouldOpenUpwards = parentRect.bottom + dropdownHeight > modalRect.bottom;
        // const shouldAlignRight = parentRect.left + dropdownWidth > modalRect.right;

        const topPosition = shouldOpenUpwards
          ? parentRect.top - dropdownHeight - 5
          : parentRect.bottom + 5;
        const leftPosition = parentRect.left;
        const width = parentRect.width - 50;

        setDropdownPosition({
          top: topPosition,
          left: leftPosition,
          width: width,
        });
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      positionDropdown();
    }
  }, [isOpen]);

  useEffect(() => {
    isOpen && dropdownRef?.current?.focus();
  }, [isOpen, optionRefs]);

  const handleOnOptionClick = (key: DropdownValue['key']) => {
    onSelect(key);
    onChangeOpenState(!isOpen);
  };

  return (
    <>
      {isOpen && (
        <DropdownMenu
          tabIndex={-1}
          ref={dropdownRef}
          className='dropdown-menu'
          style={{
            position: 'absolute',
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            width: dropdownPosition.width,
          }}
          onBlur={() => {
            setTimeout(() => {
              onChangeOpenState(!isOpen);
            }, 200);
          }}
        >
          <Options
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
            style={{ maxHeight: `${maxHeightOptions}px` }}
            className='custom-scrollbar'
          >
            {values.map((value, index) => (
              <Option
                mobile_small={mobileSmallSize}
                mobile={mobileSize}
                tablet_small={tabletSmallSize}
                tablet_large={tabletLargeSize}
                desktop={desktopSize}
                key={value.key}
                onClick={() => handleOnOptionClick(value.key)}
                className={`${hasSuccessIcon && value.selected && 'active'}`}
              >
                <Text
                  ref={(el) => {
                    if (el) optionRefs.current[index] = el;
                  }}
                  type={TextType.CAPTION_REGULAR}
                  ellipsis={true}
                >
                  {value.label}
                </Text>
                {hasSuccessIcon && value.selected && <CustomSelectedIcon />}
              </Option>
            ))}
          </Options>
        </DropdownMenu>
      )}
    </>
  );
};

export default CountySelector;

const DropdownMenu = styled.div`
  z-index: 1000;
  display: block;
  background-color: var(--dts_white);
  padding: 24px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
`;

export const OptionsContainer = styled.div<DeviceSizeProps>(
  ({ mobile, tablet_small, tablet_large, desktop }) => `
    width: calc(100% - 7px);
    position: absolute;
    box-sizing: border-box;
    margin-left: -8px;
    margin-top: 15px;
    z-index: 100;
    background-color: var(--dts_white);
    box-shadow: -1px 2px 11px rgba(0, 0, 0, 0.14);
    border-radius: 3px;
    cursor: initial;
    outline: none;
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 24px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 16px;
      max-width: 136px;
    }
`,
);

export const Options = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 4px;
    flex-shrink: 0;
    //Desktop
    @media (min-width: ${desktop}px) {
      max-height: 350px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      max-height: 355px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
      max-height: 255px;
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

export const Option = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    cursor: pointer !important;
    &.selected,
    &:hover {
      background-color: var(--dts_withe_background);
    }
    &.active {
      display: flex;
      justify-content: space-between;
      align-item: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
        linear-gradient(0deg, #f2f2f2, #f2f2f2);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 8px;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 8px;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 6px 8px 6px 8px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
`,
);

const CustomSelectedIcon = styled(SelectedIcon)`
  fill: var(--dts_black);
  width: 16px;
  height: 16px;
`;

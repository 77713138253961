import './App.css';
import * as React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { AppDispatch, AppRootStore } from '../data/appRepositoryImplementation';
import { reset, userLogin, LoggedUserReducer } from '../data/loggedUser/loggedUserReducer';
import AppComponent from './AppComponent';
import { Alert } from './alert/Alert';
import './translations/i18n';

export type ReduxAction<PayloadType = any> = { payload: PayloadType; type: string };

export type CombinedPropsType = AppReduxProps & AppReduxActions;

type AppReduxProps = {
  isAuthenticated: boolean;
  session: string | null;
  companyId: number | null;
  isFtiFinished: boolean;
  lang: string | null;
  hasUserToken: boolean;
};

interface AppReduxActions {
  userLogin: (data: LoggedUserReducer) => ReduxAction;
  removeUserData: () => ReduxAction;
}

const mapStateToProps = ({
  loggedUser: { session, accessToken, companyId, isFtiFinished, lang, authenticated },
}: AppRootStore): AppReduxProps => ({
  isAuthenticated: authenticated,
  session: session,
  companyId: companyId,
  isFtiFinished: isFtiFinished,
  lang: lang,
  hasUserToken: accessToken !== null,
});

const mapDispatchToProps = (dispatch: AppDispatch): AppReduxActions => ({
  userLogin: (data: LoggedUserReducer) => dispatch(userLogin(data)),
  removeUserData: () => dispatch(reset()),
});

const App = (props: CombinedPropsType) => {
  Modal.setAppElement('#root');

  return (
    <>
      <AppComponent {...props} />
      <Alert />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { BASE_URL } from '../../Server';
import { UpdateTeethTypeInfo } from '../../../domain/worktype/teeth/UpdateTeethTypeInfo';
import { TeethData } from '../../../domain/worktype/teeth/Teeth';
import { useApiHeaderWithCompanyId } from '../../../presentation/hooks/useApiHeaderWithCompanyId';

function getTeeth(): Promise<TeethData> {
  return fetch(BASE_URL + '/teeth', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        code: 200,
        message: 'Success',
        data: res,
      };
    })
    .catch((error) => {
      console.log('catchy getTeeth error: ' + error);
      return {
        code: error.status,
        message: 'Faild',
        data: [],
      };
    });
}

function updateTeethTypeInfo(workTypeId: number, teethList: UpdateTeethTypeInfo[]) {
  return fetch(BASE_URL + '/updateTeethTypeInfo', {
    method: 'POST',
    body: JSON.stringify({ id: workTypeId, items: teethList }),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateTeethTypeInfo error: ' + error);
      return {
        code: error.status,
        message: 'Faild',
        data: [],
      };
    });
}

export { getTeeth, updateTeethTypeInfo };

import { BASE_URL } from '../Server';
import { WorkTypeData } from '../../domain/worktype/WorkTypeData';
import UpdateWorTypeInfo from '../../domain/worktype/UpdateWorTypeInfo';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';

function getWorkTypes(): Promise<WorkTypeData> {
  return fetch(BASE_URL + '/workType?includeInactive=true', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy getWorkTypes: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

function updateWorkTypes(
  worktypeList: UpdateWorTypeInfo[],
): Promise<{ code: number; message: string; data: any }> {
  return fetch(BASE_URL + '/updateWorTypeInfo', {
    method: 'POST',
    body: JSON.stringify({ items: worktypeList }),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateWorkTypes: ' + error);
      return { code: error.status, message: error, data: [] };
    });
}

export { getWorkTypes, updateWorkTypes };

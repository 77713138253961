import React, { FC, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Text, TextColor, TextType } from '../../components/dsm/Text';
import styled from 'styled-components';
import { useDeviceParameters } from '../../hooks/useDeviceParameters';
import { DeviceSizeProps } from '../../DeviceInformations';

export type UserManagementRowProps = {
  menuItems: Array<{ title: string; url: string }>;
  navbarOpen: boolean;
  closeNavbar: () => void;
};

const Navbar: FC<UserManagementRowProps> = ({ menuItems, navbarOpen, closeNavbar }) => {
  const { isMobile, mobileSmallSize, mobileSize, tabletLargeSize, tabletSmallSize, desktopSize } =
    useDeviceParameters();

  const navigate = useNavigate();

  useEffect(() => {}, [isMobile]);

  return (
    <NavContainer
      mobile_small={mobileSmallSize}
      className={`${navbarOpen && 'mobile-open'}`}
      mobile={mobileSize}
      tablet_small={tabletSmallSize}
      tablet_large={tabletLargeSize}
      desktop={desktopSize}
    >
      <CustomNav
        className={`${navbarOpen && 'mobile-open'}`}
        mobile_small={mobileSmallSize}
        mobile={mobileSize}
        tablet_small={tabletSmallSize}
        tablet_large={tabletLargeSize}
        desktop={desktopSize}
      >
        {menuItems.map((menu, index) => {
          return (
            <NavLinkExtended
              key={index}
              end
              to={menu.url}
              mobile_small={mobileSmallSize}
              mobile={mobileSize}
              tablet_small={tabletSmallSize}
              tablet_large={tabletLargeSize}
              desktop={desktopSize}
              onClick={closeNavbar}
            >
              {!isMobile && (
                <TextExt
                  type={TextType.BODY_2_BOLD}
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                >
                  {menu.title}
                </TextExt>
              )}

              {isMobile && (
                <TextExt
                  type={TextType.BODY_2}
                  mobile_small={mobileSmallSize}
                  mobile={mobileSize}
                  tablet_small={tabletSmallSize}
                  tablet_large={tabletLargeSize}
                  desktop={desktopSize}
                >
                  {menu.title}
                </TextExt>
              )}
            </NavLinkExtended>
          );
        })}
      </CustomNav>
      {isMobile && (
        <ActionsConatiner
          mobile_small={mobileSmallSize}
          mobile={mobileSize}
          tablet_small={tabletSmallSize}
          tablet_large={tabletLargeSize}
          desktop={desktopSize}
        >
          <ActionButton
            className='blue'
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
            onClick={() => {
              navigate('/login');
            }}
          >
            <Text type={TextType.BODY_2_BOLD} clr={TextColor.WHITE}>
              Login
            </Text>
          </ActionButton>

          <ActionButton
            mobile_small={mobileSmallSize}
            mobile={mobileSize}
            tablet_small={tabletSmallSize}
            tablet_large={tabletLargeSize}
            desktop={desktopSize}
            onClick={() => {
              navigate('/signup');
            }}
          >
            <Text type={TextType.BODY_2_BOLD}>Start for free</Text>
          </ActionButton>
        </ActionsConatiner>
      )}
    </NavContainer>
  );
};

const NavContainer = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
      flex-grow: 1;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      display: none;
      &.mobile-open{
        display: flex;
        position: absolute;
        top: 90px;
        left: 0px;
        height: calc(100% - 90px);
        width: 100%;
        background-color: white;
        z-index: 999999;
        flex-direction: column;
        min-height: calc(100% - 90px);
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const CustomNav = styled.nav<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-grow: 1;
      padding: 0px 36px 0px 36px;
      justify-content: center;
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-grow: 1;
      padding: 0px 36px 0px 36px;
      justify-content: center;
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      display: flex;
      width: 100%;
      background-color: white;
      align-items: flex-end;
      -webkit-box-flex: 1;
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: auto;
      height: calc(-130px + 100%);
      padding: 0px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const NavLinkExtended = styled(NavLink)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    text-decoration: none;
    cursor: pointer;
    &:hover p {
      color: var(--dts_hover_blue);
    }
    &:active p{
      color: var(--dts_hover_blue);
    }
    //Desktop
    @media (min-width: ${desktop}px) {
      padding: 8px 16px 8px 16px;
      &.active p {
        color: var(--dts_default_blue);
      }
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
      padding: 8px 16px 8px 16px;
      &.active p {
        color: var(--dts_default_blue);
      }
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      width: 100%;
      text-align: end;
      padding: 10px 12px 10px 12px;
      margin: 0px 24px 0px 24px;
      border-right: 4px solid transparent;
      &.active {
        background: var(--dts_withe_background);
        border-right: 4px solid var(--dts_default_blue);
        p{
          color: black;
          font-weight: bold;
        }
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const TextExt = styled(Text)<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const ActionsConatiner = styled.div<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      display: flex;
      flex-direction: column;
      gap: 7px;
      padding: 24px;
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

const ActionButton = styled.button<DeviceSizeProps>(
  ({ mobile_small, mobile, tablet_small, tablet_large, desktop }) => `
    //Desktop
    @media (min-width: ${desktop}px) {
    }
    // Tablet large
    @media (max-width: ${tablet_large}px) {
    }
    // Tablet small
    @media (max-width: ${tablet_small}px) {
    }
    // Mobile
    @media (max-width: ${mobile}px) {
      padding: 7px 15px;
      border: 1px solid var(--dts_black);
      border-radius: 60px;
      background: transparent;
      min-width: 71px;
      cursor: pointer;
      &.blue {
        border: 1px solid var(--dts_default_blue);
        background: var(--dts_default_blue);
      }
    }
    // Mobile small
    @media (max-width: ${mobile_small}px) {
    }
  `,
);

export default Navbar;

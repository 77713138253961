import { BASE_URL } from '../../Server';
import { ExtensionData } from '../../../domain/worktype/extension/Extension';
import { useApiHeaderWithCompanyId } from '../../../presentation/hooks/useApiHeaderWithCompanyId';

import UpdateWorkTypeExtensionInfo from '../../../domain/worktype/extension/UpdateWorkTypeExtensionInfo';

function getExtensions(): Promise<ExtensionData> {
  return fetch(BASE_URL + '/extension', {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return {
        code: 200,
        message: 'Success',
        data: res,
      };
    })
    .catch((error) => {
      console.log('catchy getExtensions error: ' + error);
      return {
        code: error.status,
        message: 'Faild',
        data: [],
      };
    });
}

function updateWorkTypeExtensionInfo(extension: UpdateWorkTypeExtensionInfo) {
  return fetch(BASE_URL + '/updateWorkTypeExtensionInfo', {
    method: 'POST',
    body: JSON.stringify(extension),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateWorkTypeExtensionInfo error: ' + error);
      return {
        code: error.status,
        message: 'Faild',
        data: [],
      };
    });
}

function updateTeethWorkTypeExtensionInfo(extension: UpdateWorkTypeExtensionInfo) {
  return fetch(BASE_URL + '/updateTeethTypeExtensionInfo', {
    method: 'POST',
    body: JSON.stringify(extension),
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log('catchy updateTeethWorkTypeExtensionInfo error: ' + error);
      return {
        code: error.status,
        message: 'Faild',
        data: [],
      };
    });
}

export { updateWorkTypeExtensionInfo, updateTeethWorkTypeExtensionInfo, getExtensions };

import React from 'react';
import styled from 'styled-components';
import Row from '../layout/Row';
import { Text, TextType } from '../dsm/Text';

import { ReactComponent as HeadIcon } from '../../../icons/headIcon.svg';

type AvatarProps = {
  url?: string;
  fullName: string | number | undefined;
  size: {
    inner: number;
    outer: number;
  };
  overlap?: number;
  style?: React.CSSProperties;
  textType?: TextType;
  avatarStyle?: React.CSSProperties;
};

const Avatar = ({ url, fullName, size, style, textType, avatarStyle }: AvatarProps) => {
  const initials =
    fullName !== undefined
      ? typeof fullName !== 'string'
        ? `+${fullName}`
        : fullName
            .split(' ')
            .slice(0, 2)
            .map((n) => n[0])
            .join('')
            .toUpperCase()
      : fullName;

  return (
    <Container
      style={{
        width: size.outer + 'px',
        height: size.outer + 'px',
        ...style,
      }}
    >
      {fullName && (
        <BorderContainer>
          {url ? (
            <img src={url} width={size.inner} height={size.inner} alt={initials} />
          ) : (
            <AvatarStyled style={{ width: size.inner, height: size.inner, ...avatarStyle }}>
              <Text
                type={textType ? textType : TextType.CAPTION_2_BOLD}
                style={{ color: 'var(--dts_white)' }}
              >
                {initials}
              </Text>
            </AvatarStyled>
          )}
        </BorderContainer>
      )}
      {fullName === undefined && <HeadIconStyled width={size.inner} height={size.inner} />}
    </Container>
  );
};

const Container = styled('div')`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
`;

const BorderContainer = styled(Row)`
  justify-content: center;
  background-color: var(--dts_withe_background);
  width: 100%;
  height: 100%;

  img {
    border-radius: 50%;
  }
`;

const AvatarStyled = styled(Row)`
  justify-content: center;
  border-radius: 50%;
  background-color: var(--dts_primary);
  box-sizing: border-box;
`;

const HeadIconStyled = styled(HeadIcon)`
  path {
    fill: var(--dts_dark_grey);
  }
`;

export default Avatar;

import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { PayOffRow } from './PayOffRow';
import { useReportRepositoryImplementation } from '../../../../data/reports/ReportRepositoryImplementation';
import {
  useCompaniesRepositoryImplementation,
  useContactsRepositoryImplementation,
} from '../../../../data/user-management/UsersRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../../../../data/worktype/WorkTypeRepositoryImplementation';
import { usePayOffViewModel } from './PayOffViewModel';
import { PayOffUIModel, PayOffCollaborator } from '../../../ui-model/reports/PayOffUIModel';
import { useAlertManagerImplementation } from '../../../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../../../alert/AlertViewModel';
import { AlertType } from '../../../../domain/alert/AlertManager';
import Avatar from '../../../components/generic/Avatar';
import { PayOffCollaboratorItem } from './PayOffCollaboratorItem';
import useTranslate from '../../../translations/useTranslate';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';

function PayOff(props: { companyId: number | null; caseIds: Array<number> }) {
  const translate = useTranslate();
  const repo = useReportRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const { getPayOffsByIds, accountPhases } = usePayOffViewModel(
    repo,
    companiesRepo,
    workTypeRepo,
    contactRepo,
  );
  const alertManager = useAlertManagerImplementation();
  const { showAlert } = useAlertViewModel(alertManager);

  const [payOffList, setPayOffList] = useState<Array<PayOffUIModel>>([]);
  const [collaborators, setCollaborators] = useState<Array<PayOffCollaborator>>();
  const [selectedCollaborator, setSelectedCollaborator] = useState<PayOffCollaborator>({
    collaboratorId: -1,
    collaboratorName: translate(LANGUAGE_KEYS.ALL),
    collaboratorColor: 'var(--dts_default_blue)',
    phaseIds: [],
    caseIds: [],
    labworkIds: [],
  });
  const refCollaboratorsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getPayOffsByIds(props.caseIds).then((resp) => {
      if (resp.statusCode === 200) {
        setPayOffList(resp.data.model);
        setCollaborators(resp.data.collaborators);
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_FAILED_GENERIC), AlertType.FAILD);
      }
    });
  }, [
    workTypeRepo.getWorkTypes,
    workTypeRepo.workTypes,
    companiesRepo.getCompanies,
    companiesRepo.companies,
  ]);

  const calculateCollaboratorStyle = (): {
    style: 'simple' | 'just-avatar' | 'avatar-more';
    displayCollSize: number;
  } => {
    if (
      refCollaboratorsContainer.current?.offsetWidth !== undefined &&
      collaborators !== undefined
    ) {
      if (refCollaboratorsContainer.current?.offsetWidth >= collaborators.length * 110) {
        return { style: 'simple', displayCollSize: collaborators.length };
      } else if (refCollaboratorsContainer.current?.offsetWidth >= collaborators.length * 24) {
        return { style: 'just-avatar', displayCollSize: collaborators.length };
      } else {
        return {
          style: 'avatar-more',
          displayCollSize: Math.floor(refCollaboratorsContainer.current?.offsetWidth / 16),
        };
      }
    }
    return { style: 'simple', displayCollSize: 0 };
  };

  const solvedPhase = (
    caseId: number,
    isPaid: boolean,
    labworkId: number,
    solvedPhaseIds: Map<string, number>,
    unSolvedPhaseIds: Map<string, number>,
  ) => {
    accountPhases({
      caseId: caseId,
      labWorkNumber: labworkId,
      solvedPhaseIds: Object.fromEntries(solvedPhaseIds.entries()),
      unSolvedPhaseIds: Object.fromEntries(unSolvedPhaseIds.entries()),
    }).then((res) => {
      if (res.statusCode === 200) {
        if (res.data === true) {
          getPayOffsByIds(props.caseIds).then((resp) => {
            setCollaborators(resp.data.collaborators);
            getPayOffsByIds(props.caseIds).then((resp) => {
              if (resp.statusCode === 200) {
                setPayOffList(resp.data.model);
              } else {
                showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
              }
            });
            if (isPaid) {
              showAlert(
                `${translate(LANGUAGE_KEYS.ALERT_PAY_OFF_PHASE_SUCCESS)} ${caseId}`,
                AlertType.SUCCESS,
              );
            } else {
              showAlert(
                `${translate(LANGUAGE_KEYS.ALERT_WITHDRAW_PAYMENT_IN_CASE_SUCCESS)} ${caseId}`,
                AlertType.SUCCESS,
              );
            }
          });
        } else {
          showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
        }
      } else {
        showAlert(translate(LANGUAGE_KEYS.ALERT_SOMETHING_WENT_WRONG), AlertType.FAILD);
      }
    });
  };

  const changeCollaborators = (collaboratorId) => {
    const newColl = collaborators?.find((col) => {
      return col.collaboratorId === collaboratorId;
    });
    if (newColl !== undefined) {
      setSelectedCollaborator(newColl);
    }
  };

  const collaboratorFilter = () => {
    return (
      <CollaboratorsContainer ref={refCollaboratorsContainer}>
        {collaborators?.map((col, index) => {
          return (
            <PayOffCollaboratorItem
              key={index}
              collaborator={col}
              index={index}
              collaboratorSettings={calculateCollaboratorStyle()}
              selectedCollaborator={selectedCollaborator}
              collaboratorsList={collaborators
                .slice(calculateCollaboratorStyle().displayCollSize - 1, collaborators.length - 1)
                .map((c) => {
                  return {
                    key: c.collaboratorId,
                    icon: (
                      <Avatar
                        fullName={c.collaboratorName}
                        size={{ outer: 20, inner: 18 }}
                        avatarStyle={{ background: c.collaboratorColor }}
                      />
                    ),
                    label: c.collaboratorName,
                  };
                })}
              changeCollaborators={changeCollaborators}
            />
          );
        })}
      </CollaboratorsContainer>
    );
  };

  return (
    <ContentBody>
      {collaboratorFilter()}
      {payOffList.map((payoff, index) => {
        if (
          selectedCollaborator.caseIds?.some((l) => l === payoff.caseId) ||
          selectedCollaborator.collaboratorId === -1
        ) {
          return (
            <PayOffRow
              companyId={props.companyId}
              payOffModel={payoff}
              key={index}
              solvedPhase={solvedPhase}
              selectedCollaborator={selectedCollaborator}
            />
          );
        }
      })}
    </ContentBody>
  );
}

const ContentBody = styled.div`
  width: 100%;
`;

const CollaboratorsContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 2px solid var(--dts_light_grey);
`;

export default PayOff;

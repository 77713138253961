import { TemplateRepository } from '../../../domain/worktype/templates/TemplateRepository';
import { UpdateTemplate } from '../../../domain/worktype/templates/UpdateTemplate';
import { UpdateTemplateOrderOrActive } from '../../../domain/worktype/templates/UpdateTemplateOrderOrActive';

type GetTemplateRepository = Pick<
  TemplateRepository,
  'updateTemplate' | 'updateTemplateOrderOrActive' | 'getTemplates'
>;

const updateTemplateUseCase = (repo: GetTemplateRepository, template: UpdateTemplate) => {
  repo.updateTemplate(template);
};

const updateTemplateOrderOrActiveUseCase = (
  repo: GetTemplateRepository,
  templates: UpdateTemplateOrderOrActive[],
) => {
  repo.updateTemplateOrderOrActive(templates);
};

export { updateTemplateUseCase, updateTemplateOrderOrActiveUseCase };

import { AppRootStore } from '../appRepositoryImplementation';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { LobbyRepository } from '../../domain/lobby/LobbyRepository';
import { getLobbyCompaniesAction } from './lobbyActions';
import { LobbyRepositoryState } from './lobbyReducer';
import { LoggedUserReducer } from '../loggedUser/loggedUserReducer';

const lobbyCompaniesSelector = (state: AppRootStore) => state.lobbyComapnies;
const loggedUserSelector = (state: AppRootStore) => state.loggedUser;

const lobbyRepositoryImplementation = (): LobbyRepository => {
  const { lobbyCompanies, isLoading, isUpdating } = useSelector<AppRootStore, LobbyRepositoryState>(
    lobbyCompaniesSelector,
  );

  const { userId } = useSelector<AppRootStore, LoggedUserReducer>(loggedUserSelector);

  const dispatch = useDispatch();

  const getLobbyCompanies = React.useCallback(
    () => getLobbyCompaniesAction(userId)(dispatch),
    [dispatch],
  );

  return {
    lobbyCompanies,
    isLoading,
    isUpdating,
    getLobbyCompanies,
  };
};

export { lobbyRepositoryImplementation };

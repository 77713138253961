import { PhaseRepository } from '../../../domain/PhaseRepository';

type GetPhaseRepository = Pick<PhaseRepository, 'getPhases' | 'phases'>;

const getPhasesUseCase = (repo: GetPhaseRepository) => {
  return repo.getPhases();
};

const getPhasesValueUseCase = (repo: GetPhaseRepository) => {
  if (repo.phases !== undefined) {
    return repo.phases;
  } else {
    return [];
  }
};

export { getPhasesUseCase, getPhasesValueUseCase };

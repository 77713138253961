import { TemplatePhaseUiModel } from './TemplatePhaseUiModel';

export class TemplateUiModel {
  id: number;
  name: string;
  order: number;
  active: number;
  phasesSize: number;
  phases: TemplatePhaseUiModel[];
  workTypeId: number;

  constructor(
    id: number,
    name: string,
    order: number,
    active: number,
    phasesSize: number,
    phases: TemplatePhaseUiModel[],
    workTypeId: number,
  ) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.active = active;
    this.phasesSize = phasesSize;
    this.phases = phases;
    this.workTypeId = workTypeId;
  }
}

import { LANGUAGE_KEYS } from '../../presentation/translations/languageKeys';

export enum UserRoles {
  LABOR_OWNER = 1,
  LABOR_ADMIN = 2,
  LABOR_SUPERVISOR = 3,
  LABOR_EMPLOYEE = 4,
}

export const getRoleName = (roleId: number): string => {
  switch (roleId) {
    case UserRoles.LABOR_OWNER:
      return LANGUAGE_KEYS.ROLE_OWNER;
    case UserRoles.LABOR_ADMIN:
      return LANGUAGE_KEYS.ROLE_ADMIN;
    case UserRoles.LABOR_SUPERVISOR:
      return LANGUAGE_KEYS.ROLE_SUPERVISOR;
    case UserRoles.LABOR_EMPLOYEE:
      return LANGUAGE_KEYS.ROLE_EMPLOYEE;
    default:
      return '';
  }
};

export const getRoles = () => {
  return [
    { id: -1, name: LANGUAGE_KEYS.ROLE_OTHER },
    { id: UserRoles.LABOR_OWNER, name: LANGUAGE_KEYS.ROLE_OWNER },
    { id: UserRoles.LABOR_ADMIN, name: LANGUAGE_KEYS.ROLE_ADMIN },
    { id: UserRoles.LABOR_SUPERVISOR, name: LANGUAGE_KEYS.ROLE_SUPERVISOR },
    { id: UserRoles.LABOR_EMPLOYEE, name: LANGUAGE_KEYS.ROLE_EMPLOYEE },
  ];
};

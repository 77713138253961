import React, { FC, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Text, TextType, TextColor } from '../../../components/dsm/Text';
import { Checkbox } from '../../../components/dsm/Checkbox';
import { InputTextNumber } from '../../../components/dsm/InputTextNumber';
import { ReactComponent as DropdownIcon } from '../../../../icons/arrowDown.svg';
import { ExpenseCaseUi } from '../../../ui-model/reports/ExpenseUiModel';
import { ReactComponent as InvoiceIcon } from '../../../../icons/filters/invoice.svg';
import { ExpenseLabworkCard } from './ExpenseLabworkCard';
import { LANGUAGE_KEYS } from '../../../translations/languageKeys';
import useTranslate from '../../../translations/useTranslate';

export type ExpenseCardProps = {
  companyId: number | null;
  expenseCase: ExpenseCaseUi;
  expensesLength: number;
  handleCheck: (caseId: number, active: boolean) => void;
  changePrice: (caseId: number, price: number) => void;
};

export const ExpenseCaseCard: FC<ExpenseCardProps> = (expenseCardProps) => {
  const translate = useTranslate();
  const { companyId, expenseCase, expensesLength, handleCheck, changePrice } = expenseCardProps;

  const refLabworkCards = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(expenseCase.invoice === null);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOnCheckboxClick = () =>
    expenseCase.invoice === null && handleCheck(expenseCase.caseId, !expenseCase.isSelected);

  const onChangePrice = (value: string) => {
    changePrice(expenseCase.caseId, !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0);
  };

  return (
    <Card>
      <CardHeader className={expenseCase.invoice === null ? '' : 'disable'}>
        {expensesLength > 1 && (
          <Checkbox
            checkboxId={expenseCase.caseId}
            onClick={handleOnCheckboxClick}
            active={expenseCase.isSelected}
            disabled={true}
          />
        )}
        <NavLinkStyled to={`/${companyId}/case/${expenseCase.caseId}`}>
          <Text clr={TextColor.PRIMARY} type={TextType.BODY_2_BOLD} style={{ cursor: 'pointer' }}>
            {expenseCase.caseId}
          </Text>
        </NavLinkStyled>
        <Client>
          <Text type={TextType.BODY_2_BOLD}>{expenseCase.clientName}</Text>
        </Client>
        <Patient>
          <Text type={TextType.BODY_2_BOLD}>{expenseCase.patientName}</Text>
        </Patient>
        {expenseCase.invoice && (
          <Invoice>
            <InvoiceIcon />
            <Text type={TextType.CAPTION_2_BOLD_12} clr={TextColor.PRIMARY}>
              {expenseCase.invoice?.invoicePrefix} {expenseCase.invoice?.invoiceNumber}
            </Text>
          </Invoice>
        )}
        <CollapseButton
          onClick={handleFilterOpening}
          className={expenseCase.invoice === null ? '' : 'disable'}
        >
          {isOpen ? <DropdownIconStyled className='open' /> : <DropdownIconStyled />}
        </CollapseButton>
      </CardHeader>
      {isOpen && (
        <CardBody>
          <LabworkCards ref={refLabworkCards}>
            {expenseCase.labworks.map((labwork, index) => {
              return (
                <ExpenseLabworkCard
                  key={index}
                  expenseLabwork={labwork}
                  labworksSize={expenseCase.labworks.length}
                  index={index}
                  refParent={refLabworkCards}
                />
              );
            })}
          </LabworkCards>
          <LabworkSummary>
            <Text type={TextType.BODY_2_BOLD}>{translate(LANGUAGE_KEYS.TOTAL)}</Text>
            <PriceContainer>
              <Price>
                <InputTextNumber
                  placeHolder='00.00'
                  width='100%'
                  initValue={`${expenseCase.allLabworkPrice.toFixed(2)}`}
                  onChange={onChangePrice}
                  pattern='[0-9]*[.]?[0-9]*'
                />
              </Price>
              <Separator></Separator>
              <Currency>
                <Text type={TextType.CAPTION_REGULAR}>Ron</Text>
              </Currency>
            </PriceContainer>
          </LabworkSummary>
        </CardBody>
      )}
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid var(--dts_withe_background);
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
  &.disable .checkbox {
    pointer-events: none;
  }
`;

const CardBody = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;

const Client = styled.div``;

const Patient = styled.div`
  flex-grow: 1;
`;

const Invoice = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  P {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    align-items: center;
    line-height: 20px;
  }
  svg {
    width: 10px;
    height: 10px;
    fill: var(--dts_primary);
  }
`;

const CollapseButton = styled.div`
  &.disable {
    pointer-events: none;
  }
`;

const DropdownIconStyled = styled(DropdownIcon)`
  cursor: pointer;
  width: 10px;
  height: 10px;
  padding: 6px;
  &.open {
    transform: rotate(180deg);
  }
`;

const LabworkCards = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

const LabworkSummary = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.02) 100%), #f2f2f2;
`;

const PriceContainer = styled.div`
  max-width: 103px;
  padding: 7px 8px 7px 8px;
  border: 1px solid var(--dts_light_grey);
  background: var(--dts_white);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;

const Price = styled.span`
  flex-basis: 51%;
  line-height: 12px;
`;

const Separator = styled.span`
  height: 16px;
  border-right: 1px solid;
  opacity: 0.5;
`;
const Currency = styled.span`
  opacity: 0.5;
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Dropdown, DropdownValue } from './Dropdown';
import { Text, TextType } from '../dsm/Text';
import Tooltip from '../dsm/Tooltip';

type SelectableListProps = {
  values: SelectableListValues;
  onChange: (values: SelectableListProps['values']) => void;
};

export type SelectableListValues = Array<SelectableListCheckboxValue | SelectableListDropdownValue>;

type SelectableListCheckboxValue = {
  kind: 'Checkbox';
  id: number;
  groupId: number;
  value: string;
  checked: boolean;
  disabled?: boolean;
};

type SelectableListDropdownValue = {
  kind: 'Dropdown';
  id: number;
  values: Array<{
    id: number;
    value: string;
    selected?: boolean;
  }>;
  disabled?: boolean;
};

export const SelectableList = (props: SelectableListProps) => {
  const { values, onChange } = props;

  const checkboxRefs = useRef<Array<HTMLElement>>([]);
  const [visibleTooltips, setVisibleTooltips] = useState<Array<number>>([]);

  useEffect(() => {
    checkboxRefs.current.forEach((itemRef, index) => {
      if (itemRef?.scrollWidth > itemRef?.clientWidth && !visibleTooltips.includes(index)) {
        setVisibleTooltips((prevVisibleTooltips) => [...prevVisibleTooltips, index]);
      }
    });
  }, [checkboxRefs]);

  const handleOnCheckboxChange = (index: number) => {
    onChange(
      values.map((value, i) => {
        if (i === index && value.kind === 'Checkbox') return { ...value, checked: !value.checked };
        else return value;
      }),
    );
  };

  const handleOnDropdownOptionChange = (index: number, updatedValues: Array<DropdownValue>) => {
    onChange(
      values.map((value, i) => {
        if (i !== index) return value;
        return {
          ...value,
          values: updatedValues.map((updatedValue) => ({
            id: updatedValue.key,
            value: updatedValue.label,
            selected: updatedValue.selected,
          })),
        };
      }),
    );
  };

  return (
    <ListContainer>
      {values.map((value, index) => {
        switch (value.kind) {
          case 'Checkbox':
            return (
              <React.Fragment key={value.id + value?.groupId}>
                {index > 0 && values[index - 1].kind != 'Checkbox' && <Separator />}
                <Tooltip
                  key={index}
                  content={value.value}
                  disabled={!visibleTooltips.includes(index)}
                >
                  <CheckboxContainer
                    className={value.disabled ? 'disabled' : ''}
                    onClick={() => !value.disabled && handleOnCheckboxChange(index)}
                  >
                    <Checkmark
                      className={`${value.checked && 'checked'} ${value.disabled && 'disabled'}`}
                    />
                    <Text
                      ref={(el) => {
                        if (el) checkboxRefs.current[index] = el;
                      }}
                      type={TextType.CAPTION_REGULAR}
                      ellipsis={true}
                      disableUserSelect={true}
                    >
                      {value.value}
                    </Text>
                  </CheckboxContainer>
                </Tooltip>
              </React.Fragment>
            );
          case 'Dropdown':
            return (
              <React.Fragment key={value.id}>
                {index > 0 &&
                  (values[index - 1].kind != 'Dropdown' ? (
                    <Separator />
                  ) : (
                    <Separator style={{ marginTop: '8px' }} />
                  ))}
                <Dropdown
                  onChange={(values) => handleOnDropdownOptionChange(index, values)}
                  values={value.values.map((value) => ({
                    key: value.id,
                    label: value.value,
                    selected: value.selected !== undefined ? value.selected : false,
                  }))}
                  disabled={value.disabled}
                  hasSuccessIcon={false}
                />
              </React.Fragment>
            );
        }
      })}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: initial !important;
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }
`;

const Checkmark = styled.div`
  display: block;
  position: relative;
  height: 16px;
  width: 16px;
  min-width: 16px;
  border: 1px solid #151515;
  border-radius: 2px;
  box-sizing: border-box;

  &.disabled {
    opacity: 0.5;
  }

  &.checked {
    &:after {
      content: '';
      position: absolute;
      border-bottom: 1.5px solid #151515;
      border-right: 1.5px solid #151515;
      width: 3px;
      height: 7px;
      top: 2px;
      left: 5px;
      transform: rotate(45);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
    }
  }
`;

const Separator = styled.div`
  margin-top: 6px;
`;

import { E_facturaRepository } from '../../domain/e-factura/E-facturaRepository';

type GetStatusAnafFacturaRepository = Pick<
  E_facturaRepository,
  'getStatusAnafFactura' | 'eFacturaStatuses'
>;

const getStatusAnafFacturaFromCacheUseCase = (
  repo: GetStatusAnafFacturaRepository,
  invoiceId: number,
) => {
  return repo.eFacturaStatuses.find((item) => item.invoiceId === invoiceId);
};

const getStatusAnafFacturaUseCase = (repo: GetStatusAnafFacturaRepository, invoiceId: number) => {
  return repo.getStatusAnafFactura(invoiceId);
};

export { getStatusAnafFacturaUseCase, getStatusAnafFacturaFromCacheUseCase };
export type { GetStatusAnafFacturaRepository };

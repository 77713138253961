import React, { useState } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbar = (props) => {
  const [shadowBottomOpacity, setShadowBottomOpacity] = useState(1);
  const [shadowDisplay, setshadowDisplay] = useState('block');
  const [scrollBarDisplay, setScrollBarDisplay] = useState('none');

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const bottomScrollTop = scrollHeight - clientHeight;
    const opacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    if (opacity == 0) {
      setshadowDisplay('none');
    } else {
      setshadowDisplay('block');
    }
    setShadowBottomOpacity(opacity);
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      background:
        'linear-gradient(180deg, rgba(24, 160, 251, 0.53) 22.5%, rgba(197, 50, 182, 0.12) 91.25%)',
      height: '40px',
      width: '8px',
      borderRadius: '8px',
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderTrack = ({ style, ...props }) => {
    const trackStyle = {
      right: '12px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
      display: scrollBarDisplay,
    };
    return <div style={{ ...style, ...trackStyle }} {...props} />;
  };

  const handleScrollStart = () => {
    setScrollBarDisplay('inline-block');
  };

  const handleScrollStop = () => {
    setScrollBarDisplay('none');
  };

  return (
    <ContainerStyle>
      <Scrollbars
        onScrollStart={handleScrollStart}
        onScrollStop={handleScrollStop}
        renderTrackHorizontal={renderTrack}
        renderTrackVertical={renderTrack}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        onUpdate={handleUpdate}
        {...props}
        className={'scrollbar'}
      />
      <ShadowBottomStyle
        className='shadowbottom'
        style={{
          opacity: shadowBottomOpacity,
          display: shadowDisplay,
        }}
      />
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  position: relative;
  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .scrollbar > div:first-child::-webkit-scrollbar {
    display: none;
  }
  .scrollbar > div:first-child {
    margin-bottom: 0px !important;
    margin-right: 0px !important;
    max-height: auto !important;
    min-height: auto !important;
  }
`;
const ShadowBottomStyle = styled.div`
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 34px;
  background: linear-gradient(360deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 100%);
`;

export default CustomScrollbar;

function getCountiesService(): Promise<Array<{ auto: string; nume: string }>> {
  return fetch('https://roloca.coldfuse.io/judete', {
    method: 'GET',
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
}

export { getCountiesService };

import './App.css';
import * as React from 'react';
import { CombinedPropsType } from './App';
import { DashboardProvider } from './dashboard/DashboardProvider';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';

import Page404 from './alert/Page404';
import Page403 from './alert/Page403';

//StartPrivateContent links
import StartPrivateContent from './welcome/StartPrivateContent';
import Home from './welcome/Home';
import SignUp from './welcome/signUpFlow/SignUp';
import CheckEmail from './welcome/signUpFlow/CheckEmail';
import SetPassword from './welcome/signUpFlow/SetPassword';
import Login from './welcome/signUpFlow/Login';
import ResetPassword from './welcome/signUpFlow/ResetPassword';

//PrivateContent links
import Lobby from './lobby/Lobby';
import Dashboard from './dashboard/Dashboard';
import CaseDetailComponent from './case/CaseDetailComponent';
import WorktypeItem from './settings/worktypes/worktypeitem/WorktypeItem';
import Phases from './settings/phases/Phases';
import WorkTypes from './settings/worktypes/WorkTypes';
import Reports from './reports/Reports';
import ReportsOverview from './reports/ReportsOverview';
import UserManagement from './user-management/UserManagement';
import InvoiceList from './invoice/InvoiceList';
import InvoiceDetail from './invoice/InvoiceDetail';
import MyCompanyData from './user-management/MyCompanyData';
import Plans from './lobby/createLabor/Plans';
import FTISteps from '../presentation/welcome/signUpFlow/FTISteps';

import { Notifications } from './Notifications';
import { GlobalProvider3 } from './GlobalProvider3';
import { useCaseRepositoryImplementation } from '../data/cases/CaseRepositoryImplementation';
import { useWorkTypeRepositoryImplementation } from '../data/worktype/WorkTypeRepositoryImplementation';
import { useExtensionRepositoryImplementation } from '../data/worktype/extension/ExtensionRepositoryImplementation';
import { useAppComponentViewModel } from './AppComponentViewModel';
import { useTemplateRepositoryImplementation } from '../data/worktype/templates/TemplateRepositoryImplementation';
import { usePhaseRepositoryImplementation } from '../data/phase/phasesRepositoryImplementation';
import {
  useContactsRepositoryImplementation,
  useCompaniesRepositoryImplementation,
} from '../data/user-management/UsersRepositoryImplementation';

import PrivateContent from './PrivateContent';
import { lobbyRepositoryImplementation } from '../data/lobby/lobbyRepositoryImplementation';
import { useLoggedUserRepositoryImplementation } from '../data/loggedUser/loggedUserRepositoryImplementation';
import { useLobbyViewModel } from './lobby/LobbyViewModel';
import { featuresRepositoryImplementation } from '../data/features/FeaturesRepositoryImplementation';
import { useCompany } from './hooks/useCompany';
import { useEnabledRouteViewModel } from './EnabledRouteViewModel';
import { useDeviceParameters } from './hooks/useDeviceParameters';
import { AddNewComponents } from './case/mobile/MobileAddNewComponentProvider';
import { useTranslation } from 'react-i18next';
import MyProfile from './user-management/MyProfile';
import { MenuType } from './ui-model/menu/MenuUImodel';
import Billing from './billing/Billing';
import ManageSubscription from './billing/ManageSubscription';
import { useBillingRepositoryImplementation } from '../data/billing/billingRepositoryImplementation';
import { useInvoiceRepositoryImplementation } from '../data/invoice/InvoiceRepositoryImplementation';
import E_facturaAccountant from './e-factura/E_facturaAccountant';
import E_facturaRedirectFromAnaf from './e-factura/E_facturaRedirectFromAnaf';
import { useE_facturaRepositoryImplementation } from '../data/e-factura/E_facturaRepositoryImplementation';
import { useCountiesRepositoryImplementation } from '../data/county/countiesRepositoryImplementation';

export function AppComponent(props: CombinedPropsType) {
  const caseRepo = useCaseRepositoryImplementation();
  const extensionsRepo = useExtensionRepositoryImplementation();
  const workTypeRepo = useWorkTypeRepositoryImplementation();
  const templateRepo = useTemplateRepositoryImplementation();
  const phaseRepo = usePhaseRepositoryImplementation();
  const contactsRepo = useContactsRepositoryImplementation();
  const companiesRepo = useCompaniesRepositoryImplementation();
  const lobbyRepo = lobbyRepositoryImplementation();
  const billingRepo = useBillingRepositoryImplementation();
  const invoiceRepo = useInvoiceRepositoryImplementation();
  const e_factuarRepository = useE_facturaRepositoryImplementation();
  const countiesRepository = useCountiesRepositoryImplementation();

  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { isMobile, isTabletLarge, isTabletSmall } = useDeviceParameters();

  const [comingUrl, setCommingUrl] = React.useState<string | null>(null);
  const [device, setDevice] = React.useState<MenuType>(MenuType.DESKTOP);

  const [isEfacturaAccountRoute, setIsEfacturaAccountRoute] = React.useState<boolean>(false);

  const { fetchApplicationData, fetchApplicationDataAfterLogin } = useAppComponentViewModel(
    caseRepo,
    extensionsRepo,
    workTypeRepo,
    templateRepo,
    phaseRepo,
    contactsRepo,
    companiesRepo,
    lobbyRepo,
    billingRepo,
    invoiceRepo,
    e_factuarRepository,
    countiesRepository,
  );

  React.useEffect(() => {
    getLabDevice();
  }, [isMobile, isTabletLarge, isTabletSmall]);

  React.useEffect(() => {
    if (!props.isAuthenticated) {
      if (location.pathname !== '/' && !isAfterLoginUrl(location.pathname)) {
        setCommingUrl(location.pathname);
      }
    }
  }, []);

  React.useEffect(() => {
    if (props.isAuthenticated && props.companyId !== null) {
      fetchApplicationData();
    }
  }, [props.isAuthenticated, props.companyId]);

  React.useEffect(() => {
    if (props.isAuthenticated) {
      fetchApplicationDataAfterLogin();
    }
    if (props.isAuthenticated) {
      if (comingUrl !== null) {
        navigate(comingUrl);
        setCommingUrl(null);
      }
    }
  }, [props.isAuthenticated]);

  React.useEffect(() => {
    i18n.changeLanguage(props.lang !== null ? props.lang : undefined);
  }, [props.lang]);

  React.useEffect(() => {
    if (
      location.pathname.includes('/efactura') &&
      !isMobile &&
      !isTabletLarge &&
      !isTabletSmall &&
      !props.isAuthenticated
    ) {
      setIsEfacturaAccountRoute(true);
    } else {
      setIsEfacturaAccountRoute(false);
    }
  }, [location.pathname, props.isAuthenticated]);

  function isAfterLoginUrl(path: string): boolean {
    let check = false;
    const list = ['/login', '/signup', '/reset-password', '/checkemail', '/password'];
    list.forEach((item) => {
      if (path.startsWith(item)) {
        check = true;
        return;
      }
    });
    return check;
  }

  const getLabDevice = () => {
    if (isMobile) {
      setDevice(MenuType.MOBILE_LAB);
    } else if (isTabletLarge || isTabletSmall) {
      setDevice(MenuType.TABLET_LAB);
    } else {
      setDevice(MenuType.DESKTOP_LAB);
    }
  };

  return (
    <GlobalProvider3>
      <DashboardProvider>
        {isEfacturaAccountRoute && (
          <Routes>
            <Route path='/efactura/:hash' element={<E_facturaAccountant />} />
            <Route path='/efactura/registered' element={<E_facturaAccountant />} />
          </Routes>
        )}
        {!isEfacturaAccountRoute && (
          <>
            {props.isAuthenticated ? (
              <Routes>
                <Route
                  path='/'
                  element={
                    !props.isFtiFinished ? (
                      <FTISteps />
                    ) : (
                      <PrivateContent>
                        <Lobby />
                      </PrivateContent>
                    )
                  }
                />
                {!isMobile && !isTabletLarge && !isTabletSmall && (
                  <Route path='/plans' element={<Plans />} />
                )}
                {props.companyId === null && (
                  <Route
                    path='/myprofile'
                    element={
                      <PrivateContent>
                        <MyProfile />
                      </PrivateContent>
                    }
                  />
                )}
                <Route path='/efactura/registered' element={<E_facturaRedirectFromAnaf />} />
                <Route
                  path='/:companyId/*'
                  element={
                    <CheckValidCompanyId isAuthenticated={props.isAuthenticated}>
                      <>
                        <Routes>
                          <Route
                            element={
                              <PrivateContent>
                                <Outlet />
                              </PrivateContent>
                            }
                          >
                            <Route path='/myprofile' element={<MyProfile />} />
                            <Route
                              path='/'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path=''
                                  deviceType={device}
                                >
                                  <Dashboard />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/case/:id/*'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/case/:id/*'
                                  deviceType={device}
                                >
                                  <CaseDetailComponent />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/case/:id/newComponent/:componentType'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/case/:id/newComponent/:componentType'
                                  deviceType={device}
                                >
                                  <AddNewComponents />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/worktype'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/worktype'
                                  deviceType={device}
                                >
                                  <WorkTypes />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/phases'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/phases'
                                  deviceType={device}
                                >
                                  <Phases />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/worktype/:id/*'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/worktype'
                                  deviceType={device}
                                >
                                  <WorktypeItem />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/users'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/users'
                                  deviceType={device}
                                >
                                  <UserManagement />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/users/:id'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/users'
                                  deviceType={device}
                                >
                                  <UserManagement />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/mycompany'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/mycompany'
                                  deviceType={device}
                                >
                                  <MyCompanyData />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/billing'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/billing'
                                  deviceType={device}
                                >
                                  <Billing />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/settings/billing/manage-subscription'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/settings/billing'
                                  deviceType={device}
                                >
                                  <ManageSubscription />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/reports'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/reports'
                                  deviceType={device}
                                >
                                  <Reports />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/reports/reportsoverview'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/reports/reportsoverview'
                                  deviceType={device}
                                >
                                  <ReportsOverview />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/invoice'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/invoice'
                                  deviceType={device}
                                >
                                  <InvoiceList />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route
                              path='/invoice/:invoiceId'
                              element={
                                <CheckEnabledRoute
                                  isAuthenticated={props.isAuthenticated}
                                  companyId={props.companyId}
                                  path='/invoice'
                                  deviceType={device}
                                >
                                  <InvoiceDetail />
                                </CheckEnabledRoute>
                              }
                            />
                            <Route path='*' element={<Page404 {...props} />} />
                          </Route>
                        </Routes>
                      </>
                    </CheckValidCompanyId>
                  }
                />
                <Route
                  path='*'
                  element={
                    <PrivateContent>
                      <Page404 {...props} />
                    </PrivateContent>
                  }
                />
              </Routes>
            ) : isMobile ? (
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/checkemail' element={<CheckEmail />} />
                <Route path='/password/set/:userIdentifier' element={<SetPassword />} />
                <Route path='/password/recover/:userIdentifier' element={<SetPassword />} />
                <Route
                  element={
                    <StartPrivateContent>
                      <Outlet />
                    </StartPrivateContent>
                  }
                >
                  <Route path='/' element={<Home />} />
                  <Route path='*' element={<Page404 {...props} />} />
                </Route>
              </Routes>
            ) : (
              <StartPrivateContent>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/signup' element={<SignUp />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  <Route path='/checkemail' element={<CheckEmail />} />
                  <Route path='/password/set/:userIdentifier' element={<SetPassword />} />
                  <Route path='/password/recover/:userIdentifier' element={<SetPassword />} />
                  <Route path='*' element={<Page404 {...props} />} />
                </Routes>
              </StartPrivateContent>
            )}
          </>
        )}
        <Notifications />
      </DashboardProvider>
    </GlobalProvider3>
  );
}

const CheckValidCompanyId = (props: { children: JSX.Element; isAuthenticated: boolean }) => {
  const { companyId } = useParams();

  const repo = lobbyRepositoryImplementation();
  const loggedUserRepo = useLoggedUserRepositoryImplementation();
  const contactRepo = useContactsRepositoryImplementation();
  const { lobbyCompanies, getLobbyCompanies, lobbyCompaniesIsLoading } = useLobbyViewModel(
    repo,
    loggedUserRepo,
    contactRepo,
  );

  React.useEffect(() => {
    getLobbyCompanies();
  }, [loggedUserRepo.session, loggedUserRepo.userId, companyId]);

  if (lobbyCompanies.some((comp) => comp.companyId === Number(companyId))) {
    return props.children;
  }
  return !lobbyCompaniesIsLoading ? (
    <PrivateContent>
      <Page404 isAuthenticated={props.isAuthenticated} />
    </PrivateContent>
  ) : (
    <div></div>
  );
};

const CheckEnabledRoute = (props: {
  children: JSX.Element;
  isAuthenticated: boolean;
  companyId: number | null;
  path: string;
  deviceType: MenuType;
}) => {
  const currentCompany = useCompany();

  const featureRepo = featuresRepositoryImplementation();
  const loggedUserRepository = useLoggedUserRepositoryImplementation();

  const { isEnabledRoute, validSubscription } = useEnabledRouteViewModel(
    featureRepo,
    loggedUserRepository,
  );

  const [isEnabledFeature, setIsEnabledFeature] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    if (currentCompany.userRole !== null) {
      setIsEnabledFeature(
        isEnabledRoute(currentCompany.userRole, props.path, props.deviceType, validSubscription),
      );
    }
  }, [props.companyId, currentCompany.userRole, validSubscription]);

  if (
    currentCompany.userRole !== null &&
    isEnabledRoute(currentCompany.userRole, props.path, props.deviceType, validSubscription)
  ) {
    return props.children;
  }
  return isEnabledFeature !== null ? <Page403 /> : <div></div>;
};

export default AppComponent;

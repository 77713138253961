import { ReportRepository } from '../../domain/reports/ReportRepository';
import { ReportCasesRequest } from '../../domain/reports/Report';

type GetReportCasesUseCase = Pick<ReportRepository, 'getReportCases' | 'reportCases'>;

const getReportCasesUseCase = (repo: GetReportCasesUseCase, params?: ReportCasesRequest) => {
  return repo.getReportCases(params);
};

export { getReportCasesUseCase };

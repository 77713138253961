import { GoogleRepository } from '../../domain/google/GoogleRepository';

type CaptchaUseCaseRepo = Pick<GoogleRepository, 'captchaValidation' | 'getCaptchaSiteKey'>;

const getCaptchaSiteKeyUseCase = (repo: CaptchaUseCaseRepo) => {
  return repo.getCaptchaSiteKey();
};

const captchaValidationUseCase = (
  repo: CaptchaUseCaseRepo,
  captchaToken: string,
  hostName: string,
) => {
  return repo.captchaValidation(captchaToken, hostName);
};

export { getCaptchaSiteKeyUseCase, captchaValidationUseCase };

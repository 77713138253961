import React from 'react';
import { Text, TextColor, TextType } from '../Text';
import { ReactComponent as AddIcon } from '../../../../icons/add.svg';
import { OvalButtonCss } from './OvalButtonCss';
import styled from 'styled-components';

export type OvalButton = {
  onClick: () => void;
  text?: string;
};

export function OvalButton(p: OvalButton) {
  return (
    <OvalButtonContainer onClick={p.onClick}>
      <OvalButtonCss>
        <Text type={TextType.BODY_2_BOLD} clr={TextColor.BLACK}>
          {p.text}
        </Text>
        <AddIconStyled />
      </OvalButtonCss>
    </OvalButtonContainer>
  );
}

const AddIconStyled = styled(AddIcon)`
  width: 8px;
  height: 8px;
`;

const OvalButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

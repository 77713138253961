import { checkErrorStatus } from '../ErrorHandling';
import {
  activateEfacturaByAccauntat,
  activateEfacturaByUser,
  checkActivationToken,
  generateEActivationToken,
  getStatusAnafFactura,
  refreshEToken,
  updateInvoiceWithAnafData,
  uploadEFactura,
} from './E-facturaService';
import * as actionTypes from './E_facturaActionTypes';

const saveEfacturaAuthCodeAction = (code: string) => (dispatch: any) => {
  window.localStorage.setItem('eCode', code);
  dispatch({ type: actionTypes.SAVE_ANAF_AUTH_RESPONSE_CODE, code: code });
};
const saveEfacturaAuthErrorAction = (error: string) => (dispatch: any) => {
  window.localStorage.setItem('eError', error);
  dispatch({ type: actionTypes.SAVE_ANAF_AUTH_RESPONSE_ERROR, error: error });
};

const resetEfacturaAuthValuesAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.ANAF_AUTH_VALUE_DELETE });
};

const activateEfacturaByAccauntatAction =
  (activationToken: string, eCode: string) => (dispatch: any) => {
    dispatch({ type: actionTypes.ACTIVATE_E_FACTURA });
    return activateEfacturaByAccauntat(activationToken, eCode).then((resp) => {
      if (checkErrorStatus(resp.code, dispatch)) {
        dispatch({ type: actionTypes.ACTIVATE_E_FACTURA_SUCCESS });
        return resp.data;
      } else {
        dispatch({ type: actionTypes.ACTIVATE_E_FACTURA_FAILD, error: resp.code });
        return null;
      }
    });
  };

const generateEActivationTokenAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_ACTIVATION_TOKEN });
  return generateEActivationToken().then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.GET_ACTIVATION_TOKEN_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_ACTIVATION_TOKEN_FAILD, error: resp.code });
      return null;
    }
  });
};

const activateEfacturaByUserAction = (code: string) => (dispatch: any) => {
  dispatch({ type: actionTypes.ACTIVATE_E_FACTURA });
  return activateEfacturaByUser(code).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.ACTIVATE_E_FACTURA_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.ACTIVATE_E_FACTURA_FAILD, error: resp.code });
      return false;
    }
  });
};

const refreshETokenAction = () => (dispatch: any) => {
  dispatch({ type: actionTypes.REFRESH_ACCESS_TOKEN });
  return refreshEToken().then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.REFRESH_ACCESS_TOKEN_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.REFRESH_ACCESS_TOKEN_FAILD, error: resp.code });
      return false;
    }
  });
};

const checkActivationTokenAction =
  (param: { companyId: number; token: string }) => (dispatch: any) => {
    dispatch({ type: actionTypes.CHECK_ACTIVATION_TOKEN });
    return checkActivationToken(param).then((resp) => {
      if (checkErrorStatus(resp.code, dispatch)) {
        dispatch({ type: actionTypes.CHECK_ACTIVATION_TOKEN_SUCCESS });
        return resp.data;
      } else {
        dispatch({ type: actionTypes.CHECK_ACTIVATION_TOKEN_FAILD, error: resp.code });
        return null;
      }
    });
  };

const updateInvoiceWithAnafDataAction =
  (param: { invoiceId: number; onlineInvoiceId: string; onlineStatus: number }) =>
  (dispatch: any) => {
    dispatch({ type: actionTypes.UPDATE_INVOICE_WITH_ANAF_DATA });
    return updateInvoiceWithAnafData(param).then((resp) => {
      if (checkErrorStatus(resp.code, dispatch)) {
        dispatch({ type: actionTypes.UPDATE_INVOICE_WITH_ANAF_DATA_SUCCESS });
        return resp.data;
      } else {
        dispatch({ type: actionTypes.UPDATE_INVOICE_WITH_ANAF_DATA_FAILD, error: resp.code });
        return null;
      }
    });
  };

const uploadEFacturaAction = (invoiceId: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.UPLOAD_E_FACTURA });
  return uploadEFactura(invoiceId).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({ type: actionTypes.UPLOAD_E_FACTURA_SUCCESS });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.UPLOAD_E_FACTURA_FAILD, error: resp.code });
      return false;
    }
  });
};

const getStatusAnafFacturaAction = (invoiceId: number) => (dispatch: any) => {
  dispatch({ type: actionTypes.GET_E_FACTURA_STATUS });
  return getStatusAnafFactura(invoiceId).then((resp) => {
    if (checkErrorStatus(resp.code, dispatch)) {
      dispatch({
        type: actionTypes.GET_E_FACTURA_STATUS_SUCCESS,
        invoiceId: invoiceId,
        status: resp.data,
      });
      return resp.data;
    } else {
      dispatch({ type: actionTypes.GET_E_FACTURA_STATUS_FAILD, error: resp.code });
      return null;
    }
  });
};

export {
  saveEfacturaAuthCodeAction,
  saveEfacturaAuthErrorAction,
  resetEfacturaAuthValuesAction,
  activateEfacturaByAccauntatAction,
  generateEActivationTokenAction,
  activateEfacturaByUserAction,
  updateInvoiceWithAnafDataAction,
  checkActivationTokenAction,
  refreshETokenAction,
  uploadEFacturaAction,
  getStatusAnafFacturaAction,
};

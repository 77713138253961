import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type RowProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Row = (props: RowProps) => {
  return <RowStyled {...props}>{props.children}</RowStyled>;
};

const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default Row;

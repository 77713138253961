import { ReactComponent as FinishedIcon } from '../../icons/states/finished.svg';
import React, { ReactElement } from 'react';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import { ACTIVE, CLOSED, DRAFT, INVOICED } from './caseStates';

export const CaseStates: Array<CaseState> = [
  { id: DRAFT, name: LANGUAGE_KEYS.DRAFT, icon: <FinishedIcon /> },
  { id: ACTIVE, name: LANGUAGE_KEYS.ACTIVE, icon: <FinishedIcon /> },
  { id: INVOICED, name: LANGUAGE_KEYS.INVOICED, icon: <FinishedIcon /> },
  { id: CLOSED, name: LANGUAGE_KEYS.CLOSED, icon: <FinishedIcon /> },
];

export type CaseState = DraftCase | ActiveCase | InvoicedCase | ClosedCase;

type DraftCase = { id: 0; name: string; icon?: ReactElement };
type ActiveCase = { id: 1; name: string; icon?: ReactElement };
type InvoicedCase = { id: 2; name: string; icon?: ReactElement };
type ClosedCase = { id: 3; name: string; icon?: ReactElement };

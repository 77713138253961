import { FeaturesRepository } from '../../domain/features/FeaturesRepository';
import { featuresList } from '../../domain/features/Features';

const featuresRepositoryImplementation = (): FeaturesRepository => {
  const getEnabledFeaturesBeforeLab = (subscription: number) => {
    return featuresList.map((feature) => {
      return {
        featureId: feature.featureId,
        enabled: feature.subscription === subscription,
      };
    });
  };

  const getEnabledFeatures = (userRole: number, subscription: number) => {
    return featuresList.map((feature) => {
      return {
        featureId: feature.featureId,
        enabled:
          feature.roles.some((role) => role === userRole) && feature.subscription === subscription,
      };
    });
  };

  return {
    getEnabledFeaturesBeforeLab,
    getEnabledFeatures,
  };
};

export { featuresRepositoryImplementation };

import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type ColumnProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Column = (props: ColumnProps) => {
  return <ColumnStyled {...props}>{props.children}</ColumnStyled>;
};

const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Column;

import React from 'react';
import styled from 'styled-components';
import { CaseWorkSheetToothPanoramaComp } from './CaseWorkSheetToothPanorama';
import { ToothConnectionPanorama } from './CaseWorkSheetToothConnectionPanorama';
import { ToothPanoramaProvider } from './ToothPanoramaProvider';

export function CaseWorkSheetToothPanoramaComponent() {
  return (
    <CaseWorkSheetToothAndConnectionsPanorama>
      {' '}
      <ToothPanoramaProvider>
        {/* ---------------------------------------------------------------------------------------- TEETH CONTAINER ---------------------------------------------------------------------------------------- */}
        <CaseWorkSheetToothPanoramaComp />

        {/* ---------------------------------------------------------------------------------------- TEETH CONNECTIONS CONTAINER ---------------------------------------------------------------------------------------- */}
        <ToothConnectionPanorama />
      </ToothPanoramaProvider>
    </CaseWorkSheetToothAndConnectionsPanorama>
  );
}

const CaseWorkSheetToothAndConnectionsPanorama = styled.div`
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 640px;
  height: 260px;
  margin-bottom: -24px;

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const CaseWorkSheetToothPanoramaRow = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 592px;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
`;

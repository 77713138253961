import React, { FC } from 'react';
import { Text, TextColor, TextType } from '../components/dsm/Text';
import { LabWorkCardComponent } from './LabWorkCardComponent';
import styled from 'styled-components';
import { DashboardUiModel } from '../ui-model/DashboardUiModel';
import { ReactComponent as FilledFlag } from '../../icons/filledFlag.svg';
import useTranslate from '../translations/useTranslate';
import { LANGUAGE_KEYS } from '../translations/languageKeys';

export type DashboardCardComponentProps = {
  caseModel: DashboardUiModel;
  onClick?: () => void;
};

export const DashboardCardComponent: FC<DashboardCardComponentProps> = (dashboardProps) => {
  const labWorks = createLabWorkItem(dashboardProps.caseModel);
  return (
    <DashboardCardContainer
      error={dashboardProps.caseModel?.labWork?.currentlyPhaseStatus}
      onClick={dashboardProps.onClick}
    >
      <ContentContainer>
        <FirstRowContainer>
          <NameContainer
            className={dashboardProps.caseModel?.clientName === null ? 'emptyDashboardField' : ''}
          >
            <Text type={TextType.BODY_2_BOLD} ellipsis={true}>
              {dashboardProps.caseModel?.clientName}
            </Text>
          </NameContainer>
          <IDContainer>
            <Text type={TextType.BODY_2}>{dashboardProps.caseModel?.id}</Text>
          </IDContainer>
        </FirstRowContainer>
        <SecondRowContainer>
          <PatientNameConatiner
            className={dashboardProps.caseModel?.patientName === null ? 'emptyDashboardField' : ''}
          >
            <Text type={TextType.ROBOTO_400_10_14}>{dashboardProps.caseModel?.patientName}</Text>
          </PatientNameConatiner>
        </SecondRowContainer>
      </ContentContainer>
      <LabWorksContentContainer>{labWorks}</LabWorksContentContainer>
    </DashboardCardContainer>
  );
};

const getEmptyProgresView = () => {
  const emptyProgres: any[] = [];
  for (let i = 0; i < 9; i++) {
    emptyProgres.push(<ProgressIcon key={i} className='emptyDashboardField' />);
  }
  return emptyProgres;
};

function createLabWorkItem(caseModel: DashboardUiModel) {
  const translate = useTranslate();
  return (
    <>
      {caseModel?.labWork !== undefined ? (
        <LabWorksContentContainer>
          <LabWorkCardComponent
            labWork={caseModel.labWork}
            time={caseModel.expireTime}
            leftEdge={caseModel.labWork.phaseEdgeLeft}
            rightEdge={caseModel.labWork.phaseEdgeRight}
          />
          <ProgressContainer>
            {caseModel?.labWork.allPhaseStatus?.map((phase) => {
              if (phase !== undefined) {
                return phase.icon ? (
                  <FlagIcon key={phase.index} className={phase.status} />
                ) : (
                  <ProgressIcon
                    key={phase.index}
                    className={`${phase.current && 'Current'}  ${phase.status}`}
                  />
                );
              }
            })}
          </ProgressContainer>
          <SeeMoreText type={TextType.ROBOTO_400_10_14} clr={TextColor.PRIMARY}>
            {caseModel.labWorksSize !== undefined && caseModel.labWorksSize > 1
              ? `+ ${caseModel.labWorksSize - 1} ` + translate(LANGUAGE_KEYS.DASHBOAR_LABWORKS)
              : translate(LANGUAGE_KEYS.GENERIC_SEE_MORE)}
          </SeeMoreText>
        </LabWorksContentContainer>
      ) : (
        <LabWorksContentContainer>
          <LabWorkCardComponent labWork={null} time={null} leftEdge={false} rightEdge={true} />
          <ProgressContainer>{getEmptyProgresView()}</ProgressContainer>
          <SeeMoreText
            className='emptyDashboardField'
            type={TextType.ROBOTO_400_10_14}
            clr={TextColor.PRIMARY}
          ></SeeMoreText>
        </LabWorksContentContainer>
      )}
    </>
  );
}

const DashboardCardContainer = styled.div<{ error?: string }>`
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 14px 14px 10px 14px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 3px;
  display: flex;
  gap: 16px;
  min-height: 184px;
  @media (max-width: 600px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 601px) {
    flex: 0 0 calc(50% - ((20 / 2) * 1px));
    max-width: calc(50% - ((20 / 2) * 1px));
  }

  @media (min-width: 961px) {
    flex: 0 0 calc(33.333% - ((20 / 3) * 2px));
    max-width: calc(33.333% - ((20 / 3) * 2px));
  }

  @media (min-width: 1025px) {
    flex: 0 0 calc(25% - ((20 / 4) * 3px));
    max-width: calc(25% - ((20 / 4) * 3px));
  }

  @media (min-width: 1441px) {
    flex: 0 0 calc(20% - ((20 / 5) * 4px));
    max-width: calc(20% - ((20 / 5) * 4px));
  }

  &:hover {
    border: ${(props) =>
      props.error === 'Error' ? '2px solid var(--dts_red)' : '2px solid var(--dts_default_blue)'};
  }
`;

const BaseContainer = styled.div`
  display: flex;
  padding: 0;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`;

const NameContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  gap: 4px;
  order: 0;
  &.emptyDashboardField {
    flex-grow: 1;
  }
`;

const IDContainer = styled.div`
  padding: 0 4px 0 4px;
`;

const RowContainer = styled(BaseContainer)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ContentContainer = styled(BaseContainer)`
  flex-direction: column;
  align-items: flex-start;
  order: 0;
  min-height: 34px;
`;

const LabWorksContentContainer = styled(ContentContainer)`
  flex-direction: column;
  gap: 3px;
`;

const FirstRowContainer = styled(RowContainer)`
  gap: 4px;
  order: 0;
`;

const SecondRowContainer = styled(RowContainer)`
  gap: 4px;
  order: 1;
  justify-content: normal;
`;

const PatientNameConatiner = styled.div`
  &.emptyDashboardField {
    margin-top: 2px;
    min-width: 84px;
    min-height: 12px;
  }
`;

const SeeMoreText = styled(Text)`
  line-height: 16px;
  &.emptyDashboardField {
    min-width: 80px;
    min-height: 16px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  width: 100%;
`;

const ProgressIcon = styled.span`
  border-radius: 1.5px;
  flex: 1;
  background: var(--dts_light_grey);
  border: 1.5px solid var(--dts_light_grey);
  &.Started {
    background: var(--phase-started-background-color);
    border: 1.5px solid var(--phase-started-background-color);
  }
  &.Error {
    background: var(--phase-error-background-color);
    border: 1.5px solid var(--phase-error-background-color);
  }
  &.Finished {
    background: var(--phase-finished-background-color);
    border: 1.5px solid var(--phase-finished-background-color);
  }
  &.Current {
    border-radius: 2px;
  }
  &.Current.Started {
    opacity: 0.5;
    border: 2px solid var(--phase-started-background-color);
  }
  &.Current.Error {
    border: 2px solid var(--phase-error-background-color);
  }
  &.Current.Finished {
    border: 2px solid var(--phase-finished-background-color);
  }
  &.emptyDashboardField {
    border: 1.5px solid var(--dts_withe_background);
    background: var(--dts_withe_background);
  }
`;

const FlagIcon = styled(FilledFlag)`
  width: 6px;
  height: 6px;
  flex-shrink: 1;
  fill: var(--dts_light_grey);
  &.Started {
    fill: var(--phase-started-background-color);
  }
  &.Error {
    fill: var(--phase-error-background-color);
  }
  &.Finished {
    fill: var(--phase-finished-background-color);
  }
`;

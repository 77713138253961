import { Button } from '../components/dsm/buttons/Button';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext3 } from '../GlobalProvider3';
import { CaseDetailContext } from './CaseDetailProvider';
import { useCaseDetailFooterPopUpViewModel } from './CaseDetailFooterPopUpViewModel';
import { DRAFT } from './caseStates';
import * as actionTypes from '../../data/cases/casesActionTypes';
import { useDispatch } from 'react-redux';
import { featuresRepositoryImplementation } from '../../data/features/FeaturesRepositoryImplementation';
import { useCompany } from '../hooks/useCompany';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { useAlertManagerImplementation } from '../../data/alert/AlertManagerImplementation';
import { useAlertViewModel } from '../alert/AlertViewModel';

export function CaseDetailFooterPopUp() {
  const translate = useTranslate();
  const [newMessage, setNewMessage] = useContext(GlobalContext3);
  const { caseDetailObj, repo: caseRepo, validSubscription } = useContext(CaseDetailContext);
  const featureRepo = featuresRepositoryImplementation();

  const { error, createCase, isEnabledPublichAction } = useCaseDetailFooterPopUpViewModel(
    caseRepo,
    featureRepo,
  );
  const currentCompany = useCompany();

  const alertManager = useAlertManagerImplementation();
  const { showPaymentExpireModal } = useAlertViewModel(alertManager);

  function setMess(title: string, description: string, success: string) {
    setNewMessage([
      ...newMessage,
      {
        showFlash: true, // whether or not to return html or null
        title: title, // The message within the notification
        message: description, // The message within the notification
        status: success, // Success or error notification type
      },
    ]);
  }

  const dispatch = useDispatch();

  function showMessageIfNeded() {
    // console.log('TIME TO SHOW status ' + error);
    if (error === 1) {
      console.log('TIME TO SHOW A NOTY');
      dispatch({ type: actionTypes.UPDATE_CASE });
      setMess('Mindenk ok', 'Mondom minden pok', '');
    } else if (error < 0) {
      console.log('TIME TO SHOW A NOTY that something wnt wrong');
      dispatch({ type: actionTypes.UPDATE_CASE }); // TODO not the best action type
      setMess('Nem ok: ' + error, 'Mondom valami nem pok', '');
    }
  }

  if (
    caseDetailObj.status === DRAFT &&
    currentCompany.userRole !== null &&
    isEnabledPublichAction(currentCompany.userRole)
  ) {
    showMessageIfNeded();

    return (
      <CaseDetailFooterPopUpContainer>
        <Content>
          <Buttons>
            <Button
              onClick={() => {
                if (validSubscription) {
                  createCase(caseDetailObj);
                } else {
                  showPaymentExpireModal(currentCompany.userRole);
                }
              }}
              style={{ minWidth: '120px', padding: '10px 40px' }}
            >
              {translate(LANGUAGE_KEYS.PUBLISH)}
            </Button>
          </Buttons>
        </Content>
      </CaseDetailFooterPopUpContainer>
    );
  } else {
    return null;
  }
}

const CaseDetailFooterPopUpContainer = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 1px;
  position: absolute;
  width: 100%;
  height: 85px;
  right: 0;
  bottom: 0;
  z-index: 190;
  /* Dark gray */
  border: 1px solid var(--dts_light_grey);
  /* shadow */
  filter: drop-shadow(-1px 2px 11px rgba(0, 0, 0, 0.14));
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;
  height: 36px;
  /* DTS_White */
  background: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 16px;

  // width: 392px;
  // height: 36px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;

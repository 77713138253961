import { InvoiceRepository } from '../../domain/invoice/InvoiceRepository';

type CheckInvoiceNumberUseCaseRepo = Pick<InvoiceRepository, 'checkInvoiceNumber'>;

const checkInvoiceNumberUseCase = (
  repo: CheckInvoiceNumberUseCaseRepo,
  invoicePrefix: string,
  invoiceNumber: number,
) => {
  return repo.checkInvoiceNumber(invoicePrefix, invoiceNumber);
};

export { checkInvoiceNumberUseCase };

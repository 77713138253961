import { AlertManager, AlertType } from '../../domain/alert/AlertManager';

const useAlertViewModel = (manager: AlertManager) => {
  const showAlert = (message: string, type: AlertType) => {
    manager.showAlert(message, type);
  };

  const hideAlert = () => {
    manager.hideAlert();
  };

  const showPaymentExpireModal = (userRole: number | null) => {
    manager.showPaymentExpireModal(AlertType.EXPIRE_PAYMENT, userRole);
  };

  const hidePaymentExpireModal = () => {
    manager.hidePaymentExpireModal();
  };

  return { showAlert, hideAlert, showPaymentExpireModal, hidePaymentExpireModal };
};

export { useAlertViewModel };

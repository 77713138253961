import React from 'react';
import styled, { keyframes } from 'styled-components';

interface ToothParams {
  teeth: number;
  quarter: number;
  width: number;
  height: number;
  type: number;
  upper: boolean;
  opacity: string;
  selected: boolean;
  editable: boolean;
  verticalOffset: number;
  onClick: () => void;
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseEnter: () => void;
}

export function Tooth(p: ToothParams) {
  return (
    <ToothStyled className={`${p.editable && 'editable'}`} onClick={p.onClick}>
      {p.selected && (
        <Selectionindicator
          style={{
            left: p.width / 2 - 3 + 'px ',
            bottom: p.upper ? '' : p.verticalOffset + 'px',
            top: p.upper ? p.verticalOffset + 'px' : '',
          }}
        />
      )}

      <img
        src={process.env.PUBLIC_URL + '/tooth_' + (p.upper ? 'u' : 'l') + '' + p.teeth + '.png'}
        width={p.width + 'px'}
        height={p.height + 'px'}
        draggable='false'
        onMouseDown={p.onMouseDown}
        onMouseUp={p.onMouseUp}
        onMouseEnter={p.onMouseEnter}
        style={{
          objectFit: 'cover',
          objectPosition: p.width * p.type * -1 + 'px 0px',
          transform: p.quarter === 1 || p.quarter === 4 ? '' : 'scaleX(-1)',
          opacity: p.opacity,
        }}
      />
    </ToothStyled>
  );
}

const ToothStyled = styled.div`
  position: relative;
  height: 97px;
  user-select: none;
  flex: none;
  flex-grow: 0;
  cursor: not-allowed;

  &.editable {
    cursor: pointer;
  }
`;

const pulse = keyframes`


0% {
    // transform: scale(0.9);
    box-shadow: 0 0 0 #18a0fb87, 0 0 0 #c532b61f, 0 0 0 rgba(0,0,0,0.7);
}

50% {
    // transform: scale(1);
    box-shadow: 0 0 0 #18a0fb87, 0 0 0 #c532b61f,  0 0 0 4px rgba(0,0,0,0);
}

100% {
    // transform: scale(0.9);
    box-shadow: 0 0 0 #18a0fb87, 0 0 0 #c532b61f,  0 0 0 0 rgba(0,0,0,0);
}`;

const Selectionindicator = styled.div`
  position: absolute;
  animation-name: ${pulse};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: #18a0fb;
  z-index: 1;
`;

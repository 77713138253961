import { WorkPhaseGroup } from '../../../domain/WorkPhaseGroup';

export class TemplatePhaseUiModel extends WorkPhaseGroup {
  copyId?: number;

  constructor(
    type: 0 | 1,
    order: number,
    id?: number,
    name?: string,
    status?: number,
    assigneeId?: number,
    date?: number,
    copyId?: number,
  ) {
    super(type, order, id, name, status, assigneeId, date);
    this.copyId = copyId;
  }
}

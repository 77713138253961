import React from 'react';
import { getWorkTypesUseCase } from '../useCases/worktype/getWorkTypesUseCase';
import { getExtensionsUseCase } from '../useCases/worktype/extension/getExtensionsUseCases';
import { getCasesUseCase } from '../useCases/case/getCasesUseCases';
import { CaseRepository } from '../domain/CaseRepository';
import { ExtensionRepository } from '../domain/worktype/extension/ExtensionRepository';
import { WorkTypeRepository } from '../domain/worktype/WorkTypeRepository';
import { TemplateRepository } from '../domain/worktype/templates/TemplateRepository';
import { getTemplatesUseCases } from '../useCases/worktype/templates/getTemplatesUseCases';
import { getPhasesUseCase } from '../useCases/worktype/phases/getPhasesUseCase';
import { PhaseRepository } from '../domain/PhaseRepository';
import { ContactsRepository, CompaniesRepository } from '../domain/user-management/UsersRepository';
import { getContactsUseCase } from '../useCases/user-management/getContactsUseCase';
import {
  getCompaniesUseCase,
  getMyCompanyDataUseCase,
} from '../useCases/user-management/getCompaniesUseCase';
import { LobbyRepository } from '../domain/lobby/LobbyRepository';
import { getLobbyCompaniesUseCase } from '../useCases/lobby/getLobbyCompaniesUseCase';
import { getCompanySubscriptionUseCase } from '../useCases/billing/getCompanySubscriptionUseCase';
import { BillingRepository } from '../domain/billing/BillingRepository';
import { getInvoiceUseCase } from '../useCases/invoice/getInvoiceUseCase';
import { InvoiceRepository } from '../domain/invoice/InvoiceRepository';
import { E_facturaRepository } from '../domain/e-factura/E-facturaRepository';
import { refreshETokenUseCase } from '../useCases/e-factura/newAuthUseCase';
import { CountiesRepository } from '../domain/county/CountyRepository';
import { getCounties } from '../useCases/getCountiesUseCase';

function useAppComponentViewModel(
  caseRepository: CaseRepository,
  extensionRepository: ExtensionRepository,
  workTypeRepo: WorkTypeRepository,
  templateRepo: TemplateRepository,
  phaseRepo: PhaseRepository,
  contactsRepo: ContactsRepository,
  companiesRepo: CompaniesRepository,
  lobbyRepo: LobbyRepository,
  billingRepo: BillingRepository,
  invoiceRepo: InvoiceRepository,
  e_facturaRepo: E_facturaRepository,
  countiesRepo: CountiesRepository,
) {
  const fetchApplicationDataAfterLogin = React.useCallback(function () {
    console.log('fetchApplicationDataAfterLogin');
    getLobbyCompaniesUseCase({ getLobbyCompanies: lobbyRepo.getLobbyCompanies });
    getCounties({ counties: countiesRepo.counties, getCounties: countiesRepo.getCounties });
  }, []);

  const fetchApplicationData = React.useCallback(function () {
    console.log('fetchApplicationData');
    getExtensionsUseCase({ getExtensions: extensionRepository.getExtensions });
    getPhasesUseCase({ getPhases: phaseRepo.getPhases, phases: phaseRepo.phases });
    getTemplatesUseCases({ getTemplates: templateRepo.getTemplates });
    getWorkTypesUseCase({
      getWorkTypes: workTypeRepo.getWorkTypes,
      workTypes: workTypeRepo.workTypes,
    });
    getCasesUseCase({ getCases: caseRepository.getCases, getCaseById: caseRepository.getCaseById });
    getContactsUseCase({ getContacts: contactsRepo.getContacts, contacts: contactsRepo.contacts });
    getCompaniesUseCase({
      getCompanies: companiesRepo.getCompanies,
      companies: companiesRepo.companies,
      getMyCompanyData: companiesRepo.getMyCompanyData,
    });
    getCompanySubscriptionUseCase({ getCompanySubscription: billingRepo.getCompanySubscription });
    getInvoiceUseCase({
      invoices: invoiceRepo.invoices,
      getInvoices: invoiceRepo.getInvoices,
      getInvoiceById: invoiceRepo.getInvoiceById,
    });
    refreshETokenUseCase(e_facturaRepo);
    getMyCompanyDataUseCase({
      getCompanies: companiesRepo.getCompanies,
      companies: companiesRepo.companies,
      getMyCompanyData: companiesRepo.getMyCompanyData,
    });
    getCounties({ counties: countiesRepo.counties, getCounties: countiesRepo.getCounties });
  }, []);

  return {
    lobbyCompanies: lobbyRepo.lobbyCompanies,
    fetchApplicationDataAfterLogin,
    fetchApplicationData,
  };
}

export { useAppComponentViewModel };

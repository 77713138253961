import React from 'react';
import { LoggedUserRepository } from '../../domain/loggedUser/loggedUserRepository';
import { UpdateUserData } from '../../domain/signUpFlow/UpdateUserData';
import { SignUpFlowRepository } from '../../domain/signUpFlow/SignUpFlowRepository';
import { updateUserUseCases } from '../../useCases/signUpFlow/updateUserUseCase';
import { resetPasswordUseCases } from '../../useCases/signUpFlow/resetPasswordUseCase';

function useMyProfileViewModel(repo: LoggedUserRepository, signUpRepo: SignUpFlowRepository) {
  const updateUser = React.useCallback(
    function (data: UpdateUserData) {
      return updateUserUseCases({ updateUser: signUpRepo.updateUser }, data);
    },
    [signUpRepo.updateUser],
  );

  const resetPassword = React.useCallback(
    function (username: string) {
      return resetPasswordUseCases({ resetPassword: signUpRepo.resetPassword }, username);
    },
    [signUpRepo.resetPassword],
  );

  return {
    userId: repo.userId,
    firstName: repo.firstName,
    lastName: repo.lastName,
    email: repo.email,
    profileColor: repo.profileColor,
    tellNumber: repo.tellNumber,
    lang: repo.lang,
    updateUser,
    resetPassword,
  };
}

export { useMyProfileViewModel };

import {
  ResponseActivateEfactura,
  ResponseCheckEActivationToken,
  ResponseEfacturaStatus,
  ResponseGenerateEActivationToken,
  ResponseUploadEfactura,
} from '../../domain/e-factura/E-factura';
import { BASE_URL } from '../Server';
import { useApiHeaderWithCompanyId } from '../../presentation/hooks/useApiHeaderWithCompanyId';
import { InvoiceUpdateResponse } from '../../domain/invoice/Invoice';

function activateEfacturaByAccauntat(
  activationToken: string,
  eCode: string,
): Promise<ResponseActivateEfactura> {
  return fetch(BASE_URL + `/activateEInvoiceWithToken?code=${eCode}&token=${activationToken}`, {
    method: 'POST',
    headers: {
      'Access-Control-Request-Headers': 'X-Custom-Header',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code: eCode, token: activationToken }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function generateEActivationToken(): Promise<ResponseGenerateEActivationToken> {
  return fetch(BASE_URL + `/generateEActivationToken`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function refreshEToken(): Promise<ResponseActivateEfactura> {
  return fetch(BASE_URL + `/refreshEToken`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function checkActivationToken(param: {
  companyId: number;
  token: string;
}): Promise<ResponseCheckEActivationToken> {
  return fetch(BASE_URL + `/checkETokenUrl`, {
    method: 'POST',
    headers: {
      'Access-Control-Request-Headers': 'X-Custom-Header',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function activateEfacturaByUser(code: string): Promise<ResponseActivateEfactura> {
  return fetch(BASE_URL + `/activateEInvoice?code=${code}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: false };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: false };
    });
}

function updateInvoiceWithAnafData(param: {
  invoiceId: number;
  onlineInvoiceId: string;
  onlineStatus: number;
}): Promise<InvoiceUpdateResponse> {
  return fetch(BASE_URL + `/updateInvoice`, {
    method: 'POST',
    headers: useApiHeaderWithCompanyId(),
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: null };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

function uploadEFactura(invoiceId: number): Promise<ResponseUploadEfactura> {
  return fetch(BASE_URL + `/sendInvoiceToAnaf/${invoiceId}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 401) {
        return { code: res.status, message: res.error, data: false };
      }
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: false };
    });
}

function getStatusAnafFactura(invoiceId: number): Promise<ResponseEfacturaStatus> {
  return fetch(BASE_URL + `/getEInvoiceStatus/${invoiceId}`, {
    method: 'GET',
    headers: useApiHeaderWithCompanyId(),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return { code: error.status, message: error, data: null };
    });
}

export {
  activateEfacturaByAccauntat,
  generateEActivationToken,
  activateEfacturaByUser,
  updateInvoiceWithAnafData,
  checkActivationToken,
  refreshEToken,
  uploadEFactura,
  getStatusAnafFactura,
};
